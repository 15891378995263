import { SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { Resource } from '@opentelemetry/resources';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import packageJson from '../../../package.json';
import { WEBUI_VERSION } from 'constants/App';
import { OPEN_TELEMETRY_TRACES_URL } from './constants/api';
import { trace } from '@opentelemetry/api';

function initOtlTracer() {
    const provider = new WebTracerProvider({
        resource: Resource.default().merge(
            new Resource({
                [SEMRESATTRS_SERVICE_NAME]: packageJson.name,
                [SEMRESATTRS_SERVICE_VERSION]: WEBUI_VERSION,
            }),
        ),
    });

    const exporterOptions = {
        url: OPEN_TELEMETRY_TRACES_URL,
        headers: {},
    };

    const traceExporter = new OTLPTraceExporter(exporterOptions);

    provider.addSpanProcessor(new BatchSpanProcessor(traceExporter));

    provider.register({
        contextManager: new ZoneContextManager(),
    });

    const getInstrumentations = () => {
        if (process.env.NODE_ENV === 'test') {
            return [];
        }

        return [new DocumentLoadInstrumentation()];
    };

    // Registering instrumentations
    registerInstrumentations({
        instrumentations: getInstrumentations(),
        tracerProvider: provider,
    });

    return trace.getTracer(packageJson.name);
}

export const tracer = initOtlTracer();
