import {
    APICALL_NAME,
    PAAS_ACTION,
    OPERATOR_NOT,
    TIME_NODE,
    IS_PM,
    OFFSET,
    OPERATOR_AND,
    OPERATOR_OR,
    TIMES_NODE,
    HOUR_NODE,
    BLOCK_WITH_NAME_SUB_BLOCK_VALUE,
    TARGET,
    EMAIL,
    MINUS_ONE_INT,
    SUBSCRIPTION,
    CUSTOM,
} from '../../../../../constants/MeshbotConstant';
import { convertAmPmOfParametersTo24HourFormat } from '../../../utils';
import { MESHBOT_TYPES } from '../../../EzloMeshbots/constants';
import {
    ENABLED,
    CATEGORY,
    IS_EDIT,
    NAME_BEFORE_EDITING,
    NOTIFICATION_TEMPLATE,
    NOTIFY_USERS,
} from '../../../../../constants/NotificationTemplates';
import { DEFAULT_BUCKET } from '../../../EzloHubBackups/constants';
import { ACCOUNT } from '../../../../Navigation/constants';
import { VALUE } from '../../../../../constants/DevicePluginGenerator';
import { JAVASCRIPT_TYPES } from '../../../../../constants/common/js_types';
import { ONE_INT } from 'constants/Variables';
import { FORCE_SEND_EMAIL_CAPABILITIES, PUSH_META } from 'constants/Notifications';
import { generateStructureForSubscriptionId, generateUniqueId } from '../../utils';
import hash from 'constants/uniqueHash';

export const getCategoryArrayWithoutIsEditKey = (notificationCategoriesList, uuid) => {
    return notificationCategoriesList.map((currentCategory) => {
        let newObj = {};
        Object.keys(currentCategory).forEach((key) => {
            if (currentCategory[key] && currentCategory.uuid === uuid) {
                newObj[key] = currentCategory[key];
            } else {
                newObj = returnCategoryThatWasEditedButNotSaved(currentCategory);
            }
        });

        [IS_EDIT, NAME_BEFORE_EDITING].forEach((e) => delete newObj[e]);

        return newObj;
    });
};

export const returnCategoryThatWasEditedButNotSaved = (currentCategory) => {
    return {
        ...currentCategory,
        display_name: currentCategory.nameBeforeEditing
            ? currentCategory.nameBeforeEditing
            : currentCategory.display_name,
    };
};

export const convertTriggersToRequestStructure = (triggers, optionType) => {
    if (!triggers.length) {
        return {
            name: 'manual',
            parameters: [],
        };
    }

    if (triggers.length) {
        const trigger = triggers.map((trigger) => {
            const { blocks, not } = trigger;
            const [block] = blocks;
            const { parameters, name } = block;

            const updatedParameters = convertAmPmOfParametersTo24HourFormat(
                parameters || [],
                TIMES_NODE,
                TIME_NODE,
                HOUR_NODE,
                OFFSET,
                IS_PM,
            );

            if (not) {
                const notParameter = {
                    name: OPERATOR_NOT,
                    parameters: [{ name, parameters: updatedParameters }],
                };

                return notParameter;
            }

            return { name, parameters: updatedParameters };
        });

        if (trigger.length === 1) {
            return trigger[0];
        } else {
            return optionType === OPERATOR_AND
                ? { name: OPERATOR_AND, parameters: trigger }
                : { name: OPERATOR_OR, parameters: trigger };
        }
    }

    return {};
};

/**
 * Modifies the notification structure to include or remove a push_meta parameter
 * based on the selectedNotificationCapability and force send email capabilities.
 *
 * @param {Object} notification - The original notification object that may be modified.
 * @param {string} selectedNotificationCapability - The capability that determines if the notification should be modified.
 * @returns {Object} The modified notification object with the added or removed push_meta parameter if applicable.
 */
export const getUpdatedNotificationRequestStructure = (notification, selectedNotificationCapability) => {
    const updatedNotification = { ...notification, parameters: [...(notification?.parameters || [])] };

    const capabilityMatches =
        selectedNotificationCapability && FORCE_SEND_EMAIL_CAPABILITIES?.includes(selectedNotificationCapability);

    const pushMetaIndex = updatedNotification?.parameters.findIndex((param) => param?.name === PUSH_META);

    if (capabilityMatches && pushMetaIndex === MINUS_ONE_INT) {
        updatedNotification?.parameters.push({
            name: PUSH_META,
            value: { force_send_email_notification: ENABLED },
        });
    } else if (!capabilityMatches && pushMetaIndex !== MINUS_ONE_INT) {
        updatedNotification?.parameters.splice(pushMetaIndex, ONE_INT);
    }

    return updatedNotification;
};

export const convertActionsToRequestStructure = (actions, selectedNotificationCapability) => {
    return actions.map((action) => {
        const { abstract = {}, capability = '', command = [], target = {}, notification } = action;
        const { uuid } = abstract;

        if (notification) {
            const updatedNotification = getUpdatedNotificationRequestStructure(
                notification,
                selectedNotificationCapability,
            );

            return updatedNotification;
        }

        if (action['name'] === APICALL_NAME) {
            return {
                name: 'apicall',
                ...action[PAAS_ACTION],
            };
        } else {
            return {
                name: 'ac',
                parameters: [
                    {
                        abstract: uuid,
                        capability: capability,
                        command: command,
                    },
                    ...(Array.isArray(target) ? target : [target]),
                ],
            };
        }
    });
};

// TODO: MUST BE COVERED BY UNIT TESTS WITH NEXT REFACTORING
export const createMeshSceneRequestPayload = (cloudMeshbotParams) => {
    const {
        name,
        optionType,
        triggers,
        actions,
        sceneUuid,
        meta,
        notificationType,
        templateCapability,
        meshBotDefinition,
        auto_create,
        id,
        metaLabels,
        enabled,
    } = cloudMeshbotParams;

    const cloudMeshbotRequestParams =
        optionType !== MESHBOT_TYPES.NOTIFICATION_TEMPLATE
            ? {
                  name: name,
                  enabled: enabled,
                  triggers: convertTriggersToRequestStructure(triggers, optionType),
                  actions: convertActionsToRequestStructure(actions),
                  meta: {
                      type: notificationType,
                      labels: metaLabels,
                  },
              }
            : {
                  action: !id ? 'create' : 'update',
                  title: name,
                  description: 'text description',
                  capability: triggers[BLOCK_WITH_NAME_SUB_BLOCK_VALUE]?.nameSubBlock || templateCapability,
                  meshbot_definition: meshBotDefinition
                      ? { ...JSON.parse(meshBotDefinition), name }
                      : {
                            name,
                            triggers: convertTriggersToRequestStructure(triggers),
                            actions: convertActionsToRequestStructure(actions),
                        },
                  auto_create,
                  uuid: !id ? '' : id,
              };

    if (sceneUuid) {
        cloudMeshbotRequestParams.uuid = sceneUuid;
    }

    if (meta) {
        cloudMeshbotRequestParams.meta = meta;
    }

    return cloudMeshbotRequestParams;
};

export const buildNotifyCategoriesObjects = (notificationCategoriesList) => {
    return notificationCategoriesList.map((category) => {
        return {
            name: CATEGORY,
            category: category.uuid,
        };
    });
};

export const buildNotifyCategoriesObjectsByName = (notificationCategoriesList, value) => {
    const res = [];
    notificationCategoriesList.forEach((category) => {
        value.forEach((categoryName) => {
            if (category.display_name === categoryName) {
                res.push({
                    name: CATEGORY,
                    category: category.uuid,
                });
            }
        });
    });

    return res;
};

/**
 * Remove all line breaks from string
 * @param {string} str - source string
 * @returns {string} string without like breaks
 * */
export const removeLineBreaksFromString = (str) => {
    if (str && typeof str === JAVASCRIPT_TYPES.STRING) {
        return str.replace(/[\r\n]/gm, '');
    }

    return str;
};

export const buildNotifyUsersBlock = (
    body,
    targets,
    subject,
    body_html,
    notifyCategoriesObjects = [],
    notificationType,
    channels,
) => {
    const bodyHtmlWithoutLineBreaks = removeLineBreaksFromString(body_html);
    const hasEmail = channels?.includes(EMAIL);

    const defaultParams = {
        name: DEFAULT_BUCKET,
        body,
        ...(hasEmail && { subject, body_html: bodyHtmlWithoutLineBreaks }),
    };

    const targetParams = {
        name: TARGET,
        targets,
    };

    if (notificationType !== NOTIFICATION_TEMPLATE) {
        return {
            name: NOTIFY_USERS,
            parameters: [defaultParams, targetParams],
        };
    }

    return {
        name: NOTIFY_USERS,
        parameters: [
            defaultParams,
            {
                name: TARGET,
                targets: [
                    {
                        type: ACCOUNT,
                        channels,
                    },
                ],
            },
            ...notifyCategoriesObjects,
        ],
    };
};

export const buildNotificationCategoryParams = (notificationCategoriesList, name, uuid) => {
    return {
        list: getCategoryArrayWithoutIsEditKey([
            ...notificationCategoriesList,
            {
                uuid: uuid,
                display_name: name,
                meta: {},
            },
        ]),
    };
};

export const buildNotificationCategoryParamsForDeleting = (notificationCategoriesList = []) => {
    return {
        list: notificationCategoriesList,
    };
};

/**
 * Returns Object that we use in API request to Cloud for creating Meshbot
 * @param {array} targets - Array of Objects with target data
 * @param {object} dashboardData - Object with data related to dashboard, if exists should
 * contain uuid and name properties
 * @param {number} showForSeconds - Number of seconds, represents the time that dashboard should be active
 * @returns {object} object that represents 'notify-users' block
 *
 * */

export const buildInteractionDashboardBlock = ({ targets, dashboardData, showForSeconds }) => {
    if (!Array.isArray(targets) || !targets.length) {
        throw new Error('#buildInteractionDashboardBlock: targets should be non empty array');
    }
    const url = `https://ezlo/?dashboard_uuid=${dashboardData?.uuid}&is_dashboard_template=1${
        showForSeconds !== null ? `&show_for_seconds=${showForSeconds}` : ''
    }`;

    return {
        name: 'notify-users',
        parameters: [
            {
                name: 'default',
                body: `Dashboard notification ${dashboardData?.name}`,
                subject: '',
            },
            {
                name: 'target',
                targets,
            },
            {
                name: 'dashboard',
                list: [{ title: dashboardData?.name, url }],
            },
        ],
    };
};

export const buildInteractionNuCALBlock = (data) => {
    if (data.accountUuid && data.method && data.fields && data.code) {
        return {
            parameters: [
                {
                    name: 'abstract',
                    uuid: data.accountUuid,
                    action: 'call',
                },
                {
                    name: 'apiname',
                    value: data.method,
                },
                {
                    name: 'params',
                    params: {
                        ...data.fields,
                    },
                },
                {
                    name: 'code',
                    code: data.code,
                    language: 'javascript',
                },
                {
                    name: 'code_output_blacklist',
                    list: data?.blackListVariable ?? [],
                },
            ],
        };
    } else {
        return {
            parameters: [
                {
                    name: 'abstract',
                    uuid: data.accountUuid,
                    action: 'call',
                    target_custom: 'api_response',
                },
                {
                    name: 'apiname',
                    value: data.method,
                },
                {
                    name: 'params',
                    params: {
                        ...data.fields,
                    },
                },
            ],
        };
    }
};
const buildSubscriptionId = (data) => {
    const structureForSubscriptionId = generateStructureForSubscriptionId(data);
    if (!structureForSubscriptionId) {
        return;
    }

    return `${SUBSCRIPTION}_${generateUniqueId(structureForSubscriptionId)}`;
};

export const buildInteractionNuCALBlockForTrigger = (data) => {
    const subscriptionId = buildSubscriptionId(data);

    return {
        name: 'onchange',
        parameters: [
            {
                abstract: data?.accountUuid,
                capability: subscriptionId ? `${CUSTOM}_${subscriptionId}` : '',
                variable: VALUE,
                transform: 'string',
            },
        ],
    };
};

export const buildNuCALSubscriptionBlockForTrigger = (data) => {
    const subscriptionId = buildSubscriptionId(data);
    if (!subscriptionId) {
        return;
    }

    return getPayloadForCreateSubscriptionsOnCloud({
        accountUuid: data?.accountUuid,
        method: data?.method,
        fields: data?.fields,
        subscriptionId,
    });
};

export const getPayloadForCreateSubscriptionsOnCloud = (subscription) => {
    const { accountUuid, method, fields, subscriptionId } = subscription;

    return {
        call: 'abstract_command',
        params: {
            type: 'command',
            capability: 'api',
            uuid: accountUuid,
            command: 'subscribe',
            parameters: {
                name: method,
                params: {
                    ...fields,
                },
                custom: {
                    id: subscriptionId,
                },
            },
        },
        id: hash(),
        version: '1',
    };
};
