import React from 'react';
import hash from '../../../constants/uniqueHash';
import { getFieldType } from '../../../constants/MethodBlock';
import at from '../../../constants/ActionTypes/MeshBot';
import blockActionTemplate from '../../../components/blockActionTemplate';
import * as meshBot from '../../../constants/MeshbotConstant';
import _, { get } from 'lodash';

import triggerStyles from './components/TriggerBlock.module.scss';
import functionStyles from './components/FunctionForTriggerBlock/FunctionForTriggerBlock.module.scss';

import {
    ABSTRACT_INDEXES,
    ACTION_THEN,
    ACTION_TYPES,
    ARMED,
    ARMED_SELECT_VALUES,
    BLOCK_FIELD_TYPES,
    BLOCK_ID,
    BLOCK_NAME,
    BLOCK_WHEN,
    CAMERA,
    CAMERA_HOTZONE,
    CAMERA_TYPE,
    CLOUD_API,
    CLOUD_NOTIFICATION,
    COMPARATOR,
    COMPARISON_DATA,
    CONTROLLER,
    CUSTOM,
    DASHBOARD,
    DELAY,
    DEVICE_CAMERA_CAPABILITIES,
    DEVICE_STATE,
    DISARMED,
    EMPTY_STRING,
    EXCEPTION,
    EXCEPTION_NODES_WITH_FUNCTIONS,
    FALSE,
    FIELD_NAME,
    GET,
    HTTP_REQUEST_FIELD_KIND,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    INDEX_SELECTED_FIELDS_ELEMENT,
    INITIAL_VIDOO_CAPABILITY_VALUE,
    INPUT_PROPS,
    ITEM,
    LATCH,
    LIST,
    LIST_OF_INCORRECT_VALUES_FOR_SCENE,
    LIST_OF_UNIVERSAL_METHODS,
    MESHBOT_FUNCTION_PULSE_SELECT_VALUES,
    MESHBOT_FUNCTION_TYPES,
    MESHBOT_NODE_TYPES,
    MESHBOT_PROTOCOLS_PLUGIN_LIST,
    NAME_ARGUMENT_FIELDS,
    NODES_WITH_FUNCTIONS,
    NOT,
    OPERATOR_AND,
    OPERATORS_LOCAL,
    PAAS_ACTION,
    RESET_LATCH,
    RESET_SCENE_LATCHES,
    RUN_CUSTOM_SCRIPT,
    RUN_SCENE,
    SET_DEVICE_ARMED,
    SET_HTTP_REQUEST,
    SET_ITEM_VALUE,
    SET_SCENE_STATE,
    SETPOINT_COOL,
    SETPOINT_COOL_COMMAND,
    SETPOINT_HEAT,
    SETPOINT_HEAT_COMMAND,
    STATE,
    SUBSCRIPTION,
    SUMMARY_VIDOO_OPTIONS,
    THEN_SWITCH,
    TOGGLE_VALUE_METHOD_NAME,
    TRIGGER_TYPE_LIST,
    TRIGGER_TYPES,
    TRUE,
    USER_CODES,
    USER_LOCK_OPERATION,
    VALUE,
    VALUE_TYPES,
    VALUES_FOR_EMPTY_LISTS,
    VIDOO_CAMERA_CAPABILITIES,
    VIDOO_ITEM_NAME,
    VIDOO_TYPES,
    WHEN_BLOCK,
    REACHABLE_SELECT_VALUES,
    REACHABLE,
    REACHABLE_SELECT_OPTIONS,
    UNAVAILABLE_DATE_AND_TIME_TYPES_FOR_GLOBAL_RESTRICTION,
    BLOCK_ENABLE,
} from '../../../constants/MeshbotConstant';
import { isObject } from 'lodash';

import { ABSTRACT_COMMAND } from '../../../constants/cloudCallNames';
import {
    convertAmPmOfParametersTo24HourFormat,
    isActionChanged,
    isEditActionNotificationBlock,
    isNestedTriggerChanged,
    isTriggerChanged,
    isValidActionBlock,
    isValidCloudVariableBlock,
    isValidDateAndTimeBlock,
    isValidGeoFencingBlock,
    isValidNotificationTemplateBlock,
    isValidNucalBlock,
    isValidTriggerBlock,
} from '../utils';
import { HINTS_MESHBOT_URL, EZLOPI_AUTOMATION_PATH, EZLOPI_DYNAMIC_URL_REGEX } from '../../../constants/URLs';
import { generateActionBlock, getControl, setFunctionFields } from './MeshbotLocal/MeshBotLocalForm/utils';
import {
    ACCESSIBLE_CLOUD_TRIGGER_NODES_IN_INTERNAL_DOMAIN,
    listNodesForNotificationTemplate,
    listNodesIdsForExceptions,
    listOfValuesForBatteryStateCapability,
    listOfValuesForCloudConnectionCapability,
    listNodesEzlopi,
    dateAndTimeCloud,
    dateAndTimeEzlopi,
    dateAndTime,
    rangeCustomDateList,
    customDate,
    rangeSpecificDateLabels,
    specificDateLabel,
} from '../../../constants/rulesInSelect';
import { ACTION_COMPARE_PROPERTY, TRIGGER_COMPARE_PROPERTY } from '../EzloCustomization/constants';
import { NOT_NAME, CLOUD, STRING, MESHBOT_SECTION_TYPE } from './constants';
import {
    DATA_FROM_FIRST_SCENE,
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_SECOND_BLOCK,
} from '../../../constants/NotificationTemplates';
import {
    getControllerNodeValueFromNotBlockByName,
    getControllerNodeValueObjectFromNotBlock,
} from './components/ControllerNodeForTrigger/utils';
import { OEM_IDS } from '../../../services/oem/src/constants/oems';
import {
    getExpressionComparisonBlockMeta,
    getExpressionComparisonBlockOptions,
} from './components/ExpressionComparison/utils';
import { checkIfSubscriptionItemExist } from './components/PaasNodeForTrigger/utils';
import sha1 from 'sha1';
import { getUpdatedNodesByCustomization } from '../EzloCustomization/utils';
import {
    EZLOGIC_HINT_ADD_OFFSET,
    EZLOGIC_HINT_ARMED_STATE,
    EZLOGIC_HINT_CAPABILITY,
    EZLOGIC_HINT_EVENT,
    EZLOGIC_HINT_FALSE_ACTION,
    EZLOGIC_HINT_HOUSE_MODES,
    EZLOGIC_HINT_LUA_SCRIPT_NODE,
    EZLOGIC_HINT_NODE,
    EZLOGIC_HINT_NODE_TYPE,
    EZLOGIC_HINT_OFFSET,
    EZLOGIC_HINT_SAVE_RESULT_NUCAL,
    EZLOGIC_HINT_SELECT_CHANNELS,
    EZLOGIC_HINT_SELECT_USER,
    EZLOGIC_HINT_TRUE_ACTION,
    EZLOGIC_HINT_VALUE_END,
    EZLOGIC_HINT_VALUE_START,
    EZLOGIC_HINTS_COMPARATOR,
    EZLOGIC_HINTS_VALUE_TYPE,
    EZLOGIC_HINTS_VARIABLE,
    EZLOGIC_INFO_TEXT_MODE,
    EZLOGIC_LABEL_COMPARATOR,
    EZLOGIC_LABEL_VALUE_TYPE,
    EZLOGIC_LABLE_ACTION,
    EZLOGIC_LABLE_PIN_CODE,
    EZLOGIC_TITLE_ADD_OFFSET,
    EZLOGIC_TITLE_ARMED_STATE,
    EZLOGIC_TITLE_CAPABILITY,
    EZLOGIC_TITLE_EVENT,
    EZLOGIC_TITLE_HOUSE_MODES,
    EZLOGIC_TITLE_MODE,
    EZLOGIC_TITLE_NODE,
    EZLOGIC_TITLE_NODE_TYPE,
    EZLOGIC_TITLE_OFFSET,
    EZLOGIC_TITLE_SECURITY_MODES,
    EZLOGIC_TITLE_TRIGGER_FUNCTIONS_INFO,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VALUE_END,
    EZLOGIC_TITLE_VALUE_SPECIAL_TIME_OF_DAY,
    EZLOGIC_TITLE_VALUE_START,
    EZLOGIC_VARIABLES_VARIABLES_TITLE,
    EZLOGIC_LABLE_REACHABLE_STATE,
} from '../../../constants/language_tokens';
import { CLOUD_MESHBOT_TYPES, GROUP_BLOCKS_METHOD_NAMES, MESHBOT_TYPES } from '../EzloMeshbots/constants';
import { CAPABILITY_VALUE_TYPE, FIELD_TYPE_NAMES, FIELDS_NAMES } from '../../../constants/ItemGroups';
import { getExistingMeshBotLabelsUuids } from '../../../features/Labels/MeshBotsLabels/utils';
import { isEzlogicInternalDomain, isJsonToParse } from 'services/utilityService';
import { SWITCH } from '../../../constants/SystemHistory';
import { ABSTRACT_TYPE, TCP_MANAGER } from 'constants/Devices';
import { isAllObjectValuesNonEmpty } from 'helpers/common';
import { MESHBOT_STATE_RANGE_VALUES, MESHBOT_STATE_VALUES } from '../../../constants/constMeshBot';
import { getBlockFromTrigger } from './utils/getBlockFromTrigger';
import { EZLOPI_COMPARISON_METHODS_VERSIONS } from 'features/ezlopi/ezlopiConstants';
import { ZERO_INT } from '../../../constants/Expressions';
import * as meshbot from '../../../constants/MeshbotConstant';

const { trigger_block, editing, is_valid, connection } = triggerStyles;
const { function_block__inner } = functionStyles;
const isNotSet = (element) => getMethodName(element.blockOptions) === NOT_NAME;
const getItemId = (item) => item.fields[0].value;
const getItemName = (data) => data.map((item) => item.name);

const options = (data, name, id, result, not, type) => {
    return {
        id: id,
        not: not,
        type: type,
        optionType: getMethodName(data.blockOptions),
        blockName: data.blockName,
        actionGroup: data.actionGroup,
        blocks: name === at.SELECT ? [...result.select] : [...result.triggers],
    };
};

/**
 * Create block select
 * @param {object} block - Trigger block
 * @param {string} id - Id trigger
 * @param {string} not - Operator not
 * @param {string} idFunction - Id function
 * @returns {object} returned trigger
 * @example
 * createBlockSelect(block, id, not, idFunction)
 */

const createBlockSelect = (block, id, not, idFunction) => {
    const newBlock = { id: id, not: not, blocks: [block] };

    if (idFunction) {
        newBlock.idFunction = idFunction;
    }

    return newBlock;
};

const getSpecialType = (block, type) => {
    if (type !== 'custom') {
        if (getItemName(block.fields).includes('sunrise')) {
            return 'rise';
        } else if (getItemName(block.fields).includes('sunset')) {
            return 'set';
        } else if (getItemName(block.fields).includes('startTime')) {
            return 'after';
        } else if (getItemName(block.fields).includes('endTime')) {
            return 'before';
        } else {
            return '';
        }
    }

    return '';
};
/**
 * Constructs a function field object from an item.
 *
 * @param {Object} item - Item used to create the function field.
 * @returns {Object} - An object containing a deeply-cloned function from blockOptions and item's id.
 */
const getFunctionFields = (item) => {
    return {
        [at.FUNCTION]: _.cloneDeep(item.blockOptions.function),
        idFunction: item._id,
    };
};

/**
 * Checks if a block's method name is among group method names.
 *
 * @param {Object} block - A block containing fields to be checked.
 * @param {Object} block.blockOptions - Block options fields to be checked.
 * @param {Object[]} block.fields - Block fields to be checked.
 * @returns {boolean} - Returns true if the block's method name is in the group blocks method names array, false otherwise.
 */
const isBlockOfFunctionOverGroup = (block) => {
    try {
        const childBlockOptions = block?.fields?.[0]?.value?.[0]?.blockOptions;
        if (!isObject(block) || !isObject(childBlockOptions)) {
            return false;
        }

        return (
            getMethodName(block.blockOptions) === at.FUNCTION &&
            GROUP_BLOCKS_METHOD_NAMES.includes(getMethodName(childBlockOptions))
        );
    } catch (e) {
        return false;
    }
};

export const normalizeRule = (rule) => ({
    ...rule,
    name: rule.name.replace(/\s{2,}/g, ' '),
});

export const removeTempIdFromBlocks = (blocks) =>
    blocks.map((block) => {
        const newBlock = { ...block };

        delete newBlock._tempId;

        return newBlock;
    });

export const getMethodName = (blockOptions) => blockOptions.method.name;

export const getMethodArgs = (item) => {
    const { args } = item.blockOptions.method;
    const name = getMethodName(item.blockOptions);
    const [{ value }] = item.fields;
    const checkField = (propertyName) => args.hasOwnProperty(propertyName);

    if (checkField(at.BLOCKS) && !item.hasOwnProperty('blockName')) {
        return at.BLOCKS;
    } else if (item.hasOwnProperty('blockName')) {
        return at.GROUP;
    } else if (value.hasOwnProperty('blockName')) {
        return at.GROUP_NOT;
    } else if (
        (item?.blockMeta?.ruleTrigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED ||
            item?.blockMeta?.ruleTrigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE) &&
        name !== NOT_NAME
    ) {
        return at.DEVICE;
    } else if (
        item?.blockMeta?.ruleTrigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES &&
        name !== NOT_NAME
    ) {
        return at.CLOUD_VARIABLES;
    } else if (
        item?.blockMeta?.metaType === WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION ||
        item?.fields?.[0]?.value?.blockMeta?.metaType === WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION
    ) {
        return at.NUCAL_SUBSCRIPTION;
    } else if (
        name === NOT_NAME &&
        (item?.fields?.[0]?.value?.blockMeta?.ruleTrigger?.selectedFieldTrigger ===
            MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED ||
            item?.fields?.[0]?.value?.blockMeta?.ruleTrigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE)
    ) {
        return at.DEVICE_NOT;
    } else if (
        name === NOT_NAME &&
        item?.fields?.[0]?.value?.blockMeta?.ruleTrigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES
    ) {
        return at.CLOUD_VARIABLES_NOT;
    } else if (item.hasOwnProperty(BLOCK_ID) && !item.hasOwnProperty(BLOCK_ENABLE)) {
        return at.DEVICE;
    } else if (
        !value.hasOwnProperty('blockName') &&
        name === NOT_NAME &&
        value.hasOwnProperty(BLOCK_ID) &&
        !value.hasOwnProperty(BLOCK_ENABLE)
    ) {
        return at.DEVICE_NOT;
    } else if (name === NOT_NAME && item?.fields?.[0]?.value?.blockOptions?.method?.args?.scene === at.SCENE) {
        return at.SCENE_NOT;
    } else if (checkField('scene')) {
        return at.SCENE;
    } else if (name === TRIGGER_TYPES.IS_DETECTED_IN_HOTZONE && name === NOT_NAME) {
        return at.VIDOO_NOT;
    } else if (name === TRIGGER_TYPES.IS_DETECTED_IN_HOTZONE) {
        return at.VIDOO;
    } else if (item?.blockOptions?.method?.args?.hasOwnProperty(at.HOUSE_MODE)) {
        return at.TRIGGER_HOUSE_MODE;
    } else if (name === NOT_NAME && value?.blockOptions?.method?.args?.hasOwnProperty(at.HOUSE_MODE)) {
        return at.HOUSE_MODE_NOT;
    } else if (name === NOT_NAME && value?.blockOptions?.method?.args?.hasOwnProperty(at.DEVICE_GROUP)) {
        return at.DEVICE_GROUP_NOT;
    } else if (args?.hasOwnProperty(at.DEVICE_GROUP) && name !== NOT_NAME) {
        return at.DEVICE_GROUP;
    } else if (
        !value.hasOwnProperty(BLOCK_NAME) &&
        name === NOT_NAME &&
        (!value.hasOwnProperty(BLOCK_ID) || (value.hasOwnProperty(BLOCK_ID) && value.hasOwnProperty(BLOCK_ENABLE))) &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name !== TRIGGER_TYPES.IS_CLOUD_STATE &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name !== TRIGGER_TYPES.IS_BATTERY_STATE &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name !== TRIGGER_TYPES.IS_BATTERY_LEVEL &&
        !getExpressionComparisonBlockOptions(item)?.method?.args.hasOwnProperty(at.EXPRESSION)
    ) {
        return at.DATE_NOT;
    } else if (
        name !== NOT_NAME &&
        item?.blockOptions.method?.args.hasOwnProperty(at.EXPRESSION) &&
        item?.blockMeta?.isExpressionComparison
    ) {
        return at.EXPRESSION_COMPARISON;
    } else if (
        name === NOT_NAME &&
        getExpressionComparisonBlockOptions(item)?.method?.args.hasOwnProperty(at.EXPRESSION) &&
        getExpressionComparisonBlockMeta(item).isExpressionComparison
    ) {
        return at.EXPRESSION_COMPARISON_NOT;
    } else if (
        (name === TRIGGER_TYPES.COMPARE_VALUES || name === TRIGGER_TYPES.COMPARE_NUMBER_RANGE) &&
        item?.blockOptions.method?.args.hasOwnProperty(at.EXPRESSION) &&
        !item?.blockMeta?.isExpressionComparison
    ) {
        return at.VARIABLE_COMPARISON;
    } else if (
        name === NOT_NAME &&
        getExpressionComparisonBlockOptions(item)?.method?.args.hasOwnProperty(at.EXPRESSION) &&
        !getExpressionComparisonBlockMeta(item)?.isExpressionComparison
    ) {
        return at.VARIABLE_COMPARISON_NOT;
    } else if (name === TRIGGER_TYPES.IS_CLOUD_STATE) {
        return at.IS_CLOUD_STATE;
    } else if (
        name === NOT_NAME &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name === TRIGGER_TYPES.IS_CLOUD_STATE
    ) {
        return at.IS_CLOUD_STATE_NOT;
    } else if (name === TRIGGER_TYPES.IS_BATTERY_STATE) {
        return at.IS_BATTERY_STATE;
    } else if (
        name === NOT_NAME &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name === TRIGGER_TYPES.IS_BATTERY_STATE
    ) {
        return at.IS_BATTERY_STATE_NOT;
    } else if (name === TRIGGER_TYPES.IS_BATTERY_LEVEL) {
        return at.IS_BATTERY_LEVEL;
    } else if (
        name === NOT_NAME &&
        getControllerNodeValueObjectFromNotBlock(item)?.blockOptions?.method?.name === TRIGGER_TYPES.IS_BATTERY_LEVEL
    ) {
        return at.IS_BATTERY_LEVEL_NOT;
    } else {
        if (checkField('weekdays')) {
            return args.weekdays;
        } else if (checkField('days')) {
            return args.days;
        } else if (checkField('endYear') && !checkField('endTime') && !checkField('startTime')) {
            return args.endYear;
        } else if (checkField('sunstate')) {
            return 'daily';
        } else if (name === 'isInterval') {
            return 'interval';
        } else if (name === 'isDateRange') {
            return 'custom';
        } else if (item.fields[0].value === 'weeks') {
            return 'weeks';
        } else if (item.fields[0].value === 'daily') {
            return 'custom';
        } else if (item.fields[0].value === 'yearWeeks') {
            return 'yearWeeks';
        } else if (name === 'isOnce') {
            return 'isOnce';
        }

        return at.DATE;
    }
};

export const getDeviceId = (value, items) => {
    if (!value || !Array.isArray(items)) {
        throw new Error('Error, input data is not valid');
    }

    const itemRule = items.find((elem) => elem._id === value);
    if (itemRule) {
        return { deviceId: itemRule.deviceId, itemId: value };
    }

    return null;
};

/**
 * This function returns an exception id
 * @param {Object} data - Exception trigger data
 * @param {boolean} isException - flag indicating whether data is an exception
 * @returns {string | null} - exception id or null
 */
export const getExceptionId = (data, isException) => {
    if (isException && data?._id) {
        return data._id;
    }

    return null;
};

export const initialMeshbotTriggers = (data, items, devices, scenes, compareMethods) => {
    const type = (item) => getMethodArgs(item);
    let triggersList = [];
    const triggerScenesList = [];
    const triggersSelect = { blocks: [] };
    let triggersDevicesBlock = [];
    const id = hash();
    const isBlockNot = isNotSet(data);

    const getBlocksInsideGroup = (block, isSectionFunction) => {
        const [{ value }] = block.fields;
        const groupNot = type(block) === at.GROUP_NOT;

        const addBlocks = (list, subType, functionId) => {
            const isGroupOrBlock = subType === at.GROUP || subType === at.BLOCKS;
            let select = isGroupOrBlock ? [...triggersSelect.blocks] : [];
            let blocks = isGroupOrBlock ? [...triggersDevicesBlock] : [];
            let triggers = isGroupOrBlock ? [...triggersList] : [];

            list.map((item) => {
                const subId = hash();
                const subValue = item.fields[0].value;
                const subGroupNot = type(item) === at.GROUP_NOT;
                const blockRecognition = (subResult, result) => (subGroupNot ? subResult : result);

                switch (type(item)) {
                    case at.DEVICE:
                    case at.DEVICE_NOT:
                    case at.DEVICE_ADVANCED:
                    case at.DEVICE_ADVANCED_NOT:
                        const triggerId = isBlockNot
                            ? item.fields[0]?.value?.blockMeta?.ruleTrigger?.id || subId
                            : item?.blockMeta?.ruleTrigger?.id || subId;
                        blocks = [...blocks, isNotSet(item) ? subValue : item];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, triggerId, isNotSet(item), functionId),
                        ];
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.DEVICE,
                                id: triggerId,
                                scenes,
                                idFunction: functionId,
                                compareMethods,
                            }),
                        ];
                        break;
                    case at.CLOUD_VARIABLES:
                    case at.CLOUD_VARIABLES_NOT:
                        const IdtTrigger = isBlockNot
                            ? item.fields[0]?.value?.blockMeta?.ruleTrigger?.id || subId
                            : item?.blockMeta?.ruleTrigger?.id || subId;
                        blocks = [...blocks, isNotSet(item) ? subValue : item];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, IdtTrigger, isNotSet(item), functionId),
                        ];
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.CLOUD_VARIABLES,
                                id: IdtTrigger,
                                scenes,
                                idFunction: functionId,
                                compareMethods,
                            }),
                        ];
                        break;
                    case at.NUCAL_SUBSCRIPTION:
                        const idTrigger =
                            item.fields[0]?.value?.blockMeta?.ruleTrigger?.id ||
                            item?.blockMeta?.ruleTrigger?.id ||
                            subId;
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, idTrigger, isNotSet(item)),
                        ];
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.NUCAL_SUBSCRIPTION,
                                id: idTrigger,
                            }),
                        ];
                        break;
                    case at.GROUP:
                    case at.GROUP_NOT:
                    case at.BLOCKS:
                        if (getMethodName(item.blockOptions) === at.FUNCTION) {
                            const blocks = addBlocks(item.fields[0].value, type(item));
                            triggers.push(Object.assign(getFunctionFields(item), blocks.triggers[0]));
                            select.push(Object.assign(getFunctionFields(item), blocks.select[0]));
                        } else {
                            const result = addBlocks(subGroupNot ? subValue.fields[0].value : subValue);

                            triggers = [
                                ...triggers,
                                options(
                                    blockRecognition(subValue, item),
                                    at.LIST,
                                    subId,
                                    result,
                                    subGroupNot,
                                    subGroupNot ? type(subValue) : type(item),
                                ),
                            ];
                            select = [
                                ...select,
                                options(
                                    blockRecognition(subValue, item),
                                    at.SELECT,
                                    subId,
                                    result,
                                    subGroupNot,
                                    subGroupNot ? type(subValue) : type(item),
                                ),
                            ];

                            blocks = [...blocks, ...result.blocks];
                        }

                        break;

                    case at.SCENE:
                    case at.SCENE_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({ block: item, items, devices, type: at.SCENE, id: subId, scenes }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;

                    case at.VIDOO:
                    case at.VIDOO_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({ block: item, items, devices, type: at.VIDOO, id: subId }),
                        ];
                        select = [...select, createBlockSelect(item, subId, false)];
                        break;

                    case at.TRIGGER_HOUSE_MODE:
                    case at.HOUSE_MODE_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.TRIGGER_HOUSE_MODE,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;

                    case at.DATE_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.DATE,
                                id: subId,
                            }),
                        ];
                        select = [...select, createBlockSelect(subValue, subId, true)];
                        break;

                    case at.IS_CLOUD_STATE:
                    case at.IS_CLOUD_STATE_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.IS_CLOUD_STATE,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    case at.IS_BATTERY_STATE:
                    case at.IS_BATTERY_STATE_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.IS_BATTERY_STATE,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    case at.IS_BATTERY_LEVEL:
                    case at.IS_BATTERY_LEVEL_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.IS_BATTERY_LEVEL,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    case at.EXPRESSION_COMPARISON:
                    case at.EXPRESSION_COMPARISON_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.EXPRESSION_COMPARISON,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    case at.VARIABLE_COMPARISON:
                    case at.VARIABLE_COMPARISON_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.VARIABLE_COMPARISON,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    case at.DEVICE_GROUP:
                    case at.DEVICE_GROUP_NOT:
                        triggers = [
                            ...triggers,
                            createBlockList({
                                block: item,
                                items,
                                devices,
                                type: at.DEVICE_GROUP,
                                id: subId,
                            }),
                        ];
                        select = [
                            ...select,
                            createBlockSelect(isNotSet(item) ? subValue : item, subId, isNotSet(item)),
                        ];
                        break;
                    default:
                        triggers = [
                            ...triggers,
                            createBlockList({ block: item, items, devices, type: at.DATE, id: subId }),
                        ];
                        select = [...select, createBlockSelect(item, subId, false)];
                        break;
                }
            });

            return { select, blocks, triggers };
        };

        if (type(block) === at.GROUP || groupNot) {
            const result = addBlocks(groupNot ? value.fields[0].value : value, type(block));
            const options = (name) => {
                const newGroup = {
                    id: id,
                    not: groupNot,
                    type: at.GROUP,
                    optionType: groupNot ? getMethodName(value.blockOptions) : getMethodName(block.blockOptions),
                    blockName: groupNot ? value.blockName : block.blockName,
                    blocks: name === at.SELECT ? result.select : result.triggers,
                    actionGroup: block.actionGroup,
                    _id: block._id,
                };

                if (data.blockOptions.hasOwnProperty(meshBot.TRIGGER_TYPES.FUNCTION)) {
                    newGroup.function = _.cloneDeep(data.blockOptions.function);
                    newGroup.idFunction = data._id;
                }

                return newGroup;
            };

            triggersSelect.blocks = [options(at.SELECT)];
            triggersList = [options(at.LIST)];
            triggersDevicesBlock = [...result.blocks];
        }

        if (type(block) === at.BLOCKS) {
            if (isBlockOfFunctionOverGroup(block)) {
                getBlocksInsideGroup(value[0], true);
            } else {
                let result = {};
                const methodName = getMethodName(block.blockOptions);
                if (OPERATORS_LOCAL.includes(methodName)) {
                    triggersSelect.optionType = methodName;
                }

                if (block.hasOwnProperty('blockName') || !Array.isArray(value)) {
                    result = addBlocks(block.fields[0].value, type(block));
                } else {
                    result = addBlocks(value, type(block), block._id);
                }

                let modifiedSelect = [...result.select];
                let modifiedTriggers = [...result.triggers];

                if (methodName === at.FUNCTION) {
                    modifiedSelect = result.select.map((trigger) => {
                        return {
                            ...trigger,
                            [at.FUNCTION]: _.cloneDeep(block.blockOptions.function),
                        };
                    });
                    modifiedTriggers = result.triggers.map((trigger) => {
                        return {
                            ...trigger,
                            [at.FUNCTION]: _.cloneDeep(block.blockOptions.function),
                            idFunction: data._id,
                        };
                    });
                }

                if (isSectionFunction && !block.hasOwnProperty('blockName')) {
                    triggersSelect.function = _.cloneDeep(data.blockOptions.function);
                    triggersSelect.idFunction = data._id;
                }

                triggersSelect.blocks = [...modifiedSelect];
                triggersList = [...modifiedTriggers];
                triggersDevicesBlock = [...result.blocks];
            }
        }
    };

    switch (type(data)) {
        case at.DEVICE:
        case at.DEVICE_NOT:
        case at.DEVICE_ADVANCED:
        case at.DEVICE_ADVANCED_NOT:
            const triggerId = isBlockNot
                ? data.fields[0]?.value?.blockMeta?.ruleTrigger?.id || id
                : data?.blockMeta?.ruleTrigger?.id || id;
            triggersList = [
                createBlockList({
                    block: data,
                    items,
                    devices,
                    type: at.DEVICE,
                    id: triggerId,
                    scenes,
                    idFunction: null,
                    compareMethods,
                }),
            ];
            triggersDevicesBlock = isBlockNot ? [data.fields[0].value] : [data];
            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? data.fields[0].value : data, triggerId, isBlockNot),
            ];
            break;
        case at.SCENE:
        case at.SCENE_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.SCENE, id, scenes })];
            triggersSelect.blocks = [createBlockSelect(isBlockNot ? data.fields[0].value : data, id, isBlockNot)];
            break;
        case at.VIDOO:
        case at.VIDOO_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.VIDOO, id })];
            triggersSelect.blocks = [createBlockSelect(isBlockNot ? data.fields[0].value : data, id, isBlockNot)];
            break;
        case at.GROUP:
        case at.BLOCKS:
        case at.GROUP_NOT:
            getBlocksInsideGroup(data);
            break;
        case at.IS_CLOUD_STATE:
        case at.IS_CLOUD_STATE_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.IS_CLOUD_STATE, id })];
            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? getControllerNodeValueObjectFromNotBlock(data) : data, id, isBlockNot),
            ];
            break;
        case at.IS_BATTERY_STATE:
        case at.IS_BATTERY_STATE_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.IS_BATTERY_STATE, id })];
            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? getControllerNodeValueObjectFromNotBlock(data) : data, id, isBlockNot),
            ];
            break;
        case at.IS_BATTERY_LEVEL:
        case at.IS_BATTERY_LEVEL_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.IS_BATTERY_LEVEL, id })];
            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? getControllerNodeValueObjectFromNotBlock(data) : data, id, isBlockNot),
            ];
            break;
        case at.TRIGGER_HOUSE_MODE:
        case at.HOUSE_MODE_NOT:
            triggersList = [
                createBlockList({
                    block: data,
                    items,
                    devices,
                    type: at.TRIGGER_HOUSE_MODE,
                    id,
                }),
            ];
            triggersSelect.blocks = [
                createBlockSelect(isNotSet(data) ? data.fields[0].value : data, id, isNotSet(data)),
            ];
            break;
        case at.CLOUD_VARIABLES:
        case at.CLOUD_VARIABLES_NOT:
            const IdTrigger = isBlockNot
                ? data.fields[0]?.value?.blockMeta?.ruleTrigger?.id || id
                : data?.blockMeta?.ruleTrigger?.id || id;

            triggersList = [
                createBlockList({
                    block: data,
                    items,
                    devices,
                    type: at.CLOUD_VARIABLES,
                    id: IdTrigger,
                    scenes,
                    idFunction: null,
                    compareMethods,
                }),
            ];

            triggersDevicesBlock = isBlockNot ? [data.fields[0].value] : [data];
            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? data.fields[0].value : data, IdTrigger, isBlockNot),
            ];
            break;

        case at.NUCAL_SUBSCRIPTION:
            const idTrigger =
                data.fields[0]?.value?.blockMeta?.ruleTrigger?.id || data?.blockMeta?.ruleTrigger?.id || id;
            triggersList = [
                createBlockList({
                    block: data,
                    items,
                    devices,
                    type: at.NUCAL_SUBSCRIPTION,
                    id: idTrigger,
                }),
            ];

            triggersSelect.blocks = [
                createBlockSelect(isBlockNot ? data.fields[0].value : data, idTrigger, isBlockNot),
            ];
            break;
        case at.EXPRESSION_COMPARISON:
        case at.EXPRESSION_COMPARISON_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.EXPRESSION_COMPARISON, id })];
            triggersSelect.blocks = [createBlockSelect(isBlockNot ? data.fields[0].value : data, id, isBlockNot)];
            break;
        case at.VARIABLE_COMPARISON:
        case at.VARIABLE_COMPARISON_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.VARIABLE_COMPARISON, id })];
            triggersSelect.blocks = [createBlockSelect(isBlockNot ? data.fields[0].value : data, id, isBlockNot)];
            break;
        case at.DEVICE_GROUP:
        case at.DEVICE_GROUP_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.DEVICE_GROUP, id })];
            triggersSelect.blocks = [createBlockSelect(isBlockNot ? data.fields[0].value : data, id, isBlockNot)];
            break;
        case at.DATE_NOT:
            triggersList = [createBlockList({ block: data, items, devices, type: at.DATE, id })];
            triggersSelect.blocks = [createBlockSelect(data.fields[meshbot.INDEX_OF_ZERO].value, id, true)];
            break;
        default:
            triggersList = [createBlockList({ block: data, items, devices, type: at.DATE, id })];
            triggersSelect.blocks = [createBlockSelect(data, id, false)];
            break;
    }

    return { triggersList, triggersDevicesBlock, triggersSelect, triggerScenesList };
};

export const getIdSelectedCamera = (itemId, items) => {
    if (!items) {
        throw new Error('Missing field value "items"');
    }

    if (itemId) {
        return items.find((item) => item._id === itemId)?.deviceId;
    }
};

const getIdVidooItem = (fields) => {
    return fields.find((field) => field.name === NAME_ARGUMENT_FIELDS.ITEM)?.value;
};

const getHotzoneId = (fields) => {
    const hotzoneId = fields.find((field) => field.name === NAME_ARGUMENT_FIELDS.HOTZONES)?.value;

    if (hotzoneId.length === 0) {
        return SUMMARY_VIDOO_OPTIONS.ANY_ZONE;
    }

    if (hotzoneId[0] === '') {
        return SUMMARY_VIDOO_OPTIONS.FULL_SCREEN;
    }

    return hotzoneId;
};

export const getVidooType = (fields) => {
    if (!fields) {
        throw new Error('Missing field value "fields"');
    }

    const filtersField = fields.find((field) => field.name === NAME_ARGUMENT_FIELDS.FILTERS);

    if (filtersField) {
        if (filtersField.value.hasOwnProperty(NAME_ARGUMENT_FIELDS.PERSONS)) {
            return VIDOO_TYPES.FACE_DETECTION;
        }

        if (filtersField.value.hasOwnProperty(NAME_ARGUMENT_FIELDS.OBJECTS)) {
            return VIDOO_TYPES.OBJECT_DETECTION;
        }

        if (filtersField.value.hasOwnProperty(NAME_ARGUMENT_FIELDS.QRCODES)) {
            return VIDOO_TYPES.QR_CODE_DETECTION;
        }
    }

    return VIDOO_TYPES.ANY_MOTION;
};

export const getCapabilityId = (vidooType, fields) => {
    if (!vidooType || !fields) {
        throw new Error('Missing parameter arguments');
    }

    const filtersField = fields.find((field) => field.name === NAME_ARGUMENT_FIELDS.FILTERS);

    if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
        const { persons } = filtersField.value;

        if (persons.hasOwnProperty(NAME_ARGUMENT_FIELDS.LIST)) {
            return persons.list;
        }

        return SUMMARY_VIDOO_OPTIONS.ANY_PERSON;
    }

    if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
        const { objects } = filtersField.value;

        if (objects.hasOwnProperty(NAME_ARGUMENT_FIELDS.LIST)) {
            return objects.list;
        }

        return SUMMARY_VIDOO_OPTIONS.ANY_OBJECT;
    }

    if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
        const { qrcodes } = filtersField.value;

        if (qrcodes.hasOwnProperty(NAME_ARGUMENT_FIELDS.LIST)) {
            return qrcodes.list;
        }

        return SUMMARY_VIDOO_OPTIONS.ANY_QRCODE;
    }
};

/**
 * Corrects ruleTrigger.comparingValue value if it is not correct
 * @param {Object} ruleTrigger - trigger that may have a non-correct value. format: {comparingValue: 'ezlogic.title.false', ...other}
 * @param {Object} block - block in which the correct value is stored; format: {blockMeta: {ruleTrigger: {comparingValue: 'ezlogic.title.false'}}, fields: [{}, {value: true}], ...other }
 * @returns {undefined}
 */
export function buildCorrectComparingValue(ruleTrigger, block) {
    const valueField = block?.fields?.find((field) => field.name === VALUE);
    // Checking the value for correctness. If the value is not correct, for example:
    // it is a boolean translation key, then we rewrite this value to a correct one taken from the payload;
    if (LIST_OF_INCORRECT_VALUES_FOR_SCENE.includes(block.blockMeta?.ruleTrigger?.comparingValue) && valueField) {
        ruleTrigger.comparingValue = `${valueField.value}`;
    }
}

export const createBlockList = ({ block, items, devices, type, id, scenes, idFunction, compareMethods }) => {
    const [{ value }] = block.fields;
    const isBlockNot = isNotSet(block);
    const dataSource = isBlockNot ? value : block;

    if (type === at.DEVICE || type === at.DEVICE_ADVANCED) {
        if (block?.blockMeta?.ruleTrigger) {
            const ruleTrigger = { ...block.blockMeta.ruleTrigger };
            const armedStateRaw = findInFields(dataSource.fields, ARMED)?.value;
            const reachableStateRaw = findInFields(dataSource.fields, REACHABLE)?.value;

            // fixing ruleTrigger.comparingValue field if that need
            buildCorrectComparingValue(ruleTrigger, block);

            ruleTrigger.name = devices.find((device) => device._id === ruleTrigger?.deviceId)?.name || '';

            if (ruleTrigger.selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED) {
                ruleTrigger.selectedFieldTrigger = MESHBOT_NODE_TYPES.DEVICE_STATE;
            }

            if (id) {
                ruleTrigger.id = id;
            }

            if (idFunction) {
                ruleTrigger.idFunction = idFunction;
            }

            return {
                ...ruleTrigger,
                not: isNotSet(block),
                blocks: [dataSource],
                armedState: mapState(armedStateRaw, ARMED),
                reachableState: mapState(reachableStateRaw, REACHABLE),
            };
        }

        const foundDevice = isNotSet(block) ? getDeviceId(getItemId(value), items) : getDeviceId(value, items) || [];
        const deviceId = foundDevice?.deviceId || value.blockMeta?.ruleTrigger?.deviceId || null;
        const capabilityId = foundDevice?.itemId;
        const methodName = dataSource.blockOptions.method.name;
        const deviceName = devices.find((device) => device._id === deviceId)?.name || '';
        const selectedCapability =
            items.find((item) => item._id === capabilityId) || value.blockMeta?.ruleTrigger?.selectedCapability || {};
        const operationFieldBlock = findInFields(dataSource.fields, WHEN_BLOCK.OPERATION)?.value;
        const comparatorFieldBlock = findInFields(dataSource.fields, WHEN_BLOCK.COMPARATOR)?.value;
        const comparatorFromBlock = comparatorFieldBlock || operationFieldBlock;
        const selectedComparator = compareMethods
            ?.filter(({ method }) => method === methodName)
            .find(({ op }) => op === comparatorFromBlock);
        const compareTo = dataSource?.blockOptions?.method?.args?.value || VALUE;
        const comparingValue = getValueToCompareFromBlock(dataSource, methodName);
        const armedStateRaw = findInFields(dataSource.fields, ARMED)?.value;
        const reachableStateRaw = findInFields(dataSource.fields, REACHABLE)?.value;

        return {
            id,
            not: isNotSet(block),
            deviceId,
            name: deviceName,
            selectedFieldTrigger: MESHBOT_NODE_TYPES.DEVICE_STATE,
            selectedCapability,
            selectedComparator,
            compareTo,
            comparingValue,
            armedState: mapState(armedStateRaw, ARMED),
            reachableState: mapState(reachableStateRaw, REACHABLE),
            blocks: [dataSource],
        };
    }

    if (type === at.CLOUD_VARIABLES) {
        if (block?.blockMeta?.ruleTrigger) {
            const ruleTrigger = { ...block.blockMeta.ruleTrigger };

            if (id) {
                ruleTrigger.id = id;
            }

            if (idFunction) {
                ruleTrigger.idFunction = idFunction;
            }

            ruleTrigger.blocks = [dataSource];

            return ruleTrigger;
        }

        const methodName = dataSource.blockOptions.method.name;

        const {
            selectedAbstract,
            selectedCapability,
            selectedIntegrationId,
            typeVariable,
            selectedVariable,
            selectedComparator,
        } = dataSource.blockMeta.ruleTrigger;
        const compareTo = dataSource?.blockOptions?.method?.args?.value;
        const comparingValue = getValueToCompareFromBlock(dataSource, methodName);

        return {
            id,
            not: isNotSet(block),
            selectedAbstract,
            selectedFieldTrigger: MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
            selectedIntegrationId,
            selectedComparator,
            compareTo,
            comparingValue,
            selectedCapability,
            typeVariable,
            selectedVariable,
            blocks: [dataSource],
        };
    }

    if (type === at.NUCAL_SUBSCRIPTION) {
        if (block?.blockMeta?.ruleTrigger) {
            return {
                ...block.blockMeta.ruleTrigger,
                id,
            };
        }

        return {
            ...dataSource.blockMeta.ruleTrigger,
            id,
            not: isBlockNot,
        };
    }

    if (type === at.VIDOO) {
        const { fields } = block;
        const hotzoneId = getHotzoneId(fields);
        const itemId = getIdVidooItem(fields);
        const cameraId = getIdSelectedCamera(itemId, items);
        const vidooTypeValue = getVidooType(fields);
        const capabilityId = getCapabilityId(vidooTypeValue, fields);

        return {
            id: id,
            selectedFieldTrigger: 'vidoo',
            not: isNotSet(block),
            vidooTypeValue,
            capabilityValue: capabilityId,
            selectedCameraValue: cameraId,
            selectedHotzoneValue: hotzoneId,
            blocks: [block],
        };
    }

    if (type === at.DATE) {
        const getTypeRange = (data) => {
            if (data.length > 1 && data[0].name === 'startTime' && data[1].name === 'endTime') {
                return 'between';
            } else if (data[0].name === 'startTime') {
                return 'startTime';
            } else if (data[0].name === 'endTime') {
                return 'endTime';
            } else {
                return 'isDate';
            }
        };

        const getFormatTime = (data, type) => {
            if (getMethodArgs(data) === 'custom') {
                const getTime = (value) => (Number(value.split(':')[0]) >= meshBot.HOUR12 ? 'pm' : 'am');

                if (type === 'between') {
                    let start = '';
                    let end = '';

                    data.fields.forEach((item) => {
                        if (item.name === 'startTime') {
                            start = getTime(item.value);
                        }

                        if (item.name === 'endTime') {
                            end = getTime(item.value);
                        }
                    });

                    return { startTime: start, endTime: end };
                } else if (type === 'isDate') {
                    return getTime(data.fields[1].value[0]);
                } else {
                    return getTime(data.fields[0].value);
                }
            }

            return '';
        };

        const getDataInt = (data, field, type) => {
            switch (type) {
                case 'between':
                    const day = {};
                    const month = {};
                    const year = {};

                    data.fields.forEach((item) => {
                        const conditions =
                            item.name.slice(-3) !== 'Day' &&
                            item.name.slice(-5) !== 'Month' &&
                            item.name.slice(-4) !== 'Year';

                        if (field === 'day' && item.name === 'startTime' && conditions) {
                            day.startDay = 'everyday';
                        } else if (field === 'day' && item.name === 'endTime' && conditions) {
                            day.endDay = 'everyday';
                        } else if (field === 'day' && item.name.slice(-3) === 'Day') {
                            day[item.name] = item.value;
                        } else if (field === 'month' && item.name.slice(-5) === 'Month') {
                            month[item.name] = item.value;
                        } else if (field === 'year' && item.name.slice(-4) === 'Year') {
                            year[item.name] = item.value;
                        }
                    });

                    if (field === 'day') {
                        return day;
                    }

                    if (field === 'month') {
                        return month;
                    }

                    if (field === 'year') {
                        return year;
                    }
                    break;
                case 'startTime':
                case 'endTime':
                    let result = '';

                    data.fields.forEach((item) => {
                        if (
                            field === 'day' &&
                            item.name.slice(-5) !== 'Month' &&
                            item.name.slice(-4) !== 'Year' &&
                            item.name.slice(-3) !== 'Day'
                        ) {
                            result = 'everyday';
                        } else if (field === 'day' && item.name.slice(-3) === 'Day') {
                            result = item.value;
                        } else if (field === 'month' && item.name.slice(-5) === 'Month') {
                            result = item.value;
                        } else if (field === 'year' && item.name.slice(-4) === 'Year') {
                            result = item.value;
                        }
                    });

                    return result;
                default:
                    return '';
            }
        };

        const isRange = (data) =>
            getMethodName(data.blockOptions) === 'isDateRange' ? getTypeRange(data.fields) : getFieldType(data.fields);
        const isDate = (data, typeBlock) => getDataInt(data, typeBlock, getTypeRange(data.fields));
        const isTimeType = (data) => getFormatTime(data, getTypeRange(data.fields));

        return {
            id: id,
            selectedFieldTrigger: 'dataAndTime',
            selectedFieldDate: getMethodArgs(block) === 'dateNot' ? getMethodArgs(value) : getMethodArgs(block),
            selectedSpecificDate: getMethodArgs(block) === 'dateNot' ? isRange(value) : isRange(block),
            selectedSpecificLabel:
                getMethodArgs(block) === 'dateNot'
                    ? getSpecialType(value, getMethodArgs(value))
                    : getSpecialType(block, getMethodArgs(block)),
            selectedTimeType: getMethodArgs(block) === 'dateNot' ? isTimeType(value) : isTimeType(block),
            selectDay: getMethodArgs(block) === 'dateNot' ? isDate(value, 'day') : isDate(block, 'day'),
            selectMonth: getMethodArgs(block) === 'dateNot' ? isDate(value, 'month') : isDate(block, 'month'),
            selectYear: getMethodArgs(block) === 'dateNot' ? isDate(value, 'year') : isDate(block, 'year'),
            not: isNotSet(block),
            blocks: getMethodArgs(block) === 'dateNot' ? [value] : [block],
        };
    }

    if (type === at.SCENE) {
        const sceneId = dataSource.fields[0].value;
        const tokenValue = dataSource.fields[1].value;
        const getSceneName = (id) => scenes.find((item) => item._id === id)?.name;

        return {
            id: id,
            selectedFieldTrigger: 'meshBotState',
            not: isNotSet(block),
            meshBotTriggerValue: sceneId,
            meshBotStateValue: tokenValue,
            name: getSceneName(sceneId),
            blocks: [dataSource],
        };
    }

    if (type === at.TRIGGER_HOUSE_MODE) {
        return {
            id: id,
            selectedFieldTrigger: at.TRIGGER_HOUSE_MODE,
            not: isNotSet(block),
            blocks: isNotSet(block) ? [block.fields[0].value] : [block],
        };
    }

    if (type === TRIGGER_TYPES.IS_CLOUD_STATE || type === TRIGGER_TYPES.IS_BATTERY_STATE) {
        const selectedControllerCapability = !isNotSet(block)
            ? block?.blockOptions?.method?.name
            : getControllerNodeValueObjectFromNotBlock(block)?.blockOptions?.method?.name;

        return {
            id: id,
            selectedFieldTrigger: CONTROLLER,
            selectedControllerCapability: getSelectedControllerCapability(selectedControllerCapability),
            selectedControllerCapabilityValue: !isNotSet(block)
                ? getControllerNodeValueFromNotBlockByName(block, STATE)
                : getControllerNodeValueFromNotBlockByName(getControllerNodeValueObjectFromNotBlock(block), STATE),
            selectedControllerCapabilityComparatorValue: '',
            not: isNotSet(block),
            blocks: [dataSource],
        };
    }

    if (type === TRIGGER_TYPES.IS_BATTERY_LEVEL) {
        return {
            id: id,
            selectedFieldTrigger: CONTROLLER,
            selectedControllerCapability: COMPARISON_DATA.METHOD.BATTERY_LEVEL,
            selectedControllerCapabilityComparator: !isNotSet(block)
                ? getControllerNodeValueFromNotBlockByName(block, COMPARATOR)
                : getControllerNodeValueFromNotBlockByName(getControllerNodeValueObjectFromNotBlock(block), COMPARATOR),
            selectedControllerCapabilityComparatorValue: !isNotSet(block)
                ? getControllerNodeValueFromNotBlockByName(block, VALUE)
                : getControllerNodeValueFromNotBlockByName(getControllerNodeValueObjectFromNotBlock(block), VALUE),
            not: isNotSet(block),
            blocks: [dataSource],
        };
    }

    if (type === at.EXPRESSION_COMPARISON) {
        return {
            id: id,
            not: isNotSet(block),
            selectedFieldTrigger: at.EXPRESSION_COMPARISON,
            blocks: isNotSet(block) ? [block.fields[0].value] : [block],
        };
    }

    if (type === at.VARIABLE_COMPARISON) {
        return {
            id: id,
            not: isNotSet(block),
            selectedFieldTrigger: at.VARIABLE_COMPARISON,
            blocks: isNotSet(block) ? [block.fields[0].value] : [block],
        };
    }

    if (type === at.DEVICE_GROUP) {
        const deviceGroupId = getDeviceGroupIdFromBlock(dataSource);
        const selectedCapability = dataSource?.blockMeta.ruleTrigger.selectedCapability;
        const comparingValue = dataSource?.blockMeta.ruleTrigger.comparingValue;
        const selectedComparator = dataSource?.blockMeta?.ruleTrigger?.selectedComparator;
        const compareTo = dataSource?.blockOptions?.method?.args?.value;

        return {
            id: id,
            not: isNotSet(block),
            selectedFieldTrigger: at.DEVICE_GROUP,
            deviceGroupId,
            selectedCapability,
            selectedComparator,
            compareTo,
            comparingValue,
            blocks: [dataSource],
        };
    }
};

export const getListOfCapabilitiesForControllerNode = (capabilityType) => {
    if (typeof capabilityType !== 'string') {
        throw new TypeError('Wrong capabilityType type');
    }

    if (capabilityType === COMPARISON_DATA.METHOD.CLOUD_CONNECTION) {
        return listOfValuesForCloudConnectionCapability;
    } else if (capabilityType === COMPARISON_DATA.METHOD.BATTERY_STATE) {
        return listOfValuesForBatteryStateCapability;
    }
};

export const getSelectedControllerCapability = (selectedControllerCapability) => {
    if (typeof selectedControllerCapability !== 'string') {
        throw new TypeError('Wrong capabilityType type');
    }

    if (selectedControllerCapability === TRIGGER_TYPES.IS_CLOUD_STATE) {
        return COMPARISON_DATA.METHOD.CLOUD_CONNECTION;
    } else if (selectedControllerCapability === TRIGGER_TYPES.IS_BATTERY_STATE) {
        return COMPARISON_DATA.METHOD.BATTERY_STATE;
    }
};

export const getSelectedControllerCapabilityForWhenBlock = (selectedControllerCapability) => {
    if (typeof selectedControllerCapability !== 'string') {
        throw new TypeError('Wrong capabilityType type');
    }

    if (selectedControllerCapability === COMPARISON_DATA.METHOD.CLOUD_CONNECTION) {
        return TRIGGER_TYPES.IS_CLOUD_STATE;
    } else if (selectedControllerCapability === COMPARISON_DATA.METHOD.BATTERY_STATE) {
        return TRIGGER_TYPES.IS_BATTERY_STATE;
    }
};

const findInFields = (fieldsArray, fieldName) => {
    return fieldsArray.find(({ name }) => name === fieldName);
};

/**
 * Converts the boolean values ​​of the "armed" or "reachable" states to string values
 * @param {string|boolean} state - the raw state value
 * @param {string} type - state type
 * @returns {string} - the string value ​​of the "armed" or "reachable" states
 * @throws {Error} - throws an error if the type is unknown
 */
export const mapState = (state, type) => {
    const stringState = String(state);

    switch (type) {
        case ARMED:
            switch (stringState) {
                case TRUE:
                    return ARMED_SELECT_VALUES.ARMED;
                case FALSE:
                    return ARMED_SELECT_VALUES.DISARMED;
                default:
                    return ARMED_SELECT_VALUES.ANY;
            }
        case REACHABLE:
            switch (stringState) {
                case TRUE:
                    return REACHABLE_SELECT_VALUES.REACHABLE;
                case FALSE:
                    return REACHABLE_SELECT_VALUES.UNREACHABLE;
                default:
                    return REACHABLE_SELECT_VALUES.ANY;
            }
        default:
            throw new Error('Unknown state type');
    }
};

const getValueToCompareFromBlock = (block, comparingMethod) => {
    const { fields } = block;
    switch (comparingMethod) {
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER:
        case COMPARISON_DATA.METHOD.IS_BUTTON_STATE:
        case COMPARISON_DATA.METHOD.STRING_OPERATION:
        case COMPARISON_DATA.METHOD.COMPARE_STRINGS:
            return findInFields(fields, COMPARISON_DATA.VALUE).value;
        case COMPARISON_DATA.METHOD.IS_ITEM_STATE:
            return findInFields(fields, COMPARISON_DATA.VALUE).value.toString();
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE:
            return {
                start: findInFields(fields, COMPARISON_DATA.START_VALUE).value,
                end: findInFields(fields, COMPARISON_DATA.END_VALUE).value,
            };
    }
};

/**
 * This function checks if the scene block belongs to Vidoo
 * @param {object} block - action block
 * @param {array} items - items of the devices
 * @param {array} devices - devices of the account
 * @returns {boolean}
 */
export const isVidooActionBlock = (block, items, devices) => {
    const itemId = block?.fields?.find((field) => field.name === ITEM)?.value;
    const itemName = items.find((item) => item._id === itemId)?.name;
    const deviceId = items.find((item) => item._id === itemId)?.deviceId;
    const deviceType = devices?.find((device) => device._id === deviceId)?.type;

    return Object.values(VIDOO_CAMERA_CAPABILITIES).includes(itemName) && deviceType === CAMERA_TYPE;
};

const getSelectedCameraId = (block, items) => {
    const itemId = block?.fields?.find((field) => field.name === 'item')?.value;

    return items.find((item) => item._id === itemId)?.deviceId;
};

/**
 * Get an updated Vidoo camera item based on a Vidoo action block and the current item.
 *
 * This function updates a Vidoo camera item based on a Vidoo action block and the current item. It matches the item with
 * the corresponding block label and updates the label, temporary ID, and the "value" field by resetting its "enum"
 * property to an empty array, effectively clearing any predefined values.
 *
 * @param {Object} item - The Vidoo camera item to be updated.
 * @param {Object} vidooActionBlock - The Vidoo action block containing a list of blocks.
 * @param {Object} currentItem - The current item containing information about the item to be updated.
 * @returns {Object} The updated Vidoo camera item.
 *
 * @example
 * const item = {
 *     "_id": "653b7f5e0000006d50befef2",
 *     "_tempId": "e0d6345a-a963-ca0a-e23b-1c5239891c96",
 *     "blockOptions": {
 *         "method": {
 *             "args": {
 *                 "item": "item",
 *                 "value": "value"
 *             },
 *             "name": "setItemValue"
 *         }
 *     },
 *     "blockType": "then",
 *     "fields": [
 *         {
 *             "name": "item",
 *             "type": "item",
 *             "value": "65378d24000000006e063ab4"
 *         },
 *         {
 *             "name": "value",
 *             "type": "int",
 *             "value": 19
 *         }
 *     ]
 * }
 * const vidooActionBlock = {
 *     "id": "0ba0f2fa-d486-2e1e-d627-6e5c5b3c92e3",
 *     "selectedFieldTrigger": "vidoo",
 *     "selectedCameraId": "65378d24000000006e063aae",
 *     "selectedCapabilityId": "65378d24000000006e063ab4",
 *     "selectedCapabilityValue": 1,
 *     "blocks": [
 *         {
 *             "_tempId": "ca22cfaf-28f0-0f05-c7fb-48881723ff8f",
 *             "blockOptions": {
 *                 "method": {
 *                     "args": {
 *                         "item": "item",
 *                         "value": "value"
 *                     },
 *                     "name": "setItemValue"
 *                 }
 *             },
 *             "blockType": "then",
 *             "fields": [
 *                 {
 *                     "name": "item",
 *                     "type": "item",
 *                     "value": "65378d24000000006e063ab3"
 *                 },
 *                 {
 *                     "name": "value",
 *                     "enum": [],
 *                     "type": "int",
 *                     "value": 1
 *                 }
 *             ],
 *             "label": {
 *                 "lang_tag": "when_switch",
 *                 "text": "take_snapshot"
 *             }
 *         },
 *         {
 *             "_tempId": "66d5811b-942e-d181-2b6d-fb0f7eaa654c",
 *             "blockOptions": {
 *                 "method": {
 *                     "args": {
 *                         "item": "item",
 *                         "value": "value"
 *                     },
 *                     "name": "setItemValue"
 *                 }
 *             },
 *             "blockType": "then",
 *             "fields": [
 *                 {
 *                     "name": "item",
 *                     "type": "item",
 *                     "value": "65378d24000000006e063ab4"
 *                 },
 *                 {
 *                     "name": "value",
 *                     "enum": [],
 *                     "type": "int",
 *                     "value": 1
 *                 }
 *             ],
 *             "label": {
 *                 "lang_tag": "when_switch",
 *                 "text": "make_recording"
 *             }
 *         },
 *         {
 *             "_tempId": "fcb9e9a9-7334-2a4a-8b08-5e3feb6ad82e",
 *             "blockOptions": {
 *                 "method": {
 *                     "args": {
 *                         "item": "item",
 *                         "value": "value"
 *                     },
 *                     "name": "setItemValue"
 *                 }
 *             },
 *             "blockType": "then",
 *             "fields": [
 *                 {
 *                     "name": "item",
 *                     "type": "item",
 *                     "value": "65378d24000000006e063ab5"
 *                 },
 *                 {
 *                     "name": "value",
 *                     "enum": [],
 *                     "type": "int",
 *                     "value": 1
 *                 }
 *             ],
 *             "label": {
 *                 "lang_tag": "when_switch",
 *                 "text": "stop_recording"
 *             }
 *         }
 *     ]
 * }
 * const currentItem = {
 *     "_id": "65378d24000000006e063ab4",
 *     "deviceId": "65378d24000000006e063aae",
 *     "hasGetter": false,
 *     "hasSetter": true,
 *     "maxValue": 60,
 *     "minValue": 15,
 *     "name": "make_recording",
 *     "show": true,
 *     "value": 0,
 *     "valueFormatted": "0",
 *     "valueType": "int"
 * };
 * const updatedItem = getUpdatedVidooCameraItem(item, vidooActionBlock, currentItem);
 * // Result in updatedItem: {
 *     "_id": "653b7f5e0000006d50befef2",
 *     "_tempId": "13877420-fb76-db97-b37e-a48d6c03b47a",
 *     "blockOptions": {
 *         "method": {
 *             "args": {
 *                 "item": "item",
 *                 "value": "value"
 *             },
 *             "name": "setItemValue"
 *         }
 *     },
 *     "blockType": "then",
 *     "fields": [
 *         {
 *             "name": "item",
 *             "type": "item",
 *             "value": "65378d24000000006e063ab4"
 *         },
 *         {
 *             "name": "value",
 *             "type": "int",
 *             "value": 19,
 *             "enum": []
 *         }
 *     ],
 *     "label": {
 *         "lang_tag": "when_switch",
 *         "text": "make_recording"
 *     }
 * }
 */

export const getUpdatedVidooCameraItem = (item, vidooActionBlock, currentItem) => {
    const block = vidooActionBlock.blocks.find(({ label }) => label.text === currentItem.name);

    return {
        ...item,
        label: block.label,
        _tempId: block._tempId,
        fields: item.fields.map((field) => {
            if (field.name === VALUE) {
                return { ...field, enum: [] };
            }

            return field;
        }),
    };
};

const getCapabilityValue = (item) => item?.fields?.find((field) => field.name === 'value')?.value;

const getCameraCapabilityId = (item) => item?.fields?.find((field) => field.name === 'item')?.value;

export const getActionsBlocks = (data = [], items, devices, scenes) => {
    let actionsList = [];
    let actionsSelect = [];

    const actionItemScript = (id, data, field) => {
        const getFieldValue = (nameField) => data.fields.find((item) => item.name === nameField);
        const actionItem = {
            id: id,
            selectedFieldTrigger: field,
            selectedRequest: getFieldValue(HTTP_REQUEST_FIELD_KIND.REQUEST)
                ? getFieldValue(HTTP_REQUEST_FIELD_KIND.REQUEST)?.value.toUpperCase()
                : 'GET',
            selectedUrl: getFieldValue(HTTP_REQUEST_FIELD_KIND.URL).value,
            selectedSecurity: getFieldValue(HTTP_REQUEST_FIELD_KIND.SKIP_SECURITY).value,
            selectedCredentialUser: getFieldValue(HTTP_REQUEST_FIELD_KIND.CREDENTIAL)?.value?.user || '',
            selectedCredentialPassword: getFieldValue(HTTP_REQUEST_FIELD_KIND.CREDENTIAL)?.value?.password || '',
            selectedHeaders: getFieldValue(HTTP_REQUEST_FIELD_KIND.HEADERS)?.value || {},
            selectedContentType:
                getFieldValue(HTTP_REQUEST_FIELD_KIND.CONTENT_TYPE) &&
                getFieldValue(HTTP_REQUEST_FIELD_KIND.CONTENT_TYPE).value,
            selectedContent:
                getFieldValue(HTTP_REQUEST_FIELD_KIND.CONTENT) && getFieldValue(HTTP_REQUEST_FIELD_KIND.CONTENT).value,
            blocks: [data],
        };

        if (data?.saveResult) {
            actionItem.saveResult = data.saveResult;
        }

        return actionItem;
    };

    const actionItemLuaScript = (id, data, field) => {
        return {
            id: id,
            blocks: [data],
            selectedFieldTrigger: field,
            selectedName: '',
            selectedCode: '',
            selectedPoint: 'existingScript',
        };
    };

    const getBlock = (id, deviceId, item) => {
        const device = devices.find((elem) => elem._id === deviceId);
        const itemsDevice = items.filter((elem) => elem.deviceId === device?._id && elem.hasSetter);
        const name = item.blockOptions.method.name === TOGGLE_VALUE_METHOD_NAME ? TOGGLE_VALUE_METHOD_NAME : ITEM;

        let listBlocks = itemsDevice.map((elem) => {
            if (elem.hasOwnProperty('enum')) {
                return createBlock(elem, name, elem.enum);
            }

            return createBlock(elem, name);
        });

        if (device?.hasOwnProperty('armed')) {
            const armedBlock = createBlock(device, at.DEVICE, []);
            listBlocks = [...listBlocks, armedBlock];
        }

        listBlocks.map((elem) => {
            const id = elem.fields?.[0]?.value;
            // eslint-disable-next-line
            const secondField = elem?.fields?.[1];

            if (id === item.fields?.[0]?.value) {
                if (secondField && secondField.hasOwnProperty('enum')) {
                    secondField.value = _.cloneDeep(item.fields[INDEX_SELECTED_FIELDS_ELEMENT]?.value);
                } else {
                    elem.fields = _.cloneDeep(item.fields);
                }

                if (item.delay) {
                    elem.delay = _.cloneDeep(item.delay);
                }

                return elem;
            }

            return elem;
        });

        return {
            id: id,
            idDev: deviceId,
            name: device?.name,
            firstBlock: listBlocks.filter((elem) => elem.fields[0].value === item.fields[0].value)[0]?._tempId,
            selectedFieldTrigger: 'deviceState',
            blocks: listBlocks.length ? [...listBlocks] : [item],
        };
    };

    const actionMeshBotBlock = (id, item, scenes) => {
        const meshBotId = item.fields[0].value;
        const scene = scenes.find((scene) => scene._id === meshBotId);

        const methodName = item.blockOptions.method.name;

        let meshBotAction = 'runScene';

        if (methodName === 'setSceneState') {
            meshBotAction = item.fields[1].value ? 'enableScene' : 'disableScene';
        }

        return {
            id,
            name: scene?.name,
            selectedFieldTrigger: 'meshBotState',
            firstBlock: data._tempId,
            blocks: [...data],
            meshBotId,
            meshBotAction,
        };
    };

    const getCloudAPIBlock = (id, item) => {
        const isPAAS = item.fields?.[0].value === ABSTRACT_COMMAND;

        if (isPAAS) {
            return {
                id: id,
                selectedFieldTrigger: 'PAAS',
                blocks: [item.fields[2].value],
            };
        }

        return {
            id: id,
            name: 'notification',
            selectedFieldTrigger: 'notification',
            firstBlock: data._tempId,
            blocks: [item],
        };
    };

    const getVidooActionBlock = (id, item) => {
        const selectedCameraId = getSelectedCameraId(item, items);
        const selectedCameraItems = items.filter(
            ({ deviceId, hasSetter }) => deviceId === selectedCameraId && hasSetter,
        );
        const blocks = selectedCameraItems.map((item) => {
            return blockActionTemplate(
                item.name,
                item.valueType,
                meshbot.ITEM,
                item._id,
                INITIAL_VIDOO_CAPABILITY_VALUE,
                [],
            );
        });

        return {
            id: id,
            selectedFieldTrigger: MESHBOT_NODE_TYPES.VIDOO,
            selectedCameraId,
            selectedCapabilityId: getCameraCapabilityId(item),
            selectedCapabilityValue: getCapabilityValue(item),
            blocks,
        };
    };

    const getLocalVariableBlock = (id, item) => ({
        id: id,
        selectedFieldTrigger: ACTION_TYPES.LOCAL_VARIABLE,
        blocks: [item],
    });
    const getControllerNodeBlock = (id, item) => ({
        id: id,
        selectedFieldTrigger: MESHBOT_NODE_TYPES.CONTROLLER,
        blocks: [item],
    });

    const createBlock = (data, method, isEnum) => {
        return blockActionTemplate(data.name, data.valueType, method, data._id, data.value, isEnum);
    };

    data.map((item) => {
        const idItem = item.fields[0]?.value;
        const id = hash();
        const [currentItem] = items?.filter((elem) => elem._id === idItem);
        let updatedItem = item;

        switch (getMethodName(item.blockOptions)) {
            case RUN_CUSTOM_SCRIPT:
                actionsList = [...actionsList, actionItemLuaScript(id, item, MESHBOT_NODE_TYPES.LUA_SCRIPT)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case SET_HTTP_REQUEST:
                actionsList = [...actionsList, actionItemScript(id, item, MESHBOT_NODE_TYPES.HTTP_REQUEST)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case SET_DEVICE_ARMED:
                actionsList = [...actionsList, getBlock(id, idItem, item)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case RUN_SCENE:
            case SET_SCENE_STATE:
                actionsList = [...actionsList, actionMeshBotBlock(id, item, scenes)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case RESET_LATCH:
                actionsList = [...actionsList, actionMeshBotBlock(id, item, scenes)];
                actionsSelect = [...actionsSelect, { id: id, blocks: [item] }];
                break;
            case RESET_SCENE_LATCHES:
                actionsList = [...actionsList, actionMeshBotBlock(id, item, scenes)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case CLOUD_API:
                actionsList = [...actionsList, getCloudAPIBlock(id, item, scenes)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case ACTION_TYPES.SWITCH_HOUSE_MODE:
                const actionData = { id, blocks: [item] };
                actionsList = [
                    ...actionsList,
                    {
                        ...actionData,
                        selectedFieldTrigger: MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE,
                    },
                ];
                actionsSelect = [...actionsSelect, actionData];
                break;
            case SET_ITEM_VALUE:
                const isVidoo = isVidooActionBlock(item, items, devices);

                if (isVidoo) {
                    const vidooActionBlock = getVidooActionBlock(id, item);
                    updatedItem = getUpdatedVidooCameraItem(item, vidooActionBlock, currentItem);
                    actionsList = [...actionsList, vidooActionBlock];
                } else {
                    actionsList = [...actionsList, getBlock(id, currentItem?.deviceId || idItem, item)];
                }

                if (MESHBOT_PROTOCOLS_PLUGIN_LIST.includes(currentItem?.name)) {
                    updatedItem = { ...item, label: { text: currentItem.name } };
                }

                actionsSelect = [...actionsSelect, { id: id, blocks: [updatedItem] }];
                break;
            case ACTION_TYPES.LOCAL_VARIABLE:
                actionsList = [...actionsList, getLocalVariableBlock(id, item)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case ACTION_TYPES.REBOOT_HUB:
            case ACTION_TYPES.RESET_HUB:
                actionsList = [...actionsList, getControllerNodeBlock(id, item)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            case ACTION_TYPES.TOGGLE_VALUE:
                actionsList = [...actionsList, getBlock(id, currentItem?.deviceId || idItem, item)];
                actionsSelect = [...actionsSelect, { id, blocks: [item] }];
                break;
            default:
                actionsList = [...actionsList, getBlock(id, currentItem?.deviceId, item)];
                actionsSelect = [...actionsSelect, { id: id, blocks: [item] }];
                break;
        }
    });

    return { actionsList, actionsSelect };
};

/**
 * This function returns blockOptions object with the logic operator name in the exceptions
 * @param {string} type - logic operator name
 * @returns {object} - blockOptions object
 */
export const updateOptionsName = (type) => {
    if (type === null || type === undefined) {
        throw new Error('#updateOptionsName:the parameter "type" is required');
    }

    return { method: { args: { blocks: at.BLOCKS }, name: type } };
};

export const getFieldsStructure = (name, type, value) => {
    return [{ name, type, value }];
};

const getGroupTemplate = (blocks) => {
    return {
        blockOptions: { method: { args: { block: at.BLOCK }, name: NOT_NAME } },
        blockType: 'when',
        fields: [{ name: at.BLOCK, type: at.BLOCK, value: { ...blocks } }],
    };
};

const checkAndTransformFunctionalBlock = (block, item) => {
    const hasFunctionField = item.hasOwnProperty('function');

    if (hasFunctionField) {
        return createFunctionalBlock({ functionBlock: item.function, block });
    }

    return block;
};

const getGroupBlock = (data, restructureWhenBlock) => {
    return data.blocks.map((block) => {
        let [currentBlock] = block.blocks;

        if (block.hasOwnProperty('blockName')) {
            const group = {
                blockName: block.blockName,
                blockOptions: updateOptionsName(block.optionType),
                blockType: 'when',
                fields: getFieldsStructure(at.BLOCKS, at.BLOCKS, restructureWhenBlock(block.blocks).updatedWhen),
            };
            let currentBlockInGroup = group;

            if (block.not) {
                currentBlockInGroup = getGroupTemplate(group);
            }

            return checkAndTransformFunctionalBlock(currentBlockInGroup, block);
        }

        if (block.not) {
            currentBlock = getGroupTemplate(Object.assign(block.blocks[0]));
        }

        return checkAndTransformFunctionalBlock(currentBlock, block);
    });
};

const createFunctionalBlock = ({ functionBlock, block }) => {
    return {
        blockOptions: {
            method: {
                args: { blocks: meshBot.MESHBOT_BLOCKS },
                name: meshBot.TRIGGER_TYPES.FUNCTION,
            },
            function: functionBlock,
        },
        blockType: meshBot.BLOCK_WHEN,
        fields: [
            {
                name: meshBot.MESHBOT_BLOCKS,
                type: meshBot.MESHBOT_BLOCKS,
                value: [block],
            },
        ],
    };
};

const restructureWhenBlock = (when) => {
    const updatedWhen = [];
    let updatedDeviceOptionType = null;

    when.forEach((item) => {
        const notBlock = {
            blockOptions: { method: { args: { block: at.BLOCK }, name: NOT_NAME } },
            blockType: 'when',
            fields: [],
        };

        if (item.hasOwnProperty('blockName')) {
            const group = {
                blockName: item.blockName,
                blockOptions: updateOptionsName(item.optionType),
                blockType: 'when',
                fields: [],
            };

            if (item.not) {
                group.fields = getFieldsStructure(at.BLOCKS, at.BLOCKS, getGroupBlock(item, restructureWhenBlock));
                notBlock.fields = getFieldsStructure(at.BLOCK, at.BLOCK, group);

                updatedWhen.push(checkAndTransformFunctionalBlock(notBlock, item));
            } else {
                group.fields = getFieldsStructure(at.BLOCKS, at.BLOCKS, getGroupBlock(item, restructureWhenBlock));
                updatedWhen.push(checkAndTransformFunctionalBlock(group, item));
            }
        } else if (item.blocks) {
            if (item.not) {
                updatedDeviceOptionType = item.optionType;
                notBlock.fields = getFieldsStructure(at.BLOCK, at.BLOCK, Object.assign(item.blocks[0]));
                updatedWhen.push(checkAndTransformFunctionalBlock(notBlock, item));
            } else {
                updatedDeviceOptionType = item.optionType;
                updatedWhen.push(checkAndTransformFunctionalBlock(item.blocks[0], item));
            }
        } else {
            updatedWhen.push(checkAndTransformFunctionalBlock(item, item));
        }
    });

    return { updatedWhen, updatedDeviceOptionType };
};

/**
 * Create not block
 * @param {object} fields - trigger
 * @returns {object} returned wrap over trigger
 * @example
 * notBlock(fields)
 */
const notBlock = (fields) => {
    return {
        blockOptions: { method: { args: { block: at.BLOCK }, name: NOT } },
        blockType: BLOCK_WHEN,
        fields: fields,
    };
};

/**
 * Create group block
 * @param {object} trigger - Object trigger
 * @param {array} fields - Blocks trigger
 * @returns {object} returned group
 * @example
 * groupBlock(trigger, fields)
 */
export const groupBlock = (trigger, fields) => {
    return {
        blockName: trigger.blockName,
        blockOptions: updateOptionsName(trigger.optionType),
        blockType: BLOCK_WHEN,
        fields: fields,
    };
};

/**
 * Function with not block
 * @param {object} block - Trigger block
 * @param {object} item - Item mapping
 * @returns {object} returned group
 * @example
 * functionWithNotBlock({ block, item })
 */

const functionWithNotBlock = ({ block, item }) => {
    return checkAndTransformFunctionalBlock(notBlock(getFieldsStructure(at.BLOCK, at.BLOCK, block)), item);
};

/**
 * Create block for exception
 * @param {array} list - triggers list
 * @returns {array} returned trigger
 * @example
 * createBlockForException(list)
 */
export const createBlockForException = (list) => {
    return list.map((trigger) => {
        const block = { ..._.cloneDeep(trigger.blocks[meshbot.INDEX_OF_ZERO]) };

        if (block.hasOwnProperty(meshbot.ID_PROPERTY)) {
            delete block._id;
        }

        if (trigger.hasOwnProperty(BLOCK_NAME)) {
            const group = groupBlock(
                trigger,
                getFieldsStructure(at.BLOCKS, at.BLOCKS, createBlockForException(trigger.blocks)),
            );

            if (trigger.not) {
                return functionWithNotBlock({ block: group, item: trigger });
            }

            return checkAndTransformFunctionalBlock(group, trigger);
        }

        if (trigger.not) {
            return functionWithNotBlock({ block: block, item: trigger });
        }

        return checkAndTransformFunctionalBlock(block, trigger);
    });
};
const getBlocksDataFromExceptionTrigger = (trigger) => {
    const block = getBlockFromTrigger(trigger);

    if (trigger.not) {
        return functionWithNotBlock({ block, item: trigger });
    }

    return checkAndTransformFunctionalBlock(block, trigger);
};

const updateDataForGlobalRestriction = (exceptionData, exception) => {
    if (exception.blockType === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION) {
        return {
            ...exceptionData,
            blockType: exception.blockType,
        };
    }

    return exceptionData;
};

/**
 * Restructure exception in whenBlock
 * @param {array} exceptions - exceptions list
 * @returns {array} returned exceptions
 * @example
 * restructureExceptionWhenBlock(exceptions)
 */
export const restructureExceptionWhenBlock = (exceptions) => {
    return exceptions.reduce((acc, exception) => {
        if (exception.triggers.length > meshbot.INDEX_OF_ONE) {
            const triggerWithLogicOperator = {
                blockOptions: updateOptionsName(exception.optionType),
                blockType: BLOCK_WHEN,
                fields: [
                    {
                        name: at.BLOCKS,
                        type: at.BLOCKS,
                        value: createBlockForException(exception.triggers),
                    },
                ],
            };

            return [
                ...acc,
                updateDataForGlobalRestriction(
                    {
                        ...checkAndTransformFunctionalBlock(triggerWithLogicOperator, exception),
                        _id: exception._id,
                    },
                    exception,
                ),
            ];
        }

        return [
            ...acc,
            ...exception.triggers.map((trigger) =>
                updateDataForGlobalRestriction(
                    { ...getBlocksDataFromExceptionTrigger(trigger), _id: exception._id },
                    exception,
                ),
            ),
        ];
    }, []);
};

export const dataRestructuring = (data) => {
    const { updatedWhen, updatedDeviceOptionType } = restructureWhenBlock(data.when);

    let resultWhen = [...updatedWhen] || [];
    const resultExceptionWhen = restructureExceptionWhenBlock(data.exceptions);
    const resultThen = [];
    const resultElse = [];

    let deviceOptionType = updatedDeviceOptionType || null;

    if (resultWhen.length > 1) {
        deviceOptionType = data.optionType;
        resultWhen = [
            {
                blockOptions: updateOptionsName(deviceOptionType),
                blockType: BLOCK_WHEN,
                fields: [{ name: at.BLOCKS, type: at.BLOCKS, value: resultWhen }],
            },
        ];
    }

    if (data.function) {
        resultWhen = [createFunctionalBlock({ functionBlock: data.function, block: resultWhen[0] })];
    }

    data.then.forEach((item) => {
        if (item.blocks && item.blocks.length) {
            // eslint-disable-next-line
            const { label, ...rest } = item.blocks[0];

            if (rest && rest.fields && rest.fields[1]) {
                delete rest.fields[1].enum;
            }

            const data = [rest];

            return resultThen.push(...data);
        } else {
            resultThen.push(item);
        }
    });

    data.else.forEach((item) => {
        if (item.blocks) {
            // eslint-disable-next-line
            const { label, ...rest } = item.blocks[0];
            if (rest && rest.fields && rest.fields[1]) {
                delete rest.fields[1].enum;
            }
            const data = [rest];

            return resultElse.push(...data);
        } else {
            resultElse.push(item);
        }
    });

    const updatedData = _.cloneDeep(data);

    delete updatedData.optionType;
    delete updatedData.function;
    updatedData.when = resultWhen;
    updatedData.exceptions = resultExceptionWhen;
    updatedData.then = resultThen;
    updatedData.else = resultElse;
    deviceOptionType = null;

    return updatedData;
};

/**
 * Returns MeshBot fields as an object that must not be cleared
 * @param {boolean} skipName=false - skip 'name' field while clearing MeshBot
 * @returns {object} skipFields object
 * */
export const buildClearMeshBotSkipFields = (skipName = false) => {
    return {
        name: skipName,
    };
};

export const createMeshBotTriggerBlock = (name, value) => {
    return [
        {
            blockType: 'when',
            blockOptions: {
                method: {
                    name: 'isSceneState',
                    args: {
                        scene: 'scene',
                        state: 'state',
                    },
                },
            },
            fields: [
                {
                    name: 'scene',
                    type: 'sceneId',
                    value: name,
                },
                {
                    name: 'state',
                    type: 'token',
                    value: value,
                },
            ],
        },
    ];
};

export const transformHeaders = (data) => {
    return data.reduce((acc, item) => {
        return { ...acc, [item.key]: item.value };
    }, {});
};

export const checkedEmptyKey = (key) => (key.slice(0, 7) === '__empty' ? '' : key);

export const convertTimeToSeconds = (minutes, hours, days) => {
    let totalTimeInSeconds = 0;

    totalTimeInSeconds += minutes ? minutes * 60 : 0;
    totalTimeInSeconds += hours ? hours * 60 * 60 : 0;
    totalTimeInSeconds += days ? days * 24 * 60 * 60 : 0;

    return totalTimeInSeconds;
};

export const createVidooTriggerBlock = (vidooType, faceId, objectId, qrcodeId, itemId, hotzoneId) => {
    if (vidooType === VIDOO_TYPES.ANY_MOTION && itemId) {
        return [
            {
                blockType: 'when',
                blockOptions: {
                    method: {
                        name: 'isDetectedInHotzone',
                        args: {
                            item: 'item',
                            match: 'match',
                            hotzones: 'hotzones',
                        },
                    },
                },
                fields: [
                    {
                        name: 'item',
                        type: 'item',
                        value: itemId,
                    },
                    {
                        name: 'match',
                        type: 'token',
                        value: 'any',
                    },
                    {
                        name: 'hotzones',
                        type: 'array.token',
                        value: hotzoneId,
                    },
                ],
            },
        ];
    }

    if (vidooType === VIDOO_TYPES.FACE_DETECTION && itemId && faceId) {
        if (faceId === SUMMARY_VIDOO_OPTIONS.ANY_PERSON) {
            return [
                {
                    blockType: 'when',
                    blockOptions: {
                        method: {
                            name: 'isDetectedInHotzone',
                            args: {
                                item: 'item',
                                match: 'match',
                                hotzones: 'hotzones',
                                filters: 'filters',
                            },
                        },
                    },
                    fields: [
                        {
                            name: 'item',
                            type: 'item',
                            value: itemId,
                        },
                        {
                            name: 'match',
                            type: 'token',
                            value: 'filter',
                        },
                        {
                            name: 'hotzones',
                            type: 'array.token',
                            value: hotzoneId,
                        },
                        {
                            name: 'filters',
                            type: 'dictionary.hotzone_match',
                            value: {
                                persons: {
                                    match: 'any',
                                },
                            },
                        },
                    ],
                },
            ];
        }

        return [
            {
                blockType: 'when',
                blockOptions: {
                    method: {
                        name: 'isDetectedInHotzone',
                        args: {
                            item: 'item',
                            match: 'match',
                            hotzones: 'hotzones',
                            filters: 'filters',
                        },
                    },
                },
                fields: [
                    {
                        name: 'item',
                        type: 'item',
                        value: itemId,
                    },
                    {
                        name: 'match',
                        type: 'token',
                        value: 'filter',
                    },
                    {
                        name: 'hotzones',
                        type: 'array.token',
                        value: hotzoneId,
                    },
                    {
                        name: 'filters',
                        type: 'dictionary.hotzone_match',
                        value: {
                            persons: {
                                match: 'anyOf',
                                list: faceId,
                            },
                        },
                    },
                ],
            },
        ];
    }

    if (vidooType === VIDOO_TYPES.OBJECT_DETECTION && itemId && objectId) {
        if (objectId === SUMMARY_VIDOO_OPTIONS.ANY_OBJECT) {
            return [
                {
                    blockType: 'when',
                    blockOptions: {
                        method: {
                            name: 'isDetectedInHotzone',
                            args: {
                                item: 'item',
                                match: 'match',
                                hotzones: 'hotzones',
                                filters: 'filters',
                            },
                        },
                    },
                    fields: [
                        {
                            name: 'item',
                            type: 'item',
                            value: itemId,
                        },
                        {
                            name: 'match',
                            type: 'token',
                            value: 'filter',
                        },
                        {
                            name: 'hotzones',
                            type: 'array.token',
                            value: hotzoneId,
                        },
                        {
                            name: 'filters',
                            type: 'dictionary.hotzone_match',
                            value: {
                                objects: {
                                    match: 'any',
                                },
                            },
                        },
                    ],
                },
            ];
        }

        return [
            {
                blockType: 'when',
                blockOptions: {
                    method: {
                        name: 'isDetectedInHotzone',
                        args: {
                            item: 'item',
                            match: 'match',
                            hotzones: 'hotzones',
                            filters: 'filters',
                        },
                    },
                },
                fields: [
                    {
                        name: 'item',
                        type: 'item',
                        value: itemId,
                    },
                    {
                        name: 'match',
                        type: 'token',
                        value: 'filter',
                    },
                    {
                        name: 'hotzones',
                        type: 'array.token',
                        value: hotzoneId,
                    },
                    {
                        name: 'filters',
                        type: 'dictionary.hotzone_match',
                        value: {
                            objects: {
                                match: 'anyOf',
                                list: objectId,
                            },
                        },
                    },
                ],
            },
        ];
    }

    if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION && itemId && qrcodeId) {
        if (qrcodeId === SUMMARY_VIDOO_OPTIONS.ANY_QRCODE) {
            return [
                {
                    blockType: 'when',
                    blockOptions: {
                        method: {
                            name: 'isDetectedInHotzone',
                            args: {
                                item: 'item',
                                match: 'match',
                                hotzones: 'hotzones',
                                filters: 'filters',
                            },
                        },
                    },
                    fields: [
                        {
                            name: 'item',
                            type: 'item',
                            value: itemId,
                        },
                        {
                            name: 'match',
                            type: 'token',
                            value: 'filter',
                        },
                        {
                            name: 'hotzones',
                            type: 'array.token',
                            value: hotzoneId,
                        },
                        {
                            name: 'filters',
                            type: 'dictionary.hotzone_match',
                            value: {
                                qrcodes: {
                                    match: 'any',
                                },
                            },
                        },
                    ],
                },
            ];
        }

        return [
            {
                blockType: 'when',
                blockOptions: {
                    method: {
                        name: 'isDetectedInHotzone',
                        args: {
                            item: 'item',
                            match: 'match',
                            hotzones: 'hotzones',
                            filters: 'filters',
                        },
                    },
                },
                fields: [
                    {
                        name: 'item',
                        type: 'item',
                        value: itemId,
                    },
                    {
                        name: 'match',
                        type: 'token',
                        value: 'filter',
                    },
                    {
                        name: 'hotzones',
                        type: 'array.token',
                        value: hotzoneId,
                    },
                    {
                        name: 'filters',
                        type: 'dictionary.hotzone_match',
                        value: {
                            qrcodes: {
                                match: 'anyOf',
                                list: qrcodeId,
                            },
                        },
                    },
                ],
            },
        ];
    }
};

export const getSelectedTypeCapability = (value) => {
    if (value === SUMMARY_VIDOO_OPTIONS.ANY_PERSON) {
        return SUMMARY_VIDOO_OPTIONS.ANY_PERSON;
    }

    if (value === SUMMARY_VIDOO_OPTIONS.ANY_OBJECT) {
        return SUMMARY_VIDOO_OPTIONS.ANY_OBJECT;
    }

    if (value === SUMMARY_VIDOO_OPTIONS.ANY_QRCODE) {
        return SUMMARY_VIDOO_OPTIONS.ANY_QRCODE;
    }

    return [value];
};

export const setHotzoneId = (value) => {
    if (value === SUMMARY_VIDOO_OPTIONS.ANY_ZONE) {
        return [];
    }

    if (value === SUMMARY_VIDOO_OPTIONS.FULL_SCREEN) {
        return [''];
    }

    return value;
};

export const getSelectedHotzoneId = (value) => {
    if (value === SUMMARY_VIDOO_OPTIONS.ANY_ZONE) {
        return SUMMARY_VIDOO_OPTIONS.ANY_ZONE;
    }

    if (value === SUMMARY_VIDOO_OPTIONS.FULL_SCREEN) {
        return SUMMARY_VIDOO_OPTIONS.FULL_SCREEN;
    }

    return [value];
};

export const getVidooItemId = (cameraId, items) => {
    if (cameraId) {
        return items.find((item) => item.name === VIDOO_ITEM_NAME.HOTZONES && item.deviceId === cameraId)?._id;
    }
};

export const getTypesVidooList = (vidooType, items) => {
    if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
        const value = items.find((item) => item.name === VIDOO_ITEM_NAME.DETECTABLE_OBJECTS)?.value;

        if (value) {
            return Object.entries(value);
        }
    }

    if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
        const value = items.find((item) => item.name === VIDOO_ITEM_NAME.DETECTABLE_PERSONS)?.value;

        if (value) {
            return Object.entries(value);
        }
    }

    if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
        const value = items.find((item) => item.name === VIDOO_ITEM_NAME.DETECTABLE_QRCODES)?.value;

        if (value) {
            return Object.entries(value);
        }
    }

    if (vidooType === VIDOO_TYPES.ANY_MOTION) {
        return [];
    }

    return [];
};

export const getVidooCamerasList = (devices) => {
    if (devices) {
        return devices.filter((device) => device.category === CAMERA);
    }
};

export const getCameraHotzones = (cameraId, items) => {
    if (cameraId) {
        return Object.entries(
            items.find((item) => item.elementType === CAMERA_HOTZONE && item.deviceId === cameraId)?.value,
        );
    }

    return [];
};

export const isChangedHotzones = (currentHotzones, newHotzones) => {
    if (!currentHotzones || !newHotzones) {
        throw new Error('There is no data');
    }

    if (Object.values(currentHotzones).length !== Object.values(newHotzones).length) {
        return true;
    }
    for (const [key, value] of Object.entries(currentHotzones)) {
        if (value.name !== newHotzones[key]?.name) {
            return true;
        }
    }

    return false;
};

export const getCameraCapabilitiesList = (cameraId, items) => {
    if (cameraId) {
        return items?.filter(
            (item) =>
                item.deviceId === cameraId &&
                (item.name === VIDOO_CAMERA_CAPABILITIES.MAKE_RECORDING ||
                    item.name === VIDOO_CAMERA_CAPABILITIES.TAKE_SNAPSHOT ||
                    item.name === VIDOO_CAMERA_CAPABILITIES.STOP_RECORDING),
        );
    }

    return [];
};

export const validateSelectedCameraCapability = (capabilityId, items) => {
    let capabilityName = '';

    if (capabilityId) {
        capabilityName = items.find((item) => item._id === capabilityId)?.name;
    }

    return capabilityName;
};

export const getCurrentTempId = (capabilityId, blocks) => {
    if (blocks && blocks.length > 0 && capabilityId) {
        return blocks.find((block) => block.fields[0].value === capabilityId)?._tempId;
    }
};
/**
 * Get the initial value for a specific camera capability.
 *
 * This function retrieves the initial value for a specific camera capability based on its ID and the provided array of
 * camera capabilities. It returns the minimum value defined in INPUT_PROPS for the "Make Recording" capability and the
 * default value INITIAL_VIDOO_CAPABILITY_VALUE for all other capabilities.
 *
 * @param {Array} cameraCapabilities - An array of camera capabilities, each containing an "_id" and "name" property.
 * @param {string} capabilityId - The ID of the camera capability for which to retrieve the initial value.
 * @returns {number} The initial value for the specified capability.
 *
 * @example
 * const cameraCapabilities = [
 *   { _id: '123', name: 'make_recording' },
 *   { _id: '456', name: 'take_snapshot' },
 *   // Other capabilities
 * ];
 * const capabilityId = '123'; // Make Recording capability
 * const initialValue = getInitialCapabilityValue(cameraCapabilities, capabilityId);
 * // Result: The minimum value defined in INPUT_PROPS (e.g., 15) because it's the "Make Recording" capability.
 */
export const getInitialCapabilityValue = (cameraCapabilities, capabilityId) => {
    const item = cameraCapabilities.find(({ _id }) => _id === capabilityId);

    return item?.name === VIDOO_CAMERA_CAPABILITIES.MAKE_RECORDING ? INPUT_PROPS.min : INITIAL_VIDOO_CAPABILITY_VALUE;
};

/**
 * Returns className for then block
 * @param {object} block - block to analyze
 * @return {string} className
 * */

export const getClassesNamesForThenBlocks = (block) => {
    if (block.isValid && !block.isEdited) {
        return `${trigger_block} ${editing} ${connection}`;
    } else if (block.isValid && block.isEdited) {
        return `${trigger_block} ${is_valid} ${connection}`;
    } else if (!block.isValid && block.isEdited) {
        return `${trigger_block} ${connection}`;
    } else if (!block.isValid && !block.isEdited) {
        return `${trigger_block}`;
    }
};

/**
 * Returns className for validation result
 * @param {object} action - action to analyze
 * @param {array} initialActions - initial Cloud MeshBot actions
 * @return {string} className
 * */

export const getClassNameFromValidation = (
    action,
    initialActions,
    actionsList,
    id,
    selectedRuleCloudNucal,
    notificationType,
) => {
    if (initialActions) {
        if (action && action.notification && action.notification.name === 'notify-users') {
            return isEditActionNotificationBlock(action, actionsList, initialActions, notificationType);
        } else if (action && action.PAAS) {
            const findActionIndex = actionsList.findIndex((item) => item.id === id);

            if (_.isEqual(action.PAAS?.parameters, initialActions[findActionIndex]?.parameters)) {
                return `${trigger_block} ${editing}`;
            } else if (isValidActionBlock({ action, selectedRuleCloudNucal, notificationType })) {
                return `${trigger_block} ${is_valid}`;
            } else {
                return `${trigger_block}`;
            }
        } else {
            const findActionIndex = actionsList.findIndex((item) => item.id === id);

            if (
                action?.deviceParameters &&
                _.isEqual(action?.deviceParameters, initialActions[findActionIndex]?.parameters)
            ) {
                return `${trigger_block} ${editing}`;
            } else if (isValidActionBlock({ action })) {
                return `${trigger_block} ${is_valid}`;
            } else {
                return `${trigger_block}`;
            }
        }
    }

    return isValidActionBlock({ action, selectedRuleCloudNucal, notificationType })
        ? `${trigger_block} ${is_valid}`
        : `${trigger_block}`;
};

export const notValidTriggers = (data) => {
    if (data) {
        return data.filter((item) => !item?.isValid);
    }
};

export const getEditTriggers = (data) => {
    if (data) {
        return data.filter((item) => item?.isEdit);
    }
};

export const notValidActions = (data) => {
    if (data) {
        return data.filter((item) => !item?.isValid);
    }
};

export const isEditActions = (data) => {
    if (data) {
        return data?.filter((item) => item?.isEdit);
    }
};

export const checkIsActionChanged = (ruleCloudActions, initialActions) => {
    if (initialActions) {
        return ruleCloudActions
            .map((action, index) => {
                if (action.notification) {
                    return JSON.stringify(action.notification) !== JSON.stringify(initialActions[index]);
                } else {
                    return isActionChanged(action, initialActions[index]);
                }
            })
            .includes(true);
    }
};

export const isValidActions = (ruleCloudActions) => {
    return !ruleCloudActions.map((action) => isValidActionBlock({ action })).includes(false);
};

export const isTemperatureCapability = (nameSubBlock) => {
    return (
        nameSubBlock === SETPOINT_HEAT_COMMAND ||
        nameSubBlock === SETPOINT_COOL_COMMAND ||
        nameSubBlock === SETPOINT_COOL ||
        nameSubBlock === SETPOINT_HEAT
    );
};

export const isValidate = (
    cloudMeshbotName,
    isRuleCloudTriggerEmpty,
    notValidTriggerArray,
    currentScene,
    isEditedTriggerArray,
    ruleCloudTriggers,
    parentTriggerOptionType,
    ruleCloudActions,
    isValidActions,
    isChangedActions,
    notValidActionArray,
    isEditActionArray,
    labels,
    cloudMeta,
) => {
    const name = cloudMeshbotName.length > 0;
    const isValidTrigger = notValidTriggerArray.length === 0;
    const editedTriggers = isEditedTriggerArray.length === 0;
    const validActions = notValidActionArray.length === 0;
    const isEditActions = isEditActionArray.length === 0;
    let isValid = true;

    if (currentScene?.actions?.length && ruleCloudActions.length) {
        if (isValidTrigger && editedTriggers && !isChangedActions) {
            isValid = true;
        }

        if (
            ruleCloudActions.length !== currentScene?.actions?.length &&
            isValidActions &&
            name &&
            isValidTrigger &&
            validActions
        ) {
            isValid = false;
        }

        if (
            isValidTrigger &&
            editedTriggers &&
            isChangedActions &&
            ruleCloudActions.length &&
            isValidActions &&
            name &&
            validActions &&
            !isEditActions
        ) {
            isValid = false;
        }

        if (isValidTrigger && !editedTriggers && name && isEditActions) {
            isValid = false;
        }

        if (isValidActions && isChangedActions && !ruleCloudTriggers.length && validActions && !isEditActions && name) {
            isValid = false;
        }

        if (!ruleCloudActions.length && !ruleCloudTriggers.length) {
            isValid = true;
        }

        if (
            !ruleCloudTriggers.length &&
            ruleCloudActions.length &&
            isValidActions &&
            name &&
            validActions &&
            isValidTrigger &&
            !isEditActions
        ) {
            isValid = false;
        }

        if (
            currentScene.name !== cloudMeshbotName &&
            name &&
            isValidTrigger &&
            isValidActions &&
            ruleCloudActions.length &&
            ruleCloudTriggers.length &&
            validActions
        ) {
            isValid = false;
        }

        if (
            currentScene.name !== cloudMeshbotName &&
            name &&
            editedTriggers &&
            isEditActions &&
            ruleCloudActions.length &&
            ruleCloudTriggers.length
        ) {
            isValid = false;
        }

        if (
            currentScene.name !== cloudMeshbotName &&
            name &&
            (isEditActions || validActions) &&
            ruleCloudActions.length &&
            !ruleCloudTriggers.length
        ) {
            isValid = false;
        }

        if (isValidTrigger && !editedTriggers && ruleCloudActions.length && isValidActions && validActions && name) {
            isValid = false;
        }

        if (
            isValidTrigger &&
            editedTriggers &&
            ruleCloudActions.length &&
            isValidActions &&
            validActions &&
            !isEditActions &&
            name
        ) {
            isValid = false;
        }

        if (
            (!ruleCloudTriggers.length &&
                ruleCloudActions?.length &&
                isValidActions &&
                name &&
                validActions &&
                !isEditActions) ||
            (ruleCloudTriggers?.length > meshBot.SINGLE_TRIGGER &&
                isValidTrigger &&
                ruleCloudTriggers?.length !== currentScene?.triggers.parameters.length &&
                name &&
                ruleCloudActions?.length &&
                (validActions || isEditActions))
        ) {
            isValid = false;
        }

        if (
            isValidTrigger &&
            !ruleCloudTriggers?.length &&
            currentScene?.triggers.parameters.length &&
            name &&
            ruleCloudActions?.length &&
            (validActions || isEditActions)
        ) {
            isValid = false;
        }

        if (
            ruleCloudTriggers?.length > meshBot.SINGLE_TRIGGER &&
            parentTriggerOptionType !== currentScene.triggers.name &&
            isValidTrigger &&
            ruleCloudActions?.length &&
            (validActions || isEditActions) &&
            name
        ) {
            isValid = false;
        }
    } else {
        if (!name) {
            isValid = true;
        } else if (name && isRuleCloudTriggerEmpty && !validActions) {
            isValid = true;
        } else if (
            name &&
            !isRuleCloudTriggerEmpty &&
            isValidTrigger &&
            ruleCloudActions.length &&
            isValidActions &&
            validActions
        ) {
            isValid = false;
        } else if (ruleCloudActions.length && validActions && isValidTrigger) {
            isValid = false;
        }
    }

    // Label validation
    if (!isCloudMeshBotLabelsChanged(labels, currentScene?.meta?.labels, cloudMeta?.labels)) {
        isValid = false;
    }

    return isValid;
};
/**
 * Checks whether the cloud mesh bot labels have been changed in the current scene
 * compared to the initial scene, based on their UUIDs.
 *
 * @param {Object} allLabels - All meshbot labels from kvs.
 * @param {Object} initialSceneLabels - Meshbot labels in the initial scene.
 * @param {Object} currentSceneLabels - Meshbot labels in the current scene.
 * @returns {boolean} True if the cloud mesh bot labels in the current scene match the initial scene's labels,
 *                    based on their UUIDs; otherwise, returns false.
 *
 * @example
 * const allLabels = {
 *     "uuid1": { name: "name1",  },
 *     "uuid2": { name: "name2", },
 *     "uuid3": { name: "name3", },
 *     // ...
 * };
 *
 * const initialSceneLabels = [
 *     "uuid1", "uuid5", "uuid0", "uuid3"
 * ];
 *
 * const currentSceneLabels = [
 *     "uuid2", "uuid3"
 * ];
 *
 * const hasChanged = isCloudMeshBotLabelsChanged(allLabels, initialSceneLabels, currentSceneLabels);
 * console.log(hasChanged); // Output: true
 */
export const isCloudMeshBotLabelsChanged = (allLabels, initialSceneLabels, currentSceneLabels) => {
    const existingMeshBotLabelsUuids = getExistingMeshBotLabelsUuids(allLabels, initialSceneLabels);

    return JSON.stringify(existingMeshBotLabelsUuids) === JSON.stringify(currentSceneLabels);
};

export const getClassNameFromCloudTriggersValidation = (trigger, initialTriggers, notificationTemplate) => {
    const { triggers } = !notificationTemplate
        ? initialTriggers
        : initialTriggers?.[DATA_FROM_FIRST_SCENE]?.meshbot_definition
        ? JSON.parse(initialTriggers?.[DATA_FROM_FIRST_SCENE]?.meshbot_definition)
        : [];

    if (triggers) {
        if (trigger.blocks[0].name === meshBot.SCHEDULE) {
            const newCurrentItem = _.cloneDeep(trigger);
            const newCurrentItemBlocks = _.cloneDeep(trigger.blocks);
            const [currentItemBlock] = newCurrentItemBlocks;
            const { parameters } = currentItemBlock;

            const updatedParameters = convertAmPmOfParametersTo24HourFormat(
                parameters || [],
                meshBot.TIMES_NODE,
                meshBot.TIME_NODE,
                meshBot.HOUR_NODE,
                meshBot.OFFSET,
                meshBot.IS_PM,
            );

            newCurrentItem.blocks[0].parameters = updatedParameters;

            const newBlocks = {
                name: NOT,
                parameters: [newCurrentItem.blocks[0]],
            };

            trigger.not ? (newCurrentItem.blocks = [newBlocks]) : null;

            if (trigger.not) {
                if (!isNestedTriggerChanged(newCurrentItem, triggers)) {
                    return meshBot.TRIGGER_EDIT_MODE;
                }

                isValidDateAndTimeBlock(trigger) ? meshBot.TRIGGER_VALID_MODE : meshBot.TRIGGER_INVALID_MODE;
            }

            if (!isNestedTriggerChanged(newCurrentItem, triggers)) {
                return meshBot.TRIGGER_EDIT_MODE;
            }

            return isValidDateAndTimeBlock(trigger) ? meshBot.TRIGGER_VALID_MODE : meshBot.TRIGGER_INVALID_MODE;
        }

        if (!isTriggerChanged(trigger, triggers)) {
            return meshBot.TRIGGER_EDIT_MODE;
        } else if (
            isValidTriggerBlock(trigger) ||
            isValidNucalBlock(trigger) ||
            isValidCloudVariableBlock(trigger) ||
            isValidNotificationTemplateBlock(trigger) ||
            isValidGeoFencingBlock(trigger)
        ) {
            return meshBot.TRIGGER_VALID_MODE;
        }

        return meshBot.TRIGGER_INVALID_MODE;
    }

    return isValidTriggerBlock(trigger) ||
        isValidDateAndTimeBlock(trigger) ||
        isValidNucalBlock(trigger) ||
        isValidCloudVariableBlock(trigger) ||
        isValidNotificationTemplateBlock(trigger) ||
        isValidGeoFencingBlock(trigger)
        ? meshBot.TRIGGER_VALID_MODE
        : meshBot.TRIGGER_INVALID_MODE;
};
/**
 * Returns Array of classnames that we use for validation of trigger blocks in edit mode
 * @param {array} initialTriggers - Array of Objects with request data
 * @param {object} trigger - Object with data related to trigger block in UI
 * @param {object} notificationTemplate - Object with data related to notificationTemplate
 * @param {object} ruleCloudTriggers - Object with data related to ruleCloudTriggers
 * @returns {array} array that returns validity Classnames
 *
 * */
export const getClassNameFromCloudTriggersValidationNested = (
    trigger,
    initialTriggers,
    notificationTemplate,
    ruleCloudTriggers,
) => {
    const { triggers } = !initialTriggers[DATA_FROM_FIRST_SCENE]?.meshbot_definition
        ? initialTriggers
        : JSON.parse(initialTriggers[DATA_FROM_FIRST_SCENE]?.meshbot_definition);

    const currentSceneTriggerids = triggers?.parameters.map((item, key) => {
        return item?.id ?? String(key);
    });

    const currentItemTrigger = _.cloneDeep(trigger);

    if (triggers && currentSceneTriggerids.includes(String(trigger?.id))) {
        if (trigger?.not) {
            const newBlocks = {
                name: meshBot.OPERATOR_NOT,
                parameters: [currentItemTrigger?.blocks[0]],
            };

            if (trigger?.blocks[0]?.name === meshBot.SCHEDULE) {
                const [currentItemBlock] = currentItemTrigger.blocks;
                const { parameters } = currentItemBlock;

                const updatedParameters = convertAmPmOfParametersTo24HourFormat(
                    parameters || [],
                    meshBot.TIMES_NODE,
                    meshBot.TIME_NODE,
                    meshBot.HOUR_NODE,
                    meshBot.OFFSET,
                    meshBot.IS_PM,
                );
                currentItemTrigger.blocks = [newBlocks];

                currentItemTrigger.blocks[0].parameters[0].parameters = updatedParameters;

                return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidDateAndTimeBlock);
            }

            if (trigger?.blocks[0]?.name === meshBot.ON_CHANGE) {
                currentItemTrigger.blocks = [newBlocks];

                return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidNucalBlock);
            }

            if (trigger?.notificationTemplateOption && trigger?.id === currentItemTrigger?.id) {
                currentItemTrigger.blocks = [newBlocks];

                return validationForNotificationBlock(triggers, currentItemTrigger);
            }

            if (trigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
                currentItemTrigger.blocks = [newBlocks];

                return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidCloudVariableBlock);
            }

            if (trigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.GEOFENCE) {
                currentItemTrigger.blocks = [newBlocks];

                return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidGeoFencingBlock);
            }

            currentItemTrigger.blocks = [newBlocks];

            return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidTriggerBlock);
        }

        if (trigger?.blocks[0]?.name === meshBot.SCHEDULE) {
            const [currentItemBlock] = currentItemTrigger.blocks;
            const { parameters } = currentItemBlock;

            const updatedParameters = convertAmPmOfParametersTo24HourFormat(
                parameters || [],
                meshBot.TIMES_NODE,
                meshBot.TIME_NODE,
                meshBot.HOUR_NODE,
                meshBot.OFFSET,
                meshBot.IS_PM,
            );

            currentItemTrigger.blocks[0].parameters = updatedParameters;

            return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidDateAndTimeBlock);
        }

        if (trigger?.blocks[0]?.name === meshBot.ON_CHANGE) {
            return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidNucalBlock);
        }

        if (trigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
            return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidCloudVariableBlock);
        }

        if (trigger?.selectedFieldTrigger === MESHBOT_NODE_TYPES.GEOFENCE) {
            return triggerModeClass(triggers?.parameters, currentItemTrigger, isValidGeoFencingBlock);
        }

        if (trigger?.notificationTemplateOption && trigger?.id === currentItemTrigger?.id) {
            return validationForNotificationBlock(triggers?.parameters?.[trigger.id], currentItemTrigger);
        }

        return triggerModeClass(triggers?.parameters, trigger, isValidTriggerBlock);
    }

    return isValidTriggerBlock(trigger) ||
        isValidDateAndTimeBlock(trigger) ||
        isValidNucalBlock(trigger, ruleCloudTriggers) ||
        isValidCloudVariableBlock(trigger) ||
        isValidNotificationTemplateBlock(trigger) ||
        isValidGeoFencingBlock(trigger)
        ? meshBot.TRIGGER_VALID_MODE
        : meshBot.TRIGGER_INVALID_MODE;
};

export const isTheSameBlocks = (trigger, currentItemTrigger) => {
    if (currentItemTrigger.not) {
        return (
            JSON.stringify(trigger?.parameters[Number(currentItemTrigger.id)]) ===
            JSON.stringify(currentItemTrigger?.blocks[DATA_FROM_VALUE_FROM_FIRST_BLOCK])
        );
    } else {
        return (
            (JSON.stringify(
                currentItemTrigger?.blocks?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.parameters?.[
                    DATA_FROM_VALUE_FROM_FIRST_BLOCK
                ]?.parameters,
            ) === JSON.stringify(trigger?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.parameters) &&
                JSON.stringify(
                    currentItemTrigger?.blocks?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_VALUE_FROM_SECOND_BLOCK
                    ]?.parameters,
                ) === JSON.stringify(trigger?.parameters?.[DATA_FROM_VALUE_FROM_SECOND_BLOCK]?.parameters)) ||
            JSON.stringify(trigger) === JSON.stringify(currentItemTrigger?.blocks[DATA_FROM_VALUE_FROM_FIRST_BLOCK])
        );
    }
};

export const validationForNotificationBlock = (trigger, currentItemTrigger) => {
    if (isTheSameBlocks(trigger, currentItemTrigger) && isValidNotificationTemplateBlock(currentItemTrigger)) {
        return meshBot.TRIGGER_EDIT_MODE;
    } else if (!isTheSameBlocks(trigger, currentItemTrigger) && isValidNotificationTemplateBlock(currentItemTrigger)) {
        return meshBot.TRIGGER_VALID_MODE;
    } else {
        return meshBot.TRIGGER_INVALID_MODE;
    }
};

const triggerModeClass = (list, trigger, validate) => {
    const mode = list
        .map((item, key) => {
            const itemId = item.id ? item.id : String(key);
            if (itemId == trigger.id) {
                if (!isNestedTriggerChanged(trigger, item)) {
                    return meshBot.TRIGGER_EDIT_MODE;
                }

                return validate(trigger) ? meshBot.TRIGGER_VALID_MODE : meshBot.TRIGGER_INVALID_MODE;
            }
        })
        .filter((item) => !!item);

    return mode[0];
};

export const updateEditState = (list, value, id) => {
    return list.map((item) => {
        if (item.id === id) {
            item.isEdit = value;
        }

        return item;
    });
};

export const getDeviceUUID = (device) => {
    let currentUUID;
    if (device?.uuid) {
        currentUUID = device?.uuid;
    }

    if (device.idDev) {
        currentUUID = device.idDev;
    }

    if (device?.blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.abstract) {
        currentUUID = device.blocks[0].parameters[0].parameters[0].abstract;
    }

    if (device?.blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.parameters?.[0]?.abstract) {
        currentUUID = device?.blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.parameters?.[0]?.abstract;
    }

    return currentUUID;
};

export const getIntegerFromNumberInput = (e) => {
    e.target.value = parseInt(Math.abs(e.target.value)).toFixed(0);
    if (e?.target?.max) {
        e.target.value = Number(e.target.value) > Number(e.target.max) ? e.target.max : e.target.value;
    }

    if (e?.target?.value < e.target.min) {
        e.target.value = Number(e.target.value.replace(/^0+/, null));
    }

    return e.target.value;
};

export const setFunctionScript = (list, idDevice, data, items, typeAction, type) => {
    if (!Array.isArray(list) || !list) {
        throw new Error('Error, input data is not valid');
    }

    return list.map((item) => {
        const control = getControl(item);

        if (item.id === idDevice) {
            if (type === LIST && typeAction === GET) {
                item.selectedName = data._id;
                item.selectedCode = data.code;
            }

            if (item?.blocks[0]?.hasOwnProperty(DELAY)) {
                items.delay = item.blocks[0].delay;
            }

            if (item?.blocks[0]?.exec_policy) {
                items.exec_policy = item.blocks[0].exec_policy;
            }

            item.blocks = [items];

            if (control) {
                item.blocks[0] = { ...item.blocks[0], control };
            }
        }

        return item;
    });
};

/**
 * Returns Array with new data delay
 * @param {array} list - list actions
 * @param {string} id - id action
 * @param {object} params - data delay
 * @return {array} actions
 * */

export const setValueDelay = (list, id, params) => {
    if (!Array.isArray(list) || !id || !params) {
        throw new Error('Error, input data is not valid');
    }

    return list.map((action) => {
        if (action.id === id) {
            action.blocks[0].delay = Object.entries(params).reduce((acc, [key, value]) => {
                acc[key] = Number(value);

                return acc;
            }, {});
        }

        return action;
    });
};

/**
 * Returns Array without  delay
 * @param {array} list - list actions
 * @param {string} id - id action
 * @return {array} actions
 * */

export const removeDelay = (list, id) => {
    if (!Array.isArray(list) || !id) {
        throw new Error('Error, input data is not valid');
    }

    return list.map((action) => {
        if (action.id === id) {
            if (action && action.blocks && action.blocks[0] && action.blocks[0].delay) {
                delete action.blocks[0].delay;
            }
        }

        return action;
    });
};

// TODO: naming, placement?
export const info = {
    hints: {
        nodeType: EZLOGIC_TITLE_NODE_TYPE,
        node: EZLOGIC_TITLE_NODE,
        controller: 'Controller',
        capability: EZLOGIC_TITLE_CAPABILITY,
        childDevices: 'Child devices',
        attribute: 'Attribute',
        armedState: EZLOGIC_TITLE_ARMED_STATE,
        comparator: EZLOGIC_LABEL_COMPARATOR,
        valueType: EZLOGIC_LABEL_VALUE_TYPE,
        value: EZLOGIC_TITLE_VALUE,
        valueStart: EZLOGIC_TITLE_VALUE_START,
        valueEnd: EZLOGIC_TITLE_VALUE_END,
        triggerFunc: 'Trigger Function',
        trueAction: 'True',
        falseAction: 'False',
        controllable: 'Controllable',
        controllableType: 'Controllable Type',
        cameras: 'Cameras',
        zones: 'Zones',
        recordingLength: 'Recording length',
        latchName: 'Latch Name',
        mode: EZLOGIC_TITLE_MODE,
        houseModes: EZLOGIC_TITLE_HOUSE_MODES,
        variables: 'Variable',
        deviceVariables: EZLOGIC_VARIABLES_VARIABLES_TITLE,
        savingVariables: 'Place for saving variables',
        location: 'Location',
        user: 'User',
        when: 'When',
        offset: EZLOGIC_TITLE_OFFSET,
        addOffset: EZLOGIC_TITLE_ADD_OFFSET,
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
        securityModes: EZLOGIC_TITLE_SECURITY_MODES,
        event: EZLOGIC_TITLE_EVENT,
        action: EZLOGIC_LABLE_ACTION,
        pinCode: EZLOGIC_LABLE_PIN_CODE,
        reachableState: EZLOGIC_LABLE_REACHABLE_STATE,
    },
    text: {
        nodeType: EZLOGIC_HINT_NODE_TYPE,
        node: EZLOGIC_HINT_NODE,
        luaScriptNode: EZLOGIC_HINT_LUA_SCRIPT_NODE,
        capability: EZLOGIC_HINT_CAPABILITY,
        armedState: EZLOGIC_HINT_ARMED_STATE,
        comparator: EZLOGIC_HINTS_COMPARATOR,
        valueType: EZLOGIC_HINTS_VALUE_TYPE,
        value: EZLOGIC_HINTS_VARIABLE,
        valueStart: EZLOGIC_HINT_VALUE_START,
        valueEnd: EZLOGIC_HINT_VALUE_END,
        triggerFunc: EZLOGIC_TITLE_TRIGGER_FUNCTIONS_INFO,
        trueAction: EZLOGIC_HINT_TRUE_ACTION,
        falseAction: EZLOGIC_HINT_FALSE_ACTION,
        controllable: (
            <>
                <h4>Controllable</h4>
                <p>The specific item from which you want a response when your trigger conditions are met.</p>
                <p>
                    This could be a list of devices, services or{' '}
                    <a href={HINTS_MESHBOT_URL} rel="noopener noreferrer" target="_blank">
                        meshbots
                    </a>{' '}
                    etc depending on what you chose as the controllable type.
                </p>
            </>
        ),
        controllableType: (
            <>
                <h4>Controllable Type</h4>
                <p>The type of entity that will implement your action once the conditions of your trigger are met.</p>
                <p>
                    The menus that appear in the rest of the action will vary according to your choice of controllable.
                </p>
            </>
        ),
        mode: EZLOGIC_INFO_TEXT_MODE,
        saveResultHttpRequestInVariableText: (
            <>
                <h4>Variables</h4>
                <p>Now you can save the result of this HTTP request into a variable!</p>
            </>
        ),
        saveResultNuCALInVariableText: EZLOGIC_HINT_SAVE_RESULT_NUCAL,
        houseModes: EZLOGIC_HINT_HOUSE_MODES,
        selectUsers: EZLOGIC_HINT_SELECT_USER,
        selectChannels: EZLOGIC_HINT_SELECT_CHANNELS,
        selectCategories: (
            <>
                <h4>Categories</h4>
                <p>Select the category </p>
            </>
        ),
        // eslint-disable-next-line
        errorMeshbotDoesntExist: "This meshbot doesn't exist anymore",
        offset: EZLOGIC_HINT_OFFSET,
        event: EZLOGIC_HINT_EVENT,
        addingOffset: EZLOGIC_HINT_ADD_OFFSET,
        valueSpecialTimeOfDay: EZLOGIC_TITLE_VALUE_SPECIAL_TIME_OF_DAY,
    },
};

/**
 * // TODO: use single source of truth for nodes and it's values, object definition must be moved to constants
 * Returns Array of Options for Select Dropdown
 * @param {string} [type] - Type of Cloud Meshbot
 * @returns {array} array of options
 * */
export const generateOptionsByNotificationType = (type) => {
    const optionsByType = {
        notification: [{ value: CLOUD_NOTIFICATION, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.NOTIFICATION_NAME }],
        notificationTemplate: [
            { value: CLOUD_NOTIFICATION, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.NOTIFICATION_NAME },
        ],
        interaction: [{ value: DASHBOARD, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.DASHBOARD_NAME }],
        cloud: [
            { value: DEVICE_STATE, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.DEVICE },
            { value: CLOUD_NOTIFICATION, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.NOTIFICATION_NAME },
            { value: DASHBOARD, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.DASHBOARD_NAME },
            { value: MESHBOT_NODE_TYPES.PAAS, name: meshBot.CLOUD_MESHBOT_CONTROLLABLES_NAME.NUCAL },
        ],
    };

    if (optionsByType[type] === undefined) {
        throw new Error(`No such key: ${type}`);
    }

    return optionsByType[type];
};

// TODO: impossible to support/scale, must be simplified in scope of any customization task/fix
export const getActionListData = (
    updatedNodesControllables,
    notificationType,
    optionsFirstSelect,
    meshbotType,
    firmwareVersion,
) => {
    let updatedOptionsFirstSelect = [];
    // TODO: move outside in scope of any customization task/fix
    const typeCloud = () => {
        if (notificationType) {
            optionsFirstSelect = generateOptionsByNotificationType(notificationType);
            updatedOptionsFirstSelect = optionsFirstSelect;
        } else if (updatedNodesControllables?.actionCloudNodes) {
            const actionNodes = generateOptionsByNotificationType(CLOUD);
            updatedOptionsFirstSelect = updatedNodesControllables?.actionCloudNodes;
            const updatedNodes = getUpdatedNodesByCustomization(
                actionNodes,
                updatedNodesControllables?.actionCloudNodes,
                ACTION_COMPARE_PROPERTY,
            );
            updatedOptionsFirstSelect = updatedNodes;
        } else {
            updatedOptionsFirstSelect = generateOptionsByNotificationType(CLOUD);
        }
    };

    // TODO: move outside in scope of any customization task/fix
    const typeLocal = () => {
        if (updatedNodesControllables?.actionLocalNodes) {
            const updatedNodes = getUpdatedNodesByCustomization(
                optionsFirstSelect,
                updatedNodesControllables?.actionLocalNodes,
                ACTION_COMPARE_PROPERTY,
            );
            updatedOptionsFirstSelect = updatedNodes;
        } else {
            updatedOptionsFirstSelect = optionsFirstSelect;
        }
    };

    if (meshbotType === MESHBOT_TYPES.CLOUD) {
        typeCloud();
    } else if (meshbotType === MESHBOT_TYPES.EZLOPI) {
        updatedOptionsFirstSelect = getSupportedEzlopiElements(meshBot.actionsNodesListForEzlopi, firmwareVersion);
    } else {
        typeLocal();
    }

    return updatedOptionsFirstSelect;
};

export const getListNodesLocalByOemId = (oemId, listNodesLocal) => {
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        const updatedListNodesLocal = [...listNodesLocal];

        updatedListNodesLocal.forEach((node) => {
            if (node.id === MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE) {
                const updatedNode = { ...node };
                updatedNode.label = meshBot.SECURITY_MODE;
                updatedListNodesLocal[updatedListNodesLocal.indexOf(node)] = updatedNode;
            }
        });

        return updatedListNodesLocal;
    }

    return listNodesLocal;
};

/**
 * Function that filters the list of nodes depending on isException
 * @param {Boolean} isException - Marker indicating the use of a list in exceptions
 * @param {Array<Object>} listNodes - List of nodes in trigger
 * @returns {Array<Object>} returned filtered list of nodes
 * @example
 * getFilteredNodesByIsException( isException, listNodes )
 */
export const getFilteredNodesByIsException = (isException, listNodes) => {
    if (!listNodes) {
        return [];
    }

    if (isException) {
        return listNodes.filter(({ id }) => listNodesIdsForExceptions.includes(id));
    }

    return listNodes;
};

export const getTriggerListData = (
    updatedTriggerNodes,
    listNodesLocal,
    listNodesCloud,
    meshbotType,
    oemId,
    isException,
    firmwareVersion,
) => {
    let updatedCloudListNodes = [];
    const typeCloud = () => {
        if (updatedTriggerNodes?.listNodesCloud) {
            updatedCloudListNodes = updatedTriggerNodes?.listNodesCloud;
            const updatedNodes = getUpdatedNodesByCustomization(
                listNodesCloud,
                updatedTriggerNodes?.listNodesCloud,
                TRIGGER_COMPARE_PROPERTY,
            );
            updatedCloudListNodes = updatedNodes;
        } else {
            updatedCloudListNodes = listNodesCloud;
        }
    };

    const typeLocal = () => {
        const filteredNodesByIsException = getFilteredNodesByIsException(isException, listNodesLocal);
        updatedCloudListNodes = getListNodesLocalByOemId(oemId, filteredNodesByIsException);
        if (updatedTriggerNodes?.listNodesLocal) {
            const updatedNodes = getUpdatedNodesByCustomization(
                updatedCloudListNodes,
                updatedTriggerNodes?.listNodesLocal,
                TRIGGER_COMPARE_PROPERTY,
            );
            const filteredNodesByIsException = getFilteredNodesByIsException(isException, updatedNodes);
            updatedCloudListNodes = filteredNodesByIsException;
        }
    };

    if (meshbotType === at.CLOUD) {
        typeCloud();
    } else if (meshbotType === MESHBOT_TYPES.EZLOPI) {
        updatedCloudListNodes = getSupportedEzlopiElements(listNodesEzlopi, firmwareVersion);
    } else {
        typeLocal();
    }

    return updatedCloudListNodes;
};

/**
 * Returns Array of objects updated node trigger.
 * @param {boolean} notificationTemplate - boolean true when user goes to notification template page else false.
 * @param {array} updateListTrigger - trigger list.
 * @returns {array} updated nodes list trigger.
 * */
export const getMenuOptionsListForTriggerBlock = (notificationTemplate, updateListTrigger) => {
    if (notificationTemplate) {
        return listNodesForNotificationTemplate;
    } else {
        return updateListTrigger;
    }
};
/**
 * Filters Cloud Meshbot trigger nodes based on accessibility in the current domain.
 * If the domain is internal, returns all nodes. Otherwise, filters out nodes accessible only on the internal domain.
 * @param {Array} cloudTriggerNodes - List of Cloud Meshbot trigger nodes.
 * @returns {Array} Filtered list of Cloud Meshbot trigger nodes based on domain accessibility.
 * @example
 * const allCloudTriggerNodes = [
 *     { value: 'deviceState', label: 'Device State' },
 *     { value: 'dataAndTime', label: 'Date and Time' },
 *     { value: 'geofence', label: 'Geofence' },
 *     // ... other nodes
 * ];
 *
 * const filteredNodes = filterCloudTriggerNodesByInternalDomain(allCloudTriggerNodes);
 * console.log(filteredNodes);
 * // Output: [{ value: 'deviceState', label: 'Device State' }, { value: 'dataAndTime', label: 'Date and Time' }]
 * // 'geofence' is filtered out because it is accessible only on the internal domain.
 */
export const filterCloudTriggerNodesByInternalDomain = (cloudTriggerNodes) => {
    if (!_.isArray(cloudTriggerNodes)) {
        return [];
    }

    if (isEzlogicInternalDomain()) {
        return cloudTriggerNodes;
    }

    return cloudTriggerNodes.filter((node) => !ACCESSIBLE_CLOUD_TRIGGER_NODES_IN_INTERNAL_DOMAIN.includes(node?.value));
};

/**
 * Returns Boolean that answers a question is current version higher or equal to lowest supported version
 * @param {string} currentVersion - current version of feature that controller has
 * @param {string} lowestSupportedVersion - lowest supported version of feature that controller should have
 * @returns {boolean} result of version comparison
 * */
export const checkVersionSupport = (currentVersion, lowestSupportedVersion) => {
    if (typeof currentVersion !== 'string' || typeof lowestSupportedVersion !== 'string') {
        throw new Error('#checkVersionSupport should receive only strings as params');
    }

    const lowestVersionNumbersArray = lowestSupportedVersion.split('.');

    return currentVersion.split('.').every((currentNumber, index) => {
        const lowestVersionNumber = lowestVersionNumbersArray[index] || '0';

        return Number(currentNumber) >= Number(lowestVersionNumber);
    });
};

export const getTriggerFunctionTypeList = (triggerType, advancedScenesStatus, advancedScenesVersion) => {
    const supportedFunctionsTypesList = MESHBOT_FUNCTION_TYPES.filter(
        ({ dependencies: { lowestSupportedVersion } }) => {
            return advancedScenesStatus === 'on' && checkVersionSupport(advancedScenesVersion, lowestSupportedVersion);
        },
    );

    if (triggerType && triggerType === TRIGGER_TYPE_LIST.SINGLE_TRIGGER) {
        return supportedFunctionsTypesList;
    }

    return supportedFunctionsTypesList?.filter((type) => type.value !== LATCH);
};

export const getPulseFunctionSupportedType = (advancedScenesVersion) => {
    const supportedPulseFunctionTypesList = MESHBOT_FUNCTION_PULSE_SELECT_VALUES.filter((functionType) => {
        return functionType.lowestSupportedVersion <= advancedScenesVersion;
    });

    return supportedPulseFunctionTypesList;
};

export const getCurrentWhileIdFromScene = (scenesList, sceneId) => {
    return scenesList.filter((item) => item._id === sceneId)[0]?.when[0]?._id;
};

export const detectTypeTime = (data, timeType) => {
    if (timeType === 'pm' && Number(data) !== meshBot.HOUR12) {
        return Number(data) + meshBot.HOUR12;
    } else if (timeType === 'am' && Number(data) === meshBot.HOUR12) {
        return Number(data) - meshBot.HOUR12;
    }

    return data;
};

export const setStartCustomDate = (value, isMinutes, hour, timeType, typeFunction) => {
    if (isMinutes && hour === meshBot.HOUR23 && timeType === 'pm') {
        return meshBot.pmDefault;
    } else if (isMinutes && hour === meshBot.HOUR11 && timeType === 'am') {
        return meshBot.amDefault;
    } else if (isMinutes && hour !== meshBot.HOUR23 && hour !== meshBot.HOUR11) {
        hour += 1;

        return `${hour}:00`;
    } else if (typeFunction === 'blur') {
        return [
            hour === 0 ? meshBot.formattingTime(JSON.stringify(hour)) : hour,
            value && value !== '0' ? meshBot.formattingTime(value) : '00',
        ].join(':');
    } else {
        return [meshBot.formattingTime(JSON.stringify(hour)), value].join(':');
    }
};

export const getFormatTime = (value, hours) => {
    let formatTime = Number(hours);

    if (value === 'pm' && formatTime < meshBot.HOUR12) {
        formatTime += meshBot.HOUR12;
    } else if (value === 'am' && formatTime === meshBot.HOUR12) {
        formatTime = meshBot.ZERO;
    }

    return formatTime;
};

export const validationCheck = (value, numberForTest) => {
    return Number(value) >= numberForTest || Number(value) < 0 || Number(value) % 1 !== 0;
};

export const getBlocksByRecursion = (array) => {
    const arrOfRecursionBlocks = [];
    (function flat(array) {
        array.forEach(function (el) {
            if (Array.isArray(el.blocks) && el.blocks.length > 1) {
                flat(el.blocks);
            } else {
                arrOfRecursionBlocks.push(el);
            }
        });
    })(array);

    return arrOfRecursionBlocks;
};

export const getNewData = (blocks, sceneId, scenes, type) => {
    const getBlockId = (block) =>
        blocks.length === meshbot.ARRAY_LENGTH
            ? block.blockId
            : block.fields?.[meshbot.INDEX_OF_ZERO]?.value?.[meshbot.INDEX_OF_ZERO]?.blockId;

    const arr = type
        ? blocks.map((block) => ({ _id: getBlockId(block) }))
        : getBlocksByRecursion(blocks).map((block) => ({ _id: block._id }));

    return getDataOfLatchNameAndId(arr, sceneId, scenes);
};

export const getRecursiveData = (array) => {
    const arrOfRecursionBlocks = [];
    (function flat(array) {
        array.forEach(function (el) {
            if (Array.isArray(el?.fields[0]?.value) && el?.fields[0]?.value?.length > 1) {
                flat(el.fields[0]?.value);
            } else {
                arrOfRecursionBlocks.push({ _id: el._id, name: el.blockOptions?.function?.latch?.name });
            }
        });
    })(array);

    return arrOfRecursionBlocks;
};

function getLatchFields(fields) {
    let latchFields = [];

    fields.forEach((field) => {
        if (field.fields && Array.isArray(field.fields) && field.fields.length > ZERO_INT) {
            // eslint-disable-next-line
            const firstField = field.fields[meshbot.INDEX_OF_ZERO];

            if (firstField.type === at.BLOCKS && Array.isArray(firstField.value)) {
                latchFields = latchFields.concat(getLatchFields(firstField.value));
            }
        }

        if (field?.blockOptions?.function?.latch) {
            latchFields.push(field);
        }
    });

    return latchFields;
}

export const getDataOfLatchNameAndId = (blockIdArr, sceneId, scenes) => {
    const result = [];
    const scene = scenes.find((scene) => scene._id === sceneId);

    if (!scene) {
        return result;
    }

    const fields =
        (blockIdArr.length > meshbot.ARRAY_LENGTH
            ? scene.when?.[meshbot.INDEX_OF_ZERO]?.fields?.[meshbot.INDEX_OF_ZERO]?.value
            : scene.when) || [];
    const latchFields = getLatchFields(fields);

    if (!latchFields.length) {
        return result;
    }

    const addFieldToResult = (field, isRecursive = false) => {
        const name = field?.blockOptions?.function?.latch?.name;
        const _id = field._id || field?.fields?.[meshbot.INDEX_OF_ZERO]?.value?.[meshbot.INDEX_OF_ZERO]?.blockId;

        if (isRecursive) {
            result.push(...getRecursiveData(field.fields[meshbot.INDEX_OF_ZERO]?.value));
        } else {
            result.push({ _id, name });
        }
    };

    if (latchFields.length > meshbot.ARRAY_LENGTH) {
        latchFields.forEach((field) => {
            const isRecursive = field?.fields?.[meshbot.INDEX_OF_ZERO]?.value?.length > meshbot.ARRAY_LENGTH;

            addFieldToResult(field, isRecursive);
        });
    } else {
        latchFields.forEach((field) => {
            addFieldToResult(field);
        });
    }

    return result;
};

export const getLatchData = (sceneBlocks = [], sceneId, scenes) => {
    const DEFAULT_TYPE = MESHBOT_TYPES.EZLOPI;

    return sceneBlocks.flatMap((block) => {
        const value =
            block.blocks ||
            (block.fields?.[meshbot.INDEX_OF_ZERO]?.type === at.BLOCKS
                ? block.fields?.[meshbot.INDEX_OF_ZERO]?.value
                : sceneBlocks);

        return getNewData(value, sceneId, scenes, block.blocks || value === sceneBlocks ? undefined : DEFAULT_TYPE);
    });
};

export const transformSpecialCharacters = (string) => {
    if (typeof string !== 'string') {
        throw new Error('wrong data type');
    }

    const doubleQuotes = string.replace(/"/g, '\\"');

    return doubleQuotes.replace(/\\(?!")/g, '\\\\');
};

export const transformHeadersToString = (data) => {
    return data.reduce((acc, item) => {
        return `${acc}${acc.length > 0 ? '&' : ''}${item.key}:${item.value}`;
    }, '');
};

const getTrigger = (triggers, idGroup) => {
    for (const trigger of triggers) {
        if (trigger.id === idGroup) {
            return trigger.blocks;
        }

        if (trigger.type === at.GROUP) {
            const nestedBlocks = getTrigger(trigger.blocks, idGroup);
            if (nestedBlocks) {
                return nestedBlocks;
            }
        }
    }
};

/**
 * Current trigger observer
 * @param {array} triggers - Triggers list
 * @param {function} funcGetTrigger - Function for get current block
 * @param {string} id - Id trigger
 * @param {string} idGroup - Id group
 * @returns {object} returned object with trigger
 * @example
 * observerCurrentTrigger(triggers, getCurrentBlock, idGroup)
 */
const observerCurrentTrigger = (triggers, funcGetTrigger, id, idGroup) => {
    if (idGroup) {
        triggers = getTrigger(triggers, idGroup);
    }

    return funcGetTrigger(triggers, id);
};

/**
 * Get current block
 * @param {array} list - Blocks list
 * @param {string} id - Id trigger
 * @returns {object} returned current object
 * @example
 * getCurrentBlock(triggers, id)
 */
const getCurrentBlock = (list = [], id) => {
    return list.find((item) => item.id === id) || {};
};

/**
 * Get current trigger
 * @param {array} triggers - triggers list
 * @param {string} id - Id trigger
 * @param {string} idGroup - Id group
 * @returns {object} returned trigger
 * @example
 * getCurrentTrigger(triggers, id, idGroup)
 */
export const getCurrentTrigger = (triggers, id, idGroup) => {
    if (!Array.isArray(triggers)) {
        return {};
    }

    return observerCurrentTrigger(triggers, getCurrentBlock, id, idGroup);
};

/**
 * Get current cloud trigger
 * @param {array} triggers - triggers list
 * @param {string} id - Id trigger
 * @returns {object} returned trigger
 * @example
 * getCurrentCloudTrigger(triggers, id)
 */
export const getCurrentCloudTrigger = (triggers, id) => {
    if (!Array.isArray(triggers)) {
        return {};
    }

    return observerCurrentTrigger(triggers, getCurrentBlock, id);
};

/**
 * Create div container for function block
 * @param {string} wrapperId - id of node
 * @param {string} id - Id of parentNode
 * @returns {node} returned node
 * @example
 * createWrapperAndAppendToParentNode(wrapperId, id)
 */

export function createWrapperAndAppendToParentNode(wrapperId, id) {
    const parentNode = document.getElementById(`${meshBot.FUNCTION_BLOCK_PORTAL_WRAPPER}-${id}`);
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    wrapperElement.setAttribute('class', function_block__inner);
    parentNode.appendChild(wrapperElement);

    return wrapperElement;
}

/**
 * Get current exception trigger
 * @param {array} triggers - triggers list
 * @param {string} id - Id trigger
 * @param {string} actionId - Id action
 * @param {string} idGroup - Id group
 * @returns {object} returned trigger
 * @example
 * getCurrentTrigger(triggers, id, idGroup)
 */
export const getCurrentExceptionTrigger = (triggers, id, actionId, idGroup) => {
    if (!Array.isArray(triggers)) {
        return {};
    }

    const listTriggers = triggers.find((item) => item.actionId === actionId);

    return observerCurrentTrigger(listTriggers?.triggers ?? [], getCurrentBlock, id, idGroup);
};

/**
 * Get exception block id
 * @param {object} trigger - triggers list
 * @returns {object} returned trigger
 * @example
 * getExceptionBlockId(trigger)
 */

export const getExceptionBlockId = (trigger) => {
    if (!trigger) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    if (trigger.hasOwnProperty(BLOCK_NAME)) {
        return trigger?.actionGroup;
    }

    return trigger?.blocks?.[0]?.actionGroup || trigger?.blocks?.actionGroup;
};

/**
 * Get exception
 * @param {array} list - list exception
 * @param {string} exceptionId - exception id
 * @returns {object} returned exception
 * @example
 * getActionException(list, exceptionId)
 */

export const getActionException = (list, exceptionId) => {
    if (!Array.isArray(list)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.find((item) => item?.blocks[0].control?.exception.blockId === exceptionId);
};

/**
 * Generate initial Meshbot exception structures
 *
 * This function will map through the exceptions and transform each
 * exception into a more detailed structure that includes triggers,
 * action Ids, option types and block types when needed.
 *
 * @function
 * @param {Array} exceptions - list of exceptions to be parsed
 * @param {string} triggersPath - path to be used to extract triggers from an exception
 * @param {Array} actionsList - list of actions to be used to fetch actions information based on exception Id
 * @returns {Array} - Returns a new array of exceptions with more detailed structures.
 */
export const getInitialMeshbotExceptions = (exceptions, triggersPath, actionsList) => {
    return exceptions.map((exception) => {
        const _id = exception.exceptionId;
        const currentAction = getActionException(actionsList, _id);
        const exceptionAction = {
            _id: _id,
            triggers: get(exception, triggersPath),
            actionId: currentAction?.id,
            optionType: exception?.triggersSelect?.optionType,
        };

        if (exception?.isGlobalRestriction) {
            exceptionAction.actionId = MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION;
            exceptionAction.blockType = MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION;
        }

        return setFunctionFields(exception, exceptionAction);
    });
};
/**
 * This function returns a rule trigger without blocks
 * @param {object} ruleTrigger - a rule trigger
 * @returns {object} - a rule trigger without blocks
 */
export const getRuleTriggerWithoutBlocks = (ruleTrigger = {}) => {
    if (ruleTrigger.hasOwnProperty(meshBot.MESHBOT_BLOCKS)) {
        return { ...ruleTrigger, blocks: [] };
    }

    return ruleTrigger;
};

export const generateDeviceAdvancedWhenBlock = (ruleTrigger, metaType) => {
    const compareMethod = ruleTrigger?.selectedComparator?.method;

    switch (compareMethod) {
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER:
            return generateCompareNumbersBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE:
            return generateCompareNumbersRange(ruleTrigger);
        case COMPARISON_DATA.METHOD.IS_ITEM_STATE:
            return generateIsItemState(ruleTrigger);
        case COMPARISON_DATA.METHOD.IS_BUTTON_STATE:
            return generateIsButtonState(ruleTrigger);
        case COMPARISON_DATA.METHOD.COMPARE_STRINGS:
            return generateCompareStringsBlock(ruleTrigger, metaType);
        case COMPARISON_DATA.METHOD.STRING_OPERATION:
            return generateStringOperationBlock(ruleTrigger, metaType);
        case COMPARISON_DATA.METHOD.IS_USER_LOCK_OPERATION:
            return generateIsUserLockOperationBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.IS_ITEM_STATE_CHANGED:
            return generateIsItemStateChangedBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.IS_DEVICE_STATE:
            return generateIsDeviceStateBlock(ruleTrigger);
        default:
            return {};
    }
};

export const generateNucalWhenBlock = (ruleTrigger, currentWhenBlock, metaType) => {
    const compareMethod = ruleTrigger?.selectedComparator?.method;

    if (!compareMethod && metaType === meshBot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION) {
        return generateWhenBlockWithoutCompare(ruleTrigger, currentWhenBlock, metaType);
    }
};

export const generateControllerWhenBlock = (ruleTrigger) => {
    const compareMethod = ruleTrigger?.selectedControllerCapability;

    switch (compareMethod) {
        case COMPARISON_DATA.METHOD.CLOUD_CONNECTION:
        case COMPARISON_DATA.METHOD.BATTERY_STATE:
            return generateControllerCapabilityBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.BATTERY_LEVEL:
            return generateControllerCapabilityBlockForBatteryLevel(ruleTrigger);
        default:
            return {};
    }
};

const generateControllerCapabilityBlock = (ruleTrigger) => {
    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockOptions: {
            method: {
                name: getSelectedControllerCapabilityForWhenBlock(ruleTrigger?.selectedControllerCapability),
                args: {
                    state: WHEN_BLOCK.METHOD_ARGS.STATE,
                },
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.FIELDS.NAME.STATE,
                type: WHEN_BLOCK.FIELDS.TYPE.TOKEN,
                value: ruleTrigger?.selectedControllerCapabilityValue,
            },
        ],
    };
};

const generateControllerCapabilityBlockForBatteryLevel = (ruleTrigger) => {
    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockOptions: {
            method: {
                name: WHEN_BLOCK.METHOD_NAME.IS_BATTERY_LEVEL,
                args: {
                    comparator: WHEN_BLOCK.METHOD_ARGS.COMPARATOR,
                    state: WHEN_BLOCK.METHOD_ARGS.STATE,
                },
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.FIELDS.COMPARATOR,
                type: WHEN_BLOCK.FIELDS.TYPE.STRING,
                value: ruleTrigger.selectedControllerCapabilityComparator,
            },
            {
                name: WHEN_BLOCK.FIELDS.VALUE,
                type: WHEN_BLOCK.FIELDS.TYPE.INT,
                value: ruleTrigger.selectedControllerCapabilityComparatorValue,
            },
        ],
    };
};

export const mapCapabilityValueType = {
    [BLOCK_FIELD_TYPES.TEMPERATURE]: BLOCK_FIELD_TYPES.FLOAT,
    [BLOCK_FIELD_TYPES.INT]: BLOCK_FIELD_TYPES.INT,
    [BLOCK_FIELD_TYPES.FLOAT]: BLOCK_FIELD_TYPES.FLOAT,
    [BLOCK_FIELD_TYPES.HUMIDITY]: BLOCK_FIELD_TYPES.HUMIDITY,
    [BLOCK_FIELD_TYPES.ILLUMINANCE]: BLOCK_FIELD_TYPES.ILLUMINANCE,
    [BLOCK_FIELD_TYPES.LOUDNESS]: BLOCK_FIELD_TYPES.LOUDNESS,
};

const generateCompareNumbersBlock = (ruleTrigger) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);
    const valueField = {
        name: WHEN_BLOCK.VALUE,
        type:
            ruleTrigger.compareTo === WHEN_BLOCK.EXPRESSION
                ? WHEN_BLOCK.EXPRESSION
                : mapCapabilityValueType[ruleTrigger.selectedCapability.valueType],
        value:
            ruleTrigger.compareTo === WHEN_BLOCK.EXPRESSION
                ? ruleTrigger.comparingValue
                : Number(ruleTrigger.comparingValue),
    };

    if (ruleTrigger.selectedCapability?.scale) {
        valueField.scale = ruleTrigger.selectedCapability.scale;
    }

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    comparator: WHEN_BLOCK.COMPARATOR,
                    item: WHEN_BLOCK.ITEM,
                    value: ruleTrigger.compareTo,
                },
                name: ruleTrigger.selectedComparator.method,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.TYPE.ITEM,
                value: ruleTrigger.selectedCapability._id,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            { ...valueField },
        ],
    };
};

const generateCompareNumbersRange = (ruleTrigger) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);
    const startValueField = {
        name: WHEN_BLOCK.START_VALUE,
        type: mapCapabilityValueType[ruleTrigger.selectedCapability.valueType],
        value: ruleTrigger.comparingValue.start,
    };

    const endValueField = {
        name: WHEN_BLOCK.END_VALUE,
        type: mapCapabilityValueType[ruleTrigger.selectedCapability.valueType],
        value: ruleTrigger.comparingValue.end,
    };

    if (ruleTrigger.selectedCapability?.scale) {
        startValueField.scale = ruleTrigger.selectedCapability.scale;
        endValueField.scale = ruleTrigger.selectedCapability.scale;
    }

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    comparator: WHEN_BLOCK.COMPARATOR,
                    item: WHEN_BLOCK.ITEM,
                    startValue: WHEN_BLOCK.START_VALUE,
                    endValue: WHEN_BLOCK.END_VALUE,
                },
                name: ruleTrigger.selectedComparator.method,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability._id,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            { ...startValueField },
            { ...endValueField },
        ],
    };
};

const generateIsItemState = (ruleTrigger) => {
    if (!isAllObjectValuesNonEmpty(ruleTrigger)) {
        return {};
    }

    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);
    const fieldsValueType =
        ruleTrigger.compareTo === WHEN_BLOCK.VALUE ? ruleTrigger.selectedCapability.valueType : WHEN_BLOCK.EXPRESSION;
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    item: WHEN_BLOCK.ITEM,
                    value: WHEN_BLOCK.VALUE,
                },
                name: COMPARISON_DATA.METHOD.IS_ITEM_STATE,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability._id,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: fieldsValueType,
                value: getRuleTriggerCapabilityValue(ruleTrigger),
            },
        ],
    };

    if (ruleTrigger.armedState === ARMED || ruleTrigger.armedState === DISARMED) {
        generatedBlock.blockOptions.method.args.armed = 'armed';
        generatedBlock.fields.push({
            name: WHEN_BLOCK.ARMED,
            type: WHEN_BLOCK.TYPE.BOOLEAN,
            value: ruleTrigger.armedState === ARMED,
        });
    }

    return generatedBlock;
};

const generateIsButtonState = (ruleTrigger) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    item: WHEN_BLOCK.ITEM,
                    value: WHEN_BLOCK.VALUE,
                },
                name: COMPARISON_DATA.METHOD.IS_BUTTON_STATE,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability._id,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: WHEN_BLOCK.TYPE.TOKEN,
                value: ruleTrigger.comparingValue,
            },
        ],
    };
};

const generateCompareStringsBlock = (ruleTrigger, metaType) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: metaType ? metaType : WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    comparator: WHEN_BLOCK.COMPARATOR,
                    item: WHEN_BLOCK.ITEM,
                    value: ruleTrigger.compareTo,
                },
                name: ruleTrigger.selectedComparator.method,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability._id,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: ruleTrigger.compareTo === WHEN_BLOCK.EXPRESSION ? WHEN_BLOCK.EXPRESSION : WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.comparingValue,
            },
        ],
    };
};

const generateWhenBlockWithoutCompare = (ruleTrigger, currentWhenBlock, metaType) => {
    const currentFields = currentWhenBlock?.blocks?.[0]?.fields;

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: metaType,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    item: WHEN_BLOCK.ITEM,
                },
                name: TRIGGER_TYPES.IS_ITEM_STATE_CHANGED,
            },
        },
        fields: currentFields
            ? currentFields
            : [
                  {
                      name: WHEN_BLOCK.ITEM,
                      type: WHEN_BLOCK.ITEM,
                      value: '',
                  },
              ],
    };
};

const getValueFieldType = (operationFieldValue) => {
    if (
        operationFieldValue &&
        (operationFieldValue === COMPARISON_DATA.OPERATION.LENGTH ||
            operationFieldValue === COMPARISON_DATA.OPERATION.NOT_LENGTH)
    ) {
        return WHEN_BLOCK.TYPE.INT;
    }

    return WHEN_BLOCK.TYPE.STRING;
};

const generateStringOperationBlock = (ruleTrigger, metaType) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: metaType ? metaType : WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    operation: WHEN_BLOCK.OPERATION,
                    item: WHEN_BLOCK.ITEM,
                    value: ruleTrigger.compareTo,
                },
                name: ruleTrigger.selectedComparator.method,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability?._id,
            },
            {
                name: WHEN_BLOCK.OPERATION,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type:
                    ruleTrigger.compareTo === WHEN_BLOCK.EXPRESSION
                        ? WHEN_BLOCK.EXPRESSION
                        : getValueFieldType(ruleTrigger.selectedComparator?.op),
                value: ruleTrigger.comparingValue,
            },
        ],
    };
};

const generateIsUserLockOperationBlock = (ruleTrigger) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);

    return {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                name: COMPARISON_DATA.METHOD.IS_USER_LOCK_OPERATION,
                args: {
                    item: WHEN_BLOCK.ITEM,
                    state: WHEN_BLOCK.STATE,
                    user: WHEN_BLOCK.USER,
                },
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.ITEM,
                type: WHEN_BLOCK.ITEM,
                value: ruleTrigger.selectedCapability?._id,
            },
            {
                name: WHEN_BLOCK.STATE,
                type: WHEN_BLOCK.TYPE.TOKEN,
                value: ruleTrigger.selectedComparator.stateFieldValue,
            },
            {
                name: WHEN_BLOCK.USER,
                type: WHEN_BLOCK.TYPE.DICTIONARY_ID,
                optionItemValue: {
                    itemId: ruleTrigger.selectedComparator.userCodesItemId,
                },
                value: ruleTrigger.selectedComparator.userFieldValue,
            },
        ],
    };
};
/**
 * Converts the given value based on the specified value type.
 *
 * @param {*} value - The value to be converted.
 * @param {string} valueType - The type of the value.
 * @returns {*} The converted value.
 */
export const convertValue = (value, valueType) => {
    return meshbot.NUMBER_ITEM_VALUE_TYPES.includes(valueType) ? Number(value) : value;
};
/**
 * Represents the arguments for the 'isItemStateChanged' method.
 *
 * @typedef {Object} IsItemStateChangedMethodArgs
 * @property {string} item - The name of the item.
 * @property {string} [start] - The start argument name.
 * @property {string} [finish] - The finish argument name.
 */

/**
 * Represents a field associated with a rule trigger.
 *
 * @typedef {Object} RuleTriggerField
 * @property {string} name - The name of the field.
 * @property {string} type - The type of the field.
 * @property {*} value - The value of the field.
 * @property {string} [scale] - The scale of the field (if applicable).
 */

/**
 * Represents the data built for the 'isItemStateChanged' method based on the provided rule trigger.
 *
 * @typedef {Object} IsItemStateChangedData
 * @property {IsItemStateChangedMethodArgs} args - The arguments for the 'isItemStateChanged' method.
 * @property {RuleTriggerField[]} fields - The fields associated with the rule trigger.
 */

/**
 * Builds data for the 'isItemStateChanged' method based on the provided rule trigger.
 *
 * @param {Object} ruleTrigger - The rule trigger to build data from.
 * @returns {IsItemStateChangedData} The built data containing arguments and fields.
 */
export const buildIsItemStateChangedData = (ruleTrigger) => {
    const start = ruleTrigger?.comparingValue?.[meshbot.START];
    const finish = ruleTrigger?.comparingValue?.[meshbot.FINISH];
    const valueType = ruleTrigger?.selectedCapability?.valueType;
    const scale = ruleTrigger?.selectedCapability?.scale;
    const args = { item: WHEN_BLOCK.ITEM };
    const fields = [
        {
            name: WHEN_BLOCK.ITEM,
            type: WHEN_BLOCK.ITEM,
            value: ruleTrigger.selectedCapability?._id,
        },
    ];

    if (start?.value) {
        args[meshbot.START] = meshbot.START;
        fields.push({
            name: meshbot.START,
            type: start.type || valueType,
            value: convertValue(start.value, valueType),
            ...{ scale },
        });
    }

    if (finish?.value) {
        args[meshbot.FINISH] = meshbot.FINISH;
        fields.push({
            name: meshbot.FINISH,
            type: finish.type || valueType,
            value: convertValue(finish.value, valueType),
            ...{ scale },
        });
    }

    return { args, fields };
};
/**
 * Represents the options for the 'isItemStateChanged' block method.
 *
 * @typedef {Object} IsItemStateChangedBlockMethodOptions
 * @property {IsItemStateChangedMethodArgs} args - The arguments for the method.
 * @property {string} name - The name of the method.
 */

/**
 * Represents a generated 'isItemStateChanged' block.
 *
 * @typedef {Object} GeneratedIsItemStateChangedBlock
 * @property {Object} blockMeta - Meta information for the generated block.
 * @property {string} blockMeta.metaType - The meta type of the block.
 * @property {Object} blockOptions - Options for the generated block.
 * @property {IsItemStateChangedBlockMethodOptions} blockOptions.method - Options method.
 * @property {string} blockType - The type of the generated block.
 * @property {RuleTriggerField[]} fields - The fields associated with the generated block.
 */
/**
 * Generates a 'isItemStateChanged' block based on the provided rule trigger.
 *
 * @param {Object} ruleTrigger - The rule trigger to generate the block from.
 * @returns {GeneratedIsItemStateChangedBlock} The generated 'isItemStateChanged' block.
 */
export const generateIsItemStateChangedBlock = (ruleTrigger) => {
    if (!ruleTrigger.selectedCapability) {
        return {};
    }

    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);
    const { args, fields } = buildIsItemStateChangedData(ruleTrigger);

    return {
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args,
                name: COMPARISON_DATA.METHOD.IS_ITEM_STATE_CHANGED,
            },
        },
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        fields,
    };
};

/**
 * Returns Boolean that answers a question "Should selected capability use isItemState comparison method?"
 * @param {object} selectedCapability - capability that we need to check
 * @returns {boolean}
 * */

export const isItemStateComparison = (selectedCapability) => {
    switch (selectedCapability?.valueType) {
        case VALUE_TYPES.TOKEN:
            return selectedCapability.hasOwnProperty('enum');
        case VALUE_TYPES.BOOLEAN:
            return true;
        default:
            return false;
    }
};

/**
 * Returns "True" if in passed data there is a property with value equal to a second passed argument
 * @param {object} data - capability that we need to check
 * @param {string} seekingValue - value that we're seeking inside data
 * @returns {boolean}
 * */

export const detectSingleRow = (data, seekingValue) => {
    if (!_.isObject(data)) {
        throw new Error('Param "data" in #detectSingleRow should be an object');
    }
    const currentTrigger = Object.values(data).findIndex((item) => item === seekingValue);

    return currentTrigger !== -1;
};

/**
 * Extract blocks property from group rules and return flatted array with rule triggers
 * @param {Array} ruleTriggers - array of rule triggers
 * @returns {Array}
 * */

export const extractRuleTriggersFromGroups = (ruleTriggers) => {
    if (!Array.isArray(ruleTriggers)) {
        throw new Error('#extractRuleTriggersFromGroups param should be an array');
    }

    return _.flatten(ruleTriggers.map((rule) => (rule?.type === at.GROUP ? rule.blocks : rule)));
};

/**
 * Receives as param an array of rule triggers and with recursion gives back a flatten array
 * with rule triggers without groups
 * @param {Array} ruleTriggers - array of rule triggers
 * @returns {Array}
 * */

export const createFlatRuleTriggersArray = (ruleTriggers) => {
    if (!Array.isArray(ruleTriggers)) {
        throw new Error('#createFlatRuleTriggersArray param should be an array');
    }
    const extractedRules = extractRuleTriggersFromGroups(ruleTriggers);
    const hasGroups = !!extractedRules.find((rule) => rule.type === at.GROUP);
    if (hasGroups) {
        return createFlatRuleTriggersArray(extractedRules);
    } else {
        return extractedRules;
    }
};

export const getCurrentTimezone = (blocks = []) => {
    if (!blocks?.[0]?.parameters?.[1]) {
        return;
    }
    const { timezone } = blocks?.[0]?.parameters?.[1];

    return timezone;
};

export const getCurrentExpressionValue = (expressionList, nameSelectedExpression) => {
    if (!expressionList.length || !nameSelectedExpression) {
        throw new Error('There is no data');
    }
    const primitiveCurrentValue = expressionList.find(
        (expression) => expression.name === nameSelectedExpression && typeof expression.value !== 'object',
    )?.value;
    const temperatureCurrentValue = expressionList.find(
        (expression) => expression.name === nameSelectedExpression && expression.valueType === 'temperature',
    )?.value?.value;

    if (primitiveCurrentValue) {
        return primitiveCurrentValue;
    }

    return temperatureCurrentValue;
};

export const getCurrentExpressionParameters = (expressionList, nameSelectedExpression) => {
    if (expressionList.length && nameSelectedExpression) {
        return expressionList.find((expression) => expression.name === nameSelectedExpression)?.valueType;
    }
};

export const getValueForEmptyList = (expressionType) => {
    if (!expressionType) {
        throw new Error('There is no data');
    }

    if (expressionType === BLOCK_FIELD_TYPES.EXPRESSION) {
        return VALUES_FOR_EMPTY_LISTS.NO_EXPRESSIONS;
    }

    return VALUES_FOR_EMPTY_LISTS.NO_VARIABLES;
};

export const validateCurrentDataTypeForScalableValueTypes = (scalableValueTypes, selectedCapability) => {
    if (!scalableValueTypes) {
        throw new Error('There is no data');
    }

    if (selectedCapability && scalableValueTypes?.list?.includes(selectedCapability?.valueType)) {
        return true;
    }
};

export const getTime = (time) => {
    if (!time) {
        throw new Error('There is no data');
    }

    if (Array.isArray(time)) {
        const [value] = time;

        time = value;
    }

    const [hour, minute] = time.split(':');
    let hours = hour % meshBot.HOUR12;

    hours = hours ? hours : meshBot.HOUR12;

    return {
        hours: hours,
        minutes: Number(minute),
    };
};

export const getTimeForModifier = (time) => {
    if (!time) {
        throw new Error('There is no data');
    }

    const [hour] = time.split(':');

    return {
        hours: hour,
    };
};

export const updateNucalSubscriptionStruct = (id, list, value) => {
    return list.map((item) => {
        if (item.id === id) {
            item.subscription = value;
            item.subscriptionDataFromKvs && delete item.subscriptionDataFromKvs;
        }

        return item;
    });
};

export const updateNucalSubscriptionInKvsStatus = (id, list, value) => {
    return list.map((item) => {
        if (item.id === id) {
            item.hasSubscriptionInKvs = value;
        }

        return item;
    });
};

export const updateNucalSubscriptionDataFromKvs = (id, list, subscriptionData) => {
    return list.map((item) => {
        if (item.id === id) {
            item.subscriptionDataFromKvs = subscriptionData;
            item.initialSubscriptionDataFromKvs = subscriptionData;
        }

        return item;
    });
};

export const updateNucalCreateStruct = (id, list, value) => {
    return list.map((item) => {
        if (item.id === id) {
            item.blocks[0] = value;
        }

        return item;
    });
};

/*Function to update CloudVariableTrigger related data in Redux*/
export const updateCloudVariableStruct = (id, list, field, variableNodeData = {}) => {
    const {
        selectedIntegrationId,
        selectedAbstract,
        selectedVariable,
        selectedComparator,
        comparingValue,
        selectedValueType,
        selectedIntegrationIdForCompared,
        selectedAbstractForCompared,
        selectedVariableCompared,
        variableValueType,
    } = variableNodeData;

    return list.map((item) => {
        if (item.id === id && field === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
            const updatedItem = _.cloneDeep(item);

            selectedIntegrationId && (updatedItem.selectedIntegrationId = selectedIntegrationId);
            selectedAbstract && (updatedItem.selectedAbstract = selectedAbstract);
            selectedVariable !== undefined && (updatedItem.selectedVariable = selectedVariable);
            selectedComparator !== undefined && (updatedItem.selectedComparator = selectedComparator);
            selectedValueType !== undefined && (updatedItem.selectedValueType = selectedValueType);
            comparingValue !== undefined && (updatedItem.comparingValue = comparingValue);
            variableValueType !== undefined && (updatedItem.variableValueType = variableValueType);

            if (updatedItem.variableValueType !== variableValueType) {
                updateComparedValueStruct(updatedItem);
            }

            if ((updatedItem.selectedValueType || selectedValueType) === meshBot.CLOUD_VARIABLE) {
                return updateComparedCloudVariableStruct(
                    updatedItem,
                    selectedIntegrationIdForCompared,
                    selectedAbstractForCompared,
                    selectedVariableCompared,
                );
            }

            if (selectedValueType === meshBot.VALUE_TYPE || updatedItem.variableValueType !== variableValueType) {
                return updateComparedValueStruct(updatedItem);
            }

            return updatedItem;
        }

        return item;
    });
};

const updateComparedCloudVariableStruct = (
    updatedItem,
    selectedIntegrationIdForCompared,
    selectedAbstractForCompared,
    selectedVariableCompared,
) => {
    selectedIntegrationIdForCompared &&
        (updatedItem.selectedIntegrationIdForCompared = selectedIntegrationIdForCompared);
    selectedAbstractForCompared && (updatedItem.selectedAbstractForCompared = selectedAbstractForCompared);
    selectedVariableCompared !== undefined && (updatedItem.selectedVariableCompared = selectedVariableCompared);

    return updatedItem;
};

const updateComparedValueStruct = (updatedItem) => {
    updatedItem.selectedVariableCompared = null;
    updatedItem.selectedIntegrationIdForCompared = null;
    updatedItem.selectedAbstractForCompared = null;

    return updatedItem;
};

export const updateCloudVariableBlockStruct = (id, list, field, variableNodeBlockData) => {
    return list.map((item) => {
        if (
            item.id === id &&
            field === MESHBOT_NODE_TYPES.CLOUD_VARIABLES &&
            item.selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES
        ) {
            const updatedItem = _.cloneDeep(item);
            updatedItem.blocks[0] = variableNodeBlockData;

            return updatedItem;
        }

        return item;
    });
};

export const generateUniqueId = (structure) => {
    const stringifySubscriptionData = JSON.stringify(structure);

    return btoa(stringifySubscriptionData);
};

export const generateStructureForSubscriptionId = (data) => {
    if (!data?.accountUuid || !data?.method || !isObject(data?.fields)) {
        return;
    }

    return {
        uuid: data?.accountUuid,
        name: data?.method,
        params: {
            ...data?.fields,
        },
    };
};

const getRuleTriggerCapabilityValue = (ruleTrigger) => {
    const capabilityValueWithBooleanType = ruleTrigger.comparingValue === FALSE ? false : true;

    return ruleTrigger.selectedCapability.valueType === VALUE_TYPES.BOOLEAN &&
        ruleTrigger.compareTo === WHEN_BLOCK.VALUE
        ? capabilityValueWithBooleanType
        : ruleTrigger.comparingValue;
};

export const isRequiredFieldValidate = (currentItem, selectedRuleCloudNucal) => {
    if (currentItem.PAAS && currentItem.PAAS.parameters) {
        const { PAAS } = currentItem;
        const params = PAAS.parameters.find(({ name }) => name === 'params')?.params;

        if (selectedRuleCloudNucal) {
            return selectedRuleCloudNucal.every((rule) => !!params?.[rule]?.length);
        }
    } else {
        return false;
    }
};

export const validateTimeFieldValue = (blocks) => {
    if (!blocks) {
        throw new Error('There is no data');
    } else if (blocks[INDEX_SELECTED_BLOCKS_ELEMENT]) {
        return !!blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields.find((field) => field.name === FIELD_NAME).value.length;
    }
};

const getBlockTemplateForToggleValue = (itemValue) => {
    return {
        blockOptions: {
            method: {
                args: {
                    source: NAME_ARGUMENT_FIELDS.ITEM,
                },
                name: TOGGLE_VALUE_METHOD_NAME,
            },
        },
        blockType: ACTION_THEN,
        fields: [
            {
                name: NAME_ARGUMENT_FIELDS.ITEM,
                type: NAME_ARGUMENT_FIELDS.ITEM,
                value: itemValue,
            },
        ],
    };
};

export const getTemplateForToggleValue = (action, itemFields, itemId) => {
    if (!action || !itemFields) {
        throw new Error('There is no data');
    } else if (action?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT] && itemFields.length) {
        const copyAction = { ...action };
        const itemValue = itemFields.find((field) => field.type === NAME_ARGUMENT_FIELDS.ITEM)?.value;
        copyAction.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = generateActionBlock(
            copyAction.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
            getBlockTemplateForToggleValue(itemValue),
        );

        return copyAction;
    } else if (action?.blockOptions) {
        let copyAction = { ...action };
        const itemValue = itemFields.find((field) => field.type === NAME_ARGUMENT_FIELDS.ITEM)?.value;
        const actionBlockTemplate = {
            ...getBlockTemplateForToggleValue(itemValue),
            label: { lang_tag: THEN_SWITCH, text: SWITCH },
            _tempId: itemId,
        };
        copyAction = generateActionBlock(copyAction, actionBlockTemplate);

        return copyAction;
    }
};

export const validateItemType = (fields = []) => {
    return !!fields.find((field) => field.type === NAME_ARGUMENT_FIELDS.ITEM);
};

export const validateItemValue = (fields = []) => {
    return !!fields.find((field) => field.type === VALUE_TYPES.BOOLEAN && field.value !== undefined);
};

export const formatInterval = (data, intervalNumber, intervalType) => {
    if (!data || !intervalType) {
        throw new Error('There is no data');
    }

    if (
        Object.keys(data)[meshBot.INDEX_SELECTED_ELEMENT] === meshBot.INTERVAL_NUMBER &&
        String(data.intervalNumber) !== meshBot.EMPTY_STRING
    ) {
        return `${Object.values(data)[meshBot.INDEX_SELECTED_ELEMENT]}${intervalType}`;
    }

    if (
        Object.keys(data)[meshBot.INDEX_SELECTED_ELEMENT] === meshBot.INTERVAL_TYPE &&
        String(intervalNumber) !== meshBot.EMPTY_STRING
    ) {
        return `${intervalNumber}${Object.values(data)[meshBot.INDEX_SELECTED_ELEMENT]}`;
    }

    return meshBot.EMPTY_STRING;
};

/**
 * Prepares a payload to request to save a subscription to kvs
 * @param {Object} data - Selected capability, it can be a subscription or a variable or something else
 * @return {Object} - payload to request to save the subscription to kvs
 */
export const generateSubscriptionsPayloadForKvsSet = (data) => {
    const { parameters } = data?.subscription?.params;
    const parametersForSubscription = parameters;
    const subscriptionUniqueId = parameters?.custom?.id;
    delete parametersForSubscription.custom;

    const kvsStructureForSubscription = {
        [subscriptionUniqueId]: {
            uuid: data?.subscription?.params?.uuid,
            parameters: parametersForSubscription,
        },
    };

    return { kvsStructureForSubscription, subscriptionUniqueId };
};
/**
 * Function generates a unique subscription id based on the passed data.
 * @param {Object} subscription - Selected capability, it can be a subscription or a variable or something else
 * @return {String} - unique subscription id
 */
const generateSubscriptionUniqueId = (subscription) => {
    //TODO It's necessary to rewrite the algorithm for creating a unique subscription name.
    // The name must not be longer than 32 characters!
    const structure = generateStructureForSubscriptionId(subscription);
    const uniqueId = generateUniqueId(structure);
    const idSha = sha1(`${structure}`);
    const uniqueIdNumber = uniqueId
        .split('')
        .map((s) => {
            return s.charCodeAt(0) - 96;
        })
        .reduce((prev, current) => {
            return prev + Number(current);
        }, 0);

    const numberOfVacancies = 11 - String(uniqueIdNumber).length;
    const shortUniqueId = idSha.slice(0, numberOfVacancies) + String(uniqueIdNumber);

    return `${SUBSCRIPTION}_${shortUniqueId}`;
};

/**
 * Prepares the data and looks for an existing item that belongs to the selected capacity(subscription)
 * @param {Object} selectedVariable - Selected capability, it can be a subscription or a variable or something else
 * @param {Object} selectedAbstractUuid - uuid of the abstract of selected capability
 * @param {Object} ezlo - ezlo from redux store
 * @return {Object} - returns the found item if item does not exist then the object is empty
 */
const findSubscriptionItem = (selectedVariable, selectedAbstractUuid, ezlo) => {
    const data = {
        ezlo,
        selectedVariable,
        selectedAbstract: { uuid: selectedAbstractUuid },
    };

    return checkIfSubscriptionItemExist(data);
};

/**
 * Function finds and generates the necessary data to create a subscription on the cloud and on the hub
 * @param {Object} subscription - subscription object with incomplete data to create a subscription
 * @param {Object} ezlo - ezlo from redux store
 * @return {Object} - return required data for the subscription(subscriptionId, item, capability)
 */
export const collectSubscriptionData = (subscription, ezlo) => {
    const subscriptionId = generateSubscriptionUniqueId(subscription);
    const capability = `${CUSTOM}_${subscriptionId}`;
    const item = findSubscriptionItem(capability, subscription.accountUuid, ezlo);

    return { subscriptionId, item, capability };
};

/**
 * Function determines whether the abstract has a value
 * @param {Object} hubSubscriptionData - payload to create a subscription on the hub
 * @param {Object} abstract - abstract capability(from cloud) with data about it
 * @return {Boolean} - returns true if abstract capability has a value
 */
const hasAbstractValueForHubSubscription = (hubSubscriptionData, abstract) => {
    const { ABSTRACT_UUID, CAPABILITY_NAME, VALUE } = ABSTRACT_INDEXES;
    const { uuid, capability } = hubSubscriptionData;

    return capability === abstract[CAPABILITY_NAME] && uuid === abstract[ABSTRACT_UUID] && abstract[VALUE] !== null;
};

/**
 * Sets the value of the abstract capability to the payload to create a subscription on the hub
 * @param {Array} abstractsState - list of abstract capabilities(from cloud) with data about them
 * @param {Object} hubSubscription - payload to create a subscription on the hub
 */
export const setValueToHubSubscription = (abstractsState, hubSubscription) => {
    abstractsState.forEach((abstract) => {
        if (hasAbstractValueForHubSubscription(hubSubscription, abstract)) {
            const { VALUE } = ABSTRACT_INDEXES;
            hubSubscription.value = abstract[VALUE];
        }
    });
};

/**
 * Function(selector) that returns data about the current controller
 * @param {Object} state - redux state
 * @return {{ serial: (string|number|null), controllerData: (Object|null), lineLoading: boolean, isConnected: boolean}}
 */
export const selectorOfCurrentControllerData = ({ ezlo, app }) => {
    return {
        serial: ezlo?.serial || null,
        controllerData: ezlo?.data?.[ezlo.serial] || null,
        lineLoading: app?.lineLoading,
        isConnected: ezlo?.isConnected,
    };
};

export const setItemGroupId = (data, serial, capabilityName) => {
    if (!data || !serial) {
        throw new Error('No required data');
    }

    const itemGroupId = data[serial].itemGroups?.itemGroupsList?.find((itemGroup) =>
        itemGroup?.itemNames?.includes(capabilityName),
    )?._id;

    return itemGroupId || '';
};

/**
 * Creates onChange block structure for Device node
 * @param {String} deviceAbstract
 * @param {String} deviceCapability
 * @param {String} deviceVariable
 * @return {Array} DeviceBlocks - onChange payload structure for device
 */
export const createCloudTriggerDeviceOnChangeBlock = (deviceAbstract, deviceCapability, deviceVariable) => {
    return [
        {
            name: meshBot.ON_CHANGE,
            parameters: [
                {
                    abstract: deviceAbstract,
                    capability: deviceCapability,
                    variable: deviceVariable,
                },
            ],
        },
    ];
};

/**
 * Updates onChange block structure for Device node
 * @param {Array} deviceTrigger - Current Trigger
 * @param {String} deviceCapability - new value for deviceCapability
 * @param {String} deviceVariable - new value for deviceVariable
 * @return {Array} DeviceBlocks - updated onChange payload structure for device
 */
export const updateCloudTriggerDeviceOnChangeBlock = (deviceTriggerBlocks, nameSubBlock, deviceVariable) => {
    return deviceTriggerBlocks?.map((item) => {
        item.name = meshBot.ON_CHANGE;
        item.parameters[meshBot.INDEX_OF_ZERO].capability = nameSubBlock;
        item.parameters[meshBot.INDEX_OF_ZERO].variable = deviceVariable;

        return item;
    });
};

/**
 * Creates operator block structure for Device node
 * @param {String} name - Selected Operator
 * @param {String} deviceAbstract
 * @param {String} deviceCapability
 * @param {String} deviceVariable
 * @return {Array} DeviceBlocks - operator payload structure for device
 */
export const createCloudTriggerDeviceObject = (name, deviceAbstract, deviceCapability, deviceVariable) => {
    return [
        {
            name: name,
            parameters: [
                {
                    name: meshBot.AV,
                    parameters: [
                        {
                            abstract: deviceAbstract,
                            capability: deviceCapability,
                            variable: deviceVariable,
                        },
                    ],
                },
                {
                    name: meshBot.STATIC,
                    parameters: [
                        {
                            value: '',
                            transform: '',
                        },
                    ],
                },
            ],
        },
    ];
};

/**
 * Updates operator block structure for Device node
 * @param {Array} deviceTriggerBlocks - Current Trigger blocks
 * @param {String} deviceCapability
 * @param {String} deviceVariable
 * @return {Array} DeviceBlocks - updated operator payload structure in device blocks
 */
export const updateCloudTriggerDeviceObject = (deviceTriggerBlocks, deviceCapability, deviceVariable) => {
    return deviceTriggerBlocks?.map((item) => {
        item.name = meshBot.EQUAL;
        item.parameters[meshBot.INDEX_OF_ZERO].parameters[meshBot.INDEX_OF_ZERO].capability = deviceCapability;
        item.parameters[meshBot.INDEX_OF_ZERO].parameters[meshBot.INDEX_OF_ZERO].variable = deviceVariable;
        item.parameters[meshBot.INDEX_OF_ONE].parameters[meshBot.INDEX_OF_ZERO].value = '';
        item.parameters[meshBot.INDEX_OF_ONE].parameters[meshBot.INDEX_OF_ZERO].transform = '';

        return item;
    });
};

/**
 * Create structure for Device node
 * @param {Object} deviceTriggerProperties - Current Trigger
 * @param {String} deviceNodeType
 * @return {Array} DeviceBlocks - return device block structure
 */
export const generateDeviceBlock = (deviceTriggerProperties, deviceNodeType) => {
    return deviceNodeType === meshBot.ON_CHANGE
        ? createCloudTriggerDeviceOnChangeBlock(
              deviceTriggerProperties?.idDev,
              deviceTriggerProperties?.capability,
              deviceTriggerProperties?.currentVariable,
          )
        : createCloudTriggerDeviceObject(
              deviceNodeType,
              deviceTriggerProperties?.idDev,
              deviceTriggerProperties?.capability,
              deviceTriggerProperties?.currentVariable,
          );
};

/**
 * Update structure for Device node
 * @param {Object} deviceTriggerProperties - Current Trigger
 * @param {String} capability
 * @param {String} currentVariable
 * @return {Array} DeviceBlocks - returns updated device sub block structure
 */
export const updateDeviceSubBlock = (deviceTriggerProperties, capability, currentVariable) => {
    return deviceTriggerProperties?.selectedOperator === meshBot.ON_CHANGE
        ? updateCloudTriggerDeviceOnChangeBlock(deviceTriggerProperties?.blocks, capability, currentVariable)
        : updateCloudTriggerDeviceObject(deviceTriggerProperties?.blocks, capability, currentVariable);
};

/**
 * Update structure for Device node
 * @param {Object} deviceTriggerProperties - Current Trigger
 * @param {String} capability
 * @param {String} currentVariable
 * @param {String} variableValue
 * @param {String} currentVariableType
 * @return {Array} DeviceBlocks - returns updated device current variable block structure
 */
export const updateDeviceCurrentVariable = (deviceTriggerProperties, currentVariable, currentVariableType) => {
    return deviceTriggerProperties?.selectedOperator === meshBot.ON_CHANGE
        ? deviceTriggerProperties?.blocks.map((item) => {
              item.parameters[meshBot.INDEX_OF_ZERO].variable = currentVariable;

              return item;
          })
        : deviceTriggerProperties?.blocks.map((item) => {
              item.parameters[meshBot.INDEX_OF_ZERO].parameters[meshBot.INDEX_OF_ZERO].variable = currentVariable;
              item.parameters[meshBot.INDEX_OF_ONE].parameters[meshBot.INDEX_OF_ZERO].transform = currentVariableType;
              item.parameters[meshBot.INDEX_OF_ONE].parameters[meshBot.INDEX_OF_ZERO].value = '';

              return item;
          });
};

/**
 * check whether trigger is of NuCAL
 * @param {Object} triggers - trigger in current scene
 * @return {Boolean} returns boolean for whether the trigger is NuCAL or not
 */
export const checkIfNucalTrigger = (triggers) => {
    return triggers?.name === meshBot.OPERATOR_NOT
        ? triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.name === meshBot.ON_CHANGE &&
              (triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.parameters?.[meshBot.INDEX_OF_ZERO]?.capability).includes(
                  CUSTOM,
              )
        : triggers?.name === meshBot.ON_CHANGE &&
              (triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.capability).includes(CUSTOM);
};

/**
 * extract data from current scene trigger device block
 * @param {Object} triggers - trigger in current scene
 * @param {Boolean} isNotOperator
 * @param {Boolean} isDeviceOnChange
 * @return {Object} returns device data
 */
export const extractDeviceDataFromBlocks = (triggers, isNotOperator, isDeviceOnChange) => {
    if (isNotOperator) {
        return isDeviceOnChange
            ? triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.parameters?.[meshBot.INDEX_OF_ZERO] || []
            : triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.parameters?.[meshBot.INDEX_OF_ZERO]?.parameters[
                  meshBot.INDEX_OF_ZERO
              ] || [];
    } else {
        return isDeviceOnChange
            ? triggers?.parameters?.[meshBot.INDEX_OF_ZERO] || []
            : triggers?.parameters?.[meshBot.INDEX_OF_ZERO]?.parameters?.[meshBot.INDEX_OF_ZERO] || [];
    }
};

/**
 * create default block structure for Nucal
 * @return {Array} return default Nucal block structure
 * */
export const createDefaultNucalBlockStruct = () => {
    return [
        {
            name: meshBot.ON_CHANGE,
            parameters: [
                {
                    abstract: '',
                    capability: '',
                    variable: '',
                    transform: STRING,
                },
            ],
        },
    ];
};

/**
 * Finds the error text in the response of the failed request. If the function does not find the text, it will return a line
 * @param {Object|undefined} failedNucalSubscriptionData - failed request data
 * @return {string | undefined} return error text or undefined
 * */
export const getNucalSubscriptionErrorText = (failedNucalSubscriptionData) => {
    const error = failedNucalSubscriptionData?.error_text;
    const errorMessage = isJsonToParse(error) ? JSON.parse(error) : error;

    return errorMessage?.nucalError?.errorMessage || errorMessage?.error?.error?.message;
};

/**
 * The function determines whether the Meshbot is a cloud-based type
 * @param {'cloud' | 'local' | 'notification' | 'interaction'} meshbotType - current meshbot type
 * @return {boolean} return true if  meshbotTypet equal to "cloud" or "notification" or "interaction"
 * */
export function getIsCloudTypeMeshBot(meshbotType) {
    return CLOUD_MESHBOT_TYPES.includes(meshbotType);
}

/**
 * get device group id from block
 * @param {Object}  block
 * @returns {String} device group id
 */

export const getDeviceGroupIdFromBlock = (block) => {
    return block?.fields?.find((field) => field.name === MESHBOT_NODE_TYPES.DEVICE_GROUP)?.value;
};

/**
 * get current value for comparing
 * @param {Object}  ruleTrigger
 * @returns {Boolean || String} comparing value
 */

export const getCurrentValueForComparing = (ruleTrigger) => {
    if (ruleTrigger.comparingValue === TRUE) {
        return true;
    }

    if (ruleTrigger.comparingValue === FALSE) {
        return false;
    }

    return ruleTrigger.comparingValue;
};

export const createDeviceGroupWhenBlock = (ruleTrigger) => {
    const compareMethod = ruleTrigger?.selectedComparator?.method;

    switch (compareMethod) {
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER:
            return createCompareNumbersBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.IS_ITEM_STATE:
            return createIsItemStateBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE:
            return createCompareNumberRangeBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.COMPARE_STRINGS:
            return createCompareStringsBlock(ruleTrigger);
        case COMPARISON_DATA.METHOD.STRING_OPERATION:
            return createStringOperationBlock(ruleTrigger);
        default:
            return {};
    }
};

export const createIsItemStateBlock = (ruleTrigger) => {
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE_GROUP,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    deviceGroup: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                    itemGroup: FIELDS_NAMES.ITEM_GROUP,
                    value: WHEN_BLOCK.VALUE,
                },
                name: COMPARISON_DATA.METHOD.IS_ITEM_STATE,
            },
        },
        fields: [
            {
                name: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                type: FIELD_TYPE_NAMES.DEVICE_GROUP_TYPE,
                value: ruleTrigger.deviceGroupId,
            },
            {
                name: FIELDS_NAMES.ITEM_GROUP,
                type: FIELD_TYPE_NAMES.ITEM_GROUP_TYPE,
                value: ruleTrigger.itemGroupId,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: ruleTrigger.selectedCapability.valueType,
                value: getCurrentValueForComparing(ruleTrigger),
            },
        ],
    };

    return generatedBlock;
};

export const createCompareNumbersBlock = (ruleTrigger) => {
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE_GROUP,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    deviceGroup: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                    itemGroup: FIELDS_NAMES.ITEM_GROUP,
                    value: WHEN_BLOCK.VALUE,
                    comparator: WHEN_BLOCK.COMPARATOR,
                },
                name: COMPARISON_DATA.METHOD.COMPARE_NUMBER,
            },
        },
        fields: [
            {
                name: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                type: FIELD_TYPE_NAMES.DEVICE_GROUP_TYPE,
                value: ruleTrigger.deviceGroupId,
            },
            {
                name: FIELDS_NAMES.ITEM_GROUP,
                type: FIELD_TYPE_NAMES.ITEM_GROUP_TYPE,
                value: ruleTrigger.itemGroupId,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: ruleTrigger.selectedCapability.valueType,
                value: Number(ruleTrigger.comparingValue),
            },
        ],
    };

    return generatedBlock;
};

export const createCompareNumberRangeBlock = (ruleTrigger) => {
    const startValueField = {
        name: WHEN_BLOCK.START_VALUE,
        type: mapCapabilityValueType[ruleTrigger.selectedCapability.valueType],
        value: ruleTrigger.comparingValue.start,
    };

    const endValueField = {
        name: WHEN_BLOCK.END_VALUE,
        type: mapCapabilityValueType[ruleTrigger.selectedCapability.valueType],
        value: ruleTrigger.comparingValue.end,
    };

    if (ruleTrigger.selectedCapability?.scale) {
        startValueField.scale = ruleTrigger.selectedCapability.scale;
        endValueField.scale = ruleTrigger.selectedCapability.scale;
    }

    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE_GROUP,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    deviceGroup: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                    itemGroup: FIELDS_NAMES.ITEM_GROUP,
                    comparator: WHEN_BLOCK.COMPARATOR,
                    startValue: WHEN_BLOCK.START_VALUE,
                    endValue: WHEN_BLOCK.END_VALUE,
                },
                name: COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE,
            },
        },
        fields: [
            {
                name: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                type: FIELD_TYPE_NAMES.DEVICE_GROUP_TYPE,
                value: ruleTrigger.deviceGroupId,
            },
            {
                name: FIELDS_NAMES.ITEM_GROUP,
                type: FIELD_TYPE_NAMES.ITEM_GROUP_TYPE,
                value: ruleTrigger.itemGroupId,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            { ...startValueField },
            { ...endValueField },
        ],
    };

    return generatedBlock;
};

export const createCompareStringsBlock = (ruleTrigger) => {
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE_GROUP,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    deviceGroup: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                    itemGroup: FIELDS_NAMES.ITEM_GROUP,
                    value: WHEN_BLOCK.VALUE,
                    comparator: WHEN_BLOCK.COMPARATOR,
                },
                name: COMPARISON_DATA.METHOD.COMPARE_STRINGS,
            },
        },
        fields: [
            {
                name: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                type: FIELD_TYPE_NAMES.DEVICE_GROUP_TYPE,
                value: ruleTrigger.deviceGroupId,
            },
            {
                name: FIELDS_NAMES.ITEM_GROUP,
                type: FIELD_TYPE_NAMES.ITEM_GROUP_TYPE,
                value: ruleTrigger.itemGroupId,
            },
            {
                name: WHEN_BLOCK.COMPARATOR,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: ruleTrigger.selectedCapability.valueType,
                value: ruleTrigger.comparingValue,
            },
        ],
    };

    return generatedBlock;
};

/**
 * filter the capabilities list
 * @param {Array}  devicesCapabilitiesList (capabilities list)
 * @returns {Array} the filtered capabilities list
 */

export const filterCapabilitiesList = (devicesCapabilitiesList) => {
    const filteredCapabilitiesList = devicesCapabilitiesList.filter(
        (elem) =>
            elem.valueType === CAPABILITY_VALUE_TYPE.TOKEN ||
            elem.valueType === CAPABILITY_VALUE_TYPE.BOOLEAN ||
            elem.valueType === CAPABILITY_VALUE_TYPE.INTEGER ||
            elem.valueType === CAPABILITY_VALUE_TYPE.STRING,
    );

    return filteredCapabilitiesList;
};

export const createStringOperationBlock = (ruleTrigger) => {
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE_GROUP,
            ruleTrigger,
        },
        blockOptions: {
            method: {
                args: {
                    deviceGroup: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                    itemGroup: FIELDS_NAMES.ITEM_GROUP,
                    value: WHEN_BLOCK.VALUE,
                    operation: WHEN_BLOCK.OPERATION,
                },
                name: COMPARISON_DATA.METHOD.STRING_OPERATION,
            },
        },
        fields: [
            {
                name: MESHBOT_NODE_TYPES.DEVICE_GROUP,
                type: FIELD_TYPE_NAMES.DEVICE_GROUP_TYPE,
                value: ruleTrigger.deviceGroupId,
            },
            {
                name: FIELDS_NAMES.ITEM_GROUP,
                type: FIELD_TYPE_NAMES.ITEM_GROUP_TYPE,
                value: ruleTrigger.itemGroupId,
            },
            {
                name: WHEN_BLOCK.OPERATION,
                type: WHEN_BLOCK.TYPE.STRING,
                value: ruleTrigger.selectedComparator?.op,
            },
            {
                name: WHEN_BLOCK.VALUE,
                type: getValueFieldType(ruleTrigger.selectedComparator?.op),
                value:
                    getValueFieldType(ruleTrigger.selectedComparator?.op) === WHEN_BLOCK.TYPE.INT
                        ? Number(ruleTrigger.comparingValue)
                        : ruleTrigger.comparingValue,
            },
        ],
    };

    return generatedBlock;
};

/**
 * to filter device group node by version of advanced scenes
 * @param {Array}  listNodesLocal
 * @param {Boolean}  isSupportedDeviceGroupNodeForLocalMeshBot
 * @returns {Array} nodes array
 */

export const filterDeviceGroupNodeByVersionAdvancedScenes = (
    listNodesLocal,
    isSupportedDeviceGroupNodeForLocalMeshBot,
) => {
    if (!isSupportedDeviceGroupNodeForLocalMeshBot) {
        return listNodesLocal.filter((node) => node.id !== MESHBOT_NODE_TYPES.DEVICE_GROUP);
    }

    return listNodesLocal;
};
export const determineExistenceOfDevice = (meshbotType, deviceId, devices) => {
    if (meshbotType === MESHBOT_TYPES.CLOUD && deviceId) {
        return Boolean(devices.find((device) => device.uuid === deviceId));
    }

    if (meshbotType === MESHBOT_TYPES.LOCAL && deviceId) {
        return Boolean(devices.find((device) => device._id === deviceId));
    }

    if (meshbotType === MESHBOT_TYPES.EZLOPI && deviceId) {
        return Boolean(devices.find((device) => device._id === deviceId));
    }

    return false;
};

/**
 * This function checks if the capability is an unregulated capability of the camera.
 * @param {string} itemName - item(capability) name
 * @returns {boolean}
 */
export const isPredefinedCameraCapability = (itemName) => {
    const result = Object.values(DEVICE_CAMERA_CAPABILITIES).find(
        (value) => value.type === meshBot.VALUE_TYPES.INT && value.isEditable === false && value.name === itemName,
    );

    if (!result) {
        return false;
    }

    return true;
};

export const isDelayValues = (blockObj) => {
    return (
        blockObj?.delay &&
        (blockObj?.delay?.seconds > 0 ||
            blockObj?.delay?.minutes > 0 ||
            blockObj?.delay?.hours > 0 ||
            blockObj?.delay?.days > 0)
    );
};

/**
 * This function gets/returns the pin code list of the user
 * @param {array} items - item list
 * @param {string} deviceId - device id
 * @returns {array}
 */
export const getPinCodeList = (items, deviceId) => {
    const pinCodeList = items?.find((item) => item.name === USER_CODES && item.deviceId === deviceId)?.value;

    return pinCodeList ? Object?.entries(pinCodeList) : [];
};

/**
 * This function gets/returns the "user_lock_operation" item
 * @param {array} items - item list
 * @param {string} deviceId - device id
 * @returns {object}
 */
export const getUserLockOperationItem = (items, deviceId) => {
    const userLockOperationItem = items?.find(
        (item) => item.name === USER_LOCK_OPERATION && item.deviceId === deviceId,
    );

    return userLockOperationItem ? userLockOperationItem : {};
};

/**
 * This function gets/returns the "user_codes" item id
 * @param {array} items - item list
 * @param {string} deviceId - device id
 * @returns {string}
 */
export const getUserCodesItemId = (items, deviceId) => {
    const userCodesItemId = items?.find((item) => item.name === USER_CODES && item.deviceId === deviceId)?._id;

    return userCodesItemId ? userCodesItemId : EMPTY_STRING;
};

/**
 * This function generates/returns the comparator block
 * @param {array} items - item list
 * @param {string} selectedAction - value "state" field of the When block
 * @param {string} value - value "user" field of the When block
 * @param {string} deviceId - device id
 * @returns {object}
 */
export const generateSelectedComparatorBlock = (items, selectedAction, value, deviceId) => {
    return {
        method: COMPARISON_DATA.METHOD.IS_USER_LOCK_OPERATION,
        stateFieldValue: selectedAction,
        userCodesItemId: getUserCodesItemId(items, deviceId),
        userFieldValue: value,
    };
};

/**
 * This function gets/returns the string value of the pin code key, if any
 * @param {object} ruleTrigger - ruleTrigger
 * @param {array} items - items
 * @param {string} deviceId - deviceId
 * @returns {string or null} - string value of the pin code key or null
 */
export const getSelectedPinCodeKey = (ruleTrigger, items, deviceId) => {
    const pinCodeList = items?.find((item) => item.name === USER_CODES && item.deviceId === deviceId)?.value;
    let comparingValue = null;

    if (ruleTrigger.not) {
        comparingValue = ruleTrigger?.blocks?.[meshBot.INDEX_OF_ZERO]?.blockMeta?.ruleTrigger?.comparingValue;
    } else {
        comparingValue = ruleTrigger?.comparingValue;
    }

    const isSelectedPinCode = pinCodeList?.hasOwnProperty(comparingValue);

    return isSelectedPinCode ? comparingValue : null;
};

/**
 * This function gets/returns the user's action (lock or unlock)
 * @param {object} ruleTrigger - ruleTrigger
 * @return {string} - user action
 */
export const getSelectedUserAction = (ruleTrigger) => {
    if (ruleTrigger.not) {
        return ruleTrigger?.blocks?.[meshBot.INDEX_OF_ZERO]?.blockMeta?.ruleTrigger?.selectedComparator
            ?.stateFieldValue;
    }

    return ruleTrigger?.selectedComparator?.stateFieldValue;
};

/**
 * Get all comparison methods from a list of comparison operator families.
 *
 * This function extracts and combines all comparison methods from a list of comparison operator families, providing a
 * single array of all available comparison methods.
 *
 * @param {Array} comparisonOperatorsFamilies - An array of comparison operator families.
 * @returns {Array} An array containing all comparison methods from the provided operator families.
 *
 * @example
 * const comparisonOperatorsFamilies = [
 *   { methods: ['equals', 'notEquals'] },
 *   { methods: ['greaterThan', 'lessThan'] },
 *   // Other operator families
 * ];
 * const allMethods = getAllComparisonMethods(comparisonOperatorsFamilies);
 * // Result: ['equals', 'notEquals', 'greaterThan', 'lessThan', ...Other methods]
 *
 *
 * const comparisonOperatorsFamiliesFromVirtualHub = [
 *   { method: ['equals', 'notEquals'] },
 *   { method: ['greaterThan', 'lessThan'] },
 *   // Other operator families
 * ];
 * const allMethodsFromVirtualHub = getAllComparisonMethods(comparisonOperatorsFamiliesFromVirtualHub);
 * // Result: ['equals', 'notEquals', 'greaterThan', 'lessThan', ...Other methods]
 */
export const getAllComparisonMethods = (comparisonOperatorsFamilies) => {
    return comparisonOperatorsFamilies.reduce((allMethods, operator) => {
        if (operator.methods) {
            return [...allMethods, ...operator.methods];
        }

        if (operator.method) {
            return [...allMethods, ...operator.method];
        }

        return allMethods;
    }, LIST_OF_UNIVERSAL_METHODS);
};

/**
 * This function returns accumulated exception triggers
 * @param {array} exceptions - exceptions of a scene
 * @returns {array} - accumulated exception triggers
 */
export const accumulateExceptionTriggers = (exceptions) => {
    return exceptions.reduce((accumulatedTriggers, exception) => {
        if (exception.triggers && exception.triggers.length) {
            accumulatedTriggers.push(...exception.triggers);
        }

        return accumulatedTriggers;
    }, []);
};

/**
 * This function checks if the current page is for Ezlopi controllers
 * @param {object} location - object from useLocation()
 * @returns {boolean}
 */
export const isEzlopiLocation = (location) => {
    return location.pathname === EZLOPI_AUTOMATION_PATH || EZLOPI_DYNAMIC_URL_REGEX.test(location.pathname);
};

/**
 * @typedef {Object} UpdatedComparatorData
 * @property {Object} selectedComparator - The selected comparator data.
 * @property {Object|string} comparingValue - The comparing value based on the comparison type.
 * @property {string} compareTo - The field to compare to, default is 'value'.
 */

/**
 * Get updated comparator data based on the provided comparison.
 *
 * @param {Object} comparison - The comparison data.
 * @param {string} comparison.method - The method of comparison.
 * @param {string} comparison.op - The operation of comparison.
 *
 * @returns {UpdatedComparatorData} An object containing the updated comparator data.
 *
 * @example
 * const comparisonData = { method: 'isItemStateChanged', };
 * const updatedData = getUpdatedComparatorData(comparisonData);
 * // Output: {
 * //    selectedComparator: { method: 'isItemStateChanged' },
 * //    comparingValue: { start: { value: '' }, finish: { value: '' } }
 * // }
 */
export const getUpdatedComparatorData = (comparison) => {
    if (comparison?.method === COMPARISON_DATA.METHOD.IS_ITEM_STATE_CHANGED) {
        const initialComparing = { value: '' };

        return {
            selectedComparator: comparison,
            comparingValue: {
                start: initialComparing,
                finish: initialComparing,
            },
        };
    }

    return {
        selectedComparator: comparison,
        comparingValue:
            comparison?.op === COMPARISON_DATA.OPERATION.LENGTH ||
            comparison?.op === COMPARISON_DATA.OPERATION.NOT_LENGTH
                ? meshbot.ZERO_INT
                : '',
        compareTo: BLOCK_FIELD_TYPES.VALUE,
    };
};

export const getSwitchFields = (value) => {
    return {
        labels: [
            {
                label: {
                    text: TRUE,
                },
                value: true,
            },
            {
                label: {
                    text: FALSE,
                },
                value: false,
            },
        ],
        value,
    };
};

/**
 * This function filters the devices for the Action section
 * @param {array} items - all controller items
 * @param {array} devices - all controller devices
 * @returns {array} - the array of filtered devices
 */
//TODO: remove the check for deviceTypeId !== TCP_MANAGER after Cloud is ready to work with 'tcp_manager' - this comment moved from the previous code
export const filterDevices = (items = [], devices = []) => {
    const { INT, FLOAT, BOOL, TOKEN, TEMPERATURE, DICTIONARY } = meshBot.BLOCK_FIELD_TYPES;
    const ALLOWED_BLOCK_TYPES = [INT, FLOAT, BOOL, TOKEN, TEMPERATURE, DICTIONARY];

    const actionItems = items?.filter(
        ({ valueType, hasSetter }) => ALLOWED_BLOCK_TYPES.includes(valueType) && hasSetter,
    );

    const allowedDevices = devices
        ?.filter(({ category, type }) => category !== ABSTRACT_TYPE || type !== ABSTRACT_TYPE)
        .filter(({ _id, deviceTypeId }) => {
            return actionItems?.some(({ deviceId }) => deviceId === _id && deviceTypeId !== TCP_MANAGER);
        });

    const devicesWithArmed = devices?.filter((device) => device.hasOwnProperty(meshBot.ARMED_SELECT_VALUES.ARMED));

    const combinedArray = Array.from(new Set(allowedDevices.concat(devicesWithArmed)));

    return combinedArray;
};

/**
 * Checks if there are unsaved changes in an edit operation.
 *
 * @param {string} cloudMeshbotName - The name of the cloud meshbot.
 * @param {array} ruleCloudTriggers - trigger field of meshbot.
 * @param {array} ruleCloudActions - action field of meshbot.
 * @param {boolean} isValidOrChanged - isValidOrChanged tells meshbot is valid or not.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const checkEditUnsavedChanges = (
    cloudMeshbotName,
    ruleCloudTriggers,
    ruleCloudActions,
    isValidOrChanged,
    isEditedTriggerArray,
    isEditedActionArray,
) => {
    const isEdited = isEditedTriggerArray?.length || isEditedActionArray?.length;
    if (!cloudMeshbotName && !ruleCloudTriggers && !ruleCloudActions) {
        return false;
    } else if (!isEdited && isValidOrChanged && cloudMeshbotName && ruleCloudActions?.length) {
        return false;
    } else if (isEdited || !isValidOrChanged || !ruleCloudActions?.length || !cloudMeshbotName?.length) {
        return true;
    }

    return false;
};

/**
 * Checks if there are unsaved changes in a create operation.
 *
 * @param {string} cloudMeshbotName - The name of the cloud meshbot.
 * @param {array} ruleCloudTriggers - trigger field of meshbot.
 * @param {array} ruleCloudActions - action field of meshbot.
 * @param {boolean} isValidOrChanged - isValidOrChanged tells meshbot is valid or not.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const checkCreateUnsavedChanges = (cloudMeshbotName, ruleCloudTriggers, ruleCloudActions, isValidOrChanged) =>
    Boolean(cloudMeshbotName?.length || ruleCloudTriggers?.length || ruleCloudActions?.length || !isValidOrChanged);

/**
 * Determines whether the feature is supported by the current firmware version
 * @param {string} featureVersion - the feature version
 * @param {string} firmwareVersion - the firmware version of the ezlopi device
 * @returns {boolean}
 */
export const isFeatureSupportByEzlopiFirmwareVersion = (featureVersion, firmwareVersion) => {
    if (featureVersion === meshBot.UNSUPPORTED) {
        return false;
    } else {
        const featureVersionNumbers = featureVersion?.split('.');
        const firmwareVersionNumbers = firmwareVersion?.split('.');

        for (let i = 0; i < featureVersionNumbers?.length; i++) {
            const featureVersionNumber = Number(featureVersionNumbers?.[i]);
            const firmwareVersionNumber = Number(firmwareVersionNumbers?.[i] || '0');

            if (firmwareVersionNumber < featureVersionNumber) {
                return false;
            } else if (firmwareVersionNumber > featureVersionNumber) {
                return true;
            }
        }

        return true;
    }
};

/**
 * Returns the ezlopi elements depending on the firmware version
 * @param {array} elements - all elements of the feature
 * @param {string} firmwareVersion - the firmware version of the ezlopi device
 * @returns {array} - filtered elements of the feature
 */
export const getSupportedEzlopiElements = (elements, firmwareVersion) => {
    if (elements?.length && firmwareVersion && typeof firmwareVersion === STRING) {
        return elements.filter((element) =>
            isFeatureSupportByEzlopiFirmwareVersion(element.firmwareVersion, firmwareVersion),
        );
    }

    return [];
};

const getDateAndTimeTypes = (meshbotType, firmwareVersion) => {
    if (meshbotType === meshBot.CLOUD) {
        return dateAndTimeCloud;
    } else if (meshbotType === MESHBOT_TYPES.EZLOPI) {
        return getSupportedEzlopiElements(dateAndTimeEzlopi, firmwareVersion);
    } else {
        return dateAndTime;
    }
};

const getGlobalRestrictionDateAndTimeTypes = (dateAndTimeTypes) => {
    return dateAndTimeTypes.filter(
        (type) => !UNAVAILABLE_DATE_AND_TIME_TYPES_FOR_GLOBAL_RESTRICTION.includes(type.name),
    );
};
export const getAvailableDateAndTimeTypeList = (meshbotType, firmwareVersion, isGlobalRestriction) => {
    const dateAndTimeTypes = getDateAndTimeTypes(meshbotType, firmwareVersion);

    if (isGlobalRestriction) {
        return getGlobalRestrictionDateAndTimeTypes(dateAndTimeTypes);
    }

    return dateAndTimeTypes;
};
/**
 * Retrieves a list of node types to which functions are available based on a particular trigger type.
 * @param {string} typeTrigger - The type of trigger to consider when choosing the node list.
 * @returns {string[]} - A list of node types for the provided trigger type.
 */
const getNodesWithFunctionsByTypeTrigger = (typeTrigger) => {
    return typeTrigger === EXCEPTION ? EXCEPTION_NODES_WITH_FUNCTIONS : NODES_WITH_FUNCTIONS;
};
/**
 * Checks if trigger function fields are available based on a certain type of meshbot, trigger type,
 * and selected field.
 * @param {string} meshbotType - The type of the Meshbot.
 * @param {string} typeTrigger - The type of trigger.
 * @param {string} selectedFieldTrigger - The selected field trigger(selected node).
 * @returns {boolean} - `true` if the trigger function fields are available, otherwise `false`.
 */
export const isTriggerFunctionsFieldsAvailable = (meshbotType, typeTrigger, selectedFieldTrigger) => {
    return (
        (meshbotType === MESHBOT_TYPES.LOCAL || meshbotType === MESHBOT_TYPES.EZLOPI) &&
        getNodesWithFunctionsByTypeTrigger(typeTrigger).includes(selectedFieldTrigger)
    );
};

/**
 * Extracts relevant MeshBot payload data for updating status.
 * @param {Object} data - The original MeshBot data.
 * @returns {Object} - Extracted MeshBot payload data with default values if properties are missing.
 * @example
 * const originalData = {
 *   triggers: {
 *     // Trigger properties...
 *   },
 *   actions: [
 *     // Action items...
 *   ],
 *   name: 'MeshBot1',
 *   uuid: '12345678-1234-5678-1234-567812345678',
 *   meta: {
 *     // Meta properties...
 *   },
 *   enabled: true,
 *   // Other properties...
 * };
 *
 * const extractedData = extractPayloadForUpdateStatus(originalData);
 * // extractedData will be:
 * // {
 * //   triggers: {
 * //     // Trigger properties...
 * //   },
 * //   actions: [
 * //     // Action items...
 * //   ],
 * //   name: 'MeshBot1',
 * //   uuid: '12345678-1234-5678-1234-567812345678',
 * //   meta: {
 * //     // Meta properties...
 * //   },
 * //   enabled: true,
 * // }
 */
export const extractPayloadForUpdateStatus = (data) => {
    if (!data) {
        return {};
    }

    const meshBotData = {
        triggers: data?.triggers ?? {},
        actions: data?.actions ?? [],
        name: data?.name ?? '',
        uuid: data?.uuid ?? '',
        meta: data?.meta ?? {},
        enabled: data?.enabled ?? true,
    };

    return meshBotData;
};

/**
 * Update the status of a MeshBot.
 * @param {Object} data - The original MeshBot data to be updated.
 * @param {boolean} statusValue - The new status value (true for enabled, false for disabled).
 * @returns {Object} - Updated MeshBot data with the new status.
 * @example
 * const originalData = {
 *   id: 1,
 *   name: 'MeshBot1',
 *   enabled: 0,
 *   // Other properties...
 * };
 *
 * const updatedData = updateMeshBotStatus(originalData, true);
 * // updatedData will be:
 * // {
 * //   id: 1,
 * //   name: 'MeshBot1',
 * //   enabled: 1,
 * //   // Other properties...
 * // }
 */
export const updateMeshBotStatus = (data, statusValue) => {
    if (!data || typeof statusValue !== 'boolean') {
        return data;
    }

    const updatedMeshBotStatusData = {
        ...data,
        enabled: Number(statusValue),
    };

    return updatedMeshBotStatusData;
};

/**
 * Checks if the provided MeshBot type is one of the cloud-related types.
 * Assumes MESHBOT_TYPES is defined in the current scope.
 * @param {string} type - The MeshBot type to check.
 * @returns {boolean} - True if the type is cloud-related, false otherwise.
 * @example
 * const MESHBOT_TYPES = {
 *   CLOUD: 'CLOUD',
 *   NOTIFICATION: 'NOTIFICATION',
 *   CLOUD_INTERACTION: 'CLOUD_INTERACTION',
 * };
 *
 * const isCloudType = isCloudMeshBotType('CLOUD'); // true
 * const isNotificationType = isCloudMeshBotType('NOTIFICATION'); // true
 * const isInteractionType = isCloudMeshBotType('INTERACTION'); // false
 */
export const isCloudMeshBotType = (type) => {
    if (!type) {
        return false;
    }

    return (
        type === MESHBOT_TYPES?.CLOUD ||
        type === MESHBOT_TYPES?.NOTIFICATION ||
        type === MESHBOT_TYPES?.CLOUD_INTERACTION
    );
};

/**
 * Update the 'enabled' property of the given 'newData' object with the 'enabled' value from 'meshBotData'.
 * If 'meshBotData' has an 'enabled' property defined, it updates 'newData' with this value.
 * @param {object} newData - The original object to be updated.
 * @param {object} meshBotData - The object containing the 'enabled' property.
 * @returns {object} The updated 'newData' object with the 'enabled' property if 'meshBotData' has it defined, otherwise returns 'newData' unchanged.
 */
export const getUpdatedDataWithMeshBotEnabled = (newData, meshBotData) => {
    if (meshBotData && typeof meshBotData?.enabled !== meshBot.TYPE_IS_UNDEFINED) {
        return {
            ...newData,
            enabled: meshBotData?.enabled,
        };
    }

    return newData;
};

const findGroupWithPAAS = (arr, value) => {
    const checkObject = (obj) => {
        if (
            (obj.type === 'group' && Array.isArray(obj.blocks) && value === PAAS_ACTION) ||
            (obj.type === 'group' && Array.isArray(obj.blocks) && value === OPERATOR_AND)
        ) {
            return (
                (obj.blocks.some((block) => block.selectedFieldTrigger === 'PAAS') &&
                    obj.optionType === OPERATOR_AND) ||
                (obj.blocks.every((block) => block.selectedFieldTrigger === 'PAAS') && value === OPERATOR_AND)
            );
        }

        if (Array.isArray(obj.blocks)) {
            return obj.blocks.some((block) => checkObject(block));
        }

        return false;
    };

    return arr.some((obj) => checkObject(obj));
};

export const isMultipleCloudServiceWithAnd = (listRuleTriggers = [], value = '', optionType = '') => {
    const count = listRuleTriggers.reduce((counter, obj) => {
        if (obj.selectedFieldTrigger === PAAS_ACTION) {
            counter++;
        }

        return counter;
    }, ZERO_INT);

    if (hasGroupType(listRuleTriggers)) {
        return findGroupWithPAAS(listRuleTriggers, value);
    } else {
        return (
            (listRuleTriggers[ZERO_INT]?.selectedFieldTrigger === PAAS_ACTION &&
                value === PAAS_ACTION &&
                optionType === OPERATOR_AND) ||
            (listRuleTriggers[ZERO_INT]?.selectedFieldTrigger === PAAS_ACTION && count > 1)
        );
    }
};

const hasGroupType = (arr) => {
    const checkObject = (obj) => {
        if (obj.type === 'group') {
            return true;
        }

        if (Array.isArray(obj.blocks)) {
            return obj.blocks.some((block) => checkObject(block));
        }

        return false;
    };

    return arr.some((obj) => checkObject(obj));
};

export const sortByName = (array) => array.sort((a, b) => a?.name?.localeCompare(b?.name));

export const getGroupIntegrations = (categoriesList, filteredIntegrations) => {
    return categoriesList.map((category) =>
        filteredIntegrations
            .filter((integration) => integration.category === category)
            .reduce(
                (result, integration) => {
                    result['name'] = category;
                    result['values'].push(integration);

                    return result;
                },
                { name: '', values: [] },
            ),
    );
};

const generateIsDeviceStateBlock = (ruleTrigger) => {
    const ruleTriggerWithoutBlocks = getRuleTriggerWithoutBlocks(ruleTrigger);
    const generatedBlock = {
        blockType: WHEN_BLOCK.BLOCK_TYPE_WHEN,
        blockMeta: {
            metaType: WHEN_BLOCK.META_TYPE.DEVICE,
            ruleTrigger: ruleTriggerWithoutBlocks,
        },
        blockOptions: {
            method: {
                args: {
                    device: WHEN_BLOCK.METHOD_ARGS.DEVICE,
                },
                name: COMPARISON_DATA.METHOD.IS_DEVICE_STATE,
            },
        },
        fields: [
            {
                name: WHEN_BLOCK.FIELDS.NAME.DEVICE,
                type: WHEN_BLOCK.FIELDS.TYPE.DEVICE,
                value: ruleTrigger.deviceId,
            },
        ],
    };

    if (ruleTrigger?.armedState === ARMED || ruleTrigger?.armedState === DISARMED) {
        generatedBlock.blockOptions.method.args.armed = WHEN_BLOCK.METHOD_ARGS.ARMED;
        generatedBlock.fields.push({
            name: WHEN_BLOCK.FIELDS.NAME.ARMED,
            type: WHEN_BLOCK.TYPE.BOOLEAN,
            value: ruleTrigger.armedState === ARMED,
        });
    }

    if (
        ruleTrigger?.reachableState === REACHABLE_SELECT_VALUES.REACHABLE ||
        ruleTrigger?.reachableState === REACHABLE_SELECT_VALUES.UNREACHABLE
    ) {
        generatedBlock.blockOptions.method.args.reachable = WHEN_BLOCK.METHOD_ARGS.REACHABLE;
        generatedBlock.fields.push({
            name: WHEN_BLOCK.FIELDS.NAME.REACHABLE,
            type: WHEN_BLOCK.TYPE.BOOLEAN,
            value: ruleTrigger.reachableState === REACHABLE_SELECT_VALUES.REACHABLE,
        });
    }

    return generatedBlock;
};

/**
 * Returns the array of filtered reachable options
 * @param {string} armedState - the Armed state value
 * @returns {array} - the array of reachable options
 */
export const filterReachableSelectOptions = (armedState) => {
    if (armedState === ARMED_SELECT_VALUES.ANY) {
        return REACHABLE_SELECT_OPTIONS.filter((option) => option.value !== REACHABLE_SELECT_VALUES.ANY);
    }

    return REACHABLE_SELECT_OPTIONS;
};

export const getSpecificDateLabels = (isGlobalRestriction) => {
    if (isGlobalRestriction) {
        return rangeSpecificDateLabels;
    }

    return specificDateLabel;
};
export const getCustomDateList = (isGlobalRestriction) => {
    if (isGlobalRestriction) {
        return rangeCustomDateList;
    }

    return customDate;
};

export const getMeshbotStateValues = (isGlobalRestriction) => {
    if (isGlobalRestriction) {
        return MESHBOT_STATE_RANGE_VALUES;
    }

    return MESHBOT_STATE_VALUES;
};

/**
 * This function returns the filtered comparison methods by ezlopi or empty array
 * @param {array} comparisonMethods - comparison methods
 * @param {string} firmwareVersion - firmware version
 * @returns {array} - filtered comparison methods by ezlopi or empty array
 */
export const handleComparatorOptionsByEzloPi = (comparisonMethods, firmwareVersion) => {
    if (!Array.isArray(comparisonMethods) || !comparisonMethods.length) {
        return [];
    }

    return comparisonMethods.filter((method) =>
        isFeatureSupportByEzlopiFirmwareVersion(EZLOPI_COMPARISON_METHODS_VERSIONS[method.method], firmwareVersion),
    );
};

const filterUnlatchMenuItem = (list) => list.filter((element) => element.value !== meshBot.UNLATCH_SCENE);

/**
 * Retrieves a filtered list of capability values based on the meshbot type, latch status, and firmware version.
 *
 * @param {string} meshbotType - The type of meshbot. Expected values: "LOCAL" or "EZLOPI".
 * @param {boolean} hasCurrentLatches - Flag indicating whether the current scene includes latches.
 * @param {string} firmwareVersion - The firmware version of the device (only applicable for EZLOPI meshbots).
 * @returns {Array<Object>} A filtered list of capability values based on the provided parameters.
 *                          Returns an empty array if no supported capabilities are found.
 */
export const getFilteredCapabilityValues = (meshbotType, hasCurrentLatches, firmwareVersion) => {
    if (meshbotType === MESHBOT_TYPES.LOCAL) {
        return hasCurrentLatches
            ? meshBot.MESHBOT_ACTION_SELECT_VALUES
            : filterUnlatchMenuItem(meshBot.MESHBOT_ACTION_SELECT_VALUES);
    }

    if (meshbotType === MESHBOT_TYPES.EZLOPI) {
        const supportedElements = getSupportedEzlopiElements(meshBot.MESHBOT_ACTION_SELECT_VALUES, firmwareVersion);

        if (!supportedElements?.length) {
            return [];
        }

        const hasUnlatchMenuItem = supportedElements?.some((item) => item?.value === meshBot.UNLATCH_SCENE);

        return hasUnlatchMenuItem && hasCurrentLatches ? supportedElements : filterUnlatchMenuItem(supportedElements);
    }

    return [];
};

/**
 * This function is called when ezlopi controller does not support setDeviceArmed method and returns only those devices that have at least one item(capability) with the field: hasSetter === true
 * @param {Object[]} devices - the ezlopi device list
 * @param {Object[]} items - the items list of ezlopi devices
 * @returns {Object[]} - the filtered ezlopi device list by condition
 */
export const filterEzlopiDevices = (devices = [], items = []) => {
    const devicesListWithArmed = devices.filter((device) => ARMED in device);

    if (!devicesListWithArmed.length) {
        return devices;
    }

    const devicesListForHiding = devicesListWithArmed.filter(
        (device) => !items.some((item) => item.deviceId === device._id && item.hasSetter === true),
    );

    if (!devicesListForHiding.length) {
        return devices;
    }

    const devicesToHideSet = new Set(devicesListForHiding.map((device) => device._id));

    return devices.filter((device) => !devicesToHideSet.has(device._id));
};
