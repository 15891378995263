import WS from './src/WS';
import { broadcastWithOtl, withOtl } from '../../lib/opentelemetry/otlRequestsManager/wsManager';

/**
 * @class WebsocketManager
 * Used to manage multiple websockets connections
 * */
export default class WebsocketManager {
    constructor() {
        this.websockets = new Map();
        this.controllers = [];
        this.notificationCb = null;
    }

    /**
     * Describes controller serial number regular expression.
     * Examples of allowed values: 70020214, 100001110
     * */
    static CONTROLLER_SERIAL_NUMBER_REGEXP = /^([789]\d{7,})|(10\d{7,})/;

    /**
     * Websocket login event.
     * More info {@link https://log.ezlo.com/new/nma/api/unknown/#loginusermios-deprecated here}
     * */
    static WEBSOCKET_LOGIN_METHOD = 'loginUserMios';

    /**
     * Websocket waiting event timeout in milliseconds;
     * */
    static WEBSOCKET_EVENT_TIMEOUT = 10000;

    /**
     * Controller's serial number could have 'number' or 'string' type.
     * The method define serialNumber type and converts 'number' to 'string' type
     * or returns value without changes
     * @param {string|number} serial - controller serial number
     * @returns {string} string serial representation
     * */
    static convertSerialNumberToString(serial) {
        return typeof serial === 'number' ? serial.toString() : serial;
    }

    /**
     * Check is serial valid to use as controller's serial number.
     * The serial must have string or number type and be not empty.
     * @param {string|number} serial - controller serial number
     * @returns {boolean} is valid result
     * */
    static isValidSerialNumber(serial) {
        if (!serial) {
            return false;
        }
        const type = typeof serial;
        if (type !== 'number' && type !== 'string') {
            return false;
        }
        const unifiedSerial = WebsocketManager.convertSerialNumberToString(serial);

        return WebsocketManager.CONTROLLER_SERIAL_NUMBER_REGEXP.test(unifiedSerial);
    }

    /**
     * Check is 'array' valid to initialize WebsocketManager.
     * The array must be not empty and must contain objects with 'serial' and 'Server_Relay' properties
     * @returns {boolean} is valid result
     * */
    static isValidControllersArray(array) {
        const isValid = Array.isArray(array) && array.length > 0;
        if (isValid) {
            for (const item of array) {
                if (!item || !item.serial || !item.Server_Relay) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Returns 'params' object for websocket connection based on controller info
     * @returns {Object} controller - controller info
     * @returns {Object} params object
     * */
    static buildWebsocketConnectionParams(controller) {
        return { url: controller.Server_Relay };
    }

    /**
     * Returns 'params' object for websocket 'loginUserMios' event based on controller info
     * @returns {Object} controller - controller info
     * @returns {Object} params object
     * */
    static buildWebsocketLoginParams(controller) {
        const ind = localStorage.getItem('identity');
        const indSign = localStorage.getItem('identitySignature');

        return {
            MMSAuth: ind,
            MMSAuthSig: indSign,
            PK_Device: controller.serial,
        };
    }

    /**
     * Returns websocket connection by controller serial number
     * @param {string|number} serial - controller serial number
     * @returns {WS} WS instance
     * */
    getWebsocketBySerial(serial) {
        const unifiedSerial = WebsocketManager.convertSerialNumberToString(serial);

        return this.websockets.get(unifiedSerial);
    }

    /**
     * Create and open new websocket connection for the controller.
     * Put created connection to inner 'websockets' map.
     * Note: internal usage only.
     * @param {object} controller - controller object
     * */
    async connect(controller) {
        const ws = new WS();
        const params = WebsocketManager.buildWebsocketConnectionParams(controller);
        await ws.start(params);
        const method = WebsocketManager.WEBSOCKET_LOGIN_METHOD;
        const loginParams = WebsocketManager.buildWebsocketLoginParams(controller);
        await withOtl({ method, params: loginParams, serial: controller.serial }, () => ws.send(method, loginParams));
        this.websockets.set(WebsocketManager.convertSerialNumberToString(controller.serial), ws);

        return true;
    }

    /**
     * Initialize WebsocketManager instance.
     * Open websockets connections for each controller in 'controllers' array
     * @param {array} controllers - array of controllers
     * @param {function} [resolve] - onSuccess callback
     * @param {function} [reject] - onFailure callback
     * @param {function} [notificationCb] - notification callback, used to notify user about events,
     *                                      for example, notify that controller is not connected
     * @returns
     * */
    async initialize(controllers, resolve, reject, notificationCb) {
        // if (!WebsocketManager.isValidControllersArray(controllers)) {
        //     throw new Error(`Not valid 'controllers' array!`);
        // }

        this.controllers = controllers;
        if (notificationCb) {
            this.notificationCb = notificationCb;
        }

        const connectionsArr = [];
        for (const controller of this.controllers) {
            if (controller && controller.serial && controller.Server_Relay && controller.connected) {
                connectionsArr.push(this.connect(controller));
            }
        }

        return Promise.all(connectionsArr)
            .then((res) => {
                if (resolve) {
                    resolve(res);
                }
            })
            .catch((err) => {
                if (reject) {
                    reject(err);
                }
            });
    }

    /**
     * Execute 'cb' after 'ms' milliseconds
     * @param {function} cb - callback to execute
     * @param {number} [ms] - delay in milliseconds
     * @returns {number} timer id
     * */
    fallback(cb, ms = WebsocketManager.WEBSOCKET_EVENT_TIMEOUT) {
        return window.setTimeout(() => {
            if (cb) {
                cb({}); // TODO: return null?
            }
        }, ms);
    }

    /**
     * The method enqueues the specified data to be transmitted to the server
     * over the websocket connection by controller serial number.
     * More info about JSON-RPC protocol you can find {@link https://www.jsonrpc.org/specification here}
     * @param {string|number} serial - controller serial number
     * @param {string} method - json-rpc method name
     * @param {Object} params - json-rpc params
     * @param {boolean} unDetect - json-rpc detect notificationCb
     * @param {function} onSuccess - onsuccess callback
     * @param {function} onError - onerror callback
     * */
    async send(serial, method, params, onSuccess, onError, unDetect) {
        if (!WebsocketManager.isValidSerialNumber(serial)) {
            throw new Error(`Not valid serial:${serial}`);
        }
        const ws = this.getWebsocketBySerial(serial);
        if (ws) {
            const fallbackTimer = this.fallback(onSuccess);

            return withOtl({ serial, method, params }, () => ws.send(method, params))
                .then((res) => {
                    clearTimeout(fallbackTimer);
                    if (onSuccess) {
                        onSuccess(res && res.data);
                    }
                })
                .catch((e) => {
                    clearTimeout(fallbackTimer);
                    if (onError) {
                        onError(e?.error);
                    }
                });
        }

        if (this.notificationCb && !unDetect) {
            this.notificationCb(`Controller "${serial}" is offline`);
        }

        return Promise.reject(`Websocket ${serial} is not initialized`);
    }

    /**
     * Subscribe for websocket event
     * @param {string|number} serial - controller serial number
     * @param {string} name - event name
     * @param {function} cb - callback to execute
     */
    async subscribe(serial, name, cb) {
        const ws = this.getWebsocketBySerial(serial);
        if (ws) {
            ws.subscribe(name, (...args) => {
                broadcastWithOtl(serial, name, args);
                cb(...args);
            });
        }
    }

    /**
     * Unsubscribe from websocket event
     * @param {string|number} serial - controller serial number
     * @param {string} name - event name
     * @param {function} [cb] - callback to execute
     */
    async unsubscribe(serial, name, cb) {
        const ws = this.getWebsocketBySerial(serial);
        if (ws) {
            ws.unsubscribe(name, cb);
        }
    }

    /**
     * Close websocket connection for specific controller by serial number.
     * If websocket connection already closed or not exists do nothing.
     * @param {string|number} serial - controller serial number
     * @returns {bool} true - if ok, false - in other cases
     * */
    async close(serial) {
        if (!WebsocketManager.isValidSerialNumber(serial)) {
            return false;
        }

        const ws = this.getWebsocketBySerial(serial);
        if (ws) {
            ws.close();
        }

        const unifiedSerial = WebsocketManager.convertSerialNumberToString(serial);
        this.websockets.delete(unifiedSerial);

        return true;
    }

    /**
     * Close all websockets connections and clear WebsocketManager.
     * */
    async closeAll() {
        for (const ws of this.websockets.values()) {
            ws.close();
        }
        this.websockets.clear();
        this.controllers = [];
    }
}
