import { log } from '../../logging';
import { HUB_COMMAND, SEVERITY_TEXT, STATUSES } from '../../constants';
import { getDynamicWSLogAttributes } from '../../utils/getDynamicWSLogAttributes';
import { WS_ERROR_CODE, WS_ERROR_DATA, WS_ERROR_MESSAGE, WS_STATUS_TEXT } from '../../constants/attributeNames';
import { SpanStatusCode } from '@opentelemetry/api';
import { withSafelyExecute } from '../../utils/withSafelyExecute';

/**
 * Handles the rejection of a WebSocket operation.
 *
 * @param {Object} span - The OpenTelemetry span associated with the operation.
 * @param {Object} error - The error that occurred.
 * @param {Object} payload - The payload data used in the WebSocket operation.
 * @param {string|number} payload.serial - The serial number of the hub.
 * @param {string} payload.method - The WebSocket method called.
 */
function otlWsReject(span, error, { serial, method }) {
    log(`Received WS ${method} response with error`, SEVERITY_TEXT.ERROR, {
        hub_serial: serial,
        api: method,
        type: HUB_COMMAND,
        status: STATUSES.ERROR,
        ...getDynamicWSLogAttributes({ serial, error }),
    });

    span.setAttributes({
        [WS_ERROR_CODE]: error?.code || error?.error?.code || 'N/A',
        [WS_ERROR_DATA]: error?.data || error?.error?.data || 'N/A',
        [WS_ERROR_MESSAGE]: error.message || error?.error?.message || 'N/A',
        [WS_STATUS_TEXT]: STATUSES.ERROR,
    });
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
    span.end();
}

export default withSafelyExecute(otlWsReject);
