import { tracer } from '../../tracers';
import { WS_HUB_SERIAL, WS_METHOD, WS_PARAMS } from '../../constants/attributeNames';
import { EVENTS } from '../../constants';
// import { incrementMetricsCounter } from '../../metrics';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import otlWsSuccess from './otlWsSuccess';
import otlWsReject from './otlWsReject';
import { getEnvKey } from 'constants/envs';

/**
 * Initializes OpenTelemetry tracing for a WebSocket operation.
 *
 * @param {Object} payload - The payload data for the WebSocket operation.
 * @param {string|number} payload.serial - The serial number of the hub.
 * @param {string} payload.method - json-rpc method name.
 * @param {Object} payload.params - json-rpc params.
 * @returns {Object} An object containing the span, onSuccess, and onError handlers.
 */
function initialWsOtl(payload) {
    const { serial, method, params } = payload;
    const span = tracer.startSpan(`WS send ${method}`);
    span.setAttributes({
        [WS_HUB_SERIAL]: serial,
        [WS_METHOD]: method,
        [WS_PARAMS]: JSON.stringify(params),
        environment: getEnvKey(),
    });
    span.addEvent(EVENTS.WS_SEND_REQUEST_STARTED);
    // incrementMetricsCounter(method, serial, JSON.stringify(params));

    const onSuccess = (response) => {
        otlWsSuccess(span, response, payload);

        return Promise.resolve(response);
    };

    const onError = (error) => {
        otlWsReject(span, error, payload);

        return Promise.reject(error);
    };

    return {
        span,
        onSuccess,
        onError,
    };
}

export default withSafelyExecute(initialWsOtl);
