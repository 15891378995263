/* eslint-disable no-console */
import moment from 'moment';
import _, { isArray, cloneDeep } from 'lodash';
import at from '../constants/ActionTypes/MeshBot';
import * as controllerTypes from '../constants/ActionTypes/Controller';
import wsm from '../helpers/wsm';
import { getMethodArgs, getMethodName } from '../constants/MethodBlock';
import * as meshbot from '../constants/MeshbotConstant';
import blockActionTemplate, { updateHttpFields } from '../components/blockActionTemplate';
import {
    setFunctionScript,
    setValueDelay,
    removeDelay,
    getLatchData,
    generateDeviceAdvancedWhenBlock,
    createFlatRuleTriggersArray,
    getCurrentExceptionTrigger,
    dataRestructuring,
    normalizeRule,
    removeTempIdFromBlocks,
    getTemplateForToggleValue,
    generateControllerWhenBlock,
    generateNucalWhenBlock,
    createDeviceGroupWhenBlock,
    setItemGroupId,
    getIsCloudTypeMeshBot,
    extractPayloadForUpdateStatus,
    updateMeshBotStatus,
    getUpdatedDataWithMeshBotEnabled,
    getInitialMeshbotExceptions,
} from '../containers/Ezlo/EzloMeshbot/utils';
import {
    apiGetCloudMeshBot,
    apiGetAbstractList,
    apiGetCapabilities,
    apiGetOneMeshScene,
    apiSetMeshScenes,
} from '../api/apiScenes';
import { WrapperCatch } from '../api/wrapperCatch';
import {
    BLOCK_FIELD_TYPES,
    MESHBOT_NODE_TYPES,
    getNodeBlockTemplateByKey,
    MESHBOT_BLOCKS,
    MESHBOT_NOTIFICATION,
    SCRIPT,
    GET,
    LIST,
    SELECTED,
    REJECTED,
    ADD_SUCCESS,
    SET_SUCCESS,
    START_UPDATE,
    REMOVE_SUCCESS,
    SELECT_FUNCTION,
    TIME_OF_DAY,
    TRIGGER_TYPES,
    CONTROL,
    TIME_NODE,
    HOURS_TIME_ARRAY,
    TYPES,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    THREE_SECONDS,
    TOGGLE_VALUE_METHOD_NAME,
    SELECTED_REQUEST,
    URL,
    TEMPORARY_STATUS_LIST,
    SCENE_RUN_STATUSES,
    DEVICE_STATE_METHOD,
    CAPABILITY_PROPERTIES,
    ARMED_SELECT_VALUES,
    REACHABLE_SELECT_VALUES,
    COMPARISON_DATA,
} from '../constants/MeshbotConstant';
import IntegrationsActions from '../actions/IntegrationsActions';

import blockDateTemplate from '../components/blockDateTemplate';
import { toast, TOAST_TYPE } from '../components/Toast';

import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { isAllObjectValuesNonEmpty } from '../helpers/common';
import {
    creatingControl,
    addTriggerInException,
    addException,
    removingException,
    removingExceptionTrigger,
    removingControl,
    clearFieldsDateSelectedRule,
    clearFieldsDateExceptions,
    updateDateSelectedRule,
    updateMeshBotSelectedRuleTrigger,
    updateMeshBotTrigger,
    resetMeshBotTriggerBlocks,
    resetMeshBotSelectedRuleTriggerBlocks,
    updateMeshBotTriggerValue,
    updateDateExceptions,
    updateOptionTypeInException,
    updateFunctionInException,
    createTriggerFunctionObject,
    getExceptionsWithNewFunction,
    updateDeviceTriggerHelper,
    updateHouseModeTrigger,
    getControl,
    updateSelectedWhenBlock,
    updateControllerTriggerHelper,
    getDataForSubscriptions,
    scatterItemPropertyInWhenNucalBlocks,
    getMissingItemGroupsCapabilitiesList,
    generateActionBlock,
    getTemplateForSwitch,
    getGlobalRestrictionBlock,
    addTriggerInGlobalRestriction,
} from '../containers/Ezlo/EzloMeshbot/MeshbotLocal/MeshBotLocalForm/utils';
import hash from '../constants/uniqueHash';
import { CloudMeshbotActions, EzloActions, MeshBotAction, SubscriptionActions } from '.';
import { validateRule } from '../containers/Ezlo/EzloRule/helpers/validation';
import { extractNameOfVariable, parseDataForUpdatePayload } from '../helpers/helpersMeshBot';
import {
    checkIfSubscriptionItemExist,
    collectCloudVariableRequiredFields,
    collectUpdatedCloudVariableDataForTrigger,
} from '../containers/Ezlo/EzloMeshbot/components/PaasNodeForTrigger/utils';
import {
    ACTION_EXECUTION_POLICY_BY_DEFAULT,
    ACTIONS_EXECUTION_POLICY_BY_DEFAULT,
    CREATING_SUBSCRIPTION_ERROR,
    MESHBOT_SECTION_TYPE,
} from '../containers/Ezlo/EzloMeshbot/constants';
import ExpressionsActions from './ExpressionsActions';
import GenericActions from './GenericActions';
import ItemGroupsActions from './ItemGroupsActions';
import { ITEM_GROUPS_STATUS } from '../constants/ItemGroups';
import { SUBSCRIPTIONS_STATUS } from '../constants/Subscription';

import {
    EZLOGIC_TITLE_DUPLICATE_MESHBOT_SUCCESSFULLY_SAVED,
    EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED,
    EZLOGIC_WARNING_MESHBOT_NOT_SAVED,
    EZLOGIC_ERROR_TOAST_UNABLE_SAVE_MESHBOT,
} from '../constants/language_tokens';
import { updateCloudSceneStatus, handleStopSceneError } from '../components/MeshbotRun/utils';
import {
    setActiveCloudRunScenes,
    setRunningLocalMeshBotState,
    setIsSceneStoppingInitiated,
    removeExecutedLocalMeshBotState,
} from '../reducers/trackRunScenes';
import {
    buildCloudMeshBotPayloadWithUpdatedLabels,
    getExistingMeshBotLabelsUuids,
    getInitialLabels,
    getMeshBotsListWithUpdatedLabelsInMeshBot,
    getMeshBotWithUpdatedLabels,
    getNewMeshBotLabelsUuids,
    getSelectedMeshBotsWithUpdatedLabelsUuids,
    getThrottleForUpdateLabelsInMeshBot,
} from '../features/Labels/MeshBotsLabels/utils';
import { LOCAL } from '../containers/Ezlo/EzloMeshbot/constant';
import {
    clearLabelsUuidByMeshBots,
    NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS,
    setNewLabelsUuidByMeshBots,
} from '../reducers/labels';
import { ERROR_STATUS } from '../constants/api';
import MesheneActions from './MesheneActions';
import { prepareMeshBotsByTypeForQuery, sortByUuid } from '../containers/Ezlo/EzloMeshbots/utils';
import { MESHBOT_CLOUD_TYPES, MESHBOT_TYPES } from '../containers/Ezlo/EzloMeshbots/constants';
import { UPDATED_MESHBOT_LABELS_ACTIONS } from '../features/Labels/MeshBotsLabels/constants';
import { KVS_NAME } from '../reducers/kvs';
import { MESHBOT_LABELS } from '../services/kvs';
import { MESH_BOT_EDIT, EZLO_MESHBOTS } from '../constants/URLs';
import { t } from '../helpers/language';
import {
    createDuplicateLocalMeshbotPayload,
    extractDuplicateMeshbotData,
    getCloudMeshbotData,
    getCloudMeshbotDataWithUpdatedName,
    getUpdatedCloudDuplicateMeshbotPayload,
    isCloudMeshbot,
} from 'components/DuplicateMeshbot/utils';
import {
    resetDuplicateMeshBotState,
    setDuplicateMeshBotData,
    updateDuplicateCloudMeshBotPayload,
    updateIsDuplicateButtonDisabled,
    updateIsDuplicateMeshBot,
} from 'containers/Ezlo/EzloMeshbots/reducers/meshbotDuplicate';
import { BOOLEAN } from '../constants/Variables';
import { STRING } from '../constants/Devices';
import { hub } from '../services/hub';

const actions = {
    /**
     * Dispatches actions to run a scene.
     *
     * @param {Object} params - The parameters for the function.
     * @param {string} params.id - The ID of the scene to run.
     * @param {string} params.serial - The serial number of the hub.
     * @param {string} params.type - The type which can be one of the MESHBOT_CLOUD_TYPES.
     *
     * @returns {Function} - A Redux Thunk action that dispatches actions based on the parameters.
     */
    runScene:
        ({ id, serial, type }) =>
        (dispatch) => {
            if (serial) {
                dispatch(EzloActions.onRuleRun(serial, id));
                dispatch(actions.setBroadcastStatus(id, SCENE_RUN_STATUSES.STARTED, serial));
            } else if (MESHBOT_CLOUD_TYPES.includes(type)) {
                dispatch(actions.setFinishedCloudSceneStatus(id, SCENE_RUN_STATUSES.STARTED));
                dispatch(MesheneActions.runMeshScenes(id));
            }
        },
    /**
     * Thunk creator to stop a specific scene.
     *
     * @param {string} serial - The serial number of the hub.
     * @param {string} sceneId - The ID of the scene to stop.
     *
     * @returns {Function}
     * @see{[Doc for hub.scenes.stop call](https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesstop)}
     */
    stopScene: (serial, sceneId) => async (dispatch, getState) => {
        try {
            if (serial && sceneId) {
                dispatch(setIsSceneStoppingInitiated({ hubSerial: serial, sceneId, value: true }));
                await hub.scenes.stop(serial, { sceneId }, null, (error) => {
                    throw error;
                });
            }
        } catch (error) {
            dispatch(setIsSceneStoppingInitiated({ hubSerial: serial, sceneId, value: false }));
            handleStopSceneError(error, sceneId, getState()?.ezlo?.data?.[serial]?.scenes);
        }
    },

    handleCloudMeshbotSaveSuccess:
        (result, duplicateMeshbotState, duplicateMeshbotName, navigate) => async (dispatch) => {
            toast(t(EZLOGIC_TITLE_DUPLICATE_MESHBOT_SUCCESSFULLY_SAVED), { type: TOAST_TYPE.SUCCESS });
            if (duplicateMeshbotState?.editAfterDuplicate) {
                const updatedCloudMeshScenePayload = getCloudMeshbotDataWithUpdatedName(
                    duplicateMeshbotState?.cloudMeshbotPayload,
                    duplicateMeshbotName,
                    result?.data?.data?.uuid,
                );
                dispatch(CloudMeshbotActions.updateCurrentCloudMeshscene(updatedCloudMeshScenePayload));
                dispatch(CloudMeshbotActions.setCloudMeshBotName(duplicateMeshbotName));
                dispatch(updateIsDuplicateMeshBot(false));
                dispatch(updateIsDuplicateButtonDisabled(false));
                navigate(MESH_BOT_EDIT(result?.data?.data?.uuid, duplicateMeshbotState?.type));
            } else {
                dispatch(resetDuplicateMeshBotState());
                await dispatch(
                    MesheneActions.getPromiseWithTimeout(meshbot.MESHBOT_TIMEOUT, MeshBotAction.getCloudMeshBot()),
                );
            }
        },

    saveDuplicateCloudMeshbot: (duplicateMeshbotState, duplicateMeshbotName, navigate) => async (dispatch) => {
        const updatedDuplicateCloudMeshbotPayload = getUpdatedCloudDuplicateMeshbotPayload(
            duplicateMeshbotState?.cloudMeshbotPayload,
            duplicateMeshbotName,
        );
        try {
            const meshSceneResult = await dispatch(
                MesheneActions.setMeshScene(updatedDuplicateCloudMeshbotPayload, meshbot.SET_MESHBOT),
            );
            if (meshSceneResult?.data?.status === meshbot.SUCCESS) {
                dispatch(
                    actions.handleCloudMeshbotSaveSuccess(
                        meshSceneResult,
                        duplicateMeshbotState,
                        duplicateMeshbotName,
                        navigate,
                    ),
                );
            } else {
                dispatch(updateIsDuplicateMeshBot(false));
            }
        } catch (error) {
            dispatch(updateIsDuplicateMeshBot(false));
            toast(error.message, { type: TOAST_TYPE.ERROR });
        }
    },

    saveDuplicateLocalMeshbot: (duplicateMeshbotState, duplicateMeshbotName, navigate) => async (dispatch) => {
        try {
            const handleSceneAdded = (meshbot) => {
                dispatch(actions.setIsApiProcessStarted(false));
                if (duplicateMeshbotState?.editAfterDuplicate && meshbot?.name === duplicateMeshbotName) {
                    navigate(MESH_BOT_EDIT(meshbot?._id, duplicateMeshbotState?.type));
                }
                dispatch(updateIsDuplicateMeshBot(false));
                dispatch(updateIsDuplicateButtonDisabled(false));
            };
            const duplicateMeshbotPayload = createDuplicateLocalMeshbotPayload(
                duplicateMeshbotState,
                duplicateMeshbotName,
            );
            dispatch(
                actions.subscribeEzloCreateScenes([duplicateMeshbotState?.serial], (meshbot) =>
                    handleSceneAdded(meshbot),
                ),
            );
            await dispatch(EzloActions.duplicateRule(duplicateMeshbotState?.serial, duplicateMeshbotPayload));
            toast(t(EZLOGIC_TITLE_DUPLICATE_MESHBOT_SUCCESSFULLY_SAVED), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            dispatch(updateIsDuplicateMeshBot(false));
            dispatch(updateIsDuplicateButtonDisabled(false));
            dispatch(actions.setIsApiProcessStarted(false));
            toast(error.message, { type: TOAST_TYPE.ERROR });
        }
    },

    saveDuplicateMeshbot: (duplicateMeshbotState, duplicateMeshbotName, navigate) => async (dispatch) => {
        dispatch(EzloActions.selectController(duplicateMeshbotState?.serial));
        dispatch(updateIsDuplicateButtonDisabled(true));
        if (duplicateMeshbotState.type === MESHBOT_TYPES.LOCAL) {
            await dispatch(actions.saveDuplicateLocalMeshbot(duplicateMeshbotState, duplicateMeshbotName, navigate));
        } else {
            await dispatch(actions.saveDuplicateCloudMeshbot(duplicateMeshbotState, duplicateMeshbotName, navigate));
        }
    },

    updateCloudMeshbotScene: (duplicateMeshbotState) => async (dispatch) => {
        if (isCloudMeshbot(duplicateMeshbotState)) {
            try {
                const meshSceneData = await apiGetOneMeshScene(duplicateMeshbotState?.meshBotId);
                const cloudMeshbotData = getCloudMeshbotData(meshSceneData?.data);
                dispatch(updateDuplicateCloudMeshBotPayload(cloudMeshbotData));
            } catch (error) {
                toast(error.message, { type: TOAST_TYPE.ERROR });
            }
        }
    },

    updateDuplicateMeshbotData: (data, t) => (dispatch) => {
        const duplicateMeshbotData = extractDuplicateMeshbotData(data, t);
        dispatch(setDuplicateMeshBotData(duplicateMeshbotData));
    },

    updateAddedSceneBroadcastData: (data) => (dispatch) => {
        dispatch({ type: at.UPDATE_ADDED_SCENE_BROADCAST_DATA, payload: data });
    },

    goEditMeshbot: (data, navigate) => (dispatch) => {
        dispatch(MeshBotAction.clearMeshBot());
        dispatch(EzloActions.selectController(data.serial));
        navigate(MESH_BOT_EDIT(data.id, data?.type));
        dispatch(CloudMeshbotActions.setCloudMeshBotName(data?.name));
    },

    observerTrigger: (blocks, funcUpdate, idGroup) => {
        if (idGroup) {
            return blocks.map((block) => {
                if (block.id === idGroup) {
                    block.blocks = funcUpdate(block.blocks);

                    return block;
                } else if (block.type === at.GROUP) {
                    actions.observerTrigger(block.blocks, funcUpdate, idGroup);
                }

                return block;
            });
        }

        return funcUpdate(blocks);
    },

    observerTriggerException: (blocks, funcUpdate, block, id, idGroup, field, value) => {
        if (idGroup) {
            return blocks.map((item) => {
                if (item.id === idGroup) {
                    item.blocks = funcUpdate(item.blocks, id, block, value, field);

                    return item;
                } else if (item.type === at.GROUP) {
                    actions.observerTriggerException(item.blocks, funcUpdate, block, id, idGroup, field, value);
                }

                return item;
            });
        }

        return funcUpdate(blocks, id, block, value, field);
    },

    observerTriggerExceptionDataValue: (blocks, funcUpdate, id, idGroup, value, tag) => {
        if (idGroup) {
            return blocks.map((item) => {
                if (item.id === idGroup) {
                    item.blocks = funcUpdate(item.blocks, id, value, tag);

                    return item;
                } else if (item.type === at.GROUP) {
                    actions.observerTriggerExceptionDataValue(item.blocks, funcUpdate, id, idGroup, value, tag);
                }

                return item;
            });
        }

        return funcUpdate(blocks, id, value, tag);
    },

    getCurrentException: (list, func, block, id, idGroup, actionId, field, value) => {
        return list.map((item) => {
            if (item.actionId === actionId) {
                item.triggers = actions.observerTriggerException(item.triggers, func, block, id, idGroup, field, value);
            }

            return item;
        });
    },

    setExceptionGroupFields: (list, idGroup, value, field) => {
        return list.map((item) => {
            if (item.id === idGroup) {
                if (field === meshbot.TRIGGER_TYPES.FUNCTION) {
                    actions.updateOrDeleteFunction(value, item);
                } else {
                    item[field] = value;
                }

                return item;
            }

            if (item.type === at.GROUP) {
                actions.setExceptionGroupFields(item.blocks, idGroup, value, field);
            }

            return item;
        });
    },

    updateExceptions: (exceptions, funcUpdate, idGroup) =>
        exceptions.map((exception) => {
            exception.triggers = actions.observerTrigger(exception.triggers, funcUpdate, idGroup);

            return exception;
        }),

    getExceptionGroup: (list, idGroup, actionId, value, field) => {
        return list.map((item) => {
            if (item.actionId === actionId) {
                item.triggers = actions.setExceptionGroupFields(item.triggers, idGroup, value, field);
            }

            return item;
        });
    },

    getExceptionTriggers: (list, func, id, actionId, idGroup, value, tag) => {
        return list.map((item) => {
            if (item.actionId === actionId) {
                item.triggers = actions.observerTriggerExceptionDataValue(item.triggers, func, id, idGroup, value, tag);
            }

            return item;
        });
    },

    addTriggerBasic: (data, idGroup, specialType) => {
        const addTriggerInGroup = (triggers) => {
            return triggers.map((item) => {
                if (item.id === idGroup) {
                    if (specialType === at.INGROUP) {
                        item.blocks = [...item.blocks, data];
                    } else {
                        item.blocks = [...item.blocks, { id: data.id, blocks: [], not: data.not }];
                    }

                    return item;
                } else {
                    if (item.type === at.GROUP) {
                        addTriggerInGroup(item.blocks);
                    }
                }

                return item;
            });
        };

        const trigger = {
            id: data.id,
            blocks: [],
            not: data.not,
        };

        if (data.type === 'group') {
            trigger.blocks = data.blocks;
            trigger.type = at.GROUP;
        }

        if (data.hasOwnProperty('blockName')) {
            trigger.optionType = data.optionType;
            trigger.blockName = data.blockName;
        }

        return { addTriggerInGroup, trigger };
    },

    updateTriggerNodeBasic: (value, id, initialParams) => {
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item = getNodeBlockTemplateByKey(value, initialParams);
                    item.selectedFieldTrigger = value;
                    item.id = id;

                    return item;
                }

                return item;
            });
        };

        const clearBlockSelect = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item = getNodeBlockTemplateByKey(MESHBOT_NODE_TYPES.SELECT_BLOCK);
                    item.id = id;

                    return item;
                }

                return item;
            });
        };

        return { update, clearBlockSelect };
    },

    searchBlock: (...args) => {
        const [data, value, id, field, idGroup] = args;

        if (idGroup) {
            const update = (list) => {
                return list.map((item) => {
                    if (item.id === id) {
                        item[field] = value;
                    }

                    return item;
                });
            };

            return actions.observerTrigger(data, update, idGroup);
        }

        return data.map((item) => {
            if (item.id === id) {
                item[field] = value;
                if (item?.blocks) {
                    item.blocks = item.blocks.map((itemBlock) => {
                        if (itemBlock?.blockMeta?.ruleTrigger) {
                            itemBlock.blockMeta.ruleTrigger[field] = value;
                        }

                        return itemBlock;
                    });
                }

                return item;
            } else if (field === 'optionType' && !item.hasOwnProperty('blockName')) {
                item[field] = value;

                return item;
            }

            return item;
        });
    },

    searchBlockGroup: (...args) => {
        const [data, value, id, field, idGroup] = args;

        const update = (blocks) => {
            return blocks.map((elem) => {
                if (elem.id === id) {
                    elem[field] = value;

                    return elem;
                }

                return elem;
            });
        };

        return actions.observerTrigger(data, update, idGroup);
    },

    updateDeviceTriggers: (...args) => {
        const [data, value, id, field, name, idDev] = args;

        return data.map((item) => {
            if (item.id === id) {
                item[field] = value;
                item.firstBlock = value[0].blockId;
                item.name = name;
                item.idDev = idDev;

                return item;
            }

            return item;
        });
    },

    updateMeshBotTriggerBasic: (idMeshBot, block, value) => {
        const updateSelectedRuleMeshBotTrigger = (list) => {
            return list.map((item) => {
                if (item.id === idMeshBot) {
                    if (block) {
                        item.blocks = block;
                    }
                }

                return item;
            });
        };

        const updateMeshBotTrigger = (list) => {
            return list.map((item) => {
                if (item.id === idMeshBot) {
                    if (block) {
                        item.blocks = block;
                    }

                    item.meshBotStateValue = value;
                }

                return item;
            });
        };

        return { updateSelectedRuleMeshBotTrigger, updateMeshBotTrigger };
    },

    // ACTIONS

    clearScriptNotification: () => (dispatch) => {
        dispatch({ type: at.CLEAR_SCRIPT_NOTIFICATION });
    },

    clearWsRequestStatus: () => (dispatch) => {
        dispatch({ type: at.SET_WS_REQUEST_STATUS.clear });
    },

    clearEditScript: () => (dispatch) => {
        dispatch({ type: at.CLEAR_EDIT_SCRIPT });
    },

    /**
     * Action, used to clear MeshBot and MeshBot's related info in store.
     * In case, if you want partially clear MeshBot, you can provide skipFields object, with fields' names.
     * @param {object} skipFields={} - object, contains MeshBot fields, that must not be cleared
     * */

    clearMeshBot: (skipFields = {}) => {
        return (dispatch) => {
            dispatch({ type: at.CLEAR_MESH_BOT, skipFields });
        };
    },

    removeSelectedMeshBotLabel: (data, type) => {
        return (dispatch, getState) => {
            const state = getState();
            if (type === at.LOCAL) {
                const selectedRuleLabels = _.cloneDeep(state.meshBot.local.selectedRule.meta.labels);
                const selectedMeshBotLabels = _.cloneDeep(state.meshBot.local.selectedMeshBotLabels);

                const updatedData = selectedRuleLabels.filter((label) => label !== data);
                const updatedSelectedMeshBotLabels = selectedMeshBotLabels.filter((label) => label.id !== data);
                dispatch({ type: at.SET_LOCAL_MESH_BOT_LABELS, data: updatedData });
                dispatch({ type: at.SET_SELECTED_LOCAL_MESH_BOT_LABELS, data: updatedSelectedMeshBotLabels });
            }

            if (type === at.CLOUD) {
                const selectedRuleLabels = _.cloneDeep(state.meshBot.cloud.meta.labels);
                const selectedCloudLabels = _.cloneDeep(state.meshBot.cloud.selectedMeshBotLabels);

                const updatedData = selectedRuleLabels.filter((label) => label !== data);
                const updatedSelectedMeshBotLabels = selectedCloudLabels.filter((label) => label.id !== data);

                dispatch({ type: at.SET_SELECTED_CLOUD_MESH_BOT_LABELS, data: updatedSelectedMeshBotLabels });
                dispatch({
                    type: at.SET_CLOUD_MESH_BOT_LABELS,
                    data: updatedData,
                });
            }
        };
    },

    setInitialCloudLabels: (data) => {
        return (dispatch) => {
            dispatch({
                type: at.SET_CLOUD_MESH_BOT_LABELS,
                data,
            });
        };
    },

    setInitialCloudMeshBotLabels: (meta) => (dispatch, getState) => {
        const labels = getState()[KVS_NAME][MESHBOT_LABELS];
        const existingMeshBotLabelsUuids = getExistingMeshBotLabelsUuids(labels, meta?.labels);
        dispatch(actions.setInitialCloudLabels(existingMeshBotLabelsUuids));
        dispatch(actions.setInitialSelectedCloudLabels(getInitialLabels(labels, existingMeshBotLabelsUuids)));
    },

    setInitialSelectedCloudLabels: (data) => {
        return (dispatch) => {
            dispatch({
                type: at.SET_INITIAL_SELECTED_CLOUD_MESH_BOT_LABELS,
                data,
            });
        };
    },

    setMehBotLabels: (data, type) => {
        return (dispatch, getState) => {
            const state = getState();
            if (type === at.LOCAL) {
                const selectedRuleLabels = _.cloneDeep(state.meshBot.local.selectedRule.meta.labels);
                const selectedMeshBotLabels = _.cloneDeep(state.meshBot.local.selectedMeshBotLabels);
                const updatedSelectedRuleLabelsData = [...selectedRuleLabels, data.id];
                const updatedSelectedMeshBotLabels = [...selectedMeshBotLabels, data];
                dispatch({ type: at.SET_LOCAL_MESH_BOT_LABELS, data: updatedSelectedRuleLabelsData });
                dispatch({ type: at.SET_SELECTED_LOCAL_MESH_BOT_LABELS, data: updatedSelectedMeshBotLabels });
            }

            if (type === at.CLOUD) {
                const cloudLabels = _.cloneDeep(state.meshBot.cloud.meta.labels);
                const selectedCloudLabels = _.cloneDeep(state.meshBot.cloud.selectedMeshBotLabels);
                const updatedData = [...cloudLabels, data.id];
                const updatedSelectedMeshBotLabels = [...selectedCloudLabels, data];

                dispatch({
                    type: at.SET_CLOUD_MESH_BOT_LABELS,
                    data: updatedData,
                });
                dispatch({ type: at.SET_SELECTED_CLOUD_MESH_BOT_LABELS, data: updatedSelectedMeshBotLabels });
            }
        };
    },

    setNameMeshBot: (data, type) => {
        return (dispatch, getState) => {
            const state = getState();

            if (type === at.LOCAL) {
                const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

                selectedRule.name = data;
                dispatch({ type: at.SET_NAME_MESH_BOT.success, data: selectedRule });
            }

            if (type === at.CLOUD) {
                const selectCloudRule = _.cloneDeep(state.meshBot.cloud.selectCloudRule);

                selectCloudRule.name = data;
                dispatch({
                    type: at.SET_NAME_MESH_BOT_CLOUD.success,
                    data: selectCloudRule,
                });
            }
        };
    },
    setExecPolicyForActions: (value) => ({ type: at.SET_EXEC_POLICY_FOR_ACTIONS, value }),

    setExecPolicyForAction: (value, id, blockType) => (dispatch, getState) => {
        const actions = getState().meshBot.local.selectedRule?.[blockType];
        const payload = actions.map((action) => {
            if (action.id === id) {
                const copyAction = _.cloneDeep(action);

                if (value === ACTION_EXECUTION_POLICY_BY_DEFAULT) {
                    delete copyAction.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.exec_policy;
                } else {
                    copyAction.blocks = [{ ...copyAction.blocks[INDEX_SELECTED_BLOCKS_ELEMENT], exec_policy: value }];
                }

                return copyAction;
            }

            return action;
        });
        dispatch({ type: at.SET_EXEC_POLICY_FOR_ACTION, blockType, payload });
    },

    getExpressionList: (serial) => (dispatch) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.list',
                { showCode: true },
                (result) => {
                    resolve(result);
                    dispatch({
                        type: at.GET_EXPRESSION_LIST.success,
                        data: result.expressions,
                    });
                },
                (error) => {
                    bugsnagNotify(error, {
                        type: at.GET_EXPRESSION_LIST.rejected,
                        serial,
                    });
                    reject(error);
                    dispatch({ type: at.GET_EXPRESSION_LIST.rejected });
                },
            );
        });
    },

    getScenesStatus: (serial, sceneId, actionBlockName) => (dispatch, getState) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.status.get',
                { sceneId },
                (result) => {
                    const state = getState();
                    const scenes = _.cloneDeep(state.ezlo.data[serial].rules);
                    const { groupActions, groupElseActions } = _.cloneDeep(state.meshBot.local);
                    const GROUP_ACTIONS_NAMES = {
                        then: groupActions,
                        else: groupElseActions,
                    };
                    const actions = GROUP_ACTIONS_NAMES[actionBlockName];
                    const blocks = result?.status?.blocks;
                    const when = result?.status?.when || result?.when;
                    resolve(result);

                    const getActionsWithLatchesList = (actions) => {
                        return actions.map((action) => {
                            if (action.idDev === result._id || action.meshBotId === result._id) {
                                // eslint-disable-next-line
                                action.latchesList = getLatchData(blocks ? blocks : when, result?._id, scenes);
                            }

                            return action;
                        });
                    };

                    dispatch({
                        type: at.GET_SCENE_STATUS.success,
                        payload: {
                            actionBlockName,
                            actionsWithLatchesList: getActionsWithLatchesList(actions),
                        },
                    });
                },
                (error) => {
                    bugsnagNotify(error, { type: at.GET_SCENE_STATUS.rejected, serial });
                    reject(error);
                },
            );
        });
    },

    deleteExpression: (serial, params, t) => (dispatch) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.delete',
                { ...params },
                () => {
                    dispatch(ExpressionsActions.updateExpressions(serial, t));
                    dispatch({ type: at.DELETE_EXPRESSION.success });
                },
                (error) => {
                    bugsnagNotify(error, {
                        type: at.DELETE_EXPRESSION.rejected,
                        serial,
                        params,
                    });
                    reject(error);
                    dispatch({ type: at.DELETE_EXPRESSION.rejected });
                },
            );
        });
    },

    createExpression: (serial, params, id, idGroup, t) => (dispatch) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.set',
                { ...params },
                () => {
                    dispatch(ExpressionsActions.updateExpressions(serial, t));
                    dispatch({ type: at.CREATE_EXPRESSION.success });
                },
                (error) => {
                    bugsnagNotify(error, {
                        type: at.CREATE_EXPRESSION.rejected,
                        serial,
                        params,
                        id,
                        idGroup,
                    });
                    reject(error);
                    dispatch({ type: at.CREATE_EXPRESSION.rejected });
                },
            );
        });
    },

    // TODO Issue query 'hub.scenes.blocks.list' into a separate function
    getDeviceBlocks: (serial, idDevice, params, items, name, idGroup) => async (dispatch, getState) => {
        dispatch({ type: at.GET_DEVICE_BLOCKS.pending });
        await wsm.send(
            serial,
            'hub.scenes.blocks.list',
            { ...params },
            (data) => {
                const state = getState();
                const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
                let listDevicesBlocks = _.cloneDeep(state.meshBot.local.listDevicesBlocks);
                const blocks = {};

                params.devices.map((item) => {
                    blocks[item] = [];
                });

                const update = (list) => actions.updateSelectedRuleGroup(list, data, idDevice);
                selectedRule.when = actions.observerTrigger(selectedRule.when, update, idGroup);

                dispatch(actions.updateTriggerWhen(data.when, idDevice, 'blocks', idGroup, name, params.devices[0]));
                dispatch({
                    type: at.UPDATE_FIELD_SELECT_RULE.success,
                    data: selectedRule,
                });

                data.when.map((item) => {
                    const itemRule = items.find((elem) => elem._id === item.fields[0].value);
                    if (itemRule) {
                        blocks[itemRule.deviceId] = [...blocks[itemRule.deviceId], item];
                    }
                });

                const listBlocks = Object.entries(blocks);

                if (Object.keys(listDevicesBlocks).length > 0) {
                    for (const item in listDevicesBlocks) {
                        const newBlocks = listBlocks.find((elem) => elem[0] === item);
                        if (!newBlocks) {
                            listDevicesBlocks = { ...listDevicesBlocks, ...blocks };
                        }
                    }
                } else {
                    listDevicesBlocks = { ...listDevicesBlocks, ...blocks };
                }

                dispatch({
                    type: at.GET_DEVICE_BLOCKS.success,
                    data: listDevicesBlocks,
                });
            },
            (error) => {
                bugsnagNotify(error, {
                    type: at.GET_DEVICE_BLOCKS.rejected,
                    serial,
                    idDevice,
                    params,
                    items,
                    name,
                    idGroup,
                });
                dispatch({ type: at.GET_DEVICE_BLOCKS.rejected });
            },
        );
    },

    updateSelectedRuleGroup: (...args) => {
        const [data, response, idDevice] = args;

        return data.map((item) => {
            if (item.id === idDevice) {
                item.blocks = [response.when[0]];

                return item;
            }

            return item;
        });
    },

    // //TODO remove selectedRule & initialRule
    setInitialMeshbotTriggers: (data, meshBot) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            let triggersList = [];

            if (Object.keys(data).length > 0) {
                // eslint-disable-next-line
                triggersList = data.triggersList;
                selectedRule.when = data.triggersSelect.blocks;
                selectedRule.optionType = data.triggersSelect.optionType;
                if (data.triggersSelect.hasOwnProperty(TRIGGER_TYPES.FUNCTION)) {
                    selectedRule.function = data.triggersSelect.function;
                }
            }

            selectedRule.name = meshBot?.name;
            selectedRule.exec_policy = meshBot?.exec_policy ? meshBot.exec_policy : ACTIONS_EXECUTION_POLICY_BY_DEFAULT;
            dispatch({
                type: at.SET_INITIAL_MESHBOT_TRIGGERS,
                selectedRule,
                triggersList,
            });
        };
    },

    setInitialMeshbotExceptions: (data, thenData, elseData) => {
        return (dispatch) => {
            const actionsList = [...thenData.actionsSelect, ...elseData.actionsSelect];
            const exceptions = getInitialMeshbotExceptions(data, 'triggersList', actionsList);
            const selectedRuleExceptions = getInitialMeshbotExceptions(data, 'triggersSelect.blocks', actionsList);

            dispatch({ type: at.SET_INITIAL_MESHBOT_EXCEPTIONS, selectedRuleExceptions, exceptions });
        };
    },

    // TODO remove selectedRule & initialRule
    setInitialMeshbotActions: (thenData, elseData) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            selectedRule.then = thenData.actionsSelect;
            selectedRule.else = elseData.actionsSelect;

            const updateGroupActions = (groupActionsList, selectedRuleActionsList) =>
                groupActionsList.map((groupAction) => {
                    const selectedAction = selectedRuleActionsList.find(
                        (selectedRuleAction) => selectedRuleAction.id === groupAction.id,
                    );

                    if (selectedAction?.blocks[0].hasOwnProperty(CONTROL)) {
                        groupAction.isOpenedExceptionBlock = true;
                    }

                    return groupAction;
                });

            const groupActions = updateGroupActions(thenData.actionsList, selectedRule.then);

            const groupElseActions = updateGroupActions(elseData.actionsList, selectedRule.else);

            dispatch({
                type: at.SET_INITIAL_MESHBOT_ACTIONS,
                selectedRule,
                groupActions,
                groupElseActions,
            });
        };
    },

    // TODO Issue query 'hub.scenes.blocks.list' into a separate function
    getDeviceBlocksEdit: (serial, params, items) => async (dispatch, getState) => {
        dispatch({ type: at.GET_DEVICE_BLOCKS_EDIT.pending });
        await wsm.send(
            serial,
            'hub.scenes.blocks.list',
            { ...params },
            (data) => {
                const state = getState();
                let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
                const blocks = {};

                params.devices.map((item) => {
                    blocks[item] = [];
                });

                data?.when?.map((item) => {
                    const itemRule = items.find((elem) => elem._id === item.fields[0].value);
                    if (itemRule) {
                        blocks[itemRule.deviceId] = [...blocks[itemRule.deviceId], item];
                    }
                });

                const listBlocks = Object.entries(blocks);

                const updateBlocksTrigger = (list) => {
                    return list.map((item) => {
                        if (item.hasOwnProperty('blockName')) {
                            item.blocks = updateBlocksTrigger(item.blocks);
                        } else if (item.hasOwnProperty('idDev')) {
                            const [block] = listBlocks.filter((elem) => elem[0] === item.idDev);

                            if (block && item.idDev === block[0]) {
                                const currentBlock = block[1].filter((elem) => elem.blockId !== item.blocks[0].blockId);
                                item.blocks = [...item.blocks, ...currentBlock];
                            }
                        }

                        return item;
                    });
                };

                ruleTriggers = updateBlocksTrigger(ruleTriggers);
                dispatch({
                    type: at.GET_DEVICE_BLOCKS_EDIT.success,
                    blocks,
                    ruleTriggers,
                });
            },
            (error) => {
                bugsnagNotify(error, {
                    type: at.GET_DEVICE_BLOCKS_EDIT.rejected,
                    serial,
                    params,
                    items,
                });
                dispatch({ type: at.GET_DEVICE_BLOCKS_EDIT.rejected });
            },
        );
    },

    addTriggerWhen: (data, idGroup, specialType) => {
        return (dispatch, getState) => {
            const { addTriggerInGroup, trigger } = actions.addTriggerBasic(data, idGroup, specialType);

            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);

            if (idGroup) {
                selectedRule.when = addTriggerInGroup(selectedRule.when);
                ruleTriggers = addTriggerInGroup(ruleTriggers);
            } else {
                selectedRule.when = [...selectedRule.when, trigger];
                ruleTriggers = [...ruleTriggers, data];
            }

            dispatch({
                type: at.ADD_TRIGGER.success,
                data: { selected: selectedRule, trigger: ruleTriggers },
            });
        };
    },

    addExceptionTriggerWhen: ({ data, idGroup, actionId, actionBlockName }) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const { trigger } = actions.addTriggerBasic(data, idGroup);
            const exceptionId = `\${${hash()}}`;
            const hasCurrentException = exceptions.some((exception) => exception.actionId === actionId);

            if (!hasCurrentException) {
                exceptions = addException(exceptions, actionId, trigger, exceptionId);
                selectedRule.exceptions = addException(selectedRule.exceptions, actionId, trigger, exceptionId);
                selectedRule[actionBlockName] = creatingControl(selectedRule[actionBlockName], actionId, exceptionId);
            } else {
                exceptions = addTriggerInException(exceptions, actionId, trigger, idGroup);
                selectedRule.exceptions = addTriggerInException(selectedRule.exceptions, actionId, trigger, idGroup);
            }

            dispatch({ type: at.ADD_EXCEPTION_TRIGGER.success, selectedRule, exceptions });
        };
    },
    /**
     * Adds global restriction to the current rule and state.
     * @param {Object} params - The object containing parameters.
     * @param {Object} params.triggerData - The trigger data to add to the global restriction.
     * @param {string} params.idGroup - The identifier of the group where the trigger will be added.
     * @returns {Function} - Returns a function that takes the dispatch and getState functions from Redux.
     * This function updates the rule and the exceptions in the state by either adding a new global restriction
     * or adding a trigger to an existing one.
     */
    addGlobalRestriction: ({ triggerData, idGroup }) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = cloneDeep(state.meshBot.local.selectedRule);

            const { trigger } = actions.addTriggerBasic(triggerData, idGroup);
            const currentGlobalRestriction = exceptions.find(
                (exception) => exception.blockType === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION,
            );

            if (!currentGlobalRestriction) {
                const globalRestrictionBlock = getGlobalRestrictionBlock(trigger);
                exceptions.push(globalRestrictionBlock);
                selectedRule.exceptions.push(globalRestrictionBlock);
            } else {
                exceptions = addTriggerInGlobalRestriction(exceptions, trigger, idGroup);
                selectedRule.exceptions = addTriggerInGlobalRestriction(selectedRule.exceptions, trigger, idGroup);
            }

            dispatch({ type: at.ADD_EXCEPTION_TRIGGER.success, selectedRule, exceptions });
        };
    },

    toggleExceptionBlock: (id, blockName) => {
        return (dispatch, getState) => {
            const state = getState();

            const groupActions = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);

            const newGroupActions = groupActions.map((action) => {
                if (action.id === id) {
                    action.isOpenedExceptionBlock = !action.isOpenedExceptionBlock;

                    return action;
                }

                return action;
            });

            dispatch({
                type: at.TOGGLE_EXCEPTION_BLOCK.success,
                data: { groupActions: newGroupActions },
                blockName,
            });
        };
    },

    deleteTrigger: (id, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);

            let newTriggers = [];

            const searchTrigger = (list) => {
                if (idGroup) {
                    return list.map((item) => {
                        if (item.id === idGroup) {
                            item.blocks = item.blocks.filter((trigger) => trigger.id !== id);

                            return item;
                        } else if (item.type === at.GROUP) {
                            searchTrigger(item.blocks);
                        }

                        return item;
                    });
                }

                return list.filter((item) => item.id !== id);
            };

            newTriggers = searchTrigger(ruleTriggers);
            selectedRule.when = searchTrigger(selectedRule.when);

            dispatch({
                type: at.DELETE_TRIGGER.success,
                data: { selected: selectedRule, ruleTriggers: newTriggers },
            });
        };
    },

    deleteException: (actionId) => {
        return (dispatch, getState) => {
            const state = getState();

            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = removingException(exceptions, actionId);
            selectedRule.exceptions = removingException(selectedRule.exceptions, actionId);

            dispatch({ type: at.DELETE_EXCEPTION.success, selectedRule, exceptions });
        };
    },

    deleteExceptionTrigger: ({ id, actionId, actionBlockName, idGroup }) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = removingExceptionTrigger(exceptions, id, actionId, idGroup);
            selectedRule.exceptions = removingExceptionTrigger(selectedRule.exceptions, id, actionId, idGroup);

            const hasCurrentException = exceptions.find((exception) => exception.actionId === actionId);

            if (hasCurrentException?.triggers?.length === 0) {
                exceptions = removingException(exceptions, actionId);
                selectedRule.exceptions = removingException(selectedRule.exceptions, actionId);
                selectedRule[actionBlockName] = removingControl(selectedRule[actionBlockName], actionId);
            }

            dispatch({ type: at.DELETE_EXCEPTION_TRIGGER.success, selectedRule, exceptions });
        };
    },

    setUnLatch: (serial, params) => async (dispatch) => {
        dispatch({ type: at.SET_UN_LATCH.pending });

        await wsm.send(
            serial,
            'hub.scenes.block.status.reset',
            { ...params },
            () => {
                dispatch({ type: at.SET_UN_LATCH.success });
                dispatch({
                    type: at.SET_WS_REQUEST_STATUS.success,
                    payload: 'Request completed successfully',
                });
            },
            (error) => {
                bugsnagNotify(error);
                dispatch({ type: at.SET_UN_LATCH.rejected });
                dispatch({
                    type: at.SET_WS_REQUEST_STATUS.rejected,
                    payload: 'Request failed, something went wrong',
                });
            },
        );
    },

    updateTriggerNode: (value, id, idGroup, initialParams) => {
        return (dispatch, getState) => {
            const { update, clearBlockSelect } = actions.updateTriggerNodeBasic(value, id, initialParams);

            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            selectedRule.when = actions.observerTrigger(selectedRule.when, clearBlockSelect, idGroup);
            ruleTriggers = actions.observerTrigger(ruleTriggers, update, idGroup);

            dispatch({
                type: at.CLEAR_RULE_SELECTED_BLOCK.success,
                data: selectedRule,
            });
            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });
        };
    },

    updateExceptionTriggerNode: (value, id, idGroup, initialParams) => {
        return (dispatch, getState) => {
            const { update, clearBlockSelect } = actions.updateTriggerNodeBasic(value, id, initialParams);

            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const newExceptions = actions.updateExceptions(exceptions, update, idGroup);
            selectedRule.exceptions = actions.updateExceptions(selectedRule.exceptions, clearBlockSelect, idGroup);

            dispatch({
                type: at.CLEAR_RULE_SELECTED_BLOCK.success,
                data: selectedRule,
            });
            dispatch({
                type: at.UPDATE_EXCEPTION_TRIGGER.success,
                data: newExceptions,
            });
        };
    },

    updateMeshBotTriggerValue: (value, id, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);

            const update = (list) => updateMeshBotTriggerValue(list, id, value);

            ruleTriggers = actions.observerTrigger(ruleTriggers, update, idGroup);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });
        };
    },

    updateMeshBotExceptionTriggerValue: (value, id, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const update = (list) => updateMeshBotTriggerValue(list, id, value);

            const newExceptions = actions.updateExceptions(exceptions, update, idGroup);

            dispatch({
                type: at.UPDATE_EXCEPTION_TRIGGER.success,
                data: newExceptions,
            });
        };
    },

    updateDeviceExceptionTrigger: ({
        ruleId,
        name,
        deviceId,
        selectedCapability,
        selectedComparator,
        compareTo,
        comparingValue,
        armedState,
        idGroup,
        isCompareToVariable,
        actionId,
        reachableState,
    }) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const update = (list) =>
                updateDeviceTriggerHelper({
                    list,
                    ruleId,
                    name,
                    deviceId,
                    selectedCapability,
                    selectedComparator,
                    compareTo,
                    comparingValue,
                    armedState,
                    idGroup,
                    isCompareToVariable,
                    reachableState,
                });

            const newExceptions = actions.updateExceptions(exceptions, update, idGroup);

            dispatch({
                type: at.UPDATE_EXCEPTION_TRIGGER.success,
                data: newExceptions,
            });

            const currentExceptionTrigger = getCurrentExceptionTrigger(newExceptions, ruleId, actionId, idGroup);

            const updateSelectedRuleTrigger = (list) =>
                updateMeshBotSelectedRuleTrigger(list, ruleId, [
                    generateDeviceAdvancedWhenBlock(currentExceptionTrigger),
                ]);

            const shouldUpdateSelect =
                isAllObjectValuesNonEmpty(currentExceptionTrigger) ||
                (currentExceptionTrigger?.selectedComparator?.method === COMPARISON_DATA.METHOD.IS_ITEM_STATE &&
                    currentExceptionTrigger?.armedState);

            if (shouldUpdateSelect) {
                selectedRule.exceptions = actions.updateExceptions(
                    selectedRule.exceptions,
                    updateSelectedRuleTrigger,
                    idGroup,
                );
                dispatch({
                    type: at.UPDATE_SELECT_DEVICE_EXCEPTION_TRIGGER.success,
                    data: { selected: selectedRule, exceptions: newExceptions },
                });
            }
        };
    },

    updateInterfaceJSONItems: (interfaceJSONItems) => (dispatch) => {
        dispatch({ type: at.UPDATE_INTERFACE_JSON_ITEMS, interfaceJSONItems });
    },

    updateControllerTrigger: ({
        ruleId,
        idGroup,
        selectedControllerCapability,
        selectedControllerCapabilityValue,
        selectedControllerCapabilityComparator,
        selectedControllerCapabilityComparatorValue,
    }) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let selectedRuleWhen = _.cloneDeep(state.meshBot.local.selectedRule.when);

            let shouldUpdateWhenBlock = false;
            let shouldDeleteWhenBlock = false;

            const updateRuleTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);
                        selectedControllerCapability &&
                            (updatedItem.selectedControllerCapability = selectedControllerCapability);
                        selectedControllerCapability && (updatedItem.selectedControllerCapabilityValue = '');
                        selectedControllerCapability && (updatedItem.selectedControllerCapabilityComparator = '');
                        selectedControllerCapability && (updatedItem.selectedControllerCapabilityComparatorValue = '');
                        selectedControllerCapabilityValue &&
                            (updatedItem.selectedControllerCapabilityValue = selectedControllerCapabilityValue);
                        selectedControllerCapabilityComparator &&
                            (updatedItem.selectedControllerCapabilityComparator =
                                selectedControllerCapabilityComparator);
                        selectedControllerCapabilityComparatorValue &&
                            (updatedItem.selectedControllerCapabilityComparatorValue =
                                selectedControllerCapabilityComparatorValue);
                        if (updatedItem.blocks === undefined) {
                            delete updatedItem.blocks;
                        }

                        if (isAllObjectValuesNonEmpty(updatedItem)) {
                            shouldUpdateWhenBlock = true;
                        } else {
                            shouldDeleteWhenBlock = true;
                        }

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhen = (list) => {
                const ruleTrigger = ruleTriggers.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateControllerWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhenWithGroup = (list) => {
                const flattedRuleTriggersArray = createFlatRuleTriggersArray(ruleTriggers);
                const ruleTrigger = flattedRuleTriggersArray.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateControllerWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            ruleTriggers = actions.observerTrigger(ruleTriggers, updateRuleTrigger, idGroup);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });

            if (shouldUpdateWhenBlock || shouldDeleteWhenBlock) {
                if (idGroup) {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhenWithGroup, idGroup);
                } else {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhen);
                }
                dispatch({ type: at.UPDATE_CONTROLLER_CAPABILITY, data: selectedRuleWhen });
            }
        };
    },

    updateDeviceAdvancedNode: ({
        ruleId,
        name,
        deviceId,
        selectedCapability,
        selectedComparator,
        compareTo,
        comparingValue,
        armedState,
        idGroup,
        isCompareToVariable,
        reachableState,
    }) => {
        return (dispatch, getState) => {
            const state = getState();
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let selectedRuleWhen = _.cloneDeep(state.meshBot.local.selectedRule.when);

            let shouldUpdateWhenBlock = false;
            let shouldDeleteWhenBlock = false;

            const updateRuleTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        // Clearing ruleTrigger Fields on Device change
                        if (deviceId && updatedItem.deviceId && deviceId !== updatedItem.deviceId) {
                            updatedItem.deviceId = deviceId;
                            updatedItem.name = name;
                            updatedItem.selectedCapability = {};
                            updatedItem.selectedComparator = {};

                            shouldDeleteWhenBlock = true;

                            return updatedItem;
                        }

                        if (
                            selectedCapability?.name &&
                            updatedItem.selectedCapability?.name &&
                            selectedCapability.name !== updatedItem.selectedCapability.name &&
                            selectedCapability.name !== DEVICE_STATE_METHOD
                        ) {
                            updatedItem.selectedCapability = selectedCapability;
                            updatedItem.selectedComparator = {};
                            updatedItem.comparingValue = 0;

                            shouldDeleteWhenBlock = true;

                            return updatedItem;
                        }

                        deviceId && (updatedItem.deviceId = deviceId);
                        name && (updatedItem.name = name);
                        selectedCapability && (updatedItem.selectedCapability = selectedCapability);
                        selectedComparator && (updatedItem.selectedComparator = selectedComparator);
                        compareTo && (updatedItem.compareTo = compareTo);
                        comparingValue !== undefined && (updatedItem.comparingValue = comparingValue);
                        armedState && (updatedItem.armedState = armedState);
                        isCompareToVariable !== undefined && (updatedItem.isCompareToVariable = isCompareToVariable);
                        reachableState && (updatedItem.reachableState = reachableState);
                        armedState === ARMED_SELECT_VALUES.ANY &&
                            updatedItem.reachableState === REACHABLE_SELECT_VALUES.ANY &&
                            (updatedItem.reachableState = REACHABLE_SELECT_VALUES.REACHABLE);

                        if (!updatedItem?.selectedCapability?.hasOwnProperty(CAPABILITY_PROPERTIES.NAME)) {
                            shouldDeleteWhenBlock = true;
                        }

                        if (updatedItem.blocks === undefined) {
                            delete updatedItem.blocks;
                        }

                        if (isAllObjectValuesNonEmpty(updatedItem)) {
                            shouldUpdateWhenBlock = true;
                        } else {
                            shouldDeleteWhenBlock = true;
                        }

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhen = (list) => {
                const ruleTrigger = ruleTriggers.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateDeviceAdvancedWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhenWithGroup = (list) => {
                const flattedRuleTriggersArray = createFlatRuleTriggersArray(ruleTriggers);
                const ruleTrigger = flattedRuleTriggersArray.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateDeviceAdvancedWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            ruleTriggers = actions.observerTrigger(ruleTriggers, updateRuleTrigger, idGroup);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });

            if (shouldUpdateWhenBlock || shouldDeleteWhenBlock) {
                if (idGroup) {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhenWithGroup, idGroup);
                } else {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhen);
                }
                dispatch({ type: at.UPDATE_DEVICE_ADVANCED_SELECTED_RULE_WHEN, data: selectedRuleWhen });
            }
        };
    },

    updateControllerNodeAction:
        (id, controllerNodeFields, blockName = meshbot.ACTION_THEN) =>
        (dispatch, getState) => {
            const state = getState();
            const actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

            const generateControllerNodeBlocksStructure = (actions) => {
                return actions.map((action) => {
                    if (action.id === id) {
                        return {
                            ...action,
                            blocks: [
                                generateActionBlock(action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT], controllerNodeFields),
                            ],
                        };
                    }

                    return action;
                });
            };

            const updatedActions = generateControllerNodeBlocksStructure(actionsList);
            const updatedSelectedRule = generateControllerNodeBlocksStructure(selectedRule);

            dispatch({
                type: at.UPDATE_ACTION_NODE.success,
                actionsList: updatedActions,
                selectedRule: updatedSelectedRule,
                blockName,
            });
        },

    updateCloudVariablesTrigger: ({
        ruleId,
        selectedIntegrationId,
        selectedAbstract,
        selectedVariable,
        selectedComparator,
        compareTo,
        comparingValue,
        typeVariable,
        selectedCapability,
        abstractStateGetResult,
        isCompareToVariable,
        idGroup,
    }) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let selectedRuleWhen = _.cloneDeep(state.meshBot.local.selectedRule.when);

            let shouldUpdateWhenBlock = false;
            let shouldDeleteWhenBlock = false;

            const updateRuleTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        selectedIntegrationId && (updatedItem.selectedIntegrationId = selectedIntegrationId);
                        selectedAbstract && (updatedItem.selectedAbstract = selectedAbstract);
                        selectedVariable !== undefined && (updatedItem.selectedVariable = selectedVariable);
                        selectedComparator !== undefined && (updatedItem.selectedComparator = selectedComparator);
                        compareTo && (updatedItem.compareTo = compareTo);
                        comparingValue !== undefined && (updatedItem.comparingValue = comparingValue);
                        typeVariable && (updatedItem.typeVariable = typeVariable);
                        selectedCapability && (updatedItem.selectedCapability = selectedCapability);
                        isCompareToVariable !== undefined && (updatedItem.isCompareToVariable = isCompareToVariable);
                        abstractStateGetResult !== undefined &&
                            (updatedItem.abstractStateGetResult = abstractStateGetResult);

                        if (updatedItem.blocks === undefined) {
                            delete updatedItem.blocks;
                        }

                        if (isAllObjectValuesNonEmpty(collectCloudVariableRequiredFields(updatedItem))) {
                            shouldUpdateWhenBlock = true;
                        } else {
                            shouldDeleteWhenBlock = true;
                        }

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhen = (list) => {
                const ruleTrigger = ruleTriggers.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateDeviceAdvancedWhenBlock(
                                    ruleTrigger,
                                    meshbot.WHEN_BLOCK.META_TYPE.CLOUD_VARIABLES,
                                ),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhenWithGroup = (list) => {
                const flattedRuleTriggersArray = createFlatRuleTriggersArray(ruleTriggers);
                const ruleTrigger = flattedRuleTriggersArray.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateDeviceAdvancedWhenBlock(
                                    ruleTrigger,
                                    meshbot.WHEN_BLOCK.META_TYPE.CLOUD_VARIABLES,
                                ),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            ruleTriggers = actions.observerTrigger(ruleTriggers, updateRuleTrigger, idGroup);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });

            if (shouldUpdateWhenBlock || shouldDeleteWhenBlock) {
                if (idGroup) {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhenWithGroup, idGroup);
                } else {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhen);
                }
                dispatch({ type: at.UPDATE_SELECT_CLOUD_VARIABLES_TRIGGER, data: selectedRuleWhen });
            }
        };
    },

    updateNucalNodeInTrigger: (ruleId, groupId, updatedData) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let selectedRuleWhen = _.cloneDeep(state.meshBot.local.selectedRule.when);

            let shouldUpdateWhenBlock = false;
            let shouldDeleteWhenBlock = false;

            const updateRuleTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);
                        Object.assign(updatedItem, updatedData);

                        if (updatedItem.hasOwnProperty('blocks')) {
                            delete updatedItem.blocks;
                        }

                        if (!updatedItem.hasOwnProperty('not')) {
                            updatedItem.not = false;
                        }

                        isAllObjectValuesNonEmpty(updatedItem)
                            ? (shouldUpdateWhenBlock = true)
                            : (shouldDeleteWhenBlock = true);

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhen = (list) => {
                const ruleTrigger = ruleTriggers.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateNucalWhenBlock(
                                    ruleTrigger,
                                    item,
                                    meshbot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION,
                                ),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhenWithGroup = (list) => {
                const flattedRuleTriggersArray = createFlatRuleTriggersArray(ruleTriggers);
                const ruleTrigger = flattedRuleTriggersArray.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...generateNucalWhenBlock(
                                    ruleTrigger,
                                    item,
                                    meshbot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION,
                                ),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            ruleTriggers = actions.observerTrigger(ruleTriggers, updateRuleTrigger, groupId);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });

            if (shouldUpdateWhenBlock || shouldDeleteWhenBlock) {
                if (groupId) {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhenWithGroup, groupId);
                } else {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhen);
                }

                dispatch({ type: at.UPDATE_CONTROLLER_CAPABILITY, data: selectedRuleWhen });
            }
        };
    },

    updateTriggerWhen: (value, id, field, idGroup, name, idDev) => {
        return (dispatch, getState) => {
            const state = getState();
            const ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let newData = [];
            if (typeof value === 'boolean') {
                if (idGroup) {
                    const update = (blocks) => {
                        return blocks.map((elem) => {
                            if (elem.id === id) {
                                elem[field] = value;
                            }

                            return elem;
                        });
                    };

                    newData = actions.observerTrigger(ruleTriggers, update, idGroup);
                } else {
                    newData = actions.searchBlock(ruleTriggers, value, id, field);
                }
            } else {
                if (idGroup) {
                    const update = (list) => {
                        actions.updateDeviceTriggers(list, value, id, field, name, idDev);

                        return list;
                    };

                    newData = actions.observerTrigger(ruleTriggers, update, idGroup);
                } else {
                    newData = actions.updateDeviceTriggers(ruleTriggers, value, id, field, name, idDev);
                }
            }

            dispatch({ type: at.UPDATE_TRIGGER.success, data: newData });
        };
    },

    updateOrDeleteFunction: (functionData, elem) => {
        if (functionData.type === SELECT_FUNCTION && elem.function) {
            // clearing trigger block from function if 'not selected' is selected
            delete elem.function;
        }

        if (functionData.type !== SELECT_FUNCTION) {
            // adding a function to the trigger block and updating it
            elem.function = createTriggerFunctionObject(functionData);
        }
    },

    updateTriggerFunction: (functionData, idBlock, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            let newRuleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const newSelectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const updateRuleTrigger = (blocks) => {
                return blocks.map((elem) => {
                    if (elem.id === idBlock && functionData.type) {
                        actions.updateOrDeleteFunction(functionData, elem);
                        if (elem?.blocks?.[0]?.blockMeta?.ruleTrigger) {
                            actions.updateOrDeleteFunction(functionData, elem.blocks[0].blockMeta.ruleTrigger);
                        }
                    }

                    return elem;
                });
            };

            newRuleTriggers = actions.observerTrigger(newRuleTriggers, updateRuleTrigger, idGroup);
            newSelectedRule.when = actions.observerTrigger(newSelectedRule.when, updateRuleTrigger, idGroup);

            dispatch({
                type: at.UPDATE_TRIGGER_BLOCK.success,
                newRuleTriggers,
                newSelectedRule,
            });
        };
    },

    updateTriggerSectionFunction: (functionData) => {
        return (dispatch, getState) => {
            const state = getState();
            const newSelectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            if (functionData.type) {
                actions.updateOrDeleteFunction(functionData, newSelectedRule);
            }

            dispatch({ type: at.UPDATE_TRIGGER_SECTION_FUNCTION, newSelectedRule });
        };
    },

    updateExpression: (idTrigger, value, idGroup) => (dispatch, getState) => {
        const state = getState();
        const ruleTriggers = JSON.parse(JSON.stringify(state.meshBot.local.ruleTriggers));

        const update = (list, id, value) => {
            return list.map((item) => {
                if (item.id === id) {
                    Object.assign(item, value);

                    return item;
                }

                return item;
            });
        };

        if (idGroup) {
            ruleTriggers.map((item) => {
                if (item.id === idGroup) {
                    update(item.blocks, idTrigger, value);
                }

                return item;
            });
        } else {
            update(ruleTriggers, idTrigger, value);
        }

        dispatch({ type: at.UPDATE_EXPRESSION.success, data: ruleTriggers });
    },

    updateSelectedRuleNot: (value, id, field, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));
            if (typeof value === 'boolean') {
                if (idGroup) {
                    selectedRule.when = actions.searchBlockGroup(selectedRule.when, value, id, field, idGroup);
                } else {
                    selectedRule.when = actions.searchBlock(selectedRule.when, value, id, field);
                }
            } else if (field === 'optionType') {
                if (!id) {
                    selectedRule.optionType = value;
                }

                selectedRule.when = actions.searchBlock(selectedRule.when, value, id, field, idGroup);
            }

            dispatch({
                type: at.UPDATE_SELECTED_RULE_NOT.success,
                data: selectedRule,
            });
        };
    },
    /**
     * This function deletes specified fields from the selected rule in the store and dispatch an action to update the store with updated selected rule
     *
     * @param {Array.<string>} fieldNames - List of field names (as strings) that needs to be removed from the selected rule
     * @returns {Function} A thunk action creator returns a function to dispatch the UPDATE_FIELD_SELECT_RULE action with selectedRule (with deleted fields if any) as payload
     */
    deleteFieldsFromSelectedRule: (fieldNames) => {
        return (dispatch, getState) => {
            if (!isArray(fieldNames)) {
                return;
            }
            const selectedRule = JSON.parse(JSON.stringify(getState().meshBot.local.selectedRule));
            fieldNames.forEach((fieldName) => {
                if (!selectedRule.hasOwnProperty(fieldName)) {
                    return;
                }
                delete selectedRule[fieldName];
            });

            dispatch({
                type: at.UPDATE_FIELD_SELECT_RULE.success,
                data: selectedRule,
            });
        };
    },

    updateNameGroup: (id, value) => {
        return (dispatch, getState) => {
            const state = getState();
            let ruleTriggers = JSON.parse(JSON.stringify(state.meshBot.local.ruleTriggers));
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));

            const updateGroupName = (list) => {
                return list.map((item) => {
                    if (item.id === id) {
                        item.blockName = value;

                        return item;
                    } else if (item.type === at.GROUP) {
                        updateGroupName(item.blocks, id);
                    }

                    return item;
                });
            };

            selectedRule.when = updateGroupName(selectedRule.when);
            ruleTriggers = updateGroupName(ruleTriggers);

            dispatch({
                type: at.UPDATE_NAME_GROUP.success,
                data: { selected: selectedRule, trigger: ruleTriggers },
            });
        };
    },

    updateFieldInGroupException: (idGroup, actionId, value, field) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            // actions.updateOrDeleteFunction(functionData, elem)

            exceptions = actions.getExceptionGroup(exceptions, idGroup, actionId, value, field);
            selectedRule.exceptions = actions.getExceptionGroup(
                selectedRule.exceptions,
                idGroup,
                actionId,
                value,
                field,
            );

            dispatch({ type: at.UPDATE_FIELD_IN_GROUP_EXCEPTION.success, exceptions, selectedRule });
        };
    },

    updateDateTriggers: (value, idDevice, field, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const updateDateTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === idDevice) {
                        if (value === 'days' || value === 'weekdays' || value === 'endYear') {
                            item.selectedSpecificDate = 'isDate';
                        }

                        if (value === 'interval' || value === 'isOnce') {
                            item.selectedSpecificDate = '';
                        }

                        if (value === 'after' || value === 'before' || value === 'between') {
                            item.selectedSpecificDate = value;
                        }

                        if (value === meshbot.RISE) {
                            item.selectedSpecificDate = meshbot.SUN_STATE.AFTER_SUNRISE;
                        }

                        if (value === meshbot.SET) {
                            item.selectedSpecificDate = meshbot.SUN_STATE.AFTER_SUNSET;
                        }

                        if (value === TIME_OF_DAY) {
                            item.selectedTimeType = 'am';
                        }

                        if (
                            field &&
                            value !== meshbot.PLUS &&
                            value !== meshbot.MINUS &&
                            value !== meshbot.EMPTY_STRING
                        ) {
                            item[field] = value;
                        }

                        if (block) {
                            item.blocks = block;
                        }

                        return item;
                    }

                    return item;
                });
            };

            const updateDateSelect = (list) => {
                return list.map((item) => {
                    if (item.id === idDevice) {
                        if (block) {
                            item.blocks = block;
                        }

                        return item;
                    }

                    return item;
                });
            };

            const clearFieldsDate = (list) => {
                return list.map((item) => {
                    if (item.id === idDevice) {
                        item.selectedSpecificLabel = '';
                        item.selectedSpecificDate = '';
                        item.selectedTimeType = '';
                        item.selectDay = '';
                        item.selectMonth = '';
                        item.selectYear = '';
                        item.blocks = [];

                        return item;
                    }

                    return item;
                });
            };

            const clearFieldsDateSelect = (list) => {
                return list.map((item) => {
                    if (item.id === idDevice) {
                        item.blocks = [];

                        return item;
                    }

                    return item;
                });
            };

            if (field === 'selectedFieldDate' || value === 'rise' || value === 'set' || value === TIME_OF_DAY) {
                selectedRule.when = actions.observerTrigger(selectedRule.when, clearFieldsDateSelect, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, clearFieldsDate, idGroup);
            }

            if (idGroup) {
                const update = (list) => updateDateTrigger(list);
                const updateSelect = (list) => updateDateSelect(list);

                selectedRule.when = actions.observerTrigger(selectedRule.when, updateSelect, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, update, idGroup);
            } else {
                selectedRule.when = updateDateSelect(selectedRule.when);
                ruleTriggers = updateDateTrigger(ruleTriggers);
            }

            selectedRule.when = actions.observerTrigger(selectedRule.when, updateDateSelect, idGroup);
            ruleTriggers = actions.observerTrigger(ruleTriggers, updateDateTrigger, idGroup);

            dispatch({
                type: at.UPDATE_SELECT_DATE.success,
                data: { trigger: ruleTriggers, selected: selectedRule },
            });
        };
    },

    setExceptionFunctionInTrigger:
        ({ value, id, actionId, idGroup }) =>
        (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = getExceptionsWithNewFunction({ list: exceptions, actionId, id, idGroup, value });
            selectedRule.exceptions = getExceptionsWithNewFunction({
                list: selectedRule.exceptions,
                actionId,
                id,
                idGroup,
                value,
            });

            dispatch({ type: at.SET_EXCEPTION_FUNCTION_IN_TRIGGER.success, exceptions, selectedRule });
        },

    setExceptionDateNode: (value, id, actionId, field, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            if (
                field === meshbot.SELECTED_FIELD_DATE ||
                value === meshbot.RISE ||
                value === meshbot.SET ||
                value === TIME_OF_DAY
            ) {
                exceptions = actions.getCurrentException(
                    exceptions,
                    clearFieldsDateExceptions,
                    block,
                    id,
                    idGroup,
                    actionId,
                );
                selectedRule.exceptions = actions.getCurrentException(
                    selectedRule.exceptions,
                    clearFieldsDateSelectedRule,
                    block,
                    id,
                    idGroup,
                    actionId,
                );
            }

            exceptions = actions.getCurrentException(
                exceptions,
                updateDateExceptions,
                block,
                id,
                idGroup,
                actionId,
                field,
                value,
            );
            selectedRule.exceptions = actions.getCurrentException(
                selectedRule.exceptions,
                updateDateSelectedRule,
                block,
                id,
                idGroup,
                actionId,
                field,
                value,
            );

            dispatch({ type: at.SET_EXCEPTION_DATE_NODE.success, exceptions, selectedRule });
        };
    },

    setExceptionDateValue: (value, id, idGroup, tag, actionId) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = actions.getExceptionTriggers(exceptions, actions.dateValue, id, actionId, idGroup, value, tag);
            selectedRule.exceptions = actions.getExceptionTriggers(
                selectedRule.exceptions,
                actions.dateValue,
                id,
                actionId,
                idGroup,
                value,
                tag,
            );

            dispatch({ type: at.SET_EXCEPTION_DATE_VALUE.success, exceptions, selectedRule });
        };
    },

    setExceptionOptionType: (value, id, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = actions.observerTriggerException(exceptions, updateOptionTypeInException, value, id, idGroup);
            selectedRule.exceptions = actions.observerTriggerException(
                selectedRule.exceptions,
                updateOptionTypeInException,
                value,
                id,
                idGroup,
            );

            dispatch({ type: at.SET_EXCEPTION_OPTION_TYPE.success, exceptions, selectedRule });
        };
    },

    setExceptionFunction: ({ value, id }) => {
        return (dispatch, getState) => {
            const state = getState();
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            exceptions = updateFunctionInException(exceptions, id, value);
            selectedRule.exceptions = updateFunctionInException(selectedRule.exceptions, id, value);

            dispatch({ type: at.SET_EXCEPTION_FUNCTION.success, exceptions, selectedRule });
        };
    },

    updateMeshBotTrigger: (value, idMeshBot, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const updateSelectedRuleTrigger = (list) => updateMeshBotSelectedRuleTrigger(list, idMeshBot, block);
            const updateTrigger = (list) => updateMeshBotTrigger(list, idMeshBot, block, value);

            if (idGroup) {
                const updateRule = (list) => updateTrigger(list);
                const updateSelect = (list) => updateSelectedRuleTrigger(list);

                selectedRule.when = actions.observerTrigger(selectedRule.when, updateSelect, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, updateRule, idGroup);
            } else {
                selectedRule.when = actions.observerTrigger(selectedRule.when, updateSelectedRuleTrigger, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, updateTrigger, idGroup);
            }

            dispatch({
                type: at.UPDATE_SELECT_MESHBOT_TRIGGER.success,
                data: { trigger: ruleTriggers, selected: selectedRule },
            });
        };
    },

    updateMeshBotExceptionTrigger: (value, idMeshBot, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const updateSelectedRuleTrigger = (list) => updateMeshBotSelectedRuleTrigger(list, idMeshBot, block);
            const updateTrigger = (list) => updateMeshBotTrigger(list, idMeshBot, block, value);

            const newExceptions = actions.updateExceptions(exceptions, updateTrigger, idGroup);
            selectedRule.exceptions = actions.updateExceptions(
                selectedRule.exceptions,
                updateSelectedRuleTrigger,
                idGroup,
            );

            dispatch({
                type: at.UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER.success,
                data: { selected: selectedRule, exceptions: newExceptions },
            });
        };
    },

    updateHouseModeTrigger: (idHouseMode, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const updateHouseMode = (list) => updateHouseModeTrigger(list, idHouseMode, block);

            if (idGroup) {
                const update = (list) => updateHouseMode(list);

                selectedRule.when = actions.observerTrigger(selectedRule.when, update, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, update, idGroup);
            } else {
                selectedRule.when = actions.observerTrigger(selectedRule.when, updateHouseMode, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, updateHouseMode, idGroup);
            }

            dispatch({
                type: at.UPDATE_SELECT_MESHBOT_TRIGGER.success,
                data: { trigger: ruleTriggers, selected: selectedRule },
            });
        };
    },

    updateHouseModeExceptionTrigger: (idHouseMode, block, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const updateHouseMode = (list) => updateHouseModeTrigger(list, idHouseMode, block);

            const newExceptions = actions.updateExceptions(exceptions, updateHouseMode, idGroup);
            selectedRule.exceptions = actions.updateExceptions(selectedRule.exceptions, updateHouseMode, idGroup);

            dispatch({
                type: at.UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER.success,
                data: { selected: selectedRule, exceptions: newExceptions },
            });
        };
    },

    updateControllerExceptionTrigger: ({ ruleId, actionId, idGroup, data }) => {
        return (dispatch, getState) => {
            const state = getState();
            const {
                selectedControllerCapability,
                selectedControllerCapabilityValue,
                selectedControllerCapabilityComparator,
                selectedControllerCapabilityComparatorValue,
            } = data;
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const update = (list) => {
                return updateControllerTriggerHelper({
                    list,
                    ruleId,
                    idGroup,
                    selectedControllerCapability,
                    selectedControllerCapabilityValue,
                    selectedControllerCapabilityComparator,
                    selectedControllerCapabilityComparatorValue,
                });
            };
            const newExceptions = actions.updateExceptions(exceptions, update, idGroup);

            dispatch({
                type: at.UPDATE_EXCEPTION_TRIGGER.success,
                data: newExceptions,
            });

            const currentExceptionTrigger = getCurrentExceptionTrigger(newExceptions, ruleId, actionId, idGroup);

            const updateSelectedRuleTrigger = (list) =>
                updateMeshBotSelectedRuleTrigger(list, ruleId, [generateControllerWhenBlock(currentExceptionTrigger)]);

            const shouldUpdateSelect = isAllObjectValuesNonEmpty(currentExceptionTrigger);

            if (shouldUpdateSelect) {
                selectedRule.exceptions = actions.updateExceptions(
                    selectedRule.exceptions,
                    updateSelectedRuleTrigger,
                    idGroup,
                );
                dispatch({
                    type: at.UPDATE_CONTROLLER_EXCEPTION_TRIGGER.success,
                    data: { selected: selectedRule, exceptions: newExceptions },
                });
            }
        };
    },

    resetMeshBotTriggerBlocks: (idMeshBot, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);

            const updateSelectedRuleTrigger = (list) => resetMeshBotSelectedRuleTriggerBlocks(list, idMeshBot);
            const updateTrigger = (list) => resetMeshBotTriggerBlocks(list, idMeshBot);

            if (idGroup) {
                const updateRule = (list) => updateTrigger(list);
                const updateSelect = (list) => updateSelectedRuleTrigger(list);

                selectedRule.when = actions.observerTrigger(selectedRule.when, updateSelect, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, updateRule, idGroup);
            } else {
                selectedRule.when = actions.observerTrigger(selectedRule.when, updateSelectedRuleTrigger, idGroup);
                ruleTriggers = actions.observerTrigger(ruleTriggers, updateTrigger, idGroup);
            }

            dispatch({
                type: at.RESET_MESHBOT_TRIGGER_STATE_VALUE.success,
                data: { ruleTriggers, selectedRule },
            });
        };
    },

    resetMeshBotExceptionTriggerBlocks: (idMeshBot, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            const exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            const updateSelectedRuleTrigger = (list) => resetMeshBotSelectedRuleTriggerBlocks(list, idMeshBot);
            const updateTrigger = (list) => resetMeshBotTriggerBlocks(list, idMeshBot);

            const newExceptions = actions.updateExceptions(exceptions, updateTrigger, idGroup);
            selectedRule.exceptions = actions.updateExceptions(
                selectedRule.exceptions,
                updateSelectedRuleTrigger,
                idGroup,
            );

            dispatch({
                type: at.RESET_MESHBOT_EXCEPTION_TRIGGER_STATE_VALUE.success,
                data: { exceptions: newExceptions, selectedRule },
            });
        };
    },

    updateRuleTriggers: (idDevice, idBlock, value, idGroup, typeComp, data = []) => {
        return (dispatch, getState) => {
            const state = getState();
            const ruleTriggers = JSON.parse(JSON.stringify(state.meshBot.local.ruleTriggers));
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            if (idGroup) {
                const update = (blocks) => {
                    return actions.updateField(
                        idDevice,
                        idBlock,
                        value,
                        data,
                        blocks,
                        dispatch,
                        actions.updateRuleTriggers,
                        typeComp,
                    );
                };

                actions.observerTrigger(ruleTriggers, update, idGroup);
                selectedRule.when = actions.observerTrigger(selectedRule.when, update, idGroup);
                dispatch({
                    type: at.UPDATE_FIELD_BLOCK.success,
                    data: ruleTriggers,
                });
            } else {
                dispatch({
                    type: at.UPDATE_FIELD_BLOCK.success,
                    data: actions.updateField(
                        idDevice,
                        idBlock,
                        value,
                        data,
                        ruleTriggers,
                        dispatch,
                        actions.updateRuleTriggers,
                        typeComp,
                    ),
                });
            }
        };
    },

    updateSelectedRule: (idDevice, idBlock, value, idGroup, typeComp, data = []) => {
        return (dispatch, getState) => {
            const state = getState();
            const ruleBlocks = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));

            if (idGroup) {
                const update = (blocks) => {
                    return actions.updateField(
                        idDevice,
                        idBlock,
                        value,
                        data,
                        blocks,
                        dispatch,
                        actions.updateSelectedRule,
                        typeComp,
                    );
                };

                ruleBlocks.when = actions.observerTrigger(ruleBlocks.when, update, idGroup);

                dispatch({
                    type: at.UPDATE_FIELD_SELECT_RULE.success,
                    data: ruleBlocks,
                });
            } else {
                ruleBlocks.when = actions.updateField(
                    idDevice,
                    idBlock,
                    value,
                    data,
                    ruleBlocks.when,
                    dispatch,
                    actions.updateSelectedRule,
                    typeComp,
                );
                dispatch({
                    type: at.UPDATE_FIELD_SELECT_RULE.success,
                    data: ruleBlocks,
                });
            }
        };
    },

    updateField: (...args) => {
        const [idDevice, idBlock, value, data, dataState, dispatch, func, typeComp] = args;
        let newData = [];

        if (data.length > 0) {
            return data.map((item) => {
                if (item.blockId === idDevice) {
                    if (getMethodName(item.blockOptions) === meshbot.IS_ITEM_STATE && typeComp) {
                        const newField = { name: 'value', type: typeComp, value: value };
                        item.fields = [item.fields[0], newField];
                    } else {
                        item.fields.map((elem) => {
                            if (typeComp) {
                                if (elem.editable) {
                                    elem.value = value;
                                    elem.type = typeComp;
                                }
                            } else {
                                if (elem.editable) {
                                    elem.value = value;
                                } else if (elem.type === 'bool') {
                                    elem.value = value;
                                } else if (elem.type === 'token') {
                                    elem.value = value;
                                }
                            }

                            return elem;
                        });
                    }
                }

                return item;
            });
        } else {
            newData = dataState.map((item) => {
                if (item.id === idDevice) {
                    dispatch(func(idBlock, null, value, null, typeComp, item.blocks));
                }

                return item;
            });
        }

        return newData;
    },

    addBlockWhen: (block, idDevice, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            const updatedSelectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const updateBlockInWhen = (data, idDevice) => {
                return data.map((item) => {
                    if (item.id === idDevice) {
                        item.blocks = block;

                        return item;
                    }

                    return item;
                });
            };

            if (idGroup) {
                const update = (blocks) => {
                    return updateBlockInWhen(blocks, idDevice);
                };

                updatedSelectedRule.when = actions.observerTrigger(updatedSelectedRule.when, update, idGroup);
            } else {
                updateBlockInWhen(updatedSelectedRule.when, idDevice);
            }

            dispatch({ type: at.ADD_BLOCK_WHEN.success, data: updatedSelectedRule });
        };
    },

    changeActiveBlock: (block, id, idGroup) => {
        return (dispatch, getState) => {
            const state = getState();
            const ruleTriggers = JSON.parse(JSON.stringify(state.meshBot.local.ruleTriggers));
            let newData = null;

            const updateBlockInWhen = (data, id) => {
                return data.map((item) => {
                    if (item.id === id) {
                        item.firstBlock = block;

                        return item;
                    }

                    return item;
                });
            };

            if (idGroup) {
                const update = (list) => {
                    return updateBlockInWhen(list, id);
                };

                newData = actions.observerTrigger(ruleTriggers, update, idGroup);
            } else {
                newData = updateBlockInWhen(ruleTriggers, id);
            }

            dispatch({ type: at.CHANGE_ACTIVE_BLOCK.success, data: newData });
        };
    },

    updateTriggerBlock: (idTrig, block, idBlock, idGroup) => (dispatch, getState) => {
        const state = getState();
        let newRuleTriggers = JSON.parse(JSON.stringify(state.meshBot.local.ruleTriggers));
        const newSelectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));

        const findBlock = (data, id) => {
            return data.map((item) => {
                if (item.id === id) {
                    item.blocks = item.blocks.map((item) => {
                        if (item.blockId === idBlock) {
                            return JSON.parse(JSON.stringify(block));
                        }

                        return item;
                    });

                    return item;
                }

                return item;
            });
        };

        const findBlockInGroup = (data) => {
            return data.map((item) => {
                if (item.id === idGroup) {
                    item.blocks = findBlock(item.blocks, idTrig);

                    return item;
                }

                return item;
            });
        };

        if (idGroup) {
            newRuleTriggers = findBlockInGroup(newRuleTriggers);
            newSelectedRule.when = findBlockInGroup(newSelectedRule.when);

            dispatch({
                type: at.UPDATE_TRIGGER_BLOCK.success,
                newRuleTriggers,
                newSelectedRule,
            });
        } else {
            newSelectedRule.when = findBlock(newSelectedRule.when, idTrig);
            newRuleTriggers = findBlock(newRuleTriggers, idTrig);

            dispatch({
                type: at.UPDATE_TRIGGER_BLOCK.success,
                newRuleTriggers,
                newSelectedRule,
            });
        }
    },

    updateDateValue: (value, idDevice, idGroup, tag) => {
        return (dispatch, getState) => {
            const state = getState();
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            if (idGroup) {
                const update = (blocks) => {
                    return actions.dateValue(blocks, idDevice, value, tag);
                };

                ruleTriggers = actions.observerTrigger(ruleTriggers, update, idGroup);
                selectedRule.when = actions.observerTrigger(selectedRule.when, update, idGroup);
            } else {
                ruleTriggers = actions.dateValue(ruleTriggers, idDevice, value, tag);
                selectedRule.when = actions.dateValue(selectedRule.when, idDevice, value, tag);
            }

            dispatch({
                type: at.UPDATE_DATE_VALUE.success,
                data: { trigger: ruleTriggers, selected: selectedRule },
            });
        };
    },
    //TODO: Make refactoring this function
    dateValue: (...args) => {
        const [state, id, value, tag] = args;

        return state.map((item) => {
            if (item.id === id) {
                const [block] = item.blocks;
                if (getMethodArgs(block.blockOptions) === 'interval') {
                    block.fields[0].value = value;
                    block.fields[0].startTime = moment().unix();
                }

                if (getMethodName(block.blockOptions) === 'isDate') {
                    block.fields.map((elem) => {
                        if (elem.name === TIME_NODE && elem.type === HOURS_TIME_ARRAY && value?.hour?.toString()) {
                            const getCurrentHour =
                                value.hour.toString().length > 1 ? value.hour.toString() : '0' + value.hour.toString();
                            const getCurrentMinutes =
                                value.minute.toString().length > 1
                                    ? value.minute.toString()
                                    : '0' + value.minute.toString();

                            elem.value = [getCurrentHour + ':' + getCurrentMinutes];

                            return elem;
                        }

                        if (elem.name === TIME_NODE && elem.type === HOURS_TIME_ARRAY && Array.isArray(value)) {
                            elem.value = value;

                            return elem;
                        }

                        return elem;
                    });
                }

                if (getMethodName(block.blockOptions) === 'isOnce') {
                    block.fields.map((elem) => {
                        if (elem.name === 'day' && value.day) {
                            elem.value = value.day;

                            return elem;
                        }

                        if (elem.name === 'month' && value.month) {
                            elem.value = value.month;

                            return elem;
                        }

                        if (elem.name === 'year' && value.year) {
                            elem.value = value.year;

                            return elem;
                        }

                        if (elem.name === 'time' && value?.hour?.toString()) {
                            const getCurrentHour =
                                value.hour.toString().length > 1 ? value.hour.toString() : '0' + value.hour.toString();
                            const getCurrentMinutes =
                                value.minute.toString().length > 1
                                    ? value.minute.toString()
                                    : '0' + value.minute.toString();

                            elem.value = getCurrentHour + ':' + getCurrentMinutes;

                            return elem;
                        }

                        return elem;
                    });
                }

                if (
                    getMethodArgs(block.blockOptions) === 'daily' ||
                    getMethodArgs(block.blockOptions) === 'weekdays' ||
                    getMethodArgs(block.blockOptions) === 'days'
                ) {
                    block.fields?.map((elem) => {
                        if (value.time && (elem.type === '24_hours_time_array' || elem.type === 'hms_interval')) {
                            elem.value = value.time;

                            return elem;
                        }

                        if (elem.type === 'int_array') {
                            if (value.bool) {
                                if (!elem.value.includes(value.item)) {
                                    elem.value.push(value.item);
                                    elem.value.sort((a, b) => a - b);
                                }
                            } else {
                                elem.value = elem.value.filter((item) => item !== value.item);
                            }

                            return elem;
                        }
                    });
                }

                if (getMethodArgs(block.blockOptions) === 'isDateRange') {
                    const typeDateRange = Object.keys(value);
                    const { args } = block.blockOptions.method;

                    if (tag === 'add') {
                        const dateBlock = blockDateTemplate(typeDateRange[0], 'custom');

                        dateBlock.fields[0].value = value[typeDateRange[0]];
                        block.fields = [...block.fields, dateBlock.fields[0]];
                        // eslint-disable-next-line
                        args[typeDateRange[0]] = typeDateRange[0];
                    } else if (tag === 'clear') {
                        if (args.hasOwnProperty('startTime') && args.hasOwnProperty('endTime')) {
                            for (const k in args) {
                                switch (typeDateRange[0]) {
                                    case 'startDay':
                                        if (k !== 'startTime' && k.slice(0, 5) === 'start') {
                                            delete args[k];
                                        }
                                        break;
                                    case 'endDay':
                                        if (k !== 'endTime' && k.slice(0, 3) === 'end') {
                                            delete args[k];
                                        }
                                        break;
                                    case 'startMonth':
                                        if (k !== 'startTime' && k !== 'startDay' && k.slice(0, 5) === 'start') {
                                            delete args[k];
                                        }
                                        break;
                                    case 'endMonth':
                                        if (k !== 'endTime' && k !== 'endDay' && k.slice(0, 3) === 'end') {
                                            delete args[k];
                                        }
                                        break;
                                    case 'startYear':
                                        if (k !== 'startTime' && k !== 'startDay' && k.slice(0, 5) === 'start') {
                                            delete args[k];
                                        }
                                        break;
                                    case 'endYear':
                                        if (k !== 'endTime' && k !== 'endDay' && k.slice(0, 3) === 'end') {
                                            delete args[k];
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }

                            block.fields = block.fields.filter((item) => Object.values(args).includes(item.name));
                        } else {
                            for (const k in args) {
                                if (typeDateRange[0] === 'startMonth' && k !== 'startTime' && k !== 'startDay') {
                                    delete args[k];
                                } else if (typeDateRange[0] === 'endMonth' && k !== 'endTime' && k !== 'endDay') {
                                    delete args[k];
                                } else if (typeDateRange[0] === 'startDay' && k !== 'startTime' && k !== 'endTime') {
                                    delete args[k];
                                } else if (typeDateRange[0] === 'endDay' && k !== 'startTime' && k !== 'endTime') {
                                    delete args[k];
                                }
                            }

                            block.fields = block.fields.filter((item) => Object.values(args).includes(item.name));
                        }
                    } else {
                        block.fields?.map((item) => {
                            if (item.name === typeDateRange.find((elem) => elem === item.name)) {
                                item.value = value[item.name];

                                return item;
                            }

                            return item;
                        });
                    }
                }

                return item;
            }

            return item;
        });
    },

    updateDeviceGroupTrigger: ({
        ruleId,
        deviceGroupId,
        selectedCapability,
        selectedComparator,
        compareTo,
        comparingValue,
        idGroup,
        isCompareToVariable,
    }) => {
        return (dispatch, getState) => {
            const state = getState();
            let ruleTriggers = _.cloneDeep(state.meshBot.local.ruleTriggers);
            let selectedRuleWhen = _.cloneDeep(state.meshBot.local.selectedRule.when);
            const { serial, data } = state.ezlo;

            let shouldUpdateWhenBlock = false;
            let shouldDeleteWhenBlock = false;

            const updateRuleTrigger = (list) => {
                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        // Clearing ruleTrigger Fields on Device Group change
                        if (deviceGroupId && updatedItem.deviceGroupId && deviceGroupId !== updatedItem.deviceGroupId) {
                            updatedItem.deviceGroupId = deviceGroupId;
                            updatedItem.selectedCapability = {};
                            updatedItem.selectedComparator = {};

                            shouldDeleteWhenBlock = true;

                            return updatedItem;
                        }

                        if (
                            selectedCapability &&
                            updatedItem.selectedCapability &&
                            selectedCapability.name !== updatedItem.selectedCapability.name
                        ) {
                            updatedItem.selectedCapability = selectedCapability;
                            updatedItem.selectedComparator = {};
                            updatedItem.comparingValue = 0;

                            shouldDeleteWhenBlock = true;

                            return updatedItem;
                        }

                        deviceGroupId && (updatedItem.deviceGroupId = deviceGroupId);
                        selectedCapability && (updatedItem.selectedCapability = selectedCapability);
                        selectedComparator && (updatedItem.selectedComparator = selectedComparator);
                        compareTo && (updatedItem.compareTo = compareTo);
                        comparingValue !== undefined && (updatedItem.comparingValue = comparingValue);
                        isCompareToVariable !== undefined && (updatedItem.isCompareToVariable = isCompareToVariable);
                        updatedItem.itemGroupId = setItemGroupId(data, serial, updatedItem.selectedCapability?.name);

                        if (updatedItem.blocks === undefined) {
                            delete updatedItem.blocks;
                        }

                        if (isAllObjectValuesNonEmpty(updatedItem)) {
                            shouldUpdateWhenBlock = true;
                        } else {
                            shouldDeleteWhenBlock = true;
                        }

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhen = (list) => {
                const ruleTrigger = ruleTriggers.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...createDeviceGroupWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            const updateBlocksInWhenWithGroup = (list) => {
                const flattedRuleTriggersArray = createFlatRuleTriggersArray(ruleTriggers);
                const ruleTrigger = flattedRuleTriggersArray.find(({ id }) => id === ruleId);

                return list.map((item) => {
                    if (item.id === ruleId) {
                        const updatedItem = _.cloneDeep(item);

                        if (shouldDeleteWhenBlock) {
                            updatedItem.blocks = [];

                            return updatedItem;
                        }

                        updatedItem.blocks = [
                            {
                                ...updatedItem.blocks[0],
                                ...createDeviceGroupWhenBlock(ruleTrigger),
                            },
                        ];

                        return updatedItem;
                    }

                    return item;
                });
            };

            ruleTriggers = actions.observerTrigger(ruleTriggers, updateRuleTrigger, idGroup);

            dispatch({ type: at.UPDATE_TRIGGER.success, data: ruleTriggers });

            if (shouldUpdateWhenBlock || shouldDeleteWhenBlock) {
                if (idGroup) {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhenWithGroup, idGroup);
                } else {
                    selectedRuleWhen = actions.observerTrigger(selectedRuleWhen, updateBlocksInWhen);
                }
                dispatch({ type: at.UPDATE_DEVICE_GROUP_SELECTED_RULE_WHEN, data: selectedRuleWhen });
            }
        };
    },

    // MESHBOT ACTIONS

    addTriggerThen: (data, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));
            if (blockName === at.MESHBOT_LOCAL_ACTION_BLOCKS.then.name) {
                selectedRule.then = [...selectedRule.then, { id: data.id, blocks: [] }];
            } else if (blockName === at.MESHBOT_LOCAL_ACTION_BLOCKS.else.name) {
                selectedRule.else = [...selectedRule.else, { id: data.id, blocks: [] }];
            }
            dispatch({
                type: at.ADD_TRIGGER_ACTION.success,
                data: { selected: selectedRule, trigger: data },
                blockName,
            });
        };
    },

    deleteTriggerAction: (id, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);
            let groupActions = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            let exceptions = _.cloneDeep(state.meshBot.local.exceptions);

            exceptions = removingException(exceptions, id);
            selectedRule.exceptions = removingException(selectedRule.exceptions, id);
            groupActions = groupActions.filter((item) => item.id !== id);
            selectedRule[blockName] = selectedRule[blockName].filter((item) => item.id !== id);

            dispatch({
                type: at.DELETE_TRIGGER_ACTION.success,
                data: {
                    actions: groupActions,
                    selectedRule: selectedRule,
                    exceptions: exceptions,
                },
                blockName,
            });
        };
    },

    setClearAction: (id, list, templateName, type) => {
        return list.map((item) => {
            if (item.id === id) {
                if (type === LIST) {
                    const { isOpenedExceptionBlock } = item;
                    item = getNodeBlockTemplateByKey(templateName);
                    item.selectedFieldTrigger = templateName;
                    item.id = id;
                    item.isOpenedExceptionBlock = isOpenedExceptionBlock;
                } else {
                    item.blocks = [generateActionBlock(item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT])];
                }
            }

            return item;
        });
    },

    clearActionList: (value, id, templateName, blockName) => (dispatch, getState) => {
        const state = getState();
        let actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);

        actionsList = actions.setClearAction(id, actionsList, templateName, LIST);
        dispatch({ type: at.CLEAR_ACTION_LIST, actionsList, blockName });
    },

    clearActionSelected: (value, id, templateName, blockName) => (dispatch, getState) => {
        const state = getState();
        let selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

        selectedRule = actions.setClearAction(id, selectedRule, templateName, SELECTED);
        dispatch({ type: at.CLEAR_ACTION_SELECTED, selectedRule, blockName });
    },

    updateActionNode: (value, id, blockName) => (dispatch, getState) => {
        const state = getState();
        let actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
        let selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

        const setNode = (list, type) => {
            return list.map((item) => {
                if (item.id === id) {
                    if (type === 'select') {
                        item.blocks = [generateActionBlock(item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT])];
                    } else {
                        const { isOpenedExceptionBlock } = item;
                        item = getNodeBlockTemplateByKey(value);
                        item.selectedFieldTrigger = value;
                        item.id = id;
                        item.isOpenedExceptionBlock = isOpenedExceptionBlock;
                    }

                    return item;
                }

                return item;
            });
        };

        actionsList = setNode(actionsList, LIST);
        selectedRule = setNode(selectedRule, 'select');

        dispatch({
            type: at.UPDATE_ACTION_NODE.success,
            actionsList,
            selectedRule,
            blockName,
        });
    },

    updatePaasAction:
        (id, paasField, fields, fieldToValidate, blockName = 'then') =>
        (dispatch, getState) => {
            const state = getState();
            const actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

            const generateStatePaasStructure = (actions) => {
                return actions.map((action) => {
                    if (action.id === id) {
                        const actionData = {
                            ...action,
                            blocks: [generateActionBlock(action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT], paasField)],
                            fields,
                            fieldToValidate,
                        };
                        const delay = action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay;

                        if (action.blocks && action.blocks?.length > 0 && action.blocks[0]?.saveResult) {
                            actionData.blocks[0].saveResult = action.blocks[0].saveResult;
                        }

                        const control = getControl(action);

                        if (control) {
                            actionData.blocks[0] = { ...actionData.blocks[0], control };
                        }

                        if (delay) {
                            actionData.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                                ...actionData.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                                delay,
                            };
                        }

                        return actionData;
                    }

                    return action;
                });
            };

            const updatedActions = generateStatePaasStructure(actionsList);
            const updatedSelectedRule = generateStatePaasStructure(selectedRule);

            dispatch({
                type: at.UPDATE_ACTION_NODE.success,
                actionsList: updatedActions,
                selectedRule: updatedSelectedRule,
                blockName,
            });
        },

    updateHouseModeAction:
        (id, houseModeField, blockName = 'then') =>
        (dispatch, getState) => {
            const state = getState();
            const actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

            const generateStateHouseModeStructure = (actions) => {
                return actions.map((action) => {
                    if (action.id === id) {
                        const actionData = {
                            ...action,
                            blocks: [generateActionBlock(action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT], houseModeField)],
                        };

                        const control = getControl(action);
                        const delay = action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay;

                        if (control) {
                            actionData.blocks[0] = { ...actionData.blocks[0], control };
                        }

                        if (delay) {
                            actionData.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                                ...actionData.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                                delay,
                            };
                        }

                        return actionData;
                    }

                    return action;
                });
            };

            const updatedActions = generateStateHouseModeStructure(actionsList);
            const updatedSelectedRule = generateStateHouseModeStructure(selectedRule);

            dispatch({
                type: at.UPDATE_ACTION_NODE.success,
                actionsList: updatedActions,
                selectedRule: updatedSelectedRule,
                blockName,
            });
        },

    // TODO: refactoring needed
    // TODO: rename parameters
    onChangeActionField: (value, id, field, blockName, nameField) => (dispatch, getState) => {
        const state = getState();
        let actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
        let selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

        // TODO:
        //  1. move as a separate function
        //  2. split as 2 different functions depends on type
        //  3. convert to pure function
        //  4. rename
        const setFunction = (list, type) => {
            return list.map((item) => {
                if (item.id === id) {
                    if (type === LIST && field) {
                        item[field] = value;
                    }

                    if (field === SELECTED_REQUEST) {
                        const blocks = item?.blocks?.find((block) => block?.fields);

                        const template = blocks
                            ? {
                                  ...blockActionTemplate(URL, URL, URL, '', value, []),
                                  fields: updateHttpFields(blocks, value),
                              }
                            : blockActionTemplate(URL, URL, URL, '', value, []);

                        const blocksData = [
                            generateActionBlock(item.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT], template),
                        ];

                        const control = getControl(item);

                        if (control) {
                            item.blocks = blocksData;
                            item.blocks[0] = { ...item.blocks[0], control };
                        } else {
                            item.blocks = blocksData;
                        }
                    } else {
                        // TODO: why "item.blocks[0] > 0" ?
                        // if (item.blocks[0] > 0 && field !== 'selectedCode' && field !== 'selectedName') {
                        if (
                            item.blocks[0] &&
                            field !== 'selectedCode' &&
                            field !== 'selectedName' &&
                            item.blocks[0]?.fields
                        ) {
                            // TODO: refactoring
                            item.blocks[0].fields = item.blocks[0].fields.map((elem) => {
                                if (elem.name === nameField && field === 'selectedCredentialUser') {
                                    elem.value.user = value;
                                } else if (elem.name === nameField && field === 'selectedCredentialPassword') {
                                    elem.value.password = value;
                                } else if (elem.name === nameField) {
                                    elem.value = value;
                                }

                                return elem;
                            });
                        }
                    }

                    return item;
                }

                return item;
            });
        };

        // TODO: what does LIST mean?
        actionsList = setFunction(actionsList, LIST);
        // TODO: what does 'select' mean?
        selectedRule = setFunction(selectedRule, 'select');

        dispatch({
            type: at.SET_ACTION_FIELD.success,
            actionsList,
            selectedRule,
            blockName,
        });
    },

    updateActionSaveResult:
        ({ id, blockName, method, value }, isSaveResult) =>
        (dispatch, getState) => {
            const state = getState();
            let actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            let selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);

            const updateSaveResult = (list) => {
                return list.map((item) => {
                    if (item.id === id) {
                        const [block] = item.blocks;

                        if (isSaveResult) {
                            block.saveResult = {
                                args: {
                                    ...value,
                                },
                                method,
                            };
                        }

                        if (!isSaveResult) {
                            delete block.saveResult;
                        }
                    }

                    return item;
                });
            };

            actionsList = updateSaveResult(actionsList);
            selectedRule = updateSaveResult(selectedRule);

            dispatch({ type: at.SET_ACTION_FIELD.success, actionsList, selectedRule, blockName });
        },

    updateTriggerThen: (value, id, field, name, idDev, blockName, meshBotAction) => {
        return (dispatch, getState) => {
            const state = getState();
            const groupActions = JSON.parse(
                JSON.stringify(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]),
            );
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));

            const newSelectedRulesBlocks = selectedRule[blockName].map((item) => {
                if (item.id === id && field === at.SELECTED_FIELD_TRIGGER) {
                    return {
                        id: item.id,
                        blocks: [],
                    };
                }

                return item;
            });
            selectedRule[blockName] = newSelectedRulesBlocks;

            const newData = groupActions.map((item) => {
                if (item.id === id && value[0]) {
                    if (item[field] !== value) {
                        item.blocks = [];
                    }

                    if (meshBotAction) {
                        item.meshBotAction = meshBotAction;
                    }

                    item[field] = value;
                    item.firstBlock = value[0]._tempId ?? value[0].firstBlock;
                    item.name = name;
                    item.idDev = idDev;

                    return item;
                } else if (item.id === id && value._tempId) {
                    if (item[field] !== value) {
                        item.blocks = [];
                    }

                    if (meshBotAction) {
                        item.meshBotAction = meshBotAction;
                    }

                    item[field] = value;
                    item.firstBlock = value._tempId;
                    item.name = name;
                    item.idDev = idDev;

                    return item;
                }

                return item;
            });

            dispatch({
                type: at.UPDATE_TRIGGER_ACTION.success,
                data: newData,
                blockName,
                selectedRule,
            });
        };
    },

    updateMeshBotAction: (value, id, blockName, blockId) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const meshBotActionBlock = [
                blockActionTemplate(
                    value.name,
                    null,
                    value.meshBotAction,
                    value._id,
                    null,
                    null,
                    value.isEnabled,
                    blockId,
                ),
            ];

            selectedRule[blockName] = selectedRule[blockName].map((item) => {
                if (item.id === id) {
                    item.blocks = [
                        generateActionBlock(
                            item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            meshBotActionBlock[INDEX_SELECTED_BLOCKS_ELEMENT],
                        ),
                    ];
                    const control = getControl(item);
                    if (control) {
                        item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                            ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            control,
                        };
                    }
                }

                return item;
            });

            dispatch(actions.updateTriggerThen(meshBotActionBlock, id, at.BLOCKS, value.name, value._id, blockName));
            dispatch({
                type: at.UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS.success,
                data: selectedRule,
            });
        };
    },

    getDeviceBlocksAction: (serial, idDevice, params, blockName) => (dispatch, getState) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.items.list',
                { ...params },
                (data) => {
                    const state = getState();

                    const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

                    const allDevices = state.ezlo.data[serial].devices || [];
                    const deviceInfo = allDevices.filter((item) => item._id === params.deviceIds[0]);
                    const { INT, FLOAT, BOOL, TOKEN, RGB, TEMPERATURE, DICTIONARY } = BLOCK_FIELD_TYPES;
                    const ALLOWED_BLOCK_TYPES = [INT, FLOAT, BOOL, TOKEN, RGB, TEMPERATURE, DICTIONARY];
                    const actionItems = data.items?.filter(({ valueType, hasSetter }) => {
                        return ALLOWED_BLOCK_TYPES.includes(valueType) && hasSetter;
                    });
                    let items = actionItems?.map((item) => {
                        if (item && item.enum) {
                            return blockActionTemplate(
                                item.name,
                                item.valueType,
                                meshbot.ITEM,
                                item._id,
                                item.value,
                                item.enum,
                            );
                        } else {
                            if (params.type && params.type === MESHBOT_NODE_TYPES.VIDOO) {
                                return blockActionTemplate(
                                    item.name,
                                    item.valueType,
                                    meshbot.ITEM,
                                    item._id,
                                    params.value,
                                    [],
                                );
                            }

                            return blockActionTemplate(
                                item.name,
                                item.valueType,
                                meshbot.ITEM,
                                item._id,
                                item.value,
                                [],
                            );
                        }
                    });
                    if (deviceInfo && deviceInfo[0] && deviceInfo[0].armed != undefined) {
                        const armedBlock = blockActionTemplate(
                            deviceInfo[0].name,
                            deviceInfo[0].type,
                            'device',
                            deviceInfo[0]._id,
                            deviceInfo[0].armed,
                            [],
                        );
                        items = [...items, ...[armedBlock]];
                    }

                    selectedRule[blockName] = selectedRule[blockName].map((item) => {
                        if (item.id === idDevice) {
                            const control = getControl(item);
                            item.blocks = [
                                generateActionBlock(
                                    item.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT],
                                    items?.[INDEX_SELECTED_BLOCKS_ELEMENT],
                                ),
                            ];

                            if (control) {
                                item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                                    ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                                    control,
                                };
                            }
                        }

                        return item;
                    });

                    dispatch(
                        actions.updateTriggerThen(
                            items,
                            idDevice,
                            'blocks',
                            deviceInfo[0].name,
                            deviceInfo[0]._id,
                            blockName,
                        ),
                    );
                    dispatch({
                        type: at.GET_DEVICE_BLOCKS_ACTION.success,
                        data: selectedRule,
                    });
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, {
                        type: at.GET_DEVICE_BLOCKS_ACTION.rejected,
                        serial,
                        params,
                        idDevice,
                        blockName,
                    });
                    reject(error);
                    dispatch({ type: at.GET_DEVICE_BLOCKS_ACTION.rejected });
                },
            );
        });
    },

    getScriptLuaData: (serial, idDevice, params, blockName) => (dispatch, getState) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.scripts.get',
                { ...params },
                (data) => {
                    const state = getState();
                    const groupActions = JSON.parse(
                        JSON.stringify(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]),
                    );

                    if (data && groupActions) {
                        const dataItem = groupActions.map((item) => {
                            if (item.id === idDevice && item.blocks && item.blocks[0] && item.blocks[0].fields) {
                                item.blocks[0].fields.push(data);

                                return item;
                            }

                            return item;
                        });
                        dispatch(
                            actions.updateTriggerThen(dataItem, idDevice, 'blocks', 'runCustomScript', null, blockName),
                        );
                    }
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, params, idDevice, blockName });
                    reject(error);
                },
            );
        });
    },

    updateActionDelay: (id, params, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(
                state.meshBot.local.selectedRule[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].name],
            );
            const newSelectedRule = setValueDelay(selectedRule, id, params);

            dispatch({ type: at.MANAGE_ACTION_DELAY, newSelectedRule, blockName });
        };
    },

    removeActionDelay: (id, params, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = _.cloneDeep(
                state.meshBot.local.selectedRule[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].name],
            );
            const newSelectedRule = removeDelay(selectedRule, id);

            dispatch({ type: at.MANAGE_ACTION_DELAY, newSelectedRule, blockName });
        };
    },

    createNewDataLua: (data, idDevice, state, blockName, typeAction) => {
        const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);
        const actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
        const items = blockActionTemplate(SCRIPT, SCRIPT, SCRIPT, '', data._id, []);

        return {
            actionsList: setFunctionScript(actionsList, idDevice, data, items, typeAction, LIST),
            selectedRule: setFunctionScript(selectedRule, idDevice, data, items, typeAction, SELECTED),
        };
    },

    getLuaScript: (serial, params, idDevice, blockName) => async (dispatch, getState) => {
        dispatch({ type: at.GET_LUA_SCRIPT.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.get',
            { ...params },
            (data) => {
                if (data && data._id && idDevice) {
                    const state = getState();
                    const result = actions.createNewDataLua(data, idDevice, state, blockName, GET);
                    const { actionsList, selectedRule } = result;

                    dispatch({
                        type: at.GET_LUA_SCRIPT.success,
                        actionsList,
                        selectedRule,
                        blockName,
                    });
                } else if (data && data._id && !idDevice) {
                    dispatch({ type: at.GET_LUA_SCRIPT.success, payload: data });
                }
            },
            (error) => {
                bugsnagNotify(error, {
                    type: at.GET_LUA_SCRIPT.rejected,
                    serial,
                    params,
                    idDevice,
                    blockName,
                });
                dispatch({ type: at.GET_LUA_SCRIPT.rejected });
            },
        );
    },

    getLuaScripts: (serial) => async (dispatch) => {
        if (!serial) {
            return;
        }
        dispatch({ type: at.GET_LUA_SCRIPTS.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.list',
            {},
            (data) => {
                if (Array.isArray(data)) {
                    dispatch({ type: at.GET_LUA_SCRIPTS.success, payload: data });
                }
            },
            (error) => {
                bugsnagNotify(error, { type: at.GET_LUA_SCRIPT.rejected, serial });
                dispatch({ type: at.GET_LUA_SCRIPTS.rejected });
            },
        );
    },

    addLuaScript: (serial, params, idDevice, blockName) => async (dispatch, getState) => {
        dispatch({ type: at.ADD_LUA_SCRIPT.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.add',
            { ...params },
            (data) => {
                const notification = ADD_SUCCESS;

                if (data && data._id && idDevice) {
                    const state = getState();
                    const result = actions.createNewDataLua(data, idDevice, state, blockName, 'add');
                    const { actionsList, selectedRule } = result;

                    dispatch(actions.getLuaScripts(serial));
                    dispatch({
                        type: at.ADD_LUA_SCRIPT.success,
                        selectedRule,
                        actionsList,
                        blockName,
                        notification,
                    });
                } else if (data && data._id && !idDevice) {
                    dispatch({ type: at.ADD_LUA_SCRIPT.success, notification });
                }
            },
            (error) => {
                const status = REJECTED;
                const errorBody = error.message;

                bugsnagNotify(error, {
                    type: at.ADD_LUA_SCRIPT.rejected,
                    serial,
                    params,
                    idDevice,
                    blockName,
                });
                dispatch({ type: at.ADD_LUA_SCRIPT.rejected, status, errorBody });
            },
        );
    },

    removeLuaScripts: (serial, id) => async (dispatch) => {
        dispatch({ type: at.REMOVE_LUA_SCRIPT.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.delete',
            { _id: id },
            (data) => {
                if (data) {
                    dispatch(actions.getLuaScripts(serial));
                    dispatch({
                        type: at.REMOVE_LUA_SCRIPT.success,
                        payload: REMOVE_SUCCESS,
                    });
                }
            },
            (error) => {
                const status = REJECTED;
                const errorBody = error.message;

                bugsnagNotify(error, {
                    type: at.REMOVE_LUA_SCRIPT.rejected,
                    serial,
                    id,
                });
                dispatch({ type: at.REMOVE_LUA_SCRIPT.rejected, status, errorBody });
            },
        );
    },

    setLuaScript: (serial, params) => async (dispatch) => {
        dispatch({ type: at.SET_LUA_SCRIPT.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.set',
            { ...params },
            () => {
                dispatch({ type: at.SET_LUA_SCRIPT.success, payload: SET_SUCCESS });
            },
            (error) => {
                const status = REJECTED;
                const errorBody = error.message;

                bugsnagNotify(error, {
                    type: at.SET_LUA_SCRIPT.rejected,
                    serial,
                    params,
                });
                dispatch({ type: at.SET_LUA_SCRIPT.rejected, status, errorBody });
            },
        );
    },

    runLuaScript: (serial, params) => async (dispatch) => {
        dispatch({ type: at.RUN_LUA_SCRIPT.pending });

        await wsm.send(
            serial,
            'hub.scenes.scripts.run',
            { ...params },
            () => {
                dispatch({ type: at.RUN_LUA_SCRIPT.success, payload: START_UPDATE });
            },
            (error) => {
                const status = REJECTED;
                const errorBody = error.message;

                dispatch({ type: at.RUN_LUA_SCRIPT.rejected, status, errorBody });
            },
        );
    },

    updateActionDragAndDrop: (items, type) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));
            let groupActions = JSON.parse(JSON.stringify(state.meshBot.local.groupActions));
            let groupElseActions = JSON.parse(JSON.stringify(state.meshBot.local.groupElseActions));
            if (selectedRule && items) {
                selectedRule[type] = items.map((item) => {
                    const { id, firstBlock, meshBotId, fieldToValidate, fields } = item;
                    let { blocks } = item;
                    if (blocks && blocks.length > 1) {
                        if (!firstBlock) {
                            blocks = blocks.find((block) => block.fields[0].value === meshBotId);
                        }

                        if (firstBlock) {
                            blocks = blocks.find((block) => block._tempId === firstBlock);
                        }
                    }
                    blocks = Array.isArray(blocks) ? blocks : [blocks];

                    return {
                        id,
                        blocks,
                        fieldToValidate,
                        fields,
                    };
                });
                if (type === at.MESHBOT_LOCAL_ACTION_BLOCKS.then.name) {
                    groupActions = items;
                }

                if (type === at.MESHBOT_LOCAL_ACTION_BLOCKS.else.name) {
                    groupElseActions = items;
                }
                dispatch({
                    type: at.UPDATE_ACTION_DRAG_AND_DROP.success,
                    data: selectedRule,
                    groupActions,
                    groupElseActions,
                });
            }
        };
    },

    getHttpAction: (idDevice, params, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const selectedRule = JSON.parse(JSON.stringify(state.meshBot.local.selectedRule));

            if (selectedRule && params) {
                const items = blockActionTemplate('url', 'url', 'url', '', params, []);
                if (blockName === at.MESHBOT_LOCAL_ACTION_BLOCKS.then.name) {
                    selectedRule.then.map((item) => {
                        if (item.id === idDevice) {
                            item.blocks = [items];

                            return item;
                        }

                        return item;
                    });
                } else if (blockName === at.MESHBOT_LOCAL_ACTION_BLOCKS.else.name) {
                    selectedRule.else.map((item) => {
                        if (item.id === idDevice) {
                            item.blocks = [items];

                            return item;
                        }

                        return item;
                    });
                }

                dispatch(actions.updateTriggerThen(items, idDevice, 'blocks', 'sendHttpRequest', null, blockName));
                dispatch({
                    type: at.GET_DEVICE_BLOCKS_ACTION.success,
                    data: selectedRule,
                });
            }
        };
    },

    getNotificationAction: (id, params, blockName, isHtmlValid, htmlError) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            const items = [
                blockActionTemplate(MESHBOT_NOTIFICATION, MESHBOT_NOTIFICATION, MESHBOT_NOTIFICATION, '', params, []),
            ];

            selectedRule[blockName] = selectedRule[blockName].map((item) => {
                if (item.id === id) {
                    const control = getControl(item);
                    const delay = item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay;

                    item.blocks = [
                        generateActionBlock(
                            item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            items[INDEX_SELECTED_BLOCKS_ELEMENT],
                        ),
                    ];

                    if (control) {
                        item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                            ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            control,
                        };
                    }

                    if (delay) {
                        item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                            ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            delay,
                        };
                    }

                    if (params?.body_html?.length && typeof isHtmlValid === BOOLEAN) {
                        item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                            ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            isHtmlValid,
                            htmlError,
                        };
                    }
                }

                return item;
            });

            dispatch(actions.updateTriggerThen(items, id, MESHBOT_BLOCKS, MESHBOT_NOTIFICATION, null, blockName));
            dispatch({
                type: at.UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS.success,
                data: selectedRule,
            });
        };
    },

    addBlockAction: (block, idDevice, blockName) => {
        return (dispatch, getState) => {
            const state = getState();

            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule);

            selectedRule[blockName] = selectedRule[blockName].map((item) => {
                if (item.id === idDevice) {
                    const control = getControl(item);
                    item.blocks = [
                        generateActionBlock(
                            item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            block[INDEX_SELECTED_BLOCKS_ELEMENT],
                        ),
                    ];
                    if (control) {
                        item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = {
                            ...item.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
                            control,
                        };
                    }
                }

                return item;
            });

            dispatch({ type: at.ADD_BLOCK_THEN.success, data: selectedRule });
        };
    },

    setActiveBlockAction: (data, id, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const groupActions = JSON.parse(
                JSON.stringify(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]),
            );
            const newData = groupActions.map((item) => {
                if (item.id === id) {
                    item.firstBlock = data;

                    return item;
                }

                return item;
            });

            dispatch({
                type: at.CHANGE_ACTIVE_BLOCK_ACTION.success,
                data: newData,
                blockName,
            });
        };
    },

    // todo: check every 'updateActionTriggers' call
    setGroupActionsValue: (idDevice, idBlock, value, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const groupActions = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);

            const updatedGroupActions = groupActions.map((action) => {
                if (action.id === idDevice) {
                    const updatedBlocks = action.blocks.map((item) => {
                        return actions.updateFieldBlockAction(item, item._tempId, idBlock, item.fields, value);
                    });

                    return { ...action, blocks: updatedBlocks };
                }

                return action;
            });

            dispatch({
                type: at.UPDATE_FIELD_BLOCK_ACTION.success,
                data: updatedGroupActions,
                blockName,
            });
        };
    },

    setSelectedValueAction: (actionId, idBlock, value, blockName) => {
        return (dispatch, getState) => {
            const state = getState();
            const ruleBlocks = _.cloneDeep(state.meshBot.local.selectedRule);

            ruleBlocks[blockName] = ruleBlocks[blockName].map((action) => {
                return actions.updateFieldBlockAction(action, action.id, actionId, action?.blocks[0]?.fields, value);
            });

            dispatch({
                type: at.UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS.success,
                data: ruleBlocks,
            });
        };
    },

    updateFieldBlockAction: (action, itemId, id, itemFields = [], value) => {
        if (itemId === id) {
            const name =
                action?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockOptions?.method?.name ||
                action?.blockOptions?.method?.name;

            if (name === TOGGLE_VALUE_METHOD_NAME && typeof value === BOOLEAN) {
                return getTemplateForSwitch(action, value, itemId);
            }

            if (value === TOGGLE_VALUE_METHOD_NAME && typeof value === STRING) {
                return getTemplateForToggleValue(action, itemFields, itemId);
            } else {
                const currentAction = itemFields.find((elem) => TYPES.includes(elem.type) || elem.editable);

                if (currentAction) {
                    currentAction.value = value;
                }

                return action;
            }
        }

        return action;
    },

    getBlockDataList: (serial) => (dispatch) => {
        if (serial) {
            new Promise((resolve) => {
                wsm.send(
                    serial,
                    'hub.scenes.block.data.list',
                    {
                        ...meshbot.SCENES_BLOCK_DATA_LIST_PARAMS,
                    },
                    (data) => {
                        if (data) {
                            dispatch({ type: controllerTypes.GET_BLOCK_DATA_LIST.success, data, serial });
                        }
                        resolve(data);
                    },
                    (error) => {
                        bugsnagNotifyWrapper(error, { type: controllerTypes.GET_BLOCK_DATA_LIST.rejected });
                    },
                );
            });
        }
    },

    updateSelectedWhenBlockWithSubscriptions: () => (dispatch, getState) => {
        const state = getState();

        const {
            subscriptions: { payload, addedItems },
            meshBot: {
                local: { selectedRule },
            },
        } = state;

        const isAllItemAdded = payload.every((item) => item.mappings.every((map) => map.isVariableAdded));
        const copySelectedRule = _.cloneDeep(selectedRule);
        if (isAllItemAdded) {
            copySelectedRule.when = updateSelectedWhenBlock(copySelectedRule.when, addedItems);

            dispatch({
                type: at.UPDATE_FIELD_SELECT_RULE.success,
                data: copySelectedRule,
            });
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.COMPLETED));
        }
    },

    updatePayloadAndAddItem: (addedItem, t) => (dispatch, getState) => {
        const state = getState();
        const {
            ezlo: { serial, data },
            subscriptions: { payload },
        } = state;

        const devicesList = data[serial].devices;
        const foundDevice = devicesList.find(({ _id }) => _id === addedItem.deviceId);

        const parsedData = parseDataForUpdatePayload(addedItem, foundDevice);

        const copyPayloadArray = _.cloneDeep(payload);

        const updatedPayload = copyPayloadArray.map((item) => {
            if (item.abstractUUID === foundDevice.name) {
                for (const mapping of item.mappings) {
                    if (mapping.capability === extractNameOfVariable(addedItem)) {
                        mapping.isVariableAdded = true;
                    }
                }
            }

            return item;
        });

        dispatch(SubscriptionActions.updateValues(parsedData, updatedPayload));
        dispatch(actions.updateSelectedWhenBlockWithSubscriptions(t));
    },

    processLocalMeshBotSubscriptions: (t) => (dispatch, getState) => {
        const {
            ezlo,
            meshBot: {
                local: { selectedRule },
            },
        } = getState();
        const collectedSubscriptions = getDataForSubscriptions(selectedRule.when, ezlo);
        const { cloudSubscriptions, hubSubscriptions } = collectedSubscriptions;

        if (cloudSubscriptions.length || hubSubscriptions.length) {
            dispatch(actions.createSubscriptions(collectedSubscriptions, t));
        } else {
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.NOT_USED));
        }
    },

    createSubscriptions: (collectedSubscriptions) => async (dispatch) => {
        const {
            collectDataAboutSubscriptionsInKvs,
            createSubscriptionsOnCloud,
            formPayloadAndCreateOrUpdateSubscriptions,
            setCapabilitiesValueFromCloud,
            handleSubscriptionErrors,
            setSubscriptionFlagStatus,
        } = SubscriptionActions;

        try {
            const { hubSubscriptions, cloudSubscriptions, abstractsUuids } = collectedSubscriptions;

            dispatch(setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.IN_PROGRESS));

            if (cloudSubscriptions.length) {
                await collectDataAboutSubscriptionsInKvs(cloudSubscriptions);
                dispatch(actions.setItemIdsInWhenNucalBlocks(cloudSubscriptions));
                await createSubscriptionsOnCloud(cloudSubscriptions);
            }

            if (hubSubscriptions.length) {
                await setCapabilitiesValueFromCloud(hubSubscriptions, abstractsUuids);
                await dispatch(formPayloadAndCreateOrUpdateSubscriptions(hubSubscriptions));
            } else {
                dispatch(setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.NOT_USED));
            }
        } catch (error) {
            dispatch(handleSubscriptionErrors(error, { type: CREATING_SUBSCRIPTION_ERROR }));
        }
    },

    setItemIdsInWhenNucalBlocks: (cloudSubscriptions) => (dispatch, getState) => {
        const {
            meshBot: { local },
        } = getState();
        const selectedRule = _.cloneDeep(local.selectedRule);
        selectedRule.when = scatterItemPropertyInWhenNucalBlocks(selectedRule.when, cloudSubscriptions);

        dispatch({ type: at.UPDATE_FIELD_SELECT_RULE.success, data: selectedRule });
    },

    processLocalMeshBotItemGroups: () => (dispatch, getState) => {
        const {
            meshBot: {
                local: { selectedRule },
            },
        } = getState();

        const collectedCapabilities = getMissingItemGroupsCapabilitiesList(selectedRule.when);

        if (Object.keys(collectedCapabilities).length) {
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.IN_PROGRESS));
            dispatch(ItemGroupsActions.setCapabilitiesList(collectedCapabilities));
            dispatch(ItemGroupsActions.createMissingItemGroups());
        } else {
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.NOT_USED));
        }
    },

    processLocalMeshBot: () => (dispatch) => {
        dispatch(actions.processLocalMeshBotItemGroups());
        dispatch(actions.processLocalMeshBotSubscriptions());
    },

    saveLocalMeshBot: () => (dispatch) => {
        dispatch(actions.processLocalMeshBot());
    },

    unsubscribeMeshBotUpdates: (serial, meshBotId) => (dispatch) => {
        meshBotId
            ? dispatch(actions.unsubscribeMeshbotUpdate([serial]))
            : dispatch(actions.unsubscribeEzloCreateScenes([serial]));
    },

    startMeshBotUpdateTimer: (serial, meshBotId, meshBotReceived) => (dispatch) => {
        return setTimeout(() => {
            if (!meshBotReceived.current) {
                dispatch(actions.unsubscribeMeshBotUpdates(serial, meshBotId));
                toast(`${t(EZLOGIC_ERROR_TOAST_UNABLE_SAVE_MESHBOT)} ${t(EZLOGIC_WARNING_MESHBOT_NOT_SAVED)}`, {
                    type: TOAST_TYPE.ERROR,
                });
                dispatch(actions.setIsApiProcessStarted(false));
            }
        }, meshbot.BROADCAST_WAITING_TIME);
    },

    handleMeshBotActionBroadcasts:
        ({ serial, meshBotId, newDate, meshBotReceived, navigate, timer }) =>
        (dispatch) => {
            return (meshBot) => {
                if ((meshBotId && meshBot?._id === meshBotId) || (!meshBotId && meshBot?.name === newDate.name)) {
                    dispatch(actions.unsubscribeMeshBotUpdates(serial, meshBotId));
                    meshBotReceived.current = true;
                    dispatch(actions.setIsApiProcessStarted(false));
                    navigate(EZLO_MESHBOTS);
                    toast(t(EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED), { type: TOAST_TYPE.SUCCESS });
                    clearTimeout(timer);
                }
            };
        },

    subscribeToMeshBotUpdates: (serial, meshBotId, broadcastParams) => (dispatch) => {
        meshBotId
            ? dispatch(
                  actions.subscribeMeshbotUpdate(
                      [serial],
                      dispatch(actions.handleMeshBotActionBroadcasts(broadcastParams)),
                  ),
              )
            : dispatch(
                  actions.subscribeEzloCreateScenes(
                      [serial],
                      dispatch(actions.handleMeshBotActionBroadcasts(broadcastParams)),
                  ),
              );
    },

    savingMeshBot: (selectedBlock, t, navigate) => (dispatch, getState) => {
        return new Promise(() => {
            const state = getState();
            const { serial } = state.ezlo;
            const controller = state.ezlo.data[serial];
            const meshBotId = state.meshBot.local?.idOfEditedMeshBot;
            let timer = null;
            const meshBotReceived = { current: false };

            let newDate = dataRestructuring(_.cloneDeep(selectedBlock));

            const broadcastParams = {
                serial,
                meshBotId,
                newDate,
                meshBotReceived,
                navigate,
                timer,
            };

            if (meshBotId) {
                newDate._id = meshBotId;
            }

            const rules = controller && controller.rules;
            const validationData = validateRule(newDate, rules);

            if (validationData.errors.length) {
                dispatch(actions.setValidationErrors(validationData.errors));
                dispatch(actions.setIsApiProcessStarted(false));
                toast('Validation errors', { type: TOAST_TYPE.ERROR });

                return;
            }

            if (validationData.warnings.length) {
                dispatch(actions.setValidationWarnings(validationData.warnings));
                dispatch(actions.setIsApiProcessStarted(false));
                toast('Validation warnings', { type: TOAST_TYPE.WARNING });

                return;
            }

            newDate = normalizeRule(newDate);
            newDate.when = removeTempIdFromBlocks(newDate.when);

            dispatch(actions.subscribeToMeshBotUpdates(serial, meshBotId, broadcastParams));

            dispatch(actions.setIsApiProcessStarted(true));

            timer = dispatch(actions.startMeshBotUpdateTimer(serial, meshBotId, meshBotReceived));
            const meshBotsList = state.ezlo.data[serial].rules;
            const meshBot = meshBotsList?.find(({ _id }) => _id === meshBotId);
            const updatedMeshBotPayload = getUpdatedDataWithMeshBotEnabled(newDate, meshBot);

            const action = meshBotId
                ? dispatch(EzloActions.editRule(serial, updatedMeshBotPayload))
                : dispatch(EzloActions.createRule(serial, updatedMeshBotPayload));

            action
                .then(() => {
                    if (meshBotId) {
                        actions.setIdOfEditedMeshbot(null);
                    }
                })
                .catch((error) => {
                    clearTimeout(timer);
                    dispatch(actions.setIsApiProcessStarted(false));
                    dispatch(actions.unsubscribeMeshBotUpdates(serial, meshBotId));
                    toast(`${t(EZLOGIC_ERROR_TOAST_UNABLE_SAVE_MESHBOT)} ${error.message}`, { type: TOAST_TYPE.ERROR });
                });
        });
    },

    setIsLocalMeshbotPage: (value) => ({
        type: at.SET_LOCAL_MESHBOT_PAGE,
        value,
    }),
    setIdOfEditedMeshbot: (id) => ({
        type: at.SET_ID_OF_EDITED_MESHBOT,
        id,
    }),

    setValidationErrors: (errors) => ({
        type: at.SET_VALIDATION_ERRORS,
        errors,
    }),
    setValidationWarnings: (warnings) => ({
        type: at.SET_VALIDATION_WARNINGS,
        warnings,
    }),
    setIsApiProcessStarted: (value) => ({
        type: at.IS_START_API_PROCESS,
        value,
    }),

    updateLocalVariableAction:
        (id, localVariableFields, blockName = 'then') =>
        (dispatch, getState) => {
            const state = getState();
            const actionsList = _.cloneDeep(state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS[blockName].actions]);
            const selectedRule = _.cloneDeep(state.meshBot.local.selectedRule[blockName]);
            const generateLocalVariableBlocksStructure = (actions) => {
                return actions.map((action) => {
                    if (action.id === id) {
                        const actionData = {
                            ...action,
                            blocks: [
                                generateActionBlock(action.blocks[INDEX_SELECTED_BLOCKS_ELEMENT], localVariableFields),
                            ],
                        };
                        const control = getControl(action);

                        if (control) {
                            actionData.blocks[0] = { ...actionData.blocks[0], control };
                        }

                        return actionData;
                    }

                    return action;
                });
            };

            const updatedActions = generateLocalVariableBlocksStructure(actionsList);
            const updatedSelectedRule = generateLocalVariableBlocksStructure(selectedRule);

            dispatch({
                type: at.UPDATE_ACTION_NODE.success,
                actionsList: updatedActions,
                selectedRule: updatedSelectedRule,
                blockName,
            });
        },

    // CLOUD MESHBOT

    // TODO move to CloudMeshbotActions.js
    getCloudMeshBot: () => async (dispatch) => {
        dispatch({ type: at.GET_CLOUD_MESHBOT_LIST.pending });
        dispatch(GenericActions.setLineLoading(true));
        await apiGetCloudMeshBot()
            .then((res) => {
                if (res.status === 1) {
                    const sortedData = res.data.list.sort(sortByUuid);
                    dispatch({
                        type: at.GET_CLOUD_MESHBOT_LIST.success,
                        data: sortedData,
                    });
                } else {
                    WrapperCatch(res, at.GET_CLOUD_MESHBOT_LIST.rejected, dispatch);
                    throw new Error(res.data.error_text);
                }
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err, { type: at.GET_CLOUD_MESHBOT_LIST.rejected });
                WrapperCatch(err, at.GET_CLOUD_MESHBOT_LIST.rejected, dispatch);
            })
            .finally(() => dispatch(GenericActions.setLineLoading(false)));
    },
    /**
     * Thunk Creator that create thunk that update CloudMeshBot(add new labels in meshBot) in list meshbots
     * @param {Object} params - params for update CloudMeshBot(params = {meshBotUuid, newLabelsUuids})
     * @returns {Function} thunk that update CloudMeshBot(add new labels in meshBot) in list meshbots
     * @example
     * dispatch(setCloudMeshBotWithUpdatedLabels(params))
     * */
    setCloudMeshBotWithUpdatedLabels: (params) => (dispatch, getState) => {
        const reduxState = getState();
        const meshBotsList = reduxState.meshBot.cloud.cloudMeshBot;
        const kvsLabels = reduxState[KVS_NAME][MESHBOT_LABELS];
        const updatedCloudMeshBotsList = getMeshBotsListWithUpdatedLabelsInMeshBot(params, meshBotsList, kvsLabels);

        dispatch({ type: at.SET_CLOUD_MESHBOT_LIST, updatedCloudMeshBotsList });
    },
    /**
     * Thunk Creator that create thunk that update Labels in MeshBot
     * @param {Object} meshBot - meshBot that need to update
     * @param {String} labelUuid - new label uuid
     * @returns {Function} thunk that update meshBot(add new label in meshBot)
     * @example
     * dispatch(updateLabelsInMeshBot({type: 'local', id: 'fvg412', ...other}, 'hffcg5236'))
     * */
    updateLabelsInMeshBot: (meshBot, labelUuid) => (dispatch, getState) => {
        const meshBotUuid = meshBot.id;
        const newLabelsUuids = getState().labels[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid];

        if (meshBot.labelsUuids.includes(labelUuid) || (newLabelsUuids && newLabelsUuids.includes(labelUuid))) {
            return;
        }

        if (getIsCloudTypeMeshBot(meshBot.type)) {
            dispatch(actions.updateLabelsInCloudMeshBot(meshBotUuid, labelUuid));
        }

        if (meshBot.type === LOCAL) {
            dispatch(actions.updateLabelsInLocalMeshBot(meshBotUuid, labelUuid, meshBot.serial));
        }
    },
    /**
     * Thunk Creator that create thunk that update Labels in CloudMeshBot on Cloud
     * @param {{meshBotUuid: String}} params - params for update Labels in CloudMeshBot(meshBotUuid - meshBot uuid)
     * @returns {Function} thunk that update meshBot(add new labels in meshBot) on Cloud
     * @example
     * dispatch(updateLabelsInCloudMeshBotOnCloud({meshBotUuid: 'fvg412'}))
     * */
    updateLabelsInCloudMeshBotOnCloud:
        ({ meshBotUuid }) =>
        async (dispatch, getState) => {
            const reduxState = getState();
            const newLabelsUuids = reduxState.labels[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid];
            const kvsLabels = reduxState[KVS_NAME][MESHBOT_LABELS];
            try {
                //We get brand new data from Cloud about the scene
                const getSceneResponse = await apiGetOneMeshScene(meshBotUuid);

                if (getSceneResponse.data.status === ERROR_STATUS) {
                    throw new Error(getSceneResponse.data.data.error_text);
                }

                // Building a payload to update the scene
                const sceneSetPayload = buildCloudMeshBotPayloadWithUpdatedLabels(
                    getSceneResponse.data.data,
                    newLabelsUuids,
                    UPDATED_MESHBOT_LABELS_ACTIONS.ADD,
                    kvsLabels,
                );
                // Calling a request to update the scene to the cloud (API call: scene_set)
                const sceneSetResponse = await apiSetMeshScenes(sceneSetPayload);

                if (sceneSetResponse.data.status === ERROR_STATUS) {
                    throw new Error(sceneSetResponse.data.data.error_text);
                }

                // Install scene updates in redux
                dispatch(
                    actions.setCloudMeshBotWithUpdatedLabels({
                        meshBotUuid,
                        newLabelsUuids,
                        action: UPDATED_MESHBOT_LABELS_ACTIONS.ADD,
                    }),
                );
            } catch (e) {
                toast(e.message, { type: TOAST_TYPE.ERROR });
            } finally {
                // We clean the temporary storage in redux from labels
                dispatch(clearLabelsUuidByMeshBots({ meshBotUuid, newLabelsUuids }));
            }
        },

    /**
     * Thunk Creator that create thunk that update Labels in local MeshBot on Hub
     * @param {{meshBotUuid: String, hubSerial: String}} params - params for update Labels in LocalMeshBot(meshBotUuid - meshBot uuid, hubSerial: hub serial)
     * @returns {Function} thunk that update meshBot(add new labels in meshBot) on Hub
     * @example
     * dispatch(updateLabelsInLocalMeshBotOnHub({hubSerial: '9007512', meshBotUuid: 'fvg412'}))
     * */
    updateLabelsInLocalMeshBotOnHub:
        ({ hubSerial, meshBotUuid }) =>
        async (dispatch, getState) => {
            //Collect data for queries from Redux
            const reduxState = getState();
            const newLabelsUuids = reduxState.labels[NEW_ADDED_LABELS_UUIDS_BY_MESHBOTS][meshBotUuid];
            const kvsLabels = reduxState[KVS_NAME][MESHBOT_LABELS];
            try {
                //Collect data for queries from Redux
                const meshBotsList = reduxState.ezlo.data[hubSerial].rules;
                const meshBot = meshBotsList.find(({ _id }) => _id === meshBotUuid);

                // Building a payload to update the scene
                const labelsUuids = getNewMeshBotLabelsUuids(
                    UPDATED_MESHBOT_LABELS_ACTIONS.ADD,
                    meshBot?.meta,
                    newLabelsUuids,
                    kvsLabels,
                );
                const updatedMeshBotPayload = getMeshBotWithUpdatedLabels({ meshBot, labelsUuids });
                // Calling a request to update the scene to the Hub (method: "hub.scenes.edit")
                await dispatch(EzloActions.editRule(hubSerial, updatedMeshBotPayload));
            } catch (e) {
                toast(e.message, { type: TOAST_TYPE.ERROR });
            } finally {
                // We clean the temporary storage in redux from labels
                dispatch(clearLabelsUuidByMeshBots({ meshBotUuid, newLabelsUuids }));
            }
        },
    /**
     * Thunk Creator that accumulates labels and after some time saves them on the cloud in the specified MeshBot
     * @param {String} meshBotUuid - cloud meshBot uuid
     * @param {String} labelUuid - label uuid that need to add
     * @returns {Function} thunk that accumulates labels and after some time saves them on the cloud in the specified MeshBot
     * @example
     * dispatch(updateLabelsInCloudMeshBot('fvg412', 'fvghbn5'))
     * */
    updateLabelsInCloudMeshBot: (meshBotUuid, labelUuid) => async (dispatch) => {
        // Setting a new label uuid in a temporary store (the responsibility of which is to store labels uuids that are not yet stored on the Cloud)
        dispatch(setNewLabelsUuidByMeshBots({ meshBotUuid, labelUuid }));

        // Creating Throttle wrapper over the request to save labels to the cloud (the responsibility of which is to trigger requests after a period of time, not for every change)
        const updateLabelsInCloudMeshBotOnCloud = getThrottleForUpdateLabelsInMeshBot(
            meshBotUuid,
            actions.updateLabelsInCloudMeshBotOnCloud,
        );
        // Calling label updates in a scene on the cloud with throttle control
        updateLabelsInCloudMeshBotOnCloud({ meshBotUuid });
    },

    /**
     * Thunk Creator that accumulates labels and after some time saves them on the Hub in the specified MeshBot
     * @param {String} meshBotUuid - local meshBot uuid
     * @param {String} labelUuid - label uuid that need to add
     * @param {String} hubSerial - hub serial
     * @returns {Function} thunk that accumulates labels and after some time saves them on the hub in the specified MeshBot
     * @example
     * dispatch(updateLabelsInLocalMeshBot('fvg412', 'fvghbn5', '9000123'))
     * */
    updateLabelsInLocalMeshBot: (meshBotUuid, labelUuid, hubSerial) => async (dispatch) => {
        // Setting a new label uuid in a temporary store (the responsibility of which is to store labels uuids that are not yet stored on the Hub)
        dispatch(setNewLabelsUuidByMeshBots({ meshBotUuid, labelUuid }));

        // Creating Throttle wrapper over the request to save labels to the hub (the responsibility of which is to trigger requests after a period of time, not for every change)
        const updateLabelsInLocalMeshBotOnHub = getThrottleForUpdateLabelsInMeshBot(
            meshBotUuid,
            actions.updateLabelsInLocalMeshBotOnHub,
        );
        // Calling label updates in a scene on the hub with throttle control
        updateLabelsInLocalMeshBotOnHub({ hubSerial, meshBotUuid });
    },
    /**
     * Thunk Creator that create thunk that applies label updates to the selected meshbots
     * @param {Object} labelsWithSelectStatus - List of labels in object format, the key is the ID of the label, the key stores the status of the label relative to the selected Meshbots
     * @param {Object} selectedMeshBotsByType - A list of selected meshbots in the format of an object divided into types of meshbots
     * @returns {Function} thunk that applies label updates to the selected meshbots
     * @example
     * // Example usage:
     * const selectedMeshBotsByType = {'local': {'fcv412': {name: 'label', labelsUuids: ['125kj']}, ...other}};
     * const labelsWithSelectStatus = {'412hg': 'ALL_SELECT', '125kj': 'SOME_SELECT', ...other};
     * dispatch(applyLabelsUpdatesInSelectedMeshBots(labelsWithSelectStatus, selectedMeshBotsByType)
     * */
    applyLabelsUpdatesInSelectedMeshBots:
        (labelsWithSelectStatus, selectedMeshBotsByType) => async (dispatch, getState) => {
            dispatch(GenericActions.setLineLoading(true));
            const selectedMeshBotsWithLabelsUpdates = getSelectedMeshBotsWithUpdatedLabelsUuids(
                labelsWithSelectStatus,
                selectedMeshBotsByType,
                getState()[KVS_NAME][MESHBOT_LABELS],
            );
            const batchRequests = selectedMeshBotsWithLabelsUpdates.reduce((prevState, meshBotParams) => {
                if (getIsCloudTypeMeshBot(meshBotParams.type)) {
                    return [...prevState, dispatch(actions.applyLabelsUpdatesInCloudMeshBotOnCloud(meshBotParams))];
                }

                if (meshBotParams.type === MESHBOT_TYPES.LOCAL) {
                    return [...prevState, dispatch(actions.applyLabelsUpdatesInLocalMeshBotOnHub(meshBotParams))];
                }

                return prevState;
            }, []);

            await Promise.all(batchRequests);
            await dispatch(GenericActions.setLineLoading(false));
        },
    /**
     * Thunk Creator that create thunk that applies label updates to the selected local meshbots on Hub
     * @param {Object} meshBotParams - Meshbot params for which we want to update the list of labels
     * @returns {Function} thunk that applies label updates to the selected local meshbots on Hub
     * @example
     * // Example usage:
     * const meshBotParams = {serial:'9000705', id: '125rf', labelsUuids: ['41hb5', 'cf12']};
     * dispatch(applyLabelsUpdatesInLocalMeshBotOnHub(meshBotParams)
     * */
    applyLabelsUpdatesInLocalMeshBotOnHub: (meshBotParams) => async (dispatch, getState) => {
        const reduxState = getState();
        try {
            //Collect data for queries from Redux
            const meshBotsList = reduxState.ezlo.data[meshBotParams.serial].rules;
            const meshBot = meshBotsList.find(({ _id }) => _id === meshBotParams.id);

            // Building a payload to update the scene
            const updatedMeshBotPayload = getMeshBotWithUpdatedLabels({
                meshBot,
                labelsUuids: meshBotParams.labelsUuids,
            });
            // Calling a request to update the scene to the Hub (method: "hub.scenes.edit")
            await dispatch(EzloActions.editRule(meshBotParams.serial, updatedMeshBotPayload));
        } catch (e) {
            toast(e.message, { type: TOAST_TYPE.ERROR });
        }
    },
    /**
     * Thunk Creator that create thunk that applies label updates to the selected cloud meshbots on Cloud
     * @param {Object} meshBotParams - Meshbot params for which we want to update the list of labels
     * @returns {Function} thunk that applies label updates to the selected cloud meshbots on Cloud
     * @example
     * // Example usage:
     * const meshBotParams = { id: '125rf', labelsUuids: ['41hb5', 'cf12']};
     * dispatch(applyLabelsUpdatesInCloudMeshBotOnCloud(meshBotParams)
     * */
    applyLabelsUpdatesInCloudMeshBotOnCloud: (meshBotParams) => async (dispatch) => {
        const newLabelsUuids = meshBotParams.labelsUuids;
        try {
            //We get brand new data from Cloud about the scene
            const getSceneResponse = await apiGetOneMeshScene(meshBotParams.id);

            if (getSceneResponse.data.status === ERROR_STATUS) {
                throw new Error(getSceneResponse.data.data.error_text);
            }

            // Building a payload to update the scene
            const sceneSetPayload = buildCloudMeshBotPayloadWithUpdatedLabels(
                getSceneResponse.data.data,
                newLabelsUuids,
            );

            // Calling a request to update the scene to the cloud (API call: scene_set)
            const sceneSetResponse = await apiSetMeshScenes(sceneSetPayload);

            if (sceneSetResponse.data.status === ERROR_STATUS) {
                throw new Error(sceneSetResponse.data.data.error_text);
            }

            // Install scene updates in redux
            dispatch(
                actions.setCloudMeshBotWithUpdatedLabels({
                    meshBotUuid: meshBotParams.id,
                    newLabelsUuids,
                }),
            );
        } catch (e) {
            toast(e.message, { type: TOAST_TYPE.ERROR });
        }
    },

    // TODO remove and use abstracts from main
    getAbstractList: () => async (dispatch) => {
        dispatch({ type: at.GET_ABSTRACT_LIST.pending });
        await apiGetAbstractList()
            .then((res) => {
                if (res.status === 1) {
                    dispatch({
                        type: at.GET_ABSTRACT_LIST.success,
                        data: res.data.abstracts,
                    });
                } else {
                    WrapperCatch(res, at.GET_ABSTRACT_LIST.rejected, dispatch);
                    throw new Error(res.data.error_text);
                }
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err, { type: at.GET_ABSTRACT_LIST.rejected });
                WrapperCatch(err, at.GET_ABSTRACT_LIST.rejected, dispatch);
            });
    },

    // TODO remove and use capabilities from main
    getCapabilities: () => async (dispatch) => {
        dispatch({ type: at.GET_CAPABILITIES_LIST.pending });
        await apiGetCapabilities()
            .then((res) => {
                if (res.status === 1) {
                    dispatch({
                        type: at.GET_CAPABILITIES_LIST.success,
                        data: Object.values(res.data.capabilities),
                    });
                } else {
                    WrapperCatch(res, at.GET_CAPABILITIES_LIST.rejected, dispatch);
                    throw new Error(res.data.error_text);
                }
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err, { type: at.GET_CAPABILITIES_LIST.rejected });
                WrapperCatch(err, at.GET_CAPABILITIES_LIST.rejected, dispatch);
            });
    },

    updateMeshbot: (serial, data) => ({
        type: at.UPDATE_MESHBOT,
        serial,
        data,
    }),

    removeMeshbot: (serial, data) => ({
        type: at.REMOVE_MESHBOT,
        serial,
        id: data._id,
    }),

    updateExistingCapabilityValue:
        (triggerIts, { cloudVariableName, abstract }) =>
        (dispatch, getState) => {
            const { ezlo } = getState();
            const existingCapabilityValue = checkIfSubscriptionItemExist({
                ezlo,
                selectedVariable: cloudVariableName,
                selectedAbstract: abstract,
            });
            const updateData = {
                ...triggerIts,
                selectedCapability: existingCapabilityValue,
            };
            dispatch(actions.updateCloudVariablesTrigger(updateData));
        },

    updateCloudVariableInTrigger: (triggerIds, variableData) => async (dispatch) => {
        const { cloudVariableName, abstract } = variableData;
        const updatedData = collectUpdatedCloudVariableDataForTrigger(triggerIds, variableData);
        dispatch(actions.updateCloudVariablesTrigger(updatedData));
        dispatch(actions.updateExistingCapabilityValue(triggerIds, variableData));
        const cloudVariableData = await dispatch(
            IntegrationsActions.setCloudVariableCurrentValue(abstract?.uuid, cloudVariableName),
        );

        if (cloudVariableData?.error === null) {
            dispatch(
                actions.updateCloudVariablesTrigger({
                    ...triggerIds,
                    abstractStateGetResult: cloudVariableData.value,
                }),
            );
        }
    },

    setInitialMeshBotLabels: (data) => (dispatch) => {
        dispatch({ type: at.SET_INITIAL_LOCAL_MESH_BOT_LABELS, data });
    },

    setInitialSelectedMeshBotLabels: (data) => (dispatch) => {
        dispatch({ type: at.SET_INITIAL_SELECTED_LOCAL_MESH_BOT_LABELS, data });
    },

    setInitialLocalMeshBotLabels: (singleMeshbot) => (dispatch, getState) => {
        if (!singleMeshbot?.meta?.labels) {
            return;
        }

        const labels = getState()[KVS_NAME][MESHBOT_LABELS];
        const existingMeshBotLabelsUuids = getExistingMeshBotLabelsUuids(labels, singleMeshbot?.meta?.labels);
        dispatch(actions.setInitialMeshBotLabels(existingMeshBotLabelsUuids));
        dispatch(actions.setInitialSelectedMeshBotLabels(getInitialLabels(labels, existingMeshBotLabelsUuids)));
    },

    removeActiveCloudRunSceneStatus: (scene_id) => (dispatch, getState) => {
        const state = getState();
        const { trackRunScenes } = state;
        const { cloud } = trackRunScenes;
        const updatedCloud = { ...cloud };
        delete updatedCloud[scene_id];

        dispatch(setActiveCloudRunScenes({ cloud: updatedCloud }));
    },

    setBroadcastStatus: (scene_id, status, controller_id) => (dispatch) => {
        dispatch(setRunningLocalMeshBotState({ scene_id, controller_id, status }));
        if (TEMPORARY_STATUS_LIST.includes(status)) {
            setTimeout(() => {
                dispatch(removeExecutedLocalMeshBotState({ scene_id, controller_id }));
            }, THREE_SECONDS);
        }
    },

    setCloudBroadcastStatus:
        ({ status, scene_id }) =>
        (dispatch) => {
            dispatch(MeshBotAction.setFinishedCloudSceneStatus(scene_id, status));
            setTimeout(() => {
                dispatch(MeshBotAction.removeActiveCloudRunSceneStatus(scene_id));
            }, THREE_SECONDS);
        },

    setFinishedCloudSceneStatus: (scene_id, status) => (dispatch, getState) => {
        const state = getState();
        const { trackRunScenes } = state;
        const { local, cloud } = trackRunScenes;

        const updatedActiveRunScenes = updateCloudSceneStatus({ local, cloud, scene_id, status });
        dispatch(setActiveCloudRunScenes(updatedActiveRunScenes));
    },

    // subscription realtime update meshbot

    subscribeMeshbotUpdate: (serials, cb) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.scene.changed', (data) => cb(data.result)));
    },

    subscribeMeshbotDelete: (serials, cb) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.scene.deleted', (data) => cb(data.result)));
    },

    unsubscribeMeshbotUpdate: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.scene.changed'));
    },

    unsubscribeMeshbotDelete: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.scene.deleted'));
    },

    subscribeMeshbotRun: (serials, cb) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.scene.run.progress', (data) => cb(data.result)));
    },

    unsubscribeMeshbotRun: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.scene.run.progress'));
    },

    subscribeEzloCreateScenes: (serials, cb) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.scene.added', (data) => cb(data.result)));
    },

    unsubscribeEzloCreateScenes: (serials, cb) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.scene.added', (data) => cb(data.result)));
    },
    /**
     * Thunk creator that return thunk that delete selected MeshBots
     * @param {Object} selectedMeshBotsByType - selected MeshBots by type
     * @returns {Function} returns thunk that delete selected MeshBots
     * @example
     * dispatch(deleteSelectedMeshBots({local: { '412': {id:'412', name: 'newMeshbot', ...other}}}))
     * */
    deleteSelectedMeshBots: (selectedMeshBotsByType) => async (dispatch) => {
        try {
            dispatch(GenericActions.setLineLoading(true));
            const { localMeshBots, cloudMeshBots } = prepareMeshBotsByTypeForQuery(selectedMeshBotsByType);
            const batchRequestsOfDeleteLocalMeshBots = Object.keys(localMeshBots).map((id) =>
                dispatch(EzloActions.deleteLocalMeshBot(localMeshBots[id])),
            );
            const batchRequestsOfDeleteCloudMeshBots = Object.keys(cloudMeshBots).map((id) =>
                dispatch(MesheneActions.deleteCloudMeshBot(cloudMeshBots[id])),
            );

            await Promise.all(batchRequestsOfDeleteLocalMeshBots.concat(batchRequestsOfDeleteCloudMeshBots));
        } finally {
            dispatch(GenericActions.setLineLoading(false));
        }
    },

    updateCloudMeshbotStatus: (params, statusValue) => async () => {
        try {
            const sceneResponse = await apiGetOneMeshScene(params?.id);
            if (sceneResponse?.data?.status === meshbot.SUCCESS) {
                const meshBotPayloadData = extractPayloadForUpdateStatus(sceneResponse?.data?.data);
                const updatedMeshBotStatusPayloadData = updateMeshBotStatus(meshBotPayloadData, statusValue);
                await apiSetMeshScenes(updatedMeshBotStatusPayloadData);
            }
        } catch (error) {
            toast(error.message, { type: TOAST_TYPE.ERROR });
        }
    },
};

export default actions;
