import _ from 'lodash';

import {
    ENDUSER_PERMISSION,
    INSTALLER_PERMISSION,
    ACTION_COMPARE_PROPERTY,
    CUSTOMIZATION,
    LISTING_UI_ID,
    LOCATION,
    MESHBOTS,
    TRIGGER_COMPARE_PROPERTY,
    ZERO,
    PRIMARY_COLOR_KEY,
    COLORS,
    PALETTE_COLOR,
    HEX,
} from './constants';
import { PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN } from '../../../constants/URLs';
import { MENU_ITEMS_IDS } from '../../Navigation/constants';
import * as localStorageKeys from '../../../constants/localStorageKeys';
import { filterMenuItemsByInternalDomain, updateNavigationMenuItemsBySsoType } from '../../Navigation/utils';
import { ONE_INT, ZERO_INT } from '../../../constants/Variables';
import { filterCloudTriggerNodesByInternalDomain } from '../EzloMeshbot/utils';

export const finalListingUIData = (listingUIName, columns) => {
    const finalListingUI = {
        listingMeshbotName: listingUIName ?? MESHBOTS,
        listingColumns: columns,
    };

    return finalListingUI;
};

export const createPayloadForColumns = (columns) => {
    const updatedNewColumn = columns.map((columnItem) => {
        return {
            field: columnItem?.field,
            headerName: columnItem?.headerName,
            hide: columnItem?.hide,
        };
    });

    return updatedNewColumn;
};

export const finalMeshbotUIData = (checkboxItemsTrigger, checkboxItemsAction) => {
    const finalMeshbotData = {
        triggers: checkboxItemsTrigger,
        actions: checkboxItemsAction,
    };

    return finalMeshbotData;
};

export const decodeCloudToken = (CloudToken) => {
    const [, base64Url] = CloudToken.split('.');
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return jsonPayload;
};

export const parseData = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
};

/**
 * Returns className for validation result
 * @param {object} updatedCustomizationData - action to analyze
 * @param {string} KVS_KEY - initial Cloud MeshBot actions
 * @return {string} className
 * */

export const uploadKvsSetData = (userType, encodedCustomizationData, KVS_KEY, KVS_USER_KEY, VERA_USER) => {
    const data = {
        type: userType ? VERA_USER : LOCATION,
        public: 1,
        key: userType ? KVS_USER_KEY : KVS_KEY,
        value: encodedCustomizationData,
    };

    return data;
};

export const getUpdatedMenu = (updatedMenuList, menuList) => {
    if (updatedMenuList) {
        updatedMenuList.filter((newMenu) => {
            menuList?.some((menu) => {
                if (newMenu.id === menu.id) {
                    menu.checked = newMenu.checked;
                }

                if (newMenu && newMenu.subItems && menu && menu.subItems) {
                    newMenu?.subItems.filter((subitem) => {
                        menu?.subItems.some((submenu) => {
                            if (subitem.id === submenu.id) {
                                submenu.checked = subitem.checked;
                            }
                        });
                    });
                }
            });
        });
    } else {
        return menuList;
    }

    return menuList;
};

export const getUpdatedListingUi = (listingColumns, newColumns) => {
    if (listingColumns) {
        listingColumns.filter((listcolumn) => {
            newColumns?.some((column) => {
                if (listcolumn.field === column.field) {
                    column.hide = listcolumn.hide;
                }
            });
        });
    } else {
        return newColumns;
    }

    return newColumns;
};

export const payloadDataMenuItems = (updatedMenu) => {
    const subMenuItems = (menuList) => {
        const updatedMenuItems = menuList?.map((subItem) => {
            return returnUpdatedMenu(subItem);
        });

        return updatedMenuItems;
    };
    const returnUpdatedMenu = (item) => {
        return {
            id: item?.id,
            checked: item?.checked,
            title: item?.title,
            rename: item?.rename,
            show: item?.show,
            subItems: item?.subItems?.length > ZERO ? subMenuItems(item.subItems) : null,
        };
    };

    const finalMenu = updatedMenu?.map((item) => {
        return returnUpdatedMenu(item);
    });

    return finalMenu;
};

const getMenuItems = (menuItem, customizedMenuListIds, userType) => {
    const customizedItem = customizedMenuListIds?.find((customizedMenu) => customizedMenu?.id == menuItem?.id);

    if ((userType || menuItem?.id !== CUSTOMIZATION) && menuItem?.id !== MENU_ITEMS_IDS.OEM_SETTINGS) {
        return customizationMenuItems(menuItem, customizedItem, userType, customizedMenuListIds);
    }
};

const customizationMenuItems = (menuItem, customizedItem, userType, customizedMenuListIds) => {
    if (customizedItem) {
        return {
            ...menuItem,
            checked: customizedItem?.checked,
            show: customizedItem?.show,
            rename: customizedItem?.rename,
            subItems:
                menuItem?.subItems?.length > ZERO
                    ? subMenuItems(menuItem.subItems, customizedMenuListIds, userType).filter(
                          (menuSubItem) => menuSubItem !== undefined,
                      )
                    : null,
        };
    } else {
        return {
            ...menuItem,
            show: true,
            rename: false,
            checked: true,
            subItems:
                menuItem?.subItems?.length > ZERO
                    ? subMenuItems(menuItem.subItems, customizedMenuListIds, userType).filter(
                          (menuSubItem) => menuSubItem !== undefined,
                      )
                    : null,
        };
    }
};

const subMenuItems = (menuList, customizationList, userType) => {
    const updatedMenuSubItems = menuList?.map((subItem) => {
        return getMenuItems(subItem, customizationList, userType);
    });

    return updatedMenuSubItems;
};
/**
 * Function that filters menu items by sso type
 * @param {Array<Object>} menuItems - menu list
 * @return {Array<Object>} filtered menu items by sso type.
 * */
export const filterMenuBySsoType = (menuItems) => {
    if (!menuItems) {
        throw Error('There is no menuItems');
    }
    const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);

    return updateNavigationMenuItemsBySsoType(menuItems, ssoType);
};

export const updatedMenuListItem = (MENU_ITEMS, customizedMenuListIds, userType) => {
    const filteredMenuByInternalDomain = filterMenuItemsByInternalDomain(MENU_ITEMS);
    const filteredMenuBySsoType = filterMenuBySsoType(filteredMenuByInternalDomain);
    const menuItems = filteredMenuBySsoType.map((menuItem) => {
        return getMenuItems(menuItem, customizedMenuListIds, userType);
    });

    return menuItems.filter((menuItem) => menuItem != null && menuItem != undefined);
};

export const getItemsAndNestedSubItems = (updatedMenuList) => {
    if (!updatedMenuList || Object.keys(updatedMenuList).length === ZERO) {
        return [];
    }

    return extractSubItems(updatedMenuList);
};

const extractSubItems = (subItemMenu) => {
    return Object.values(subItemMenu).flatMap((subMenuItem) => {
        if (subMenuItem.subItems && Object.keys(subMenuItem.subItems).length > ZERO) {
            return [
                {
                    id: subMenuItem.id,
                    checked: subMenuItem.checked,
                    title: subMenuItem.title,
                    rename: subMenuItem.rename,
                    show: subMenuItem.show,
                },
                ...extractSubItems(subMenuItem.subItems),
            ];
        } else {
            return {
                id: subMenuItem.id,
                checked: subMenuItem.checked,
                title: subMenuItem.title,
                rename: subMenuItem.rename,
                show: subMenuItem.show,
            };
        }
    });
};
export const updatedListNodesCloud = (listNodesCloud, customizedTriggersList) => {
    const updatedListNode = listNodesCloud?.map((node) => {
        const customizedItem = customizedTriggersList?.find(
            (customizedtriggerList) => customizedtriggerList?.value == node?.value,
        );
        if (customizedItem) {
            return {
                ...node,
                checked: customizedItem?.checked,
            };
        } else {
            return {
                ...node,
                checked: true,
            };
        }
    });

    return filterCloudTriggerNodesByInternalDomain(updatedListNode);
};

export const updatedListNodeAction = (listNodeAction, customizedActionList) => {
    const updatedListNode = listNodeAction?.map((node) => {
        const customizedItem = customizedActionList?.find(
            (customizedActionItem) => customizedActionItem?.value == node?.value,
        );
        if (customizedItem) {
            return {
                ...node,
                checked: customizedItem?.checked,
            };
        } else {
            return {
                ...node,
                checked: true,
            };
        }
    });

    return updatedListNode;
};

export const updatedListingColumnData = (listingColumnData, listingColumns) => {
    const updatedData = listingColumnData?.map((column) => {
        const customizedColumnItem = listingColumns?.find(
            (customizedActionItem) => customizedActionItem?.field == column?.field,
        );
        if (customizedColumnItem) {
            return {
                ...column,
                hide: customizedColumnItem?.hide,
            };
        } else {
            if (column?.field == LISTING_UI_ID) {
                return {
                    ...column,
                    hide: true,
                };
            } else {
                return {
                    ...column,
                    hide: false,
                };
            }
        }
    });

    return updatedData;
};

/**
 * Returns Array of objects with deleted id from trigger nodes.
 * @param {array} meshbotTrigger -
 * @return {array} updated array with deleted id from trigger nodes.
 * */
const deleteIdFromTriggerNodes = (meshbotTrigger) => {
    if (meshbotTrigger) {
        const copyOfMeshbotTriggerNodes = JSON.parse(JSON.stringify(meshbotTrigger));
        copyOfMeshbotTriggerNodes?.forEach((item) => {
            delete item.id;
        });

        return copyOfMeshbotTriggerNodes;
    }
};

/**
 * Returns boolean true or false if menu changed.
 * @param {object} initialCustomizationData -
 * @param {object} currentMenuData -
 * @return {boolean} updated true or false.
 * */
const checkMenuChanged = (initialCustomizationData, currentMenuData) => {
    const isInitialMenuDataChanged = !_.isEqual(initialCustomizationData?.initialMenuUi, currentMenuData);

    return isInitialMenuDataChanged;
};

/**
 * Returns boolean true or false if listing Ui changed.
 * @param {object} initialCustomizationData -
 * @param {object} currentlistingUiData -
 * @return {boolean} updated true or false.
 * */
const checkListingUiChanged = (initialCustomizationData, currentlistingUiData) => {
    const isInitialListingDataChanged = !_.isEqual(initialCustomizationData?.initialListingUi, currentlistingUiData);

    return isInitialListingDataChanged;
};

/**
 * Returns boolean true or false if meshbot Ui changed.
 * @param {object} initialCustomizationData -
 * @param {object} currentMeshbotUiData -
 * @return {boolean} updated true or false.
 * */
const checkMeshbotUiChanged = (initialCustomizationData, currentMeshbotUiData) => {
    const initialMeshbotTriggersData = initialCustomizationData?.initialMeshbotUi?.triggers;
    const initialMeshbotActionsData = initialCustomizationData?.initialMeshbotUi?.actions;
    const currentMeshbotTriggersData = currentMeshbotUiData?.triggers;
    const currentMeshbotActionsData = currentMeshbotUiData?.actions;
    const updatedCurrentMeshbotTriggers = deleteIdFromTriggerNodes(currentMeshbotTriggersData);
    const updatedInitialMeshbotTriggers = deleteIdFromTriggerNodes(initialMeshbotTriggersData);

    const isInitialMeshbotTriggerDataChanged = !_.isEqual(updatedInitialMeshbotTriggers, updatedCurrentMeshbotTriggers);
    const isInitialMeshbotActionDataChanged = !_.isEqual(initialMeshbotActionsData, currentMeshbotActionsData);
    const isInitialMeshbotDataChanged = isInitialMeshbotTriggerDataChanged || isInitialMeshbotActionDataChanged;

    return isInitialMeshbotDataChanged;
};

/**
 * Returns boolean true or false if logo changed.
 * @param {object} initialCustomizationData -
 * @param {object} currentKvsPayloadCustomizationData -
 * @return {boolean} updated true or false.
 * */
const checkLogoChanged = (initialCustomizationData, logo) => {
    const isInitialLogoDataChanged = !_.isEqual(initialCustomizationData?.initialLogo, logo ? logo : null);

    return isInitialLogoDataChanged;
};

const checkColorThemeChanged = (initialCustomizationData, colorTheme) => {
    const isInitialColorThemeChanged = !_.isEqual(initialCustomizationData?.initialColorTheme, colorTheme);

    return isInitialColorThemeChanged;
};
export const compareNodes = (initialNodes, listNodesCloud) => {
    if (initialNodes?.length !== listNodesCloud?.length) {
        return false;
    }

    for (let i = 0; i < initialNodes?.length; i++) {
        const initialNode = initialNodes[i];
        const cloudNode = listNodesCloud[i];

        const initialNodeKeys = Object.keys(initialNode);
        const cloudNodeKeys = Object.keys(cloudNode);

        if (initialNodeKeys.length !== cloudNodeKeys.length) {
            return false;
        }

        for (const key of initialNodeKeys) {
            if (key === 'id') {
                continue;
            }

            if (initialNode[key] !== cloudNode[key]) {
                return false;
            }
        }
    }

    return true;
};

/**
 *
 * @param {array} initialCustomizationData -
 * @param {array} nodes -
 * @return {boolean} updated true or false.
 * */
export const checkNodesControllableChanged = (initialCustomizationData, nodes) => {
    const isInitialCloudTriggerNodeChanged = compareNodes(
        initialCustomizationData?.initialNodesControllables?.listNodesCloud,
        nodes?.listNodesCloud,
    );
    const isInitialCloudActionNodeChanged = compareNodes(
        initialCustomizationData?.initialNodesControllables?.actionCloudNodes,
        nodes?.actionCloudNodes,
    );

    const isInitialLocalNodeChanged = compareNodes(
        initialCustomizationData?.initialNodesControllables?.listNodesLocal,
        nodes?.listNodesLocal,
    );

    const isInitialLocalActionChanged = compareNodes(
        initialCustomizationData?.initialNodesControllables?.actionLocalNodes,
        nodes?.actionLocalNodes,
    );

    const isInitialLogoDataChanged =
        !isInitialCloudTriggerNodeChanged ||
        !isInitialCloudActionNodeChanged ||
        !isInitialLocalNodeChanged ||
        !isInitialLocalActionChanged;

    return isInitialLogoDataChanged;
};

/**
 * Returns boolean true or false after compare initial and current state of customization.
 * @param {object} initialCustomizationData -
 * @param {object} initialCustomizationData -
 * @param {object} currentKvsPayloadCustomizationData -
 * @return {boolean} true or false.
 * */
export const checkCustomizationChanged = (
    initialCustomizationData,
    currentCustomizationData,
    currentKvsPayloadCustomizationData,
) => {
    const isMenuChanged = checkMenuChanged(initialCustomizationData, currentCustomizationData?.menuBar);
    const isListingUiChanged = checkListingUiChanged(initialCustomizationData, currentCustomizationData?.listUi);
    const isMeshbotUiChanged = checkMeshbotUiChanged(initialCustomizationData, currentCustomizationData?.meshbot);
    const isLogoChanged = checkLogoChanged(initialCustomizationData, currentKvsPayloadCustomizationData?.images?.logo);
    const isColorThemeChanged = checkColorThemeChanged(initialCustomizationData, currentCustomizationData?.colorTheme);

    const isNodesControllableChanged = checkNodesControllableChanged(
        initialCustomizationData,
        currentCustomizationData?.nodesControllables,
    );
    const isCustomizationChanged =
        isMenuChanged ||
        isListingUiChanged ||
        isMeshbotUiChanged ||
        isLogoChanged ||
        isNodesControllableChanged ||
        isColorThemeChanged;

    return isCustomizationChanged;
};

/**
 * Returns boolean true or false.
 * @param {object} initialCustomizationData -
 * @param {boolean} isVeraUser -
 * @return {boolean} false when any changed with default else true.
 * */
export const checkCustomizationReset = (initialCustomizationData, isVeraUser) => {
    const isMenuChanged = checkMenuChanged(initialCustomizationData, initialCustomizationData?.defaultMenuUi);
    const isListingUiChanged = checkListingUiChanged(
        initialCustomizationData,
        initialCustomizationData?.defaultListingUi,
    );
    const isMeshbotUiChanged = checkMeshbotUiChanged(
        initialCustomizationData,
        initialCustomizationData?.defaultMeshbotUi,
    );
    const isLogoChanged = checkLogoChanged(initialCustomizationData, initialCustomizationData?.defaultLogo);
    const isColorThemeChanged = checkColorThemeChanged(
        initialCustomizationData,
        initialCustomizationData?.defaultColorTheme,
    );
    const isNodesControllableChanged = checkNodesControllableChanged(
        initialCustomizationData,
        initialCustomizationData?.defaultNodesControllables,
    );

    let isCustomizationChanged;
    if (isVeraUser) {
        isCustomizationChanged =
            isMenuChanged ||
            isListingUiChanged ||
            isMeshbotUiChanged ||
            isLogoChanged ||
            isColorThemeChanged ||
            isNodesControllableChanged;
    } else {
        isCustomizationChanged =
            isMenuChanged ||
            isListingUiChanged ||
            isMeshbotUiChanged ||
            isColorThemeChanged ||
            isNodesControllableChanged;
    }

    return !isCustomizationChanged;
};

export const getUpdatedMenuUiData = (menuBar, payloadMenuUi) => {
    if (menuBar?.length) {
        return menuBar;
    } else {
        return payloadMenuUi;
    }
};

export const getUpdatedLogo = (logo) => {
    if (logo == undefined) {
        return null;
    } else {
        return logo;
    }
};

export const updateColorValue = (colorTheme, newColorValue) => {
    const updatedColorTheme = colorTheme?.map((color) => {
        if (color.key === PRIMARY_COLOR_KEY) {
            return { ...color, value: newColorValue };
        }

        return color;
    });

    return updatedColorTheme;
};

/**
 * Returns object of customization payload.
 * @param {array} menuBar -
 * @param {object} listingUi -
 * @param {object} meshbotUi -
 * @param {string} logo -
 * @return {object} object of customization payload.
 * */
export const getCustomizationPayload = (menuBar, listingUi, meshbotUi, defaultNodesControllables, colorTheme, logo) => {
    const customizationInitialData = {
        menuBar,
        listingUi,
        meshbotUi,
        defaultNodesControllables,
        logo: logo ? logo : null,
        colorTheme,
    };

    return customizationInitialData;
};

export const getUpdatedColorCustomizationPayload = (colorTheme, primaryColor) => {
    let updatedColorData;
    if (primaryColor && colorTheme) {
        updatedColorData = updateColorValue(colorTheme, primaryColor);

        return updatedColorData;
    }

    return colorTheme;
};

export const getFinalCustomizationPayload = (updatedKvsPayload, isVeraUser, isReset, customizationPayload) => {
    let finalDefaultCustomizationPayload;
    if (isReset === false) {
        finalDefaultCustomizationPayload = {
            ezlogicPortal: customizationPayload.ezlogicPortal,
            ...updatedKvsPayload,
        };

        return finalDefaultCustomizationPayload;
    }

    if (isVeraUser) {
        finalDefaultCustomizationPayload = {};
    } else {
        finalDefaultCustomizationPayload = {
            ezlogicPortal: {},
            ...updatedKvsPayload,
        };

        if (
            updatedKvsPayload?.colors &&
            updatedKvsPayload?.colors?.paletteColor &&
            updatedKvsPayload?.colors.paletteColor?.hex
        ) {
            updatedKvsPayload[COLORS][PALETTE_COLOR][HEX] = null;
        }
    }

    return finalDefaultCustomizationPayload;
};

/**
 * Returns string in encoded form.
 * @param {object} finalPayload -
 * @return {string} encoded customization data.
 * */
export const getEncodedCustomizationData = (finalPayload) => {
    const stringifyCustomizationData = JSON.stringify(finalPayload);
    const encodedCustomizationData = btoa(stringifyCustomizationData);

    return encodedCustomizationData;
};

/**
 * Find the page opened by the customization and return its path
 * @param {Array<Object>} customizationMenu - menu list for app customization
 * @return {string} path of the first open page that is listed in the customization menu.
 * */
export const getPathOfFirstOpenPageByCustomization = (customizationMenu) => {
    if (!customizationMenu) {
        throw new Error('There is no customizationMenu');
    }
    for (const menuItem of customizationMenu) {
        if (PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN.hasOwnProperty(menuItem?.id) && menuItem?.checked) {
            return PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN[menuItem.id];
        }

        if (menuItem?.subItems && Array.isArray(menuItem.subItems)) {
            const path = getPathOfFirstOpenPageByCustomization(menuItem.subItems);

            if (path) {
                return path;
            }
        }
    }
};

export const handleCheckboxClick = (menuList, id) => {
    for (let menuItem = ZERO; menuItem < menuList.length; menuItem++) {
        if (menuList[menuItem]?.id === id) {
            menuList[menuItem].checked = !menuList[menuItem].checked;
            updateSubItemsChecked(menuList[menuItem]);
            updateParentChecked(menuList[menuItem]);

            return;
        }

        if (menuList[menuItem]?.subItems) {
            handleCheckboxClick(menuList[menuItem].subItems, id);
            updateParentChecked(menuList[menuItem]);
        }
    }
};

const updateSubItemsChecked = (item) => {
    if (item?.subItems) {
        for (let subMenuItem = ZERO; subMenuItem < item.subItems.length; subMenuItem++) {
            item.subItems[subMenuItem].checked = item.checked;
            updateSubItemsChecked(item.subItems[subMenuItem]);
        }
    }
};

const updateParentChecked = (item) => {
    if (item?.subItems) {
        const hasCheckedSubItems = item?.subItems.some((subItem) => subItem?.checked);
        const hasUncheckedSubItems = item?.subItems.some((subItem) => !subItem?.checked);
        item.checked = hasCheckedSubItems && hasUncheckedSubItems ? true : hasCheckedSubItems;
    }
};

const getColorValue = (item, key) => {
    const colorValue = getComputedStyle(document.documentElement).getPropertyValue(key);
    const slicedColor = colorValue.trim();

    return { ...item, value: slicedColor };
};

export const getUpdatedColors = (colors, updatedColor) => {
    let updatedColors;
    if (updatedColor?.length) {
        updatedColors = colors?.map((color) => {
            const updatedColorFound = updatedColor?.find((x) => x.key === color.key);

            return updatedColorFound || getColorValue(color, color.key);
        });
    } else {
        updatedColors = colors?.map((item) => getColorValue(item, item.key));
    }

    return updatedColors;
};

export const setUpdatedColors = (theme) => {
    theme?.forEach(({ key, value }) => {
        document.documentElement.style.setProperty(key, value);
    });
};

export const getUpdatedColorTheme = (colors, colorTheme, permissionRole, isPartnerDomain) => {
    const updatedColor =
        permissionRole === INSTALLER_PERMISSION || permissionRole === ENDUSER_PERMISSION || isPartnerDomain
            ? getUpdatedColors(colors, colorTheme)
            : getUpdatedColors(colors);

    const colorsData = {};
    updatedColor?.forEach(({ key, value }) => {
        colorsData[key] = value;
    });

    return colorsData;
};
export const getPayloadForNodes = (listNodesCloud, listNodesLocal, actionCloudNodes, actionLocalNodes) => {
    const obj = {
        listNodesCloud: listNodesCloud,
        listNodesLocal: listNodesLocal,
        actionCloudNodes: actionCloudNodes,
        actionLocalNodes: actionLocalNodes,
    };

    return obj;
};

export const getUpdatedNodesByCustomization = (listNodes, initialNodesControllablesData, propertyToCompare) => {
    let updatedListNodesLocal = [];

    if (initialNodesControllablesData && initialNodesControllablesData.length > ZERO) {
        updatedListNodesLocal = [...initialNodesControllablesData];
    }

    listNodes.forEach((node) => {
        const index = updatedListNodesLocal?.findIndex(
            (updatedNode) =>
                updatedNode.value === node.value && updatedNode[propertyToCompare] === node[propertyToCompare],
        );

        if (index >= ZERO_INT) {
            updatedListNodesLocal[index] = node;
        } else {
            updatedListNodesLocal?.push(node);
        }
    });

    const updatedNodeByCustomization = getFilterAndAppendNodes(listNodes, updatedListNodesLocal, propertyToCompare);

    return filterCloudTriggerNodesByInternalDomain(updatedNodeByCustomization);
};

export const getFilterAndAppendNodes = (listNodes, initialNodesControllablesData, propertyToCompare) => {
    const updatedInitialNodesControllablesData = initialNodesControllablesData.filter((initialItem) =>
        listNodes.some(
            (node) => node.value === initialItem.value && node[propertyToCompare] === initialItem[propertyToCompare],
        ),
    );

    return updatedInitialNodesControllablesData;
};

export const getNodesCustomizationPayload = (
    initialNodesControllablesData,
    listNodesCloud,
    listNodesLocal,
    actionCloudNodes,
    actionLocalNodes,
) => {
    const obj = {
        listNodesCloud: getUpdatedNodesByCustomization(
            listNodesCloud,
            initialNodesControllablesData?.listNodesCloud,
            TRIGGER_COMPARE_PROPERTY,
        ),
        actionCloudNodes: getUpdatedNodesByCustomization(
            actionCloudNodes,
            initialNodesControllablesData?.actionCloudNodes,
            ACTION_COMPARE_PROPERTY,
        ),
        listNodesLocal: getUpdatedNodesByCustomization(
            listNodesLocal,
            initialNodesControllablesData?.listNodesLocal,
            TRIGGER_COMPARE_PROPERTY,
        ),
        actionLocalNodes: getUpdatedNodesByCustomization(
            actionLocalNodes,
            initialNodesControllablesData?.actionLocalNodes,
            ACTION_COMPARE_PROPERTY,
        ),
    };

    return obj;
};

export const getSortedNodesArray = (source, destination, NodesArray) => {
    const newNodesArray = Array.from(NodesArray);
    const [removed] = newNodesArray?.splice(source.index, ONE_INT);
    newNodesArray?.splice(destination.index, ZERO, removed);

    return newNodesArray;
};

export const getUpdatedKvsPayloadData = (colorTheme, currentKvsPayload) => {
    const primaryColorThemeValue = colorTheme?.find((obj) => obj?.key === PRIMARY_COLOR_KEY).value;

    if (currentKvsPayload.colors && currentKvsPayload.colors.paletteColor) {
        currentKvsPayload[COLORS][PALETTE_COLOR][HEX] = primaryColorThemeValue;
    } else {
        currentKvsPayload.colors = {
            paletteColor: {
                hex: primaryColorThemeValue,
            },
        };
    }

    return currentKvsPayload;
};
