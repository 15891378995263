import moment from 'moment';
import * as types from '../constants/ActionTypes';
import wsm from '../helpers/wsm';
import EzloActions from './EzloActions';
//Hidden for task 3085 called "Remove grouping of hubs on UI".
// import GroupsActions from './GroupsActions';
import GenericActions from './GenericActions';
import { t } from '../helpers/language';
import { EZLO_DASHBOARD, PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN } from '../constants/URLs';
import { apiGetUser, apiRegister, apiForgotPassword, apiRegisterForPartnerUser } from '../api/apiAuth';
import { getSetCloudToken, loginUser } from 'services/auth';
import { getPathOfFirstPageByPageValue, tokenParser } from '../helpers/auth';
import EzloGroupActions from './EzloGroupActions';
import EzloCustomizationAction from './EzloCustomization';
import CustomizationAction from './CustomizationActions';
import * as localStorageKeys from '../constants/localStorageKeys';
import { API_ERROR_MESSAGES } from '../constants/Errors';
import { LOCATION_CHANGE } from '../constants/App';

import { MainAction } from '.';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import {
    setBounceGetRequest,
    setBounceGetParams,
    getUserAuthData,
} from '../containers/AuthorizationPages/LoginWithToken/utils';
import {
    ENDUSER_PERMISSION,
    INSTALLER_PERMISSION,
    PARTNER_INSTALLER_LOGIN_ERROR,
    PARTNER_PERMISSION,
} from '../containers/Ezlo/EzloCustomization/constants';
import { supportActions } from './SupportActions';
import { PK_ACCOUNT_CHILD_EMPTY } from '../constants/Devices';
import { itarianService } from '../services/sso';
import { ITARIAN_SSO_TYPE } from '../services/sso/src/common/constants';
import {
    checkPartnerDomain,
    getDataFromUserIdentity,
    getPreventPartnerInstallerLoginError,
    isSecurityOrVeraPartnerInstallerUser,
} from '../containers/AuthorizationPages/Login/utils';
import PrivacyPolicyAction from '../features/EzloPolicies/actions/EzloPoliciesAction';
import { LOGIN_TYPES } from '../constants/ActionTypes';
import { getPathOfFirstOpenPageByCustomization } from '../containers/Ezlo/EzloCustomization/utils';
import installerActions from '../features/InstallerAccess/actions/InstallerAccessRights';
// Do not remove below commented code it may need in future related to ENWT-4042
// import { ACCESS_REVOKED, SECURITY_OEM, ZERO_INT, ZERO_STRING } from '../features/InstallerAccess/constants';
import { ZERO_INT, ZERO_STRING } from '../features/InstallerAccess/constants';
import { getPathOfFirstPageBySso } from '../helpers/auth';
import { PAGE_VALUE } from '../constants/localStorageKeys';
import { updateLang } from '../features/languages/utils';
import { TRUE } from '../constants/MeshbotConstant';
import kvsActions from './kvsActions';
import { setIsInstallerLoggedInAsResident } from '../features/InstallerAccess/reducers/installerAccessRights';
import { getInstallerAccessRightsStatus } from '../features/InstallerAccess/actions/installerAccessRightsApi';
import {
    EZLOGIC_TITLE_CONNECTION_ISSUE_TRY_AGAIN,
    EZLOGIC_TITLE_DO_NOT_GO_THROUGH,
    EZLOGIC_TITLE_INCORRECT_EMAIL,
    EZLOGIC_TITLE_TOO_MANY_LOGINS,
} from '../constants/language_tokens';
import { compareDateTimes } from '../features/InstallerAccess/utils';
import { runTokensFlow } from 'features/tokensFlows/thunks';
import { apiGetUserUuid } from '../api/apiScenes';
import { TOO_MANY_LOGINS } from '../services/auth';
import { MENU_ITEMS_IDS } from 'containers/Navigation/constants';

const actions = {
    setDevicePageEditMode: (isEditMode) => (dispatch) =>
        dispatch({
            type: types.SET_DEVICE_PAGE_EDIT_MODE,
            isEditMode,
        }),

    editProfile: (serial, md5hash, email, fields) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'editUser',
                { md5hash, email, fields },
                () => {
                    dispatch(actions.updateProfile(fields));
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, md5hash, email, fields });
                    dispatch(GenericActions.showError(error));
                    reject();
                },
            );
        }),

    updateProfile: (profileData) => (dispatch) => {
        dispatch(actions.updateProfileState(profileData));
    },

    updateProfileState: (data) => ({
        type: types.UPDATE_PROFILE,
        data: data,
    }),

    initialAccountInfoLoading: () => async (dispatch) => {
        try {
            await Promise.all([
                // 9. Fetch abstract capabilities
                dispatch(MainAction.getAbstractCapabilities()),
                // 10. Fetch abstracts list
                dispatch(MainAction.getAbstractsList()),
                // 11. Fetch integrations list and integrations preview list
                dispatch(MainAction.getIntegrationsList()),
            ]);

            // 12. Enroll to PAASAuthService
            await dispatch(MainAction.enrollToPAASAuthService());
        } catch (err) {
            bugsnagNotify(err);
        }
    },
    /**
     * Object that stores customization functions(redux hunk creators) depending on login type. These functions
     * are responsible for getting customization and storing this data in redux storage
     * @param {Object} userDetails - complete user details
     * @param {String} loginType - login type
     * @param {String|undefined} page - page from logged user's identity
     * @example dispatch(accountCustomizationByLoginType[LOGIN_TYPES.WITH_CREDENTIALS](userDetails, page));
     * */
    accountCustomizationByLoginType: {
        [LOGIN_TYPES.WITH_CREDENTIALS]:
            ({ PK_PermissionRole }) =>
            async (dispatch) => {
                const isPartnerDomain = checkPartnerDomain();

                if (
                    (PK_PermissionRole === INSTALLER_PERMISSION || PK_PermissionRole === ENDUSER_PERMISSION) &&
                    isPartnerDomain === false
                ) {
                    await Promise.all([
                        dispatch(EzloCustomizationAction.getCustomization()),
                        dispatch(EzloCustomizationAction.getCustomizationForUser()),
                    ]);
                }

                if (PK_PermissionRole === PARTNER_PERMISSION) {
                    await dispatch(CustomizationAction.getCustomizationForPartner());
                }
            },
        [LOGIN_TYPES.WITH_TOKEN]:
            ({ PK_PermissionRole }, page) =>
            async (dispatch) => {
                await dispatch(actions.getCustomizationForChild());
                if (PK_PermissionRole === ENDUSER_PERMISSION) {
                    await dispatch(EzloCustomizationAction.getCustomizationForUser());
                }

                if (page) {
                    localStorage.setItem(localStorageKeys.PAGE_VALUE, page);
                    if (PK_PermissionRole === PARTNER_PERMISSION) {
                        await dispatch(CustomizationAction.getCustomizationForPartner());
                        dispatch(MainAction.getAbstractCapabilities());
                    }
                }
            },
        [LOGIN_TYPES.WITH_ITARIAN_TOKEN]:
            ({ PK_Account, PK_PermissionRole }) =>
            async (dispatch) => {
                if (PK_PermissionRole === ENDUSER_PERMISSION) {
                    await dispatch(EzloCustomizationAction.getCustomizationForUser(PK_Account));
                }
            },
    },
    /**
     * Function that return redux hunk that run customization processes(requests and save result) depending on login type
     * @param {Object} userDetails - complete user details
     * @param {String} loginType - login type
     * @param {String|undefined} page - page from logged user's identity
     * @return {Function} Redux hunk.
     * @example dispatch(accountCustomization(userDetails, loginType, page);
     * */
    accountCustomization: (userDetails, loginType, page) => async (dispatch) => {
        dispatch(actions.setIsCustomizationProgressing(true));
        await dispatch(actions.accountCustomizationByLoginType[loginType](userDetails, page));
        await dispatch(actions.setIsCustomizationProgressing(false));
    },
    accountPrivacyPolicy: (dispatch) => {
        dispatch(PrivacyPolicyAction.getEulaData());
        dispatch(PrivacyPolicyAction.getNoticesData());
        dispatch(PrivacyPolicyAction.getConsentData());
    },
    /**
     * Thunk creator to set 'user_uuid' of logged-in user in redux store.
     *
     * @function
     * @param { number } PK_User - The primary key of the user.
     * @returns {Function} A Redux Thunk function that dispatches actions or performs asynchronous operations.
     *
     * @throws {Error} Throws an error if the API request fails or if the user UUID is not retrieved.
     *
     * @example
     * // Usage
     * const PK_User = 123;
     * dispatch(setLoggedInUserUuid(PK_User));
     */

    setLoggedInUserUuid: (PK_User) => async (dispatch) => {
        const id = String(PK_User);
        const response = await apiGetUserUuid([id]);
        const user_uuid = response[id];
        if (user_uuid) {
            dispatch(actions.setInfoOfLoggedUser({ user_uuid }));
        }
    },
    /**
     * Function that returns redux hunk that makes a request to get complete details about user and saves this data in redux storage
     * @param {Object} userLoginData - user data obtained from the authorization request.
     * @return {Function} Redux hunk that returns complete user details.
     * @example dispatch(setCompleteUserDetails(userLoginData);
     * */
    setCompleteUserDetails: (userLoginData) => async (dispatch) => {
        const userData = await dispatch(actions.getUser(userLoginData));
        await dispatch(actions.setInfoOfLoggedUser(userData));

        return userData;
    },
    /**
     * Function that return redux hunk that runs the initialized requests that the application needs
     * @param {Object} userDetails - login user details
     * @param {String} loginType - login type
     * @param {String|undefined} page - page from logged user's identity
     * @return {Function} Redux hunk.
     * @example dispatch(runAppInitializationRequests(userDetails, loginType, page);
     * */
    runAppInitializationRequests: (userDetails, loginType, page) => (dispatch) => {
        dispatch(actions.accountCustomization(userDetails, loginType, page));
        dispatch(kvsActions.getMeshBotLabels);
        dispatch(actions.setLoggedInUserUuid(userDetails.PK_User));
        if (!page) {
            dispatch(actions.initializeControllersGroup());
            dispatch(actions.initialAccountInfoLoading());
        }
    },

    // TODO: need to refactor, do not make API calls as a sequence (check and refactor)
    login: (name, hash, hashCS) => async (dispatch) => {
        // TODO: move login cleanup function outside
        dispatch(actions.removeUserInfoFromLocalStorage());
        try {
            if (!name || !hash || !hashCS) {
                return;
            }
            // part 1: login actions
            // 1. Disconnect from group, close websockets connections
            await dispatch(EzloGroupActions.disconnectEzloGroup());
            // 2. Initialize login process
            dispatch(actions.startLogin());
            // 3. API call to login user
            const user = await loginUser(name, hash, hashCS);
            const { PK_Account, Expires, PK_Oem, PK_PermissionRole } = getDataFromUserIdentity(user.data.Identity);
            // 4. Save login response payload to localStorage
            const userLoginData = await dispatch(actions.setUser(name, hash, hashCS, user));
            if (isSecurityOrVeraPartnerInstallerUser(PK_Oem, PK_PermissionRole)) {
                const error = getPreventPartnerInstallerLoginError();
                dispatch(actions.handleLoginActionError({ error, name, hash, hashCS }));

                return;
            }

            const [userDetails] = await Promise.all([
                // 5. API call to get user info and save to redux storage
                dispatch(actions.setCompleteUserDetails(userLoginData)),
                // 5. API call to get cloud token and save to localStorage
                getSetCloudToken(),
                // 5. API call to get partner-uuid from kvs and save to redux storage (supportData.PartnerUuid)
                dispatch(supportActions.getPartnerUuidData(PK_Account)),
                dispatch(actions.accountPrivacyPolicy),
            ]);
            // 6. Set the markers that authorization is successful
            await dispatch(actions.logged());
            // 7. Run application's initialization requests
            await dispatch(actions.runAppInitializationRequests(userDetails, LOGIN_TYPES.WITH_CREDENTIALS));

            dispatch(runTokensFlow(Expires));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(actions.handleLoginActionError({ error, name, hash, hashCS }));
            } else {
                bugsnagNotify(error, { name, hash, hashCS });
                dispatch(actions.handleLoginActionError({ error, name, hash, hashCS }));
            }
        }
    },

    checkInstallerAccess: async (dispatch) => {
        const installerAccessStatus = await getInstallerAccessRightsStatus();
        const installerActionRightsDeadlineValue = await dispatch(
            installerActions.getInstallerAccessRightsDeadlineData(false),
        );
        const currentDateAndTime = moment();
        const isAccessEnabled = compareDateTimes(currentDateAndTime, installerActionRightsDeadlineValue);
        dispatch(setIsInstallerLoggedInAsResident(true));
        if (installerAccessStatus === ZERO_STRING || !isAccessEnabled) {
            dispatch(actions.logout());

            return false;
        } else {
            return true;
        }
    },

    loginUserWithToken: (token) => async (dispatch) => {
        try {
            dispatch(actions.removeUserInfoFromLocalStorage());
            // part 1: login actions
            // 1. Disconnect from group, close websockets connections
            await dispatch(EzloGroupActions.disconnectEzloGroup());
            // 2. Initialize login process
            dispatch(actions.startLogin());
            // 3. API call to login user
            const getUserDataWithToken = await setBounceGetRequest(setBounceGetParams(token));
            const userAuthData = getUserAuthData(getUserDataWithToken);
            updateLang(userAuthData?.data?.language);
            // Do not remove below commented code it may need in future related to ENWT-4042
            // const { PK_Account, PK_AccountChild, PK_Oem } = tokenParser(userAuthData?.data?.Identity);
            const { PK_Account } = tokenParser(userAuthData?.data?.Identity);
            // 1. Save login response payload to localStorage
            const userLoginData = await dispatch(actions.setUser(null, null, null, userAuthData));
            // 2. API call to get user info and save to redux storage
            const [userDetails] = await Promise.all([
                dispatch(actions.setCompleteUserDetails(userLoginData)),
                // 3. API call to get partner-uuid from kvs and save to redux storage (supportData.PartnerUuid)
                dispatch(supportActions.getPartnerUuidData(PK_Account)),
                // 4. API call to get cloud token and save to localStorage
                getSetCloudToken(),
            ]);
            // Do not remove below commented code it may need in future related to ENWT-4042
            // let shouldProceedWithLoginActions = true;
            // if (PK_AccountChild && PK_Oem === SECURITY_OEM) {
            //     shouldProceedWithLoginActions = await dispatch(actions.checkInstallerAccess);
            // }

            // if (!shouldProceedWithLoginActions) {
            //     navigate(ACCESS_REVOKED);

            //     return;
            // }
            await dispatch(actions.logged());
            await dispatch(
                actions.runAppInitializationRequests(userDetails, LOGIN_TYPES.WITH_TOKEN, userAuthData.data.page),
            );
        } catch (error) {
            bugsnagNotify(error);
            dispatch(actions.loginCredentialsError(error));
        }
    },

    // TODO: combine with other login actions?
    loginUserWithItarianToken: (token) => async (dispatch) => {
        try {
            dispatch(actions.removeUserInfoFromLocalStorage());
            localStorage.removeItem(localStorageKeys.USER_TYPE);
            // part 1: login actions
            // 1. Disconnect from group, close websockets connections
            await dispatch(EzloGroupActions.disconnectEzloGroup());
            // 2. Initialize login process
            dispatch(actions.startLogin());
            // 3. API call to login user
            const userAuthData = await itarianService.getUserAuthDataByToken(token);
            localStorage.setItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE, ITARIAN_SSO_TYPE);
            // 4. Save login response payload to localStorage
            const userLoginData = await dispatch(actions.setUser(null, null, null, userAuthData));

            const [userDetails] = await Promise.all([
                // 5. API call to get user info and save to redux storage
                dispatch(actions.setCompleteUserDetails(userLoginData)),
                // 5. Save cloud token to localStorage
                dispatch(actions.setCloudToken(userAuthData.data.token)),
            ]);
            // 6. Set the markers that authorization is successful
            await dispatch(actions.logged());
            // 7. Run application's initialization requests
            await dispatch(actions.runAppInitializationRequests(userDetails, LOGIN_TYPES.WITH_ITARIAN_TOKEN));
        } catch (error) {
            bugsnagNotify(error);
            dispatch(actions.handleLoginActionError({ error }));
        }
    },
    /**
     * Redux hunk that returns the first page path after login
     * @param {Function} dispatch - dispatch from redux
     * @param {Function} getState - getState from redux
     * @return {string} path of the first page path after login. Default dashboard page path.
     * @example dispatch(getPathOfFirstPageAfterLogin);
     * */
    getPathOfFirstPageAfterLogin: (dispatch, getState) => {
        const {
            ezloCustomization,
            account: { isCustomizationProgressing },
        } = getState();
        const customizationMenu = ezloCustomization.menuBar;
        const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
        const pageValue = localStorage.getItem(PAGE_VALUE);

        if (pageValue) {
            return getPathOfFirstPageByPageValue(pageValue);
        }

        if (customizationMenu?.length > 0) {
            return (
                getPathOfFirstOpenPageByCustomization(customizationMenu) ||
                PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN[MENU_ITEMS_IDS.CUSTOMIZATION]
            );
        }

        if (ssoType) {
            return getPathOfFirstPageBySso(ssoType);
        }

        if (!isCustomizationProgressing) {
            return EZLO_DASHBOARD;
        }
    },

    disconnectNMA: () =>
        new Promise(() => {
            return wsm.closeAll();
        }),
    locationChange: (location) => (dispatch) => {
        dispatch({ type: LOCATION_CHANGE, payload: { location } });
    },

    //Hidden for task 3085 called "Remove grouping of hubs on UI".
    // initializeControllersGroup: () => async (dispatch) => {
    //     try {
    //         const defaultGroup = await dispatch(GroupsActions.registerDefaultControllersGroup());
    //         // 8. Prepare group info
    //         if (defaultGroup) {
    //             // 8.1 Select default group
    //             await dispatch(EzloGroupActions.selectGroup(defaultGroup));
    //         } else {
    //             // 8.2 Default group does/could not exist, try to wrap any of existing controller as group
    //             await dispatch(EzloGroupActions.initializeDefaultGroupCompatibleMode());
    //         }
    //     } catch (err) {
    //         bugsnagNotify(err);
    //         dispatch(EzloActions.receiveEzlos([])); // clear array of ezlos
    //     }
    // },

    // The method changed for task 3085 called "Remove grouping of hubs on UI".
    initializeControllersGroup: () => async (dispatch) => {
        try {
            // 8.Emulate a group for all account controllers
            await dispatch(EzloGroupActions.initializeDefaultGroupCompatibleMode());
        } catch (err) {
            bugsnagNotify(err);
            dispatch(EzloActions.receiveEzlos([])); // clear array of ezlos
        }
    },

    handleLoginActionError:
        ({ error, name }) =>
        async (dispatch) => {
            await dispatch(actions.logout());
            if (error?.response?.data?.hasOwnProperty(TOO_MANY_LOGINS)) {
                dispatch(actions.showError(EZLOGIC_TITLE_TOO_MANY_LOGINS, EZLOGIC_TITLE_DO_NOT_GO_THROUGH));
            } else if (error && error.message === API_ERROR_MESSAGES.INVALID_CREDENTIALS) {
                // not valid credentials
                dispatch(actions.loginCredentialsError());
            } else if (error && error.data === 'nma.user.notconfirmed.name') {
                // todo: legacy code, need to check
                localStorage.setItem(localStorageKeys.NAME, name);
            } else if (error && error?.data === PARTNER_INSTALLER_LOGIN_ERROR) {
                dispatch(actions.showError(error?.message));
            } else {
                dispatch(actions.showError(EZLOGIC_TITLE_CONNECTION_ISSUE_TRY_AGAIN, EZLOGIC_TITLE_DO_NOT_GO_THROUGH));
            }
        },

    removeUserInfoFromLocalStorage: () => () => {
        localStorage.removeItem(localStorageKeys.PAGE_VALUE);
        localStorage.removeItem(localStorageKeys.PK_User);
        localStorage.removeItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
        localStorage.removeItem(localStorageKeys.USER_TYPE);
        localStorage.removeItem(localStorageKeys.NAME);
        localStorage.removeItem(localStorageKeys.HASH);
        localStorage.removeItem(localStorageKeys.HASH_CS);
    },

    logout: () => async (dispatch) => {
        dispatch(actions.removeUserInfoFromLocalStorage());

        // TODO: we must be declarative, move partner related logic as a separate action outside:
        const partnerUuid = Boolean(localStorage.getItem(localStorageKeys.DOMAIN_PARTNER_UUID));

        dispatch({
            type: types.LOGOUT,
        });

        if (partnerUuid === false) {
            dispatch({
                type: types.LOGOUT_RESET,
            });
        }

        dispatch(EzloGroupActions.disconnectEzloGroup());
    },

    setUser: (name, hash, hashCS, response) => () =>
        new Promise((resolve, reject) => {
            localStorage.setItem(localStorageKeys.NAME, name);
            localStorage.setItem(localStorageKeys.HASH, hash);
            localStorage.setItem(localStorageKeys.HASH_CS, hashCS);
            localStorage.setItem(localStorageKeys.IS_FIRST_LOAD_INTEGRATIONS, TRUE);
            localStorage.setItem(localStorageKeys.IS_FIRST_LOAD_NEW_INTEGRATIONS, TRUE);

            if (response) {
                const { data } = response;
                localStorage.setItem(localStorageKeys.IDENTITY, data.Identity);
                localStorage.setItem(localStorageKeys.IDENTITY_SIGNATURE, data.IdentitySignature);
                localStorage.setItem(localStorageKeys.SERVER_ACCOUNT, data.Server_Account);
                localStorage.setItem(localStorageKeys.SERVER_ACCOUNT_ALT, data.Server_Account_Alt);
                localStorage.setItem(localStorageKeys.PK_User, data.PK_User ? data.PK_User : '');

                resolve(data);
            } else {
                reject();
            }
        }),

    getUser: (data) => () =>
        new Promise((resolve, reject) => {
            if (data) {
                const userData = tokenParser(data.Identity);
                const { PK_Account, PK_AccountChild } = userData;
                const PK_User = data.PK_User ? data.PK_User : userData.PK_User;

                apiGetUser(PK_User)
                    .then((res) => {
                        resolve({
                            PK_User,
                            ...res.data,
                            serial: PK_AccountChild || PK_Account,
                        });
                    })
                    .catch((err) => {
                        bugsnagNotify(err, { data });
                        reject(err);
                    });
            } else {
                reject();
            }
        }),

    getCustomizationForChild: () => async (dispatch) => {
        const { PK_Account, PK_AccountChild } = tokenParser(localStorage.getItem(localStorageKeys.IDENTITY));

        if (PK_AccountChild && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY && PK_Account) {
            try {
                await dispatch(EzloCustomizationAction.getCustomization());
            } catch (err) {
                bugsnagNotify(err);
            }
        }
    },

    setCloudToken: (token) => async () => {
        localStorage.setItem(localStorageKeys.CLOUD_TOKEN, token);

        return token;
    },

    setReconnectStatus: (bool) => ({
        type: bool ? types.START_RECONNECT : types.STOP_RECONNECT,
    }),

    setCheckConnectionStatus: (bool) => ({
        type: bool ? types.START_CHECK_CONNECTION : types.STOP_CHECK_CONNECTION,
    }),

    setPollingStatus: (bool) => ({
        type: bool ? types.START_POLLING : types.STOP_POLLING,
    }),

    isConnectionOK: () => () => {
        return new Promise((resolve, reject) => {
            if (!navigator.onLine) {
                reject();
            }

            const xhr = new XMLHttpRequest();
            const link = '//api.ipify.org/?nocache=' + Date.now();
            xhr.open('HEAD', link, true);
            xhr.setRequestHeader('Access-Control-Allow-Origin', link);
            xhr.addEventListener('load', () => {
                if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
                    resolve();
                } else {
                    reject();
                }
            });
            xhr.addEventListener('error', () => {
                reject();
            });
            xhr.send();
        });
    },

    checkDisconnect: () => (dispatch) => {
        dispatch(actions.setPollingStatus(true));
    },

    showNotification: (message, messageType = 'success') => {
        if (!message) {
            return {
                type: types.HIDE_NOTIFICATION,
            };
        }

        return {
            type: types.SHOW_NOTIFICATION,
            message,
            messageType,
        };
    },

    hideNotification: () => ({
        type: types.HIDE_NOTIFICATION,
    }),

    showError: (error = 'Unexpected error', errorTitle) => {
        if (error && error.hasOwnProperty('stack')) {
            error = error.stack;
        }

        return {
            type: types.SHOW_ERROR,
            errorMessage: typeof error === 'string' ? error : t(error.data),
            errorTitle,
        };
    },

    hideError: () => ({
        type: types.HIDE_ERROR,
    }),

    setInfoOfLoggedUser: (user) => ({
        type: types.SET_INFO_OF_LOGGED_USER,
        user: user,
    }),
    setIsCustomizationProgressing: (value) => ({
        type: types.SET_IS_CUSTOMIZATION_PROGRESSING,
        value,
    }),
    logged: () => ({
        type: types.ACCOUNT_LOGGED,
    }),

    loginCredentialsError: () => ({
        type: types.LOGIN_CREDENTIALS_ERROR,
    }),

    startLogin: () => ({
        type: types.LOGIN,
    }),

    register: (name, email, pass, setOpen, setIsLoading) => async (dispatch) => {
        const partnerUuid = localStorage.getItem(localStorageKeys.DOMAIN_PARTNER_UUID);
        try {
            if (!name || !email || !pass) {
                return;
            }
            setIsLoading(true);
            if (partnerUuid?.length) {
                await dispatch(actions.registerPartnerUser(name, email, pass, setOpen, partnerUuid));
            } else {
                await dispatch(actions.registerUser(name, email, pass, setOpen));
            }
        } catch (error) {
            bugsnagNotify(error, { name, email, pass, setOpen, setIsLoading });
            if (
                (error && error.message === API_ERROR_MESSAGES.INVALID_CREDENTIALS) ||
                API_ERROR_MESSAGES.STATUS_CODE_403
            ) {
                let errorMessage = 'It’s possible there are some connection issues. Please try again.';
                if (error?.response?.data === 'ERROR:Username already exists') {
                    errorMessage = 'Username already exists.';
                }

                if (error?.response?.data === 'ERROR:Email already exists') {
                    errorMessage = 'Email already exists.';
                }

                if (error?.data?.status === ZERO_INT) {
                    errorMessage = error?.data?.data?.error_message;
                }
                dispatch(actions.showError(errorMessage, 'Sorry, that didn’t go through'));
            } else {
                const errorMessage = 'It’s possible there are some connection issues. Please try again.';
                dispatch(actions.showError(errorMessage, 'Sorry, that didn’t go through'));
            }
        } finally {
            setIsLoading(false);
        }
    },
    registerUser: (name, email, pass, setOpen) => () =>
        new Promise((resolve, reject) => {
            apiRegister(name, email, pass)
                .then((res) => {
                    if (res?.data?.status === ZERO_INT) {
                        return reject(res);
                    }
                    setOpen(true);
                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotify(err, { name, email, pass, setOpen });
                    reject(err);
                });
        }),

    registerPartnerUser: (name, email, pass, setOpen, partnerUuid) => () =>
        new Promise((resolve, reject) => {
            apiRegisterForPartnerUser(name, email, pass, partnerUuid)
                .then((res) => {
                    if (res?.data?.status === ZERO_INT) {
                        return reject(res);
                    }
                    setOpen(true);
                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotify(err, { name, email, pass, setOpen });
                    reject(err);
                });
        }),

    forgotPassword: (email, setOpen, setIsLoading, t) => async (dispatch) => {
        try {
            if (!email) {
                return;
            }
            setIsLoading(true);
            await dispatch(actions.resetUserPassword(email, setOpen));
        } catch (error) {
            bugsnagNotify(error, { email, setOpen, setIsLoading });
            if (error && error.message === API_ERROR_MESSAGES.INVALID_CREDENTIALS) {
                const errorMessage = t(EZLOGIC_TITLE_INCORRECT_EMAIL);
                dispatch(actions.showError(errorMessage, t(EZLOGIC_TITLE_DO_NOT_GO_THROUGH)));
            } else {
                const errorMessage = t(EZLOGIC_TITLE_CONNECTION_ISSUE_TRY_AGAIN);
                dispatch(actions.showError(errorMessage, t(EZLOGIC_TITLE_DO_NOT_GO_THROUGH)));
            }
        } finally {
            setIsLoading(false);
        }
    },

    resetUserPassword: (email, setOpen) => () =>
        new Promise((resolve, reject) => {
            apiForgotPassword(email)
                .then((res) => {
                    setOpen(true);
                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotify(err, { email, setOpen });
                    reject(err);
                });
        }),

    setForgotMessage:
        (forgotMessage = '') =>
        (dispatch) => {
            dispatch({
                type: types.SET_FORGOT_MSG_FOR_LOGIN_PAGE,
                forgotMessage,
            });
        },

    subscribeWSmessage: (serial, message, cb) => () => {
        wsm.subscribe(serial, message, (data) => cb(data.result));
    },

    unsubscribeWSmessage: (serial, message, cb) => () => {
        wsm.unsubscribe(serial, message, cb);
    },

    appLoading: (bool) => ({
        type: bool ? types.APP_LOADING : types.APP_LOADED,
    }),

    showAboutPopup: () => ({
        type: types.SHOW_ABOUT_POPUP,
    }),

    hideAboutPopup: () => ({
        type: types.HIDE_ABOUT_POPUP,
    }),

    showSelectUIPopup: (movedToThisSerial) => ({
        type: types.SHOW_SELECT_UI_POPUP,
        movedToThisSerial,
    }),

    hideSelectUIPopup: () => ({
        type: types.HIDE_SELECT_UI_POPUP,
    }),

    setLineLoading: (payload) => ({
        type: types.SET_LINE_LOADING,
        payload,
    }),
};

export default actions;
