import { log } from '../../logging';
import { EVENTS, HUB_COMMAND, SEVERITY_TEXT, STATUSES } from '../../constants';
import { getDynamicWSLogAttributes } from '../../utils/getDynamicWSLogAttributes';
import { withSafelyExecute } from '../../utils/withSafelyExecute';

/**
 * Handles the successful completion of a WebSocket operation.
 *
 * @param {Object} span - The OpenTelemetry span associated with the operation.
 * @param {Object} response - The response data from the WebSocket operation.
 * @param {Object} payload - The payload data used in the WebSocket operation.
 * @param {string} payload.serial - The serial number of the hub.
 * @param {string} payload.method - The WebSocket method called.
 * @param {Object} payload.params - The parameters used in the WebSocket method.
 */
function otlWsSuccess(span, response, { serial, method, params }) {
    log(`Received WS ${method} response`, SEVERITY_TEXT.INFO, {
        hub_serial: serial,
        api: method,
        status: STATUSES.OK,
        type: HUB_COMMAND,
        ...getDynamicWSLogAttributes({ data: response, serial, params }),
    });

    span.addEvent(EVENTS.WS_SEND_RESPONSE_RECEIVED);
    span.end();
}

export default withSafelyExecute(otlWsSuccess);
